import { useScheduleEditorState } from "../../contexts/ScheduleEditorContext"
import ScheduleEditorWarning from "../shiftEditor/ScheduleEditorWarning"

const ScheduleWarnings = () => {
    const editorState = useScheduleEditorState()

    return <ScheduleEditorWarning hasNewShiftMutations={editorState.hasNewShiftMutations} hasNewLocalityMutations={editorState.hasNewLocalityMutations} />
}

export default ScheduleWarnings
