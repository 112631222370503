import { FC } from "react"
import { Badge } from "react-bootstrap"
import { useIsAdmin } from "../../contexts/UserSettingsContext"
import DateNoteType, { NotesType } from "../../types/DateNoteType"

interface DateNotesProps {
    notes: NotesType
    setEditingDateNote?: (editingNote: DateNoteType) => void
}

const DateNotes: FC<DateNotesProps> = ({ notes, setEditingDateNote }) => {
    const isAdmin = useIsAdmin()

    if (!notes) {
        return
    }

    return (
        <div>
            <div>
                {notes.holidayNotes.map((note, i) => (
                    <div key={i}>
                        <Badge key={i} bg="secondary-light" text="dark" className="mb-2" data-cy="holidayNote">
                            <i className="text-small text-bold fa-solid fa-calendar-days me-2 note-calendar" />
                            {note.text}
                        </Badge>
                    </div>
                ))}
            </div>
            <div>
                {notes.dateNotes.map((note, i) => (
                    <div key={i}>
                        <Badge
                            bg="info-light"
                            text="dark"
                            className={"mb-2" + (isAdmin && setEditingDateNote ? " clickable" : "")}
                            onClick={() => (setEditingDateNote ? setEditingDateNote(note) : null)}
                            data-cy="dateNote"
                        >
                            <i className="text-small text-bold fa-solid fa-thumbtack me-2 date-note-pin" />
                            {note.text}
                        </Badge>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DateNotes
