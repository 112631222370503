import { createContext, FC, ReactNode, useContext, useMemo } from "react"
import { useQuery } from "react-query"
import { getStatus } from "../services/Status"
import { useIsAdmin } from "./UserSettingsContext"

interface StatusContextProps {
    online: number[]
}

const contextValue = {
    online: [],
}

export const StatusContext = createContext<StatusContextProps>(contextValue)

interface Props {
    children: ReactNode
}

export const StatusProvider: FC<Props> = ({ children }) => {
    const isAdmin = useIsAdmin()

    const { data } = useQuery(
        ["status"],
        () => {
            return new Promise<StatusContextProps>((resolve) => {
                getStatus().then((response) => resolve(response.data))
            })
        },
        {
            refetchInterval: 30000,
            enabled: !!isAdmin,
        }
    )

    const value = useMemo(() => (data ? data : { online: [] }), [data])

    return <StatusContext.Provider value={value} children={children} />
}

export const StatusConsumer = StatusContext.Consumer

export const useStatus = () => {
    return useContext(StatusContext)
}

export const useOnlineAdmins = () => {
    const status = useContext(StatusContext)
    return status ? status.online : []
}
