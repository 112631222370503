import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { loadShiftTemplate } from "../../services/ShiftTemplate"
import EditShiftTemplatePage from "./EditShiftTemplateDetailsPage"

export default function UpdateShiftTemplateDetailsPage() {
    const { id } = useParams()
    const { data: shiftTemplate } = useQuery(["ShiftTemplate", id], loadShiftTemplate(parseInt(id!)))

    return <EditShiftTemplatePage mode="Update" shiftTemplate={shiftTemplate} />
}
