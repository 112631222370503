import { FC, useMemo } from "react"
import { Form } from "react-bootstrap"
import { UseFormRegister, UseFormWatch } from "react-hook-form"
import { useUsersForSelectedLocation } from "../../../contexts/UserSettingsContext"
import ShiftImportMappingScore from "./ShiftImportMappingScore"
import { ShiftImportUsersInput } from "./ShiftImportUsers"

interface Props {
    index: number
    register: UseFormRegister<ShiftImportUsersInput>
    watch: UseFormWatch<ShiftImportUsersInput>
}

const ShiftImportUserMapping: FC<Props> = ({ index, register, watch }) => {
    const users = useUsersForSelectedLocation()

    const externalUsername = watch(`userMappings.${index}.externalUsername`)
    const suggestedUser = watch(`userMappings.${index}.suggestedUser`)
    const suggestedUserScore = watch(`userMappings.${index}.suggestedUserScore`)
    const selectedUser = watch(`userMappings.${index}.selectedUser`)

    const isMatchingSuggestedUser = useMemo(() => suggestedUser !== "new" && selectedUser === suggestedUser, [suggestedUser, selectedUser])

    return (
        <tr>
            <td className="p-1 fs-small">{externalUsername}</td>
            <td className="p-1">
                <Form.Select {...register(`userMappings.${index}.selectedUser`)}>
                    <option value="new">Maak nieuw...</option>
                    <option value="ignore">Sla over...</option>
                    {users.map(({ id, firstName, lastName }) => (
                        <option key={id.toString()} value={id.toString()}>
                            {firstName} {lastName}
                        </option>
                    ))}
                </Form.Select>
            </td>
            <td className="p-1">{isMatchingSuggestedUser ? <ShiftImportMappingScore score={suggestedUserScore * 50} /> : null}</td>
        </tr>
    )
}

export default ShiftImportUserMapping
