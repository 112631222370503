import { FC } from "react"
import { getBorderLeft } from "../../helpers/BorderHelper"

interface Props {
    startSlot: number
    endSlot: number
    order: number
}

const EditorFilling: FC<Props> = ({ startSlot, endSlot, order }) => {
    const cells = []
    for (let i = startSlot; i <= endSlot; i++) {
        cells.push(
            <div
                key={i}
                className="editor-cell"
                style={{
                    backgroundColor: "transparent",
                    borderLeft: getBorderLeft(i, null, false),
                    cursor: "default",
                }}
            />
        )
    }
    return (
        <div style={{ display: "flex", flex: "1 1 auto", order: order }}>
            <div className="editor-cell-title" />
            {cells}
        </div>
    )
}

export default EditorFilling
