import { FC, useCallback, useMemo } from "react"
import Select, { CSSObjectWithLabel, MultiValueProps, OptionProps, StylesConfig, Theme } from "react-select"
import ClearIndicator from "../select/ClearIndicator"
import DropdownIndicator from "../select/DropdownIndicator"
import MultiValueRemove from "../select/MultiValueRemove"
import { TableFilterValue } from "./Table"
import { FilterSelectOption } from "./TableFilters"

interface Props {
    id: string
    label: string
    values: TableFilterValue[]
    placeholder: string
    options: FilterSelectOption[]
    putFilterValue: (value: TableFilterValue) => void
    isMulti: boolean
}

interface SelectValue {
    label: string
    value: string
}

const styles: StylesConfig = {
    clearIndicator: (styles: CSSObjectWithLabel) => ({ ...styles, color: "#0d6efd", cursor: "pointer", padding: "0.25rem", fontSize: "80%" }),
    dropdownIndicator: (styles: CSSObjectWithLabel) => ({ ...styles, color: "#0d6efd", cursor: "pointer", padding: "0.25rem", fontSize: "80%" }),
    indicatorsContainer: (styles: CSSObjectWithLabel) => ({ ...styles, paddingRight: "1rem" }),
    indicatorSeparator: (styles: CSSObjectWithLabel) => ({ ...styles, backgroundColor: "transparent" }),
    control: (styles: CSSObjectWithLabel) => ({
        ...styles,
        backgroundColor: "rgba(55, 125, 255, 0.1)",
        border: 0,
        boxShadow: "none",
        padding: "5px 0",
    }),
    option: (styles: CSSObjectWithLabel, { isFocused }: OptionProps) => {
        return {
            ...styles,
            fontSize: "80%",
            fontWeight: 600,
            cursor: "pointer",
            backgroundColor: isFocused ? "rgba(55, 125, 255, 0.1)" : "transparent",
            color: isFocused ? "#0d6efd" : null,
            lineHeight: "28px",
        } as CSSObjectWithLabel
    },
    input: (styles: CSSObjectWithLabel) => ({ ...styles, fontSize: "80%" }),
    placeholder: (styles: CSSObjectWithLabel) => ({ ...styles, fontSize: "80%", fontWeight: 600 }),
    menu: (styles: CSSObjectWithLabel) => ({ ...styles, boxShadow: "0px 12px 15px rgba(140, 152, 164, 0.1)", overflow: "hidden" }),
    menuList: (styles: CSSObjectWithLabel) => ({ ...styles, border: "none", boxShadow: "none" }),
    multiValue: (styles: CSSObjectWithLabel) => ({ ...styles, backgroundColor: "transparent" }),
    multiValueLabel: (styles: CSSObjectWithLabel) => ({ ...styles, fontWeight: 600 }),
    multiValueRemove: (styles: CSSObjectWithLabel, props: MultiValueProps) => ({ ...styles, backgroundColor: "transparent", color: props.isFocused ? "green" : "#0d6efd" }),
    singleValue: (styles: CSSObjectWithLabel) => ({ ...styles, fontSize: "80%", fontWeight: 600 }),
}

const PickerFilter: FC<Props> = ({ id, label, values, placeholder, options, putFilterValue, isMulti }) => {
    const value = useMemo(() => {
        const stringValue = values.find((v) => v.key === id)?.value
        const idValues = stringValue ? stringValue.split(",") : []
        const value: SelectValue[] = []
        for (const id of idValues) {
            const option = options.find((o) => o.key === id)
            value.push({
                label: option?.title ?? "",
                value: id,
            })
        }
        return value
    }, [values, id, options])

    const onChange = useCallback(
        (newValue: unknown) => {
            if (!newValue) {
                putFilterValue({ key: id, value: "" })
            } else if (isMulti && Array.isArray(newValue)) {
                const v = newValue as SelectValue[]
                putFilterValue({ key: id, value: v.map((v) => v.value).join() })
            } else if (!Array.isArray(newValue)) {
                const v = newValue as SelectValue
                putFilterValue({ key: id, value: v.value })
            }
        },
        [putFilterValue, id, isMulti]
    )

    return (
        <li>
            <div style={{ width: "300px" }}>
                {label ? <div className="text-bold text-muted mb-2">{label}</div> : null}
                <div className="form-group">
                    <Select
                        data-cy={id}
                        id={id}
                        components={{ ClearIndicator, DropdownIndicator, MultiValueRemove }}
                        isClearable={true}
                        isMulti={isMulti}
                        onChange={onChange}
                        placeholder={placeholder}
                        options={options.map((o) => ({ value: o.key, label: o.title }))}
                        value={value}
                        styles={styles}
                        theme={(theme: Theme) => ({
                            ...theme,
                            borderRadius: 16,
                            colors: {
                                ...theme.colors,
                                primary25: "rgba(55, 125, 255, 0.1)",
                                primary: "black",
                                dangerLight: "transparent",
                                danger: "#0d6efd",
                            },
                        })}
                    />
                </div>
            </div>
        </li>
    )
}

export default PickerFilter
