import { FC, useCallback, useMemo } from "react"
import { UseFormWatch } from "react-hook-form"
import { isEnabled } from "../../../helpers/DaysHelper"
import { CopyModalInputs } from "./CopyModal"
import CopyModalDay from "./CopyModalDay"

interface Props {
    week: number
    weekCycle: number
    enabledDays: string
    watch: UseFormWatch<CopyModalInputs>
    setToDays: (toDays: string[]) => void
}

const CopyModalWeek: FC<Props> = ({ week, weekCycle, enabledDays, watch, setToDays }) => {
    const days = useMemo(() => {
        const days: string[] = []
        for (let i = 0; i < 7; i++) {
            const day = i + week * 7
            if (isEnabled(enabledDays, weekCycle, day, true)) {
                days.push(day.toString())
            }
        }
        return days
    }, [week])

    const toDays = watch("toDays")

    const toggleDay = useCallback(
        (day: string) => {
            const newValues = [...toDays]
            if (newValues.includes(day)) {
                newValues.splice(newValues.indexOf(day), 1)
            } else {
                newValues.push(day)
            }
            setToDays(newValues)
        },
        [setToDays, toDays]
    )

    return (
        <div className="col-6">
            {weekCycle > 1 ? <div className="text-bold mb-2">Week {week + 1}</div> : null}
            {days.map((d) => (
                <div className="my-2" key={d}>
                    <CopyModalDay day={d} watch={watch} toggleDay={toggleDay} />
                </div>
            ))}
        </div>
    )
}

export default CopyModalWeek
