import { useContext, useEffect, useState } from "react"
import { ScheduleEditorContext, useScheduleEditorState } from "../../contexts/ScheduleEditorContext"
import DateSelector, { DateLabelType } from "../DateSelector"
import DateSelectorDays from "./DateSelectorDays"

const ScheduleDateSelector = () => {
    const { updateDate } = useContext(ScheduleEditorContext)
    const editorState = useScheduleEditorState()

    const [date, setDate] = useState<Date>(editorState.date!)

    useEffect(() => {
        updateDate(date)
    }, [date])

    return (
        <div className="selectable-segment">
            <DateSelector selectedDate={date} setSelectedDate={setDate} labelType={DateLabelType.DATE} />
            <DateSelectorDays enabledDays={editorState.location.enabledDays} selectedDate={date} setSelectedDate={setDate} />
        </div>
    )
}

export default ScheduleDateSelector
