import ShiftMutationsPlanType, { CreateShiftMutationsPlanType } from "../types/ShiftMutationsPlanType"
import api from "./Api"

export function createShiftMutationsPlan(shiftMutationsPlan: CreateShiftMutationsPlanType) {
    return api.post<ShiftMutationsPlanType>(`/schedule/plan/`, shiftMutationsPlan)
}

export function getShiftMutationsPlan(id: number) {
    return api.get<ShiftMutationsPlanType>(`/schedule/plan/${id}/`)
}

export function loadShiftMutationsPlan(id: number) {
    return new Promise<ShiftMutationsPlanType>((resolve) => {
        getShiftMutationsPlan(id).then((response) => resolve(response.data))
    })
}
