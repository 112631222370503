import { FC, useContext, useMemo } from "react"
import { OccupationEditorContext, useOccupationEditorState } from "../../contexts/OccupationEditorContext"
import DaySelector from "../editor/DaySelector"

const OccupationEditorDaySelector: FC = ({}) => {
    const { updateDay } = useContext(OccupationEditorContext)
    const editorState = useOccupationEditorState()

    const enabledDays = useMemo(() => editorState.location.enabledDays, [editorState.location])

    const weekCycle = useMemo(() => editorState!.occupationTemplate!.weekCycle, [editorState.occupationTemplate])

    return <DaySelector enabledDays={enabledDays} weekCycle={weekCycle} selectDay={updateDay} />
}

export default OccupationEditorDaySelector
