import { useContext, useEffect, useState } from "react"
import { ScheduleEditorContext, useScheduleEditorState } from "../../contexts/ScheduleEditorContext"
import DaySelector from "../editor/DaySelector"

const ScheduleDaySelector = () => {
    const { updateDay } = useContext(ScheduleEditorContext)
    const editorState = useScheduleEditorState()

    const [day, setDay] = useState<number>(editorState.day!)

    useEffect(() => {
        updateDay(day)
    }, [updateDay, day])

    return <DaySelector enabledDays={editorState.location.enabledDays} weekCycle={editorState.shiftTemplate ? editorState.shiftTemplate.weekCycle : 1} selectDay={setDay} />
}

export default ScheduleDaySelector
