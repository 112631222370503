import { useQuery } from "react-query"
import { getDetailedUser } from "../services/User"
import { UserDetailedType } from "../types/UserType"

const useDetailedUser = (id: number) => {
    return useQuery(
        ["detailedUser", id],
        () =>
            new Promise<UserDetailedType>((resolve) => {
                getDetailedUser(id).then((response) => resolve(response.data))
            })
    )
}

export default useDetailedUser
