import { FC, useCallback } from "react"
import { Button, Form } from "react-bootstrap"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"
import InfoPopover from "../../../components/InfoPopover"
import { Inputs, UnexusFileRow } from "../EditOccupationTemplateDetailsPage"
import UnexusFileRowView from "./UnexusFileRowView"

interface Props {
    watch: UseFormWatch<Inputs>
    setValue: UseFormSetValue<Inputs>
}

const getNextRowId = (rows: UnexusFileRow[]) => {
    return rows.map((r) => r.id).reduce((a, b) => Math.max(a, b), 0) + 1
}

const UnexusFileRowsView: FC<Props> = ({ watch, setValue }) => {
    const unexusFileRows = watch("unexusFileRows")

    const addRow = useCallback(() => {
        const rows = [...unexusFileRows]
        rows.push({
            id: getNextRowId(rows),
            role: "",
            file: "",
            errors: [],
        })
        setValue("unexusFileRows", rows)
    }, [unexusFileRows, setValue])

    return (
        <Form.Group className="mb-3">
            <Form.Label>Unexusbestanden toevoegen per taak</Form.Label>
            <InfoPopover
                id="unexusFileInfo"
                body="Indien je gebruik maakt van Unexus kun je een export maken van de basisbezetting en deze koppelen aan een taak binnen Rooster Planner. Kies hieronder de desbetreffende taak en selecteer de export binnen je bestanden om deze in te vullen. Het is mogelijk om meerdere taken en bestanden toe te voegen."
            />
            <ul className="list-group">
                {unexusFileRows.map((row, i) => (
                    <UnexusFileRowView key={row.id} rowIndex={i} watch={watch} setValue={setValue} />
                ))}
            </ul>
            <Button type="button" variant="link" onClick={addRow} data-cy="addFileRow">
                Voeg toe
            </Button>
        </Form.Group>
    )
}

export default UnexusFileRowsView
