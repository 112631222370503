import { FC } from "react"
import { getCSSColor, getLightBorderColor } from "../../helpers/ColorHelper"

interface Props {
    order: number
}

const EditorDivider: FC<Props> = ({ order }) => {
    return <div style={{ height: "3px", backgroundColor: getCSSColor(getLightBorderColor()), marginTop: "-3px", order: order }} />
}

export default EditorDivider
