import { ChangeEvent, FC, useCallback } from "react"

interface DaySelectorInlineProps {
    selectedDay: number
    setSelectedDay: (day: number) => void
}

const DaySelectorInline: FC<DaySelectorInlineProps> = ({ selectedDay, setSelectedDay }) => {
    const onChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedDay(parseInt(event.target.value))
    }, [])

    return (
        <select className="form-select form-control form-select-inline" onChange={onChange} value={selectedDay}>
            <option value={0}>Maandag</option>
            <option value={1}>Dinsdag</option>
            <option value={2}>Woensdag</option>
            <option value={3}>Donderdag</option>
            <option value={4}>Vrijdag</option>
            <option value={5}>Zaterdag</option>
            <option value={6}>Zondag</option>
        </select>
    )
}

export default DaySelectorInline
