import { FC } from "react"
import { useInitMode, useIsAuthenticated } from "../../contexts/UserSettingsContext"
import { UserInitMode } from "../../types/UserType"
import Menu from "./Menu"
import styles from "./MenuFixed.module.scss"

const MenuFixed: FC = () => {
    const isAuthenticated = useIsAuthenticated()
    const initMode = useInitMode()

    return isAuthenticated && initMode >= UserInitMode.STARTED_TOUR ? (
        <nav className={`d-none d-lg-block mt-4 ${styles.menuFixed}`}>
            <Menu />
        </nav>
    ) : null
}

export default MenuFixed
