import { FC, useCallback, useMemo } from "react"
import DatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker"
import { dateFromIso, isoPrint } from "../../helpers/DaysHelper"
import DatePickerHeader from "./DatePickerHeader"
import styles from "./DateRangeFilter.module.scss"
import { TableFilterValue } from "./Table"

interface Props {
    id: string
    label: string
    values: TableFilterValue[]
    putFilterValues: (newValues: TableFilterValue[]) => void
}

const DateRangeFilter: FC<Props> = ({ id, label, values, putFilterValues }) => {
    const startDateString = useMemo(() => values.find((v) => v.key === `${id}From`)?.value, [values, id])
    const endDateString = useMemo(() => values.find((v) => v.key === `${id}To`)?.value, [values, id])

    const startDate = useMemo(() => (startDateString ? dateFromIso(startDateString) : undefined), [startDateString])
    const endDate = useMemo(() => (endDateString ? dateFromIso(endDateString) : undefined), [endDateString])

    const onChangeEndDate = useCallback(
        (date: Date | null) => {
            const newValues: TableFilterValue[] = []
            newValues.push({ key: `${id}To`, value: date ? isoPrint(date) : "" })
            if (startDate && date && date < startDate) {
                newValues.push({ key: `${id}From`, value: "" })
            }
            putFilterValues(newValues)
        },
        [id, startDate, putFilterValues]
    )

    const onChangeStartDate = useCallback(
        (date: Date | null) => {
            const newValues: TableFilterValue[] = []
            newValues.push({ key: `${id}From`, value: date ? isoPrint(date) : "" })
            if (endDate && date && date > endDate) {
                newValues.push({ key: `${id}To`, value: "" })
            }
            putFilterValues(newValues)
        },
        [id, putFilterValues, endDate]
    )

    return (
        <li>
            <div style={{ width: "200px" }}>
                <div className="text-bold text-muted mb-2">{label}</div>
                <div className="mb-2">
                    <span className={`text-bold text-muted ${styles.datePickerLabel}`}>van</span>
                    <div className="d-inline-block">
                        <DatePicker
                            id={`${id}Start`}
                            className={styles.dateRangeInput}
                            dateFormat="dd-MM-yyyy"
                            selected={startDate}
                            onChange={onChangeStartDate}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            disabledKeyboardNavigation
                            isClearable={startDate !== null}
                            renderCustomHeader={({ date, decreaseMonth, increaseMonth }: ReactDatePickerCustomHeaderProps) => (
                                <DatePickerHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />
                            )}
                        />
                    </div>
                </div>
                <div>
                    <span className={`text-bold text-muted ${styles.datePickerLabel}`}>tot</span>
                    <div className="d-inline-block">
                        <DatePicker
                            id={`${id}End`}
                            className={styles.dateRangeInput}
                            dateFormat="dd-MM-yyyy"
                            selected={endDate}
                            onChange={onChangeEndDate}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            disabledKeyboardNavigation
                            isClearable={endDate !== null}
                            renderCustomHeader={({ date, decreaseMonth, increaseMonth }: ReactDatePickerCustomHeaderProps) => (
                                <DatePickerHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />
                            )}
                        />
                    </div>
                </div>
            </div>
        </li>
    )
}

export default DateRangeFilter
