import { faChevronLeft, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { ScheduleEditorContext } from "../../../contexts/ScheduleEditorContext"
import { useRolesForSelectedLocation } from "../../../contexts/UserSettingsContext"
import { refetchInterval } from "../../../helpers/QueryHelper"
import { toScheduleMapFromGeneratedMutations } from "../../../helpers/ScheduleMapHelper"
import { createTaskShiftImport, loadTask, TaskShiftImportResult, TaskType } from "../../../services/Task"
import { ShiftImportType } from "../../../types/ShiftImportType"
import Loader from "../../Loader"
import GeneratedMutationsResult from "../breakPlanner/GeneratedMutationsResult"

interface Props {
    shiftImport: ShiftImportType
    goBack: () => void
    onHide: () => void
}

const ShiftImportShifts: FC<Props> = ({ shiftImport, goBack, onHide }) => {
    const { t } = useTranslation()
    const roles = useRolesForSelectedLocation()
    const { applyMutations } = useContext(ScheduleEditorContext)
    const [taskId, setTaskId] = useState<number | null>(null)

    useEffect(() => {
        createTaskShiftImport(shiftImport).then((response) => setTaskId(response.data.id))
    }, [shiftImport, setTaskId])

    const fetchCount = useRef(0)
    const { data: task } = useQuery<TaskType<TaskShiftImportResult>>(["Task", taskId], () => loadTask<TaskShiftImportResult>(taskId!), {
        enabled: !!taskId,
        refetchInterval: refetchInterval(fetchCount),
    })

    const apply = useCallback(() => {
        const scheduleMap = toScheduleMapFromGeneratedMutations(task!.result.mutations)
        applyMutations(scheduleMap)
        onHide()
    }, [task, applyMutations, onHide])

    return (
        <>
            <Modal.Header className="justify-content-between">
                <Modal.Title>
                    <Button type="button" variant="link" onClick={goBack} size="sm">
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                    Verifieer shifts {task?.done ? `(${task.result.mutations.length})` : null}
                </Modal.Title>
                <Button type="button" variant="link" onClick={onHide}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body>
                {task?.done ? (
                    <GeneratedMutationsResult task={task} roles={roles} mode="DATERANGE" weekCycle={1} />
                ) : (
                    <div className="w-100 text-center">
                        <Loader />
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" onClick={apply} disabled={!task?.success}>
                    {t("Buttons.apply")}
                </Button>
            </Modal.Footer>
        </>
    )
}

export default ShiftImportShifts
