import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import { isoPrint } from "../helpers/DaysHelper"
import { getAbsenceRequests } from "../services/AbsenceRequest"
import AbsenceRequestType, { AbsenceRequestHandleStatus, AbsenceRequestReviewStatus } from "../types/AbsenceRequestType"
import { getCSSColor, getGreenColor, getRedColor } from "./ColorHelper"

type AbsenceRequestReviewStatusOption = {
    id: AbsenceRequestReviewStatus
    name: string
    icon?: FontAwesomeIconProps
}

export const reviewStatusOptions: AbsenceRequestReviewStatusOption[] = [
    { id: "TO_BE_REVIEWED", name: "Nog niet beoordeeld" },
    { id: "ACCEPTED", name: "Geaccepteerd", icon: { icon: faCheck, color: getCSSColor(getGreenColor()) } },
    { id: "DENIED", name: "Afgewezen", icon: { icon: faXmark, color: getCSSColor(getRedColor()) } },
]

export const getReviewStatusIcon = (status: AbsenceRequestReviewStatus) => reviewStatusOptions.find((o) => o.id === status)?.icon

type AbsenceRequestHandleStatusOption = {
    id: AbsenceRequestHandleStatus
    name: string
    icon?: FontAwesomeIconProps
}

export const handleStatusOptions: AbsenceRequestHandleStatusOption[] = [
    { id: "TO_BE_HANDLED", name: "Nog niet doorgevoerd" },
    { id: "NOT_NEEDED", name: "Niet nodig", icon: { icon: faCheck, color: getCSSColor(getGreenColor()) } },
    { id: "HANDLED", name: "Rooster aangepast", icon: { icon: faCheck, color: getCSSColor(getGreenColor()) } },
]

export const getHandleStatusIcon = (status: AbsenceRequestHandleStatus) => handleStatusOptions.find((o) => o.id === status)?.icon

export const loadAbsenceRequests = (fromDate: Date, user: number): Promise<AbsenceRequestType[]> => {
    return new Promise((resolve) => {
        if (!fromDate || !user) {
            resolve([])
            return
        }
        getAbsenceRequests({
            pageIndex: 0,
            pageSize: 99,
            filters: [
                {
                    key: "dateFrom",
                    value: isoPrint(fromDate),
                },
                {
                    key: "user",
                    value: user.toString(),
                },
            ],
        }).then((response) => resolve(response.data.items))
    })
}
