import { Button } from "react-bootstrap"
import { TooltipRenderProps } from "react-joyride"
import styles from "./JoyrideTooltip.module.scss"

const JoyRideTooltip = ({ continuous, index, step, primaryProps, tooltipProps, size }: TooltipRenderProps) => {
    return (
        <div {...tooltipProps} className={styles.tooltipBody}>
            <div className={styles.header}>
                <h3 className="mb-2">{step.title}</h3>
                <span className="text-muted text-bold">
                    {index + 1}/{size}
                </span>
            </div>
            <div>{step.content}</div>
            <div className="mt-3 text-end w-100">{continuous && <Button {...primaryProps}>{index + 1 === size ? "Sluiten" : "Volgende"}</Button>}</div>
        </div>
    )
}

export default JoyRideTooltip
