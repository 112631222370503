import { FC, useEffect, useRef } from "react"
import { useQuery } from "react-query"
import { refetchInterval } from "../../helpers/QueryHelper"
import { loadShiftMutationsPlan } from "../../services/ShiftMutationsPlan"
import { loadShiftTemplateMutationsPlan } from "../../services/ShiftTemplateMutationsPlan"
import ShiftMutationsPlanType from "../../types/ShiftMutationsPlanType"
import ShiftTemplateMutationsPlanType from "../../types/ShiftTemplateMutationsPlanType"
import ProgressBar from "../ProgressBar"

interface ShiftPlannerProgressProps {
    plan: ShiftMutationsPlanType | ShiftTemplateMutationsPlanType
    setPlan: (plan: ShiftMutationsPlanType | ShiftTemplateMutationsPlanType) => void
    mode: "DAYS" | "DATERANGE"
}

const ShiftPlannerProgress: FC<ShiftPlannerProgressProps> = ({ plan, setPlan, mode }) => {
    const fetchCount = useRef(0)
    const { data: shiftMutationsPlan } = useQuery<ShiftMutationsPlanType | ShiftTemplateMutationsPlanType>(
        ["ShiftMutationsPlan", "ShiftTemplateMutationsPlan", plan.id],
        () => {
            if (mode === "DATERANGE") {
                return loadShiftMutationsPlan(plan.id)
            } else {
                return loadShiftTemplateMutationsPlan(plan.id)
            }
        },
        {
            initialData: plan,
            refetchInterval: refetchInterval(fetchCount),
        }
    )

    useEffect(() => {
        if (shiftMutationsPlan?.done) {
            setPlan(shiftMutationsPlan)
        }
    }, [shiftMutationsPlan, setPlan])

    return <ProgressBar done={plan.done} progress={plan.progress} />
}

export default ShiftPlannerProgress
