import { faQuestion } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useCallback, useMemo } from "react"
import { useIsAdmin, useLocalityOptionsForSelectedLocation, useResolveLocalityOptionForSelectedLocation } from "../../contexts/UserSettingsContext"
import useLocalityOptionIconMap from "../../hooks/UseLocalityOptionIconMap"
import { LocalityOptionOrInherit } from "../../types/LocalityType"
import styles from "./LocalityButton.module.scss"

interface LocalityButtonProps {
    type: LocalityOptionOrInherit
    setType: (type: LocalityOptionOrInherit) => void
}

const LocalityButton = ({ type, setType }: LocalityButtonProps) => {
    const isAdmin = useIsAdmin()
    const resolveLocalityOption = useResolveLocalityOptionForSelectedLocation()
    const localityOptions = useLocalityOptionsForSelectedLocation()
    const iconMap = useLocalityOptionIconMap()

    const content = useMemo(() => {
        if (!type || type === "INHERIT" || type === "UNKNOWN") {
            return <></>
        }
        const option = resolveLocalityOption(type)
        return <FontAwesomeIcon icon={iconMap.get(option.icon) || faQuestion} />
    }, [type, resolveLocalityOption, iconMap])

    const toggle = useCallback(() => {
        const i = type === "INHERIT" || type === "UNKNOWN" ? 0 : localityOptions.findIndex((o) => o.id === type) + 1
        if (i >= localityOptions.length) {
            setType("UNKNOWN")
        } else {
            setType(localityOptions[i].id)
        }
    }, [type, localityOptions, setType])

    return isAdmin ? (
        <button className={styles.userLocatedButton} onClick={toggle}>
            {content}
        </button>
    ) : (
        <div className={styles.userLocatedButton}>{content}</div>
    )
}

export default LocalityButton
