import { faUserLock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Badge, Dropdown } from "react-bootstrap"
import { useQuery } from "react-query"
import { useOnlineAdmins } from "../contexts/StatusContext"
import { getAdmins } from "../services/Status"
import styles from "./AdminsOnline.module.scss"

export default function AdminsOnline() {
    const onlineAdmins = useOnlineAdmins()

    const { data: admins, isLoading } = useQuery("admins", () => {
        return new Promise<{ id: number; name: string }[]>((resolve) => getAdmins().then((response) => resolve(response.data.admins)))
    })

    if (isLoading) {
        return null
    }

    return (
        <Dropdown align="end">
            <Dropdown.Toggle variant="secondary-menu" data-cy="admins-dropdown-toggle">
                <FontAwesomeIcon icon={faUserLock} />
                {onlineAdmins.length > 1 ? (
                    <Badge className="notifications-badge" style={{ position: "absolute", top: "-2px", right: "-2px" }}>
                        {onlineAdmins.length}
                    </Badge>
                ) : null}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {admins
                    ? admins.map((a) => (
                          <Dropdown.ItemText key={a.id}>
                              <div className={`me-2 ${styles.onlineIndicator} indicator-${onlineAdmins.includes(a.id) ? "online" : "offline"}`} />
                              <span className="text-small">{a.name}</span>
                          </Dropdown.ItemText>
                      ))
                    : null}
            </Dropdown.Menu>
        </Dropdown>
    )
}
