import { useCallback, useMemo } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { loadDetailedUser } from "../../services/User"
import UserEditPage from "./UserEditPage"

export default function UpdateUserPage() {
    const params = useParams()
    const id = useMemo(() => parseInt(params.id!), [params])

    const loadUser = useCallback(() => {
        return loadDetailedUser(id)
    }, [id])

    const { data: user } = useQuery(["User", id], loadUser)

    return <UserEditPage mode="update" user={user} />
}
