import { FC, useMemo } from "react"
import { useCurrentUser, useRoles } from "../../../contexts/UserSettingsContext"
import { StaticShift } from "../../../helpers/ScheduleMapHelper"
import RoleType from "../../../types/RoleType"
import StaticWeekTimeLineItem from "./StaticWeekTimeLineItem"

interface Props {
    shifts: StaticShift[]
}

interface ItemProps {
    key: string
    startSlot: number
    endSlot: number
    role?: RoleType
}

const StaticWeekTimeLineItems: FC<Props> = ({ shifts }) => {
    const { startSlot, endSlot } = useCurrentUser()
    const roles = useRoles()

    const timeLineItems = useMemo(() => {
        const components: ItemProps[] = []
        shifts.forEach((shift, index) => {
            if (index === 0) {
                if (shift.startSlot !== startSlot) {
                    components.push({ key: `${index}_pre`, startSlot, endSlot: shift.startSlot - 1 })
                }
            } else {
                const previousEndSlot = shifts[index - 1].endSlot
                if (shift.startSlot !== previousEndSlot + 1) {
                    const gapStartSlot = previousEndSlot + 1
                    const gapEndSlot = shift.startSlot - 1
                    components.push({ key: `${index}_pre`, startSlot: gapStartSlot, endSlot: gapEndSlot })
                }
            }
            const role = roles.find((r) => r.id === shift.role)
            components.push({ key: `${index}`, startSlot: shift.startSlot, endSlot: shift.endSlot, role })
            if (index === shifts.length - 1 && shift.endSlot !== endSlot) {
                components.push({ key: `${index}_post`, startSlot: shift.endSlot + 1, endSlot })
            }
        })
        return components
    }, [shifts, startSlot, endSlot, roles])

    return timeLineItems.map(({ key, startSlot, endSlot, role }) => <StaticWeekTimeLineItem key={key} startSlot={startSlot} endSlot={endSlot} role={role} />)
}

export default StaticWeekTimeLineItems
