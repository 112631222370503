import { FC, useCallback, useMemo } from "react"
import { Badge } from "react-bootstrap"
import { useCurrentUser } from "../../contexts/UserSettingsContext"
import { onClickFilter } from "../../helpers/FilterHelper"
import DateRangeFilter from "./DateRangeFilter"
import PickerFilter from "./PickerFilter"
import { TableFilterDef, TableFilterValue } from "./Table"
import ToggleFilter from "./ToggleFilter"

export interface FilterSelectOption {
    key: string
    title: string
    parent?: string
    children?: string[]
}

interface Props {
    contextId: string
    filters: TableFilterDef[]
    filterValues: TableFilterValue[]
    setFilterValues: (values: TableFilterValue[]) => void
}

const hasVisibleFilters = (filters: TableFilterDef[]) => {
    return filters.some((f) => f.type !== "hidden")
}

const TableFilters: FC<Props> = ({ contextId, filters, filterValues, setFilterValues }) => {
    const currentUser = useCurrentUser()

    const appliedFilterCount = useMemo(() => {
        const totalFilters = filterValues.filter((v) => v.value !== "").length
        const hiddenFilters = filters.filter((f) => f.type === "hidden").reduce((total, f) => total + f.initialValues.filter((v) => v.value !== "").length || 0, 0)
        return totalFilters - hiddenFilters
    }, [filters, filterValues])

    const userContextId = useMemo(() => `${currentUser?.id}_${contextId}`, [currentUser, contextId])

    const mergeFilterValue = useCallback(
        (filterValues: TableFilterValue[], newValue: TableFilterValue) => {
            const i = filterValues.findIndex((v) => v.key === newValue.key)
            const cacheKey = `${userContextId}_${newValue.key}`
            sessionStorage.setItem(cacheKey, newValue.value || "")
            if (i >= 0) {
                filterValues.splice(i, 1)
            }
            if (newValue.value) {
                filterValues.push(newValue)
            }
        },
        [userContextId]
    )

    const applyFilterValues = useCallback(
        (newValues: TableFilterValue[]) => {
            const newFilterValues = [...filterValues]
            for (let newValue of newValues) {
                mergeFilterValue(newFilterValues, newValue)
            }
            setFilterValues(newFilterValues)
        },
        [filterValues, mergeFilterValue, setFilterValues]
    )

    const applyFilterValue = useCallback((value: TableFilterValue) => applyFilterValues([value]), [applyFilterValues])

    if (!hasVisibleFilters(filters)) {
        return null
    }

    return (
        <div className="filter-panel">
            {filters ? (
                <div className="dropdown d-inline-block">
                    <button className="btn btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-cy="filter-toggle">
                        Filters {appliedFilterCount > 0 ? <Badge className="notifications-badge">{appliedFilterCount}</Badge> : null}
                    </button>
                    <ul className="dropdown-menu filter-list text-start" onClick={onClickFilter}>
                        {filters.map(({ id, label, placeholder, type, options }) => {
                            if (type === "daterange") {
                                return <DateRangeFilter key={id} id={id} label={label!} values={filterValues} putFilterValues={applyFilterValues} />
                            } else if (type === "toggle") {
                                return <ToggleFilter key={id} id={id} label={label!} values={filterValues} putFilterValue={applyFilterValue} />
                            } else if (type === "select") {
                                return (
                                    <PickerFilter
                                        key={id}
                                        id={id}
                                        label={label!}
                                        options={options!}
                                        values={filterValues}
                                        putFilterValue={applyFilterValue}
                                        placeholder={placeholder!}
                                        isMulti={false}
                                    />
                                )
                            } else if (type === "picker") {
                                return (
                                    <PickerFilter
                                        key={id}
                                        id={id}
                                        label={label!}
                                        options={options!}
                                        values={filterValues}
                                        putFilterValue={applyFilterValue}
                                        placeholder={placeholder!}
                                        isMulti={true}
                                    />
                                )
                            } else {
                                return null
                            }
                        })}
                    </ul>
                </div>
            ) : null}
        </div>
    )
}

export default TableFilters
