import { FC } from "react"
import { useFieldArray } from "react-hook-form"
import { useCurrentUser } from "../../contexts/UserSettingsContext"

interface UserArrayProps {
    control: any
    register: any
}

const UserArray: FC<UserArrayProps> = ({ control, register }) => {
    const currentUser = useCurrentUser()
    const { fields, append, remove } = useFieldArray({ control, name: "users" })
    return (
        <>
            <table>
                <thead>
                    <tr>
                        <td className="p-2"></td>
                        <td className="ps-2">
                            <span className="input-label">Voornaam</span>
                        </td>
                        <td className="ps-2">
                            <span className="input-label">Achternaam</span>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="p-2">
                            <span className="text-small text-muted">1</span>
                        </td>
                        <td className="p-2">
                            <input disabled className="form-control" value={currentUser.firstName} />
                        </td>
                        <td className="p-2">
                            <input disabled className="form-control" value={currentUser.lastName} />
                        </td>
                    </tr>

                    {fields.map((item, index) => {
                        return (
                            <tr key={item.id}>
                                <td className="p-2">
                                    <span className="text-small text-muted">{index + 2}</span>
                                </td>
                                <td className="p-2">
                                    <input {...register(`users.${index}.firstName`, { required: true })} data-cy={`users.${index}.firstName`} className="form-control" />
                                </td>
                                <td className="p-2">
                                    <input {...register(`users.${index}.lastName`, { required: true })} data-cy={`users.${index}.lastName`} className="form-control" />
                                </td>
                                <td className="p-2">
                                    <button type="button" className="btn btn-link" onClick={() => remove(index)}>
                                        <i className="fas fa-trash-alt"></i>
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                    append({ firstName: "", lastName: "" })
                }}
            >
                Voeg toe
            </button>
        </>
    )
}

export default UserArray
