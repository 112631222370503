import { HolidaySettingsType } from "../types/HolidaySettingsType"
import api from "./Api"

export function getHolidaySettings(location: number) {
    return api.get<HolidaySettingsType>(`/holiday-settings/${location}/`)
}

export function updateHolidaySettings(data: HolidaySettingsType, location: number) {
    return api.put<void>(`/holiday-settings/${location}/`, data)
}

export function loadHolidaySettings(location: number) {
    return new Promise<HolidaySettingsType>((resolve) => {
        getHolidaySettings(location).then((response) => resolve(response.data))
    })
}
