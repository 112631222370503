import { FC, useMemo } from "react"
import { useColors, useNestedRolesForSelectedLocation } from "../../../../contexts/UserSettingsContext"
import { prettyPrintQuarters } from "../../../../helpers/DaysHelper"
import { NestedRoleItem, getRoleBadge } from "../../../../helpers/RolesHelper"
import { canShowData, getIntervalLabel } from "../../../../helpers/TaskHelper"
import subIcon from "../../../../images/sub-icon.svg"
import { TaskRoleTimeResult, TaskType } from "../../../../services/Task"
import NoData from "../../NoData"
import styles from "../Statistics.module.scss"

interface RoleHoursTableProps {
    task?: TaskType<TaskRoleTimeResult>
}

interface RoleHoursTableDatum {
    roleItem: NestedRoleItem
    total: number
    average: number
    isSubItem: boolean
}

const RoleHoursTable: FC<RoleHoursTableProps> = ({ task }) => {
    const colors = useColors()
    const nestedRoles = useNestedRolesForSelectedLocation()

    const taskResult = useMemo(() => {
        return task?.result
    }, [task])

    const rows = useMemo<RoleHoursTableDatum[]>(() => {
        if (!taskResult) {
            return []
        }

        const result: RoleHoursTableDatum[] = []
        for (const roleItem of nestedRoles) {
            const itemAggregate = taskResult["rolesAggregate"][roleItem.uid]
            if (itemAggregate) {
                result.push({
                    roleItem,
                    total: itemAggregate[0],
                    average: itemAggregate[1],
                    isSubItem: false,
                })
            }
            if (roleItem.subRoles) {
                for (const subItem of roleItem.subRoles) {
                    const subItemAggregate = taskResult["rolesAggregate"][subItem.uid]
                    if (subItemAggregate) {
                        result.push({
                            roleItem: subItem,
                            total: subItemAggregate[0],
                            average: subItemAggregate[1],
                            isSubItem: true && !!itemAggregate, // If parent is not included, don't treat role as subRole
                        })
                    }
                }
            }
        }

        return result
    }, [nestedRoles, taskResult])

    const intervalLabel = useMemo(() => {
        if (!taskResult) {
            return "Gemiddeld"
        }
        return `Gemiddeld per ${getIntervalLabel(taskResult["interval"])}`
    }, [taskResult])

    return canShowData(task?.result) ? (
        <table className={styles.table}>
            <thead>
                <tr>
                    <td className={`${styles.cell} text-small text-muted`}>Taak</td>
                    <td className={`${styles.cell} text-small text-muted`}>Totaal</td>
                    <td className={`${styles.cell} text-small text-muted`}>{intervalLabel}</td>
                </tr>
            </thead>
            <tbody>
                {rows.map(({ roleItem, total, average, isSubItem }) => (
                    <tr key={`${roleItem.uid}_${total}_${average}`}>
                        <td className={styles.cell}>
                            {isSubItem ? <img className="custom-fa-icon me-1" src={subIcon} alt="sub-arrow" /> : null}
                            {getRoleBadge(roleItem, colors)}
                        </td>
                        <td className={`${styles.cell} text-small`}>{prettyPrintQuarters(total)}</td>
                        <td className={`${styles.cell} text-small`}>{prettyPrintQuarters(average)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    ) : (
        <NoData taskResult={task?.result} />
    )
}

export default RoleHoursTable
