import { FC } from "react"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"
import { UserEditInputs } from "../../pages/users/UserEditPage"
import RoleType from "../../types/RoleType"
import UserRoleRow from "./UserRoleRow"

interface Props {
    roles: RoleType[]
    watch: UseFormWatch<UserEditInputs>
    setValue: UseFormSetValue<UserEditInputs>
    disabled: boolean
}

const UserRolesTable: FC<Props> = ({ roles, watch, setValue, disabled }) => {
    return (
        <table className="table mb-3" role="table">
            <tbody>
                {roles.map((r) => (
                    <UserRoleRow key={r.id} role={r} watch={watch} setValue={setValue} disabled={disabled} />
                ))}
            </tbody>
        </table>
    )
}

export default UserRolesTable
