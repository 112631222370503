import { FC } from "react"
import { TableButtonDef } from "./Table"
import TableButton from "./TableButton"
import styles from "./TableButtons.module.scss"

interface Props {
    buttons?: TableButtonDef[]
}

const TableButtons: FC<Props> = ({ buttons }) => {
    if (!buttons) {
        return null
    }

    return (
        <div className={styles.container}>
            {buttons.map(({ name, link, onClick, variant, dataCy }) => (
                <TableButton key={name} name={name} link={link} onClick={onClick} variant={variant} dataCy={dataCy} />
            ))}
        </div>
    )
}

export default TableButtons
