import { FC, useCallback, useMemo } from "react"
import { Popover } from "react-bootstrap"
import { useScheduleEditorState } from "../../../contexts/ScheduleEditorContext"
import { getOccupationData, getOccupationIndicators } from "../../../helpers/OccupationHelper"
import { ScheduleItem } from "../../../helpers/ScheduleMapHelper"
import OccupationFixPopover from "./occupationFixing/OccupationFixPopover"
import OccupationTimeSlot from "./OccupationTimeSlot"

interface Props {
    selectedSlot: number | undefined
    setSelectedSlot: (selectedSlot: number | undefined) => void
    daySchedule: Map<string, ScheduleItem>
}

const OccupationTimeSlots: FC<Props> = ({ selectedSlot, setSelectedSlot, daySchedule }) => {
    const editorState = useScheduleEditorState()
    const { location, nestedRoles, occupations, enabledRoles } = editorState
    const { startSlot, endSlot } = location

    const occupationData = useMemo(
        () => getOccupationData(nestedRoles, enabledRoles, startSlot, endSlot, daySchedule, occupations),
        [nestedRoles, enabledRoles, startSlot, endSlot, daySchedule, occupations]
    )

    const occupationIndicators = useMemo(() => getOccupationIndicators(startSlot, endSlot, occupationData), [startSlot, endSlot, occupationData])

    const slotItems = useMemo(() => {
        const items: number[] = []
        for (let slot = startSlot; slot <= endSlot; slot++) {
            items.push(slot)
        }
        return items
    }, [startSlot, endSlot])

    const closeModal = useCallback(() => setSelectedSlot(undefined), [setSelectedSlot])

    const popover = useMemo(
        () => (
            <Popover id="OccupationFix" style={{ maxWidth: "400px" }}>
                <Popover.Body className="p-0">
                    <OccupationFixPopover selectedSlot={selectedSlot} occupationData={occupationData} closeModal={closeModal} />
                </Popover.Body>
            </Popover>
        ),
        [selectedSlot, occupationData]
    )

    return (
        <div className="editor-occupations">
            <div className="editor-cell-title" />
            {slotItems.map((slot) => (
                <OccupationTimeSlot
                    key={slot}
                    slot={slot}
                    startSlot={startSlot}
                    selectedSlot={selectedSlot}
                    occupationIndicators={occupationIndicators}
                    setSelectedSlot={setSelectedSlot}
                    popover={popover}
                />
            ))}
        </div>
    )
}

export default OccupationTimeSlots
