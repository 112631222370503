import { FC } from "react"
import { Badge } from "react-bootstrap"

interface ScheduleEditorWarningProps {
    hasNewShiftMutations: boolean
    hasNewLocalityMutations: boolean
}

const ScheduleEditorWarning: FC<ScheduleEditorWarningProps> = ({ hasNewShiftMutations, hasNewLocalityMutations }) => {
    if (!hasNewShiftMutations && !hasNewLocalityMutations) {
        return null
    }

    return (
        <div data-cy="unsavedChanges">
            <Badge bg="secondary-light" text="dark" className="mb-2">
                <i className="fas fa-exclamation-circle me-2"></i>Er zijn onopgeslagen wijzigingen
            </Badge>
        </div>
    )
}

export default ScheduleEditorWarning
