import { FC } from "react"
import LocationType from "../../types/LocationType"

interface Props {
    location: LocationType
    selected: boolean
    disabled?: boolean
    handleLocationChange: (location: number) => void
}

const UserLocationRow: FC<Props> = ({ location, selected, disabled, handleLocationChange }) => {
    const id = "location-" + location.id
    return (
        <tr role="row">
            <td role="cell" style={{ width: "300px", height: "2rem" }}>
                <label className={"toggle-switch d-flex align-items-center" + (disabled ? " toggle-switch-disabled" : "")} htmlFor={id}>
                    <input type="checkbox" className="toggle-switch-input" id={id} checked={selected} onChange={() => handleLocationChange(location.id)} disabled={disabled} />
                    <span className="toggle-switch-label">
                        <span className="toggle-switch-indicator"></span>
                    </span>
                    <span className="toggle-switch-content">
                        <span className={"d-block ms-3" + (disabled ? " text-muted" : "")} style={{ padding: "0.5rem" }}>
                            {location.name}
                        </span>
                    </span>
                </label>
            </td>
        </tr>
    )
}

export default UserLocationRow
