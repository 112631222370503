import { FC, Fragment, ReactElement } from "react"
import { getBorderLeft } from "../../helpers/BorderHelper"
import { constructRoleMap } from "../../helpers/RolesHelper"
import { ScheduleItem } from "../../helpers/ScheduleMapHelper"
import RoleType from "../../types/RoleType"

interface OccupationTotalsProps {
    startSlot: number
    endSlot: number
    daySchedule: Map<string, ScheduleItem>
    roles: RoleType[]
    enabledRoles: number[]
}

const OccupationTotals: FC<OccupationTotalsProps> = ({ startSlot, endSlot, daySchedule, roles, enabledRoles }) => {
    const roleMap = constructRoleMap(roles)

    let occupationMap: number[] = []
    for (var i = 0; i <= endSlot - startSlot; i++) {
        occupationMap.push(0)
    }

    daySchedule.forEach((scheduleItem) => {
        scheduleItem.shifts.forEach((userShift) => {
            for (let i = userShift.startSlot; i <= userShift.endSlot; i++) {
                const roleId = userShift.role
                if (roleId === 0 || roleId === null || enabledRoles.indexOf(roleId) === -1 || !roleMap.has(roleId.toString()) || roleMap.get(roleId.toString())!.isAbsence) {
                    continue
                }

                occupationMap[i - startSlot] += 1
            }
        })
    })

    let comps: ReactElement[] = []
    for (let slot = startSlot; slot <= endSlot; slot++) {
        comps.push(
            <div
                key={slot}
                className="editor-head-cell text-center"
                style={{
                    borderLeft: getBorderLeft(slot, null, false),
                }}
            >
                <span data-cy="occupationCount" className="text-bold text-muted occupation-count">
                    {occupationMap[slot - startSlot]}
                </span>
            </div>
        )
    }
    return <Fragment>{comps}</Fragment>
}

export default OccupationTotals
