import { ChangeEvent, FC } from "react"
import subIcon from "../../images/sub-icon.svg"
import styles from "./MultiSelectFilter.module.scss"
import { FilterSelectOption } from "./TableFilters"

interface MultiSelectFilterProps {
    options: FilterSelectOption[]
    values: string[]
    putFilterValue: (values: string[]) => void
    toggleAllEnabled: boolean
    autoUpdateParent: boolean
}

const MultiSelectFilter: FC<MultiSelectFilterProps> = ({ options, values, putFilterValue, toggleAllEnabled, autoUpdateParent }) => {
    const handleChange = (event: ChangeEvent) => {
        const target = event.target as HTMLInputElement
        let newValues = [...values]

        const optionKey = target.id
        const option = options.find((o) => o.key === optionKey)!
        const parentOption = option.parent ? options.find((o) => o.key === option.parent) : null

        if (newValues.indexOf(optionKey) !== -1) {
            newValues.splice(newValues.indexOf(optionKey), 1)

            if (autoUpdateParent) {
                if (parentOption && newValues.indexOf(parentOption.key) !== -1) {
                    // Uncheck parent
                    newValues.splice(newValues.indexOf(parentOption.key), 1)
                }
                if (option.children) {
                    // Uncheck children
                    for (let s in option.children) {
                        const childOptionKey = option.children[s]
                        newValues.splice(newValues.indexOf(childOptionKey), 1)
                    }
                }
            }
        } else {
            newValues.push(optionKey)

            if (autoUpdateParent) {
                if (parentOption && newValues.indexOf(parentOption.key) === -1) {
                    let allCheckedForParent = true
                    if (parentOption.children) {
                        for (let s in parentOption.children) {
                            const childOptionKey = parentOption.children[s]
                            if (newValues.indexOf(childOptionKey) === -1) {
                                allCheckedForParent = false
                            }
                        }
                    }
                    if (allCheckedForParent) {
                        // Check parent
                        newValues.push(parentOption.key)
                    }
                }
                if (option.children) {
                    for (let s in option.children) {
                        // Check children
                        const childOptionKey = option.children[s]
                        if (newValues.indexOf(childOptionKey) === -1) {
                            newValues.push(childOptionKey)
                        }
                    }
                }
            }
        }

        putFilterValue(newValues)
    }

    const toggleAll = () => {
        const allChecked = options.length === values.length
        putFilterValue(allChecked === true ? [] : options.map((item) => item.key))
    }

    const allChecked = options.length === values.length

    return (
        <div>
            <div className={`${styles.container} form-group`}>
                {toggleAllEnabled ? (
                    <div key="-1" className="mb-2">
                        <input className="form-check-input" type="checkbox" id="all" name="all" checked={allChecked} onChange={toggleAll} />
                        <label className="ms-2 text-bold text-muted text-truncated filter-label" htmlFor="all">
                            {allChecked === true ? "Deselecteer" : "Selecteer"} alle
                        </label>
                    </div>
                ) : null}
                {options.map((option) => {
                    return (
                        <div key={option.key} style={{ whiteSpace: "nowrap" }} className="mb-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                style={{ fontSize: "1rem" }}
                                id={option.key}
                                name={option.key}
                                checked={values.includes(option.key)}
                                onChange={handleChange}
                            />
                            <label className="ms-2 text-bold text-truncated filter-label" htmlFor={option.key}>
                                {option.parent ? <img className="custom-fa-icon me-1" src={subIcon} alt="sub-arrow" /> : null}
                                {option.title}
                            </label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default MultiSelectFilter
