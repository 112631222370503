import { ThemeConfig } from "react-select"

const InlineSelectTheme: ThemeConfig = (theme) => ({
    ...theme,
    borderRadius: 5,
    colors: {
        ...theme.colors,
        primary25: "rgba(55, 125, 255, 0.1)",
        primary: "black",
        dangerLight: "transparent",
        danger: "#0d6efd",
    },
})

export default InlineSelectTheme
