import { FC } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { loadOccupationTemplate } from "../../services/OccupationTemplate"
import EditOccupationTemplateDetailsPage from "./EditOccupationTemplateDetailsPage"

const UpdateOccupationTemplateDetailsPage: FC = () => {
    const { id } = useParams()
    const { data: occupationTemplate } = useQuery(["OccupationTemplate", id], loadOccupationTemplate(parseInt(id!)))

    return <EditOccupationTemplateDetailsPage mode="Update" occupationTemplate={occupationTemplate} />
}

export default UpdateOccupationTemplateDetailsPage
