import { FC } from "react"
import { UseFormWatch, useFieldArray } from "react-hook-form"
import { HOLIDAY_TYPE_DATE } from "../../helpers/Constants"
import { HolidaySettingsType } from "../../types/HolidaySettingsType"
import HolidayItem from "./CustomHolidayItem"

interface CustomHolidayArrayProps {
    control: any
    register: any
    watch: UseFormWatch<HolidaySettingsType>
    setValue: any
    getValues: any
}

const CustomHolidayArray: FC<CustomHolidayArrayProps> = ({ control, register, watch }) => {
    const { fields, append, remove } = useFieldArray({ control, name: "customHolidays" })

    return (
        <div>
            {fields.length > 0 ? (
                <table className="ms-2">
                    <thead>
                        <tr>
                            <td className="p-1 text-small text-bold">Naam</td>
                            <td className="p-1 text-small text-bold">Type</td>
                            <td className="p-1 text-small text-bold">Wanneer</td>
                            <td className="p-1 text-small text-bold">Verwerking</td>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((item, index) => {
                            return <HolidayItem key={index} item={item} index={index} register={register} remove={remove} watch={watch} />
                        })}
                    </tbody>
                </table>
            ) : null}
            <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                    append({ name: "", type: HOLIDAY_TYPE_DATE })
                }}
            >
                Voeg toe
            </button>
        </div>
    )
}

export default CustomHolidayArray
