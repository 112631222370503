import Cookies from "js-cookie"
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { constructColorMap } from "../helpers/ColorHelper"
import { constructNestedRoleMap, createNestedRoles } from "../helpers/RolesHelper"
import { isEmployableNow } from "../helpers/UsersHelper"
import { useGetSelectedLocationCacheKey } from "../hooks/UseCache"
import { csrf, me } from "../services/User"
import { DefaultColorType } from "../types/ColorType"
import LocalityOptionType from "../types/LocalityOptionType"
import UserSettingsType from "../types/UserSettingsType"

interface UserSettingsContextProps {
    loading: boolean
    userSettings?: UserSettingsType
    selectedLocationId?: number
    selectLocation: (locationId: number) => void
    reloadUserSettings: () => void
}

const contextValue = {
    loading: false,
    userSettings: undefined,
    selectedLocationId: -1,
    selectLocation: () => {},
    reloadUserSettings: () => {},
}

export const UserSettingsContext = createContext<UserSettingsContextProps>(contextValue)

interface Props {
    children: ReactNode
}

export const UserSettingsProvider: FC<Props> = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [userSettings, setUserSettings] = useState<UserSettingsType>()
    const [selectedLocationId, setSelectedLocationId] = useState<number>()
    const selectedLocationCacheKey = useGetSelectedLocationCacheKey()

    useEffect(() => {
        const locations = userSettings?.locations
        if (locations && locations?.length > 0) {
            const idFromCache = sessionStorage.getItem(selectedLocationCacheKey)
            const locationFromCache = idFromCache ? locations.find((l) => l.id === parseInt(idFromCache)) : undefined
            selectLocation(locationFromCache ? locationFromCache.id : userSettings.locations[0].id)
        }
    }, [userSettings, selectedLocationCacheKey])

    const selectLocation = useCallback(
        (id: number) => {
            setSelectedLocationId(id)
            sessionStorage.setItem(selectedLocationCacheKey, id.toString())
        },
        [setSelectedLocationId, selectedLocationCacheKey]
    )

    const loading = useMemo(() => {
        if (userSettings && (!userSettings.authenticated || !userSettings.verified)) {
            return false
        }
        return !selectedLocationId
    }, [userSettings, selectedLocationId])

    const reloadUserSettings = useCallback(() => {
        let companySystemName = searchParams.get("c") || undefined
        if (companySystemName) {
            Cookies.set("companySystemName", companySystemName)
        } else {
            companySystemName = Cookies.get("companySystemName")
        }

        me(companySystemName)
            .then((response) => {
                setUserSettings(response.data)
                if (response.data.company) {
                    Cookies.set("companySystemName", response.data.company.systemName)
                }
            })
            .catch(() => setUserSettings(undefined))
    }, [setUserSettings])

    return <UserSettingsContext.Provider value={{ loading, userSettings, selectedLocationId, selectLocation, reloadUserSettings }} children={children} />
}

export const UserSettingsConsumer = UserSettingsContext.Consumer

export function useLocations() {
    const { userSettings } = useContext(UserSettingsContext)
    const locations = userSettings ? userSettings.locations : undefined
    return locations ? locations : []
}

export function useOptionalSelectedLocation() {
    const locations = useLocations()
    const { selectedLocationId } = useContext(UserSettingsContext)
    return locations.find((l) => l.id === selectedLocationId)
}

export function useSelectedLocation() {
    const selectedLocation = useOptionalSelectedLocation()
    return selectedLocation!
}

export function useLocalityOptions() {
    const { userSettings } = useContext(UserSettingsContext)
    const localityOptions = userSettings ? userSettings.localityOptions : undefined
    return localityOptions ? localityOptions : []
}

export function useLocalityOptionsForSelectedLocation() {
    const localityOptions = useLocalityOptions()
    const { selectedLocationId } = useContext(UserSettingsContext)
    return localityOptions.filter((o) => o.location === selectedLocationId)
}

export function useResolveLocalityOption(): (option: number) => LocalityOptionType {
    const options = useLocalityOptions()
    let optionMap: Map<string, LocalityOptionType> = new Map()
    options.forEach((option) => {
        optionMap.set(option.id.toString(), option)
    })
    return (option: number) => {
        return optionMap.get(option.toString())!
    }
}

export function useResolveLocalityOptionForSelectedLocation(): (option: number) => LocalityOptionType {
    const options = useLocalityOptionsForSelectedLocation()
    let optionMap: Map<string, LocalityOptionType> = new Map()
    options.forEach((option) => {
        optionMap.set(option.id.toString(), option)
    })
    return (option: number) => {
        return optionMap.get(option.toString())!
    }
}

export function useBreakTemplates() {
    const { userSettings } = useContext(UserSettingsContext)
    const breakTemplates = userSettings ? userSettings.breakTemplates : undefined
    return breakTemplates ? breakTemplates : []
}

export function useBreakTemplatesForSelectedLocation() {
    const breakTemplates = useBreakTemplates()
    const { selectedLocationId } = useContext(UserSettingsContext)
    return breakTemplates.filter((t) => t.location === selectedLocationId)
}

export function useUsers() {
    const { userSettings } = useContext(UserSettingsContext)
    return userSettings ? userSettings.users : []
}

export function useUsersForSelectedLocation() {
    const users = useUsers()
    const selectedLocation = useSelectedLocation()
    return users.filter((u) => u.locations.includes(selectedLocation.id))
}

export function useEmployableUsersForSelectedLocation() {
    const users = useUsersForSelectedLocation()
    return users.filter(isEmployableNow)
}

export function useRoles() {
    const { userSettings } = useContext(UserSettingsContext)
    return userSettings ? userSettings.roles : []
}

export function useRolesForSelectedLocation() {
    const roles = useRoles()
    const selectedLocation = useSelectedLocation()
    return roles.filter((r) => r.location === selectedLocation.id)
}

export function useRoleGroups() {
    const { userSettings } = useContext(UserSettingsContext)
    return userSettings ? userSettings.roleGroups : []
}

export function useRoleGroupsForSelectedLocation() {
    const roleGroups = useRoleGroups()
    const selectedLocation = useSelectedLocation()
    return roleGroups.filter((rg) => rg.location === selectedLocation.id)
}

export function useNestedRolesForSelectedLocation() {
    const roles = useRolesForSelectedLocation()
    const roleGroups = useRoleGroupsForSelectedLocation()
    return createNestedRoles(roles, roleGroups)
}

export function useNestedRolesMapForSelectedLocation() {
    const nestedRoles = useNestedRolesForSelectedLocation()
    return useMemo(() => constructNestedRoleMap(nestedRoles), [nestedRoles])
}

export const useOptionalCurrentUser = () => {
    const { userSettings } = useContext(UserSettingsContext)
    return userSettings ? userSettings.user : undefined
}

export const useCurrentUser = () => {
    const currentUser = useOptionalCurrentUser()
    return currentUser!
}

export const useIsAuthenticated = () => {
    const user = useOptionalCurrentUser()
    const selectedLocation = useOptionalSelectedLocation()
    return !!user && !!selectedLocation
}

export const useTOTPDevices = () => {
    const { userSettings } = useContext(UserSettingsContext)
    const devices = userSettings ? userSettings.totpDevices : undefined
    return devices ? devices : []
}

export const useInitMode = () => {
    const user = useOptionalCurrentUser()
    return user ? user.initMode : 0
}

export const ensureCsrfToken = () => {
    csrf()
}

export const useColors = () => {
    const { userSettings } = useContext(UserSettingsContext)
    const colors = userSettings ? userSettings.colors : undefined
    const map: Map<number, DefaultColorType> = colors ? constructColorMap(colors) : new Map()
    return map
}

export const useCompanyModules = () => {
    const { userSettings } = useContext(UserSettingsContext)
    const company = userSettings ? userSettings.company : undefined
    return company ? company.modules : []
}

export const useCurrentCompany = () => {
    const { userSettings } = useContext(UserSettingsContext)
    return userSettings ? userSettings.company : undefined
}

export const useIsStaff = () => {
    const { userSettings } = useContext(UserSettingsContext)
    return userSettings?.isStaff
}

export const useIsAdmin = () => {
    const { userSettings } = useContext(UserSettingsContext)
    const user = userSettings ? userSettings.user : undefined
    return user && user.type === "ADMIN"
}

export const useIsExternalHire = () => {
    const { userSettings } = useContext(UserSettingsContext)
    const user = userSettings ? userSettings.user : undefined
    return user && user.type === "EXTERNAL"
}
