import { FC } from "react"
import { UseFormClearErrors, UseFormSetValue, UseFormWatch } from "react-hook-form"
import { UserEditInputs } from "../../../pages/users/UserEditPage"
import UserTimeTableRow from "./UserTimeTableRow"

interface UserTimeTableProps {
    selectedWeek: number
    disabled: boolean
    watch: UseFormWatch<UserEditInputs>
    setValue: UseFormSetValue<UserEditInputs>
    clearErrors: UseFormClearErrors<UserEditInputs>
}

const UserTimeTable: FC<UserTimeTableProps> = ({ selectedWeek, disabled, watch, setValue, clearErrors }) => {
    return (
        <table>
            <tbody>
                <UserTimeTableRow day={0} week={selectedWeek} disabled={disabled} watch={watch} setValue={setValue} clearErrors={clearErrors} />
                <UserTimeTableRow day={1} week={selectedWeek} disabled={disabled} watch={watch} setValue={setValue} clearErrors={clearErrors} />
                <UserTimeTableRow day={2} week={selectedWeek} disabled={disabled} watch={watch} setValue={setValue} clearErrors={clearErrors} />
                <UserTimeTableRow day={3} week={selectedWeek} disabled={disabled} watch={watch} setValue={setValue} clearErrors={clearErrors} />
                <UserTimeTableRow day={4} week={selectedWeek} disabled={disabled} watch={watch} setValue={setValue} clearErrors={clearErrors} />
                <UserTimeTableRow day={5} week={selectedWeek} disabled={disabled} watch={watch} setValue={setValue} clearErrors={clearErrors} />
                <UserTimeTableRow day={6} week={selectedWeek} disabled={disabled} watch={watch} setValue={setValue} clearErrors={clearErrors} />
            </tbody>
        </table>
    )
}

export default UserTimeTable
