import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useMemo } from "react"
import { Button, OverlayTrigger, Popover } from "react-bootstrap"

interface Props {
    id: string
    body: string
}

const InfoPopover: FC<Props> = ({ id, body }) => {
    const popover = useMemo(
        () => (
            <Popover id={id}>
                <Popover.Body>{body}</Popover.Body>
            </Popover>
        ),
        [id, body]
    )

    return (
        <OverlayTrigger trigger="click" rootClose overlay={popover}>
            <Button variant="link" size="sm">
                <FontAwesomeIcon icon={faInfoCircle} />
            </Button>
        </OverlayTrigger>
    )
}

export default InfoPopover
