import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useMemo } from "react"
import { useResolveLocalityOption } from "../../../contexts/UserSettingsContext"
import useLocalityOptionIconMap from "../../../hooks/UseLocalityOptionIconMap"
import { LocalityOptionOrInherit } from "../../../types/LocalityType"
import styles from "./StaticWeekDayLocality.module.scss"

interface Props {
    locality: LocalityOptionOrInherit
}

const StaticWeekDayLocality: FC<Props> = ({ locality }) => {
    const iconMap = useLocalityOptionIconMap()
    const resolveLocalityOption = useResolveLocalityOption()
    const icon = useMemo(() => {
        if (locality === "INHERIT" || locality === "UNKNOWN") {
            return undefined
        }
        const option = resolveLocalityOption(locality)
        return iconMap.get(option.icon)
    }, [locality, resolveLocalityOption, iconMap])

    if (!icon) {
        return null
    }

    return (
        <span className={styles.locality}>
            <FontAwesomeIcon icon={icon} />
        </span>
    )
}

export default StaticWeekDayLocality
