import { OccupationScheduleMap } from "./OccupationMapHelper"
import { ScheduleMap } from "./ScheduleMapHelper"

export function toObject(map: ScheduleMap | OccupationScheduleMap) {
    return mapToPlainObject(map)
}

const mapToPlainObject = (map: Map<string, any>) => {
    return Object.fromEntries(Array.from(map.entries(), ([k, v]) => [k, toPlainObject(v)]))
}

const listToPlainObjects = (array: any[]) => {
    return array.map(toPlainObject)
}

const toPlainObject = (object: any): {} | any[] => {
    if (object instanceof Array) {
        return listToPlainObjects(object)
    } else if (object instanceof Map) {
        return mapToPlainObject(object)
    }
    return object
}

export function ensureMapForKey(map: Map<string, any>, key: string) {
    if (!map.has(key)) {
        map.set(key, new Map())
    }
}

export function ensureListForKey(map: Map<string, any[]>, key: string) {
    if (!map.has(key)) {
        map.set(key, [])
    }
}
