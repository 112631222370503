import { FC, useMemo } from "react"
import { toScheduleMapFromGeneratedMutations } from "../../../helpers/ScheduleMapHelper"
import { TaskGeneratedMutationsResult, TaskType } from "../../../services/Task"
import RoleType from "../../../types/RoleType"
import MutationTable from "../../editor/MutationTable"
import styles from "./GeneratedMutationsResult.module.scss"

interface BreakPlannerResultProps {
    task: TaskType<TaskGeneratedMutationsResult>
    roles: RoleType[]
    mode: "DAYS" | "DATERANGE"
    weekCycle: number
}

const GeneratedMutationsResult: FC<BreakPlannerResultProps> = ({ task, roles, mode, weekCycle }) => {
    const scheduleMap = useMemo(() => {
        return toScheduleMapFromGeneratedMutations(task.result.mutations)
    }, [task])

    const mutationMode = useMemo(() => {
        return mode === "DAYS" ? "DAY" : "DATE"
    }, [mode])

    return (
        <div className={styles.container}>
            <MutationTable mutations={scheduleMap} roles={roles} mode={mutationMode} weekCycle={weekCycle} />
        </div>
    )
}

export default GeneratedMutationsResult
