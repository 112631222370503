import { ChangeEventHandler, FC, useCallback, useMemo, useState } from "react"
import { Button, Form } from "react-bootstrap"

interface OccupationsEditorControlsProps {
    clearSelection: () => void
    setOccupation: (value: (number | null)[]) => void
}

const OccupationsEditorControls: FC<OccupationsEditorControlsProps> = ({ clearSelection, setOccupation }) => {
    const [minValue, setMinValue] = useState<string>("1")
    const [maxValue, setMaxValue] = useState<string>("")

    const apply = useCallback(() => {
        setOccupation([minValue !== "" ? parseInt(minValue) : null, maxValue !== "" ? parseInt(maxValue) : null])
    }, [setOccupation, minValue, maxValue])

    const onChangeMin: ChangeEventHandler<HTMLSelectElement> = useCallback(
        (event) => {
            const value = event.target.value
            setMinValue(value)
            if (value !== "" && maxValue !== "" && parseInt(value) > parseInt(maxValue)) {
                setMaxValue("")
            }
        },
        [setMinValue, setMaxValue, maxValue]
    )

    const onChangeMax: ChangeEventHandler<HTMLSelectElement> = useCallback(
        (event) => {
            const value = event.target.value
            setMaxValue(value)
            if (value !== "" && minValue !== "" && parseInt(value) < parseInt(minValue)) {
                setMinValue("")
            }
        },
        [setMaxValue, setMinValue, minValue]
    )

    const minOptions = useMemo(() => {
        const options: { label: string; value: string }[] = []
        options.push({ label: "-", value: "" })
        for (let i = 1; i <= 25; i++) {
            options.push({ label: i.toString(), value: i.toString() })
        }
        return options
    }, [])

    const maxOptions = useMemo(() => {
        const options: { label: string; value: string }[] = []
        options.push({ label: "-", value: "" })
        for (let i = 0; i <= 25; i++) {
            options.push({ label: i.toString(), value: i.toString() })
        }
        return options
    }, [])

    return (
        <div className="d-flex align-items-center mt-3 mb-3">
            <table className="me-3">
                <tbody>
                    <tr>
                        <td>
                            <span className="text-bold me-2">Max:</span>
                        </td>
                        <td>
                            <Form.Select value={maxValue} onChange={onChangeMax} className="form-select-inline">
                                {maxOptions.map(({ label, value }) => (
                                    <option key={label} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </Form.Select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="text-bold me-2">Min:</span>
                        </td>
                        <td>
                            <Form.Select value={minValue} onChange={onChangeMin} className="form-select-inline">
                                {minOptions.map(({ label, value }) => (
                                    <option key={label} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </Form.Select>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Button type="button" onClick={apply} data-cy="apply">
                Pas toe
            </Button>
            <Button type="button" onClick={clearSelection} variant="link">
                Annuleer
            </Button>
        </div>
    )
}

export default OccupationsEditorControls
