import { FC, ReactElement } from "react"
import { Navigate } from "react-router-dom"
import { useIsAuthenticated } from "../contexts/UserSettingsContext"

interface PrivateElementProps {
    children: ReactElement<any, any>
    requiredModule?: string
}

const InitModeElement: FC<PrivateElementProps> = ({ children }) => {
    const isAuthenticated = useIsAuthenticated()
    if (!isAuthenticated) {
        return <Navigate to="/inloggen" />
    }
    return children
}

export default InitModeElement
