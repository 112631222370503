import { FC, useCallback, useMemo } from "react"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"
import { UserEditInputs } from "../../pages/users/UserEditPage"
import RoleType from "../../types/RoleType"

interface UserRoleRowProps {
    role: RoleType
    watch: UseFormWatch<UserEditInputs>
    setValue: UseFormSetValue<UserEditInputs>
    disabled: boolean
}

const UserRoleRow: FC<UserRoleRowProps> = ({ role, watch, setValue, disabled }) => {
    const rolePreferences = watch("rolePreferences")

    const checked = useMemo(() => {
        const preference = rolePreferences.find((p) => p.role === role.id)
        return preference ? preference.priority === 1 : false
    }, [rolePreferences, role])

    const onChange = useCallback(() => {
        const newPreferences = [...rolePreferences]
        const preference = rolePreferences.find((p) => p.role === role.id)
        if (preference) {
            preference.priority = checked ? 0 : 1
        } else {
            newPreferences.push({
                role: role.id,
                priority: checked ? 0 : 1,
            })
        }
        setValue("rolePreferences", newPreferences)
    }, [rolePreferences, checked, role])

    const id = "role-" + role.id
    return (
        <tr role="row">
            <td role="cell" style={{ width: "300px", height: "2rem" }}>
                <label className={"toggle-switch d-flex align-items-center" + (disabled ? " toggle-switch-disabled" : "")} htmlFor={id}>
                    <input type="checkbox" className="toggle-switch-input" id={id} checked={checked} onChange={onChange} disabled={disabled} />
                    <span className="toggle-switch-label">
                        <span className="toggle-switch-indicator"></span>
                    </span>
                    <span className="toggle-switch-content">
                        <span className={"d-block ms-3" + (disabled ? " text-muted" : "")} style={{ padding: "0.5rem" }}>
                            {role.name}
                        </span>
                    </span>
                </label>
            </td>
        </tr>
    )
}

export default UserRoleRow
