import { FC } from "react"

interface Props {
    errors: string[]
    className?: string
}

const FormErrorMessages: FC<Props> = ({ errors, className }) => {
    if (errors.length === 0) {
        return null
    }

    const errorMessages = errors.map((item, index) => (
        <li key={index} className="font-danger">
            <i className="fas fa-exclamation-circle me-2"></i>
            {item}
        </li>
    ))
    return (
        <ul data-cy="form_errors" className={"ms-1 font-awesome-list d-inline-block" + (className ? " " + className : "")}>
            {errorMessages}
        </ul>
    )
}

export default FormErrorMessages
