import { SlotType } from "../../components/shiftEditor/ShiftsEditorUser"
import ColorType from "../../types/ColorType"
import { HiringAvailabilityStatusWithUnknown } from "../../types/HiringAvailabilityType"

export interface Cell {
    dayId: string
    user: number
    slot: number
}

export interface CellSelection {
    from: Cell | undefined
    to: Cell | undefined
}

export interface ShiftEditorCellDetails {
    color: ColorType | null
    selected: boolean
    isFirstOfShift: boolean
    roleName: string
    nthColor: number
    isMuted: boolean
    slotType: SlotType
    availability: HiringAvailabilityStatusWithUnknown | null
}

export const isSelected = (cellSelection: CellSelection, userId: number, cell: number): boolean => {
    const userMatches = !!cellSelection.from && cellSelection.from!.user === userId
    const cellAfterStart = !!cellSelection.from && cellSelection.from!.slot <= cell
    const cellBeforeEnd = !!cellSelection.to && cellSelection.to!.slot >= cell
    const cellIsStartWithoutEnd = !cellSelection.to && !!cellSelection.from && cellSelection.from!.slot === cell
    return userMatches && cellAfterStart && (cellIsStartWithoutEnd || cellBeforeEnd)
}

export const getClickedCell = (id: string) => {
    const idRegex = /(\d+)#(\d+)#(\d+)/g
    const match = idRegex.exec(id)!
    return {
        dayId: match[1],
        user: parseInt(match[2]),
        slot: parseInt(match[3]),
    }
}
