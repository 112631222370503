import { FC } from "react"
import { useFieldArray } from "react-hook-form"
import { ROLE_TYPE_LEAVE, ROLE_TYPE_REGULAR, ROLE_TYPE_SICK } from "../../helpers/Constants"

interface RoleArrayProps {
    control: any
    register: any
    setValue: any
    getValues: any
}

const RoleArray: FC<RoleArrayProps> = ({ control, register }) => {
    const { fields, append, remove } = useFieldArray({ control, name: "roles" })

    return (
        <>
            <table>
                <thead>
                    <tr>
                        <td className="ps-2"></td>
                        <td className="ps-2">
                            <span className="input-label">Naam</span>
                        </td>
                        <td className="ps-2">
                            <span className="input-label">Type</span>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="p-2">
                            <span className="text-small text-muted">1</span>
                        </td>
                        <td className="p-2">
                            <input disabled className="form-control" value="Vakantie" />
                        </td>
                        <td className="p-2">
                            <select disabled className="form-select form-control" value={ROLE_TYPE_LEAVE}>
                                <option value={ROLE_TYPE_REGULAR}>Standaard</option>
                                <option value={ROLE_TYPE_LEAVE}>Verlof</option>
                                <option value={ROLE_TYPE_SICK}>Ziek</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2">
                            <span className="text-small text-muted">2</span>
                        </td>
                        <td className="p-2">
                            <input disabled className="form-control" value="Ziek" />
                        </td>
                        <td className="p-2">
                            <select disabled className="form-select form-control" value={ROLE_TYPE_SICK}>
                                <option value={ROLE_TYPE_REGULAR}>Standaard</option>
                                <option value={ROLE_TYPE_LEAVE}>Verlof</option>
                                <option value={ROLE_TYPE_SICK}>Ziek</option>
                            </select>
                        </td>
                    </tr>
                    {fields.map((item, index) => {
                        return (
                            <tr key={item.id}>
                                <td className="p-2">
                                    <span className="text-small text-muted">{index + 3}</span>
                                </td>
                                <td className="p-2">
                                    <input {...register(`roles.${index}.name`)} data-cy={`roles.${index}.name`} className="form-control" />
                                </td>
                                <td className="p-2">
                                    <select {...register(`roles.${index}.type`, { required: true })} className="form-select form-control">
                                        <option value={ROLE_TYPE_REGULAR}>Standaard</option>
                                        <option value={ROLE_TYPE_LEAVE}>Verlof</option>
                                        <option value={ROLE_TYPE_SICK}>Ziek</option>
                                    </select>
                                </td>
                                <td className="p-2">
                                    <button type="button" className="btn btn-link" data-cy={`roles.${index}.delete`} onClick={() => remove(index)}>
                                        <i className="fas fa-trash-alt"></i>
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                    append({ name: "", type: "" })
                }}
            >
                Voeg toe
            </button>
        </>
    )
}

export default RoleArray
