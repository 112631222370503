import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, MouseEvent, useCallback } from "react"
import { Button } from "react-bootstrap"
import { prettyPrintMonth } from "../../helpers/DaysHelper"
import styles from "./DatePickerHeader.module.scss"

interface DatePickerHeaderProps {
    date: Date
    decreaseMonth: () => void
    increaseMonth: () => void
}

const DatePickerHeader: FC<DatePickerHeaderProps> = ({ date, decreaseMonth, increaseMonth }) => {
    const onClickDecreaseMonth = useCallback(
        (event: MouseEvent) => {
            event.preventDefault()
            decreaseMonth()
        },
        [decreaseMonth]
    )

    const onClickIncreaseMonth = useCallback(
        (event: MouseEvent) => {
            event.preventDefault()
            increaseMonth()
        },
        [increaseMonth]
    )

    return (
        <div className={styles.header}>
            <Button type="button" variant="link" onClick={onClickDecreaseMonth}>
                <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <div className={"text-center " + styles.monthLabel}>{prettyPrintMonth(date)}</div>
            <Button type="button" variant="link" onClick={onClickIncreaseMonth}>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>
        </div>
    )
}

export default DatePickerHeader
