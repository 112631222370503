import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"
import { useColors, useRolesForSelectedLocation } from "../../contexts/UserSettingsContext"
import { getCSSColor } from "../../helpers/ColorHelper"

const RolesLegend: FC = () => {
    const colors = useColors()
    const roles = useRolesForSelectedLocation()

    if (!roles || !colors) {
        return null
    }

    var components = []
    for (var i = 0; i < roles.length; i++) {
        var role = roles[i]
        var color = colors.get(role.color)!
        components.push(
            <div key={role.id} className="roles-legend-item">
                <FontAwesomeIcon icon={faCircle} color={getCSSColor(color)} className="me-2" />
                <span className="text-bold text-muted">{role.name}</span>
            </div>
        )
    }
    return <div>{components}</div>
}

export default RolesLegend
