import { FC, useMemo } from "react"
import { useCompanyModules, useCurrentUser, useLocations } from "../../../contexts/UserSettingsContext"
import { COMPANY_MODULE_REMOTE_WORKING } from "../../../helpers/Constants"
import { getTimeSlots } from "../../../helpers/DaysHelper"
import styles from "./StaticWeekHead.module.scss"

interface Props {
    showWeekNumber: boolean
    weekNumber: number
    showLocation: boolean
}

const StaticWeekHead: FC<Props> = ({ showWeekNumber, weekNumber, showLocation }) => {
    const { startSlot, endSlot } = useCurrentUser()
    const companyModules = useCompanyModules()
    const locations = useLocations()

    const showLocalities = useMemo(() => companyModules.includes(COMPANY_MODULE_REMOTE_WORKING), [companyModules])

    const showLocationCell = useMemo(() => showLocation && locations.length > 1, [showLocation, locations])

    const flexSize = useMemo(() => {
        const dateSize = 48
        const localitySize = showLocalities ? 24 : 0
        const locationSize = showLocationCell ? 64 : 0
        return dateSize + localitySize + locationSize
    }, [showLocalities, showLocationCell])

    return (
        <>
            <div className={styles.headerRow}>{showWeekNumber ? <div className="text-bold text-small">Week {weekNumber}</div> : null}</div>
            <div className="d-flex">
                <div style={{ flex: `0 0 ${flexSize}px` }}></div>
                <div className={styles.headerContainer}>{getTimeSlots(startSlot, endSlot, "small")}</div>
            </div>
        </>
    )
}

export default StaticWeekHead
