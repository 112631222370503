import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react"
import { usePopper } from "react-popper"
import UserTimeInputButton from "./UserTimeInputButton"
import customStyles from "./UserTimeInputPopover.module.scss"

interface UserTimeInputPopoverProps {
    startSlot: number
    endSlot: number
    selectedTime: string
    setSelectedTime: (time: string) => void
    type: "start" | "end"
    disabled?: boolean
    inline?: boolean
}

const UserTimeInputPopover: FC<UserTimeInputPopoverProps> = ({ startSlot, endSlot, selectedTime, setSelectedTime, type, disabled, inline }) => {
    const [referenceElement, setReferenceElement] = useState<HTMLInputElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
    const { styles, attributes } = usePopper(referenceElement, popperElement)
    const [popperVisible, setPopperVisible] = useState(false)

    useEffect(() => {
        function handleClickOutside(e: MouseEvent) {
            const target = e.target as Node
            if (!popperElement?.contains(target) && !referenceElement?.contains(target)) {
                setPopperVisible(false)
            }
        }
        document.addEventListener("click", handleClickOutside)
        return () => document.removeEventListener("click", handleClickOutside)
    }, [popperElement])

    const showPopper = useCallback(() => setPopperVisible(true), [setPopperVisible])

    const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => setSelectedTime(event.target.value), [setSelectedTime])

    const onClickTime = useCallback(
        (time: string) => {
            setSelectedTime(time)
            setPopperVisible(false)
        },
        [setSelectedTime, setPopperVisible]
    )

    const slotsToShow = useMemo(() => {
        const slots = []
        for (let slot = startSlot; slot <= endSlot; slot++) {
            slots.push(slot)
        }
        return slots
    }, [startSlot, endSlot])

    return (
        <>
            <input
                ref={setReferenceElement}
                value={selectedTime}
                placeholder="--:--"
                className={`form-control form-control-lg text-center ${inline ? "m-1" : ""}`}
                onChange={onChange}
                onFocus={showPopper}
                disabled={disabled}
                data-cy={`${type}Time`}
            />
            {popperVisible ? (
                <div ref={setPopperElement} className={customStyles.popover} style={{ zIndex: 999, ...styles.popper }} {...attributes.popper}>
                    {slotsToShow.map((slot) => (
                        <UserTimeInputButton key={slot} slot={slot} type={type} setSelectedTime={onClickTime} />
                    ))}
                </div>
            ) : null}
        </>
    )
}

export default UserTimeInputPopover
