import { FC } from "react"
import { useColors } from "../../../contexts/UserSettingsContext"
import { getTimeString } from "../../../helpers/DaysHelper"
import { getRoleBadge } from "../../../helpers/RolesHelper"
import LocationType from "../../../types/LocationType"
import RoleType from "../../../types/RoleType"
import styles from "./StaticDayItem.module.scss"

interface Props {
    startSlot: number
    endSlot: number
    role?: RoleType
    location?: LocationType
    firstOfLocation: boolean
}

const StaticDayItem: FC<Props> = ({ startSlot, endSlot, role, location, firstOfLocation }) => {
    const colors = useColors()

    return (
        <>
            {location && firstOfLocation ? (
                <tr className={styles.locationRow}>
                    <td data-cy="location" className="text-bold" colSpan={4}>
                        {location?.name}:
                    </td>
                </tr>
            ) : null}
            <tr data-cy="staticDayShift">
                <td data-cy="startTime" className="text-small text-center" style={{ width: "40px" }}>
                    {getTimeString("start", startSlot)}
                </td>
                <td className="text-small text-center" style={{ width: "10px" }}>
                    -
                </td>
                <td data-cy="endTime" className="text-small text-center" style={{ width: "40px" }}>
                    {getTimeString("end", endSlot)}
                </td>
                <td data-cy="role" className="ps-2">
                    {getRoleBadge(role, colors)}
                </td>
            </tr>
        </>
    )
}

export default StaticDayItem
