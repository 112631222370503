import { FC, useContext, useMemo } from "react"
import { Form } from "react-bootstrap"
import { FieldErrors, UseFormRegister, UseFormWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { UserSettingsContext } from "../../contexts/UserSettingsContext"
import { UserEditInputs, UserEditMode } from "../../pages/users/UserEditPage"
import { UserInvitationMode } from "../../types/UserType"
import InfoPopover from "../InfoPopover"
import UserTabErrors from "./UserTabErrors"
import { UserTab } from "./UserTabs"
import UserTypeOptions from "./UserTypeOptions"

interface Props {
    selectedTab: UserTab
    watch: UseFormWatch<UserEditInputs>
    register: UseFormRegister<UserEditInputs>
    mode: UserEditMode
    errors: FieldErrors<UserEditInputs>
    tabErrors: UserTab[]
}

const UserDetailsForm: FC<Props> = ({ selectedTab, watch, register, errors, mode, tabErrors }) => {
    const { t } = useTranslation()
    const { userSettings } = useContext(UserSettingsContext)
    const invitationModeOptions = useMemo(() => {
        let invitationModeOptions: { id: UserInvitationMode; label: string; disabled: boolean }[] = []
        invitationModeOptions.push({ id: "PASSWORD", label: t("UserDetailsForm.password"), disabled: !!userSettings?.samlLoginConfig?.disablePasswordLogin })
        invitationModeOptions.push({ id: "SSO", label: t("UserDetailsForm.sso"), disabled: (userSettings?.samlLoginConfig?.samlOptions.length || 0) === 0 })
        return invitationModeOptions
    }, [t, userSettings])

    const sendInvitation = watch("sendInvitation")

    if (selectedTab !== "profile") {
        return null
    }

    return (
        <div>
            <Form.Group className="mb-3">
                <Form.Label>{t("Forms.first_name")}</Form.Label>
                <Form.Control size="lg" type="text" {...register("firstName")} isInvalid={!!errors.firstName} />
                <Form.Control.Feedback type="invalid">{errors.firstName?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>{t("Forms.last_name")}</Form.Label>
                <Form.Control size="lg" type="text" {...register("lastName")} isInvalid={!!errors.lastName} />
                <Form.Control.Feedback type="invalid">{errors.lastName?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>{t("Forms.email_address")}</Form.Label>
                <Form.Control size="lg" type="email" {...register("email")} isInvalid={!!errors.email} disabled={mode === "update-my"} />
                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
            </Form.Group>
            {mode !== "update-my" ? (
                <>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("UserDetailsForm.account_type")}</Form.Label>
                        <Form.Select size="lg" {...register("type")} isInvalid={!!errors.type}>
                            <UserTypeOptions />
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.type?.message}</Form.Control.Feedback>
                    </Form.Group>
                    {mode === "create" ? (
                        <div className="row">
                            <Form.Group className="mb-3 col-6">
                                <Form.Label>{t("UserDetailsForm.send_invitation_mail_immediately")}</Form.Label>
                                <Form.Check {...register("sendInvitation")} isInvalid={!!errors.sendInvitation} />
                                <Form.Control.Feedback type="invalid">{errors.sendInvitation?.message}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3 col-6" hidden={!sendInvitation}>
                                <Form.Label>{t("UserDetailsForm.type_of_invitation_mail")}</Form.Label>
                                <Form.Select size="lg" {...register("invitationMode")} isInvalid={!!errors.invitationMode}>
                                    {invitationModeOptions.map(({ id, label, disabled }) => (
                                        <option key={id} value={id} disabled={disabled}>
                                            {label}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.invitationMode?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </div>
                    ) : (
                        <Form.Group className="mb-3">
                            <Form.Label>{t("UserDetailsForm.active")}</Form.Label>
                            <InfoPopover id="isActivePopover" body={t("UserDetailsForm.active_explanation")} />
                            <Form.Check {...register("isActive")} isInvalid={!!errors.isActive} disabled={true} />
                            <Form.Control.Feedback type="invalid">{errors.isActive?.message}</Form.Control.Feedback>
                        </Form.Group>
                    )}
                </>
            ) : null}
            <UserTabErrors tabErrors={tabErrors} except="profile" />
        </div>
    )
}

export default UserDetailsForm
