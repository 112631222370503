import { FC, useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import DateSelector, { DateLabelType } from "../../../components/DateSelector"
import InfoPopover from "../../../components/InfoPopover"
import DaySelectorInline from "../../../components/editor/DaySelectorInline"
import RegularPage from "../../../components/page/RegularPage"
import { useTabs } from "../../../contexts/TabsContext"
import { useSelectedLocation } from "../../../contexts/UserSettingsContext"
import useLoadOccupationSource from "../../../hooks/UseLoadOccupationSource"
import useLoadUnexusRoutersForSource from "../../../hooks/UseLoadUnexsusRoutersForSource"
import { useLoadDateUnexusRouterOccupations, useLoadDayUnexusRouterOccupations } from "../../../hooks/UseLoadUnexusRouterOccupations"
import useResolveRoleForSelectedLocation from "../../../hooks/UseResolveRole"
import useResolveRoleGroupForSelectedLocation from "../../../hooks/UseResolveRoleGroup"
import { loadOccupationTemplate } from "../../../services/OccupationTemplate"
import OccupationSourceType from "../../../types/OccupationSourceType"
import { SourcedDayOccupationType } from "../../../types/OccupationType"
import UnexusRouterOccupationType from "../../../types/UnexusRouterOccupationType"
import UnexusRouterType from "../../../types/UnexusRouterType"
import UnexusRouterOccupationsView from "./UnexusRouterOccupationsView"

const getRouterNames = (routerIds: number[], routers: UnexusRouterType[]) => {
    if (routers.length < 1) {
        return []
    }
    return routerIds.map((id) => routers.find((r) => r.id === id)?.name).join(", ")
}

const OccupationSourcePage: FC = ({}) => {
    const { setActiveTab } = useTabs()
    const params = useParams()
    const location = useSelectedLocation()
    const resolveRoleForSelectedLocation = useResolveRoleForSelectedLocation()
    const resolveRoleGroupForSelectedLocation = useResolveRoleGroupForSelectedLocation()
    const template = parseInt(params.template!)
    const id = parseInt(params.id!)
    const navigate = useNavigate()

    const [occupationSource, setOccupationSource] = useState<OccupationSourceType>()
    const [selectedDate, setSelectedDate] = useState<Date>(new Date())
    const [dateUnexusRouterOccupations, setDateUnexusRouterOccupations] = useState<UnexusRouterOccupationType[]>([])
    const [dateUnexusOccupations, setDateUnexusOccupations] = useState<SourcedDayOccupationType[]>([])
    const [dateReferenceDates, setDateReferenceDates] = useState<Date[]>([])
    const [dayUnexusRouterOccupations, setDayUnexusRouterOccupations] = useState<UnexusRouterOccupationType[]>([])
    const [dayUnexusOccupations, setDayUnexusOccupations] = useState<SourcedDayOccupationType[]>([])
    const [dayReferenceDates, setDayReferenceDates] = useState<Date[]>([])
    const [unexusRouters, setUnexusRouters] = useState<UnexusRouterType[]>([])
    const [selectedDay, setSelectedDay] = useState<number>(0)

    const { data: occupationTemplate } = useQuery(["OccupationTemplate", template], loadOccupationTemplate(template))
    const loadOccupationSource = useLoadOccupationSource(id, setOccupationSource)
    const loadDateUnexusRouterOccupations = useLoadDateUnexusRouterOccupations(occupationSource, selectedDate, setDateUnexusRouterOccupations, setDateUnexusOccupations, setDateReferenceDates)
    const loadDayUnexusRouterOccupations = useLoadDayUnexusRouterOccupations(occupationSource, selectedDay, setDayUnexusRouterOccupations, setDayUnexusOccupations, setDayReferenceDates)
    const loadUnexusRouters = useLoadUnexusRoutersForSource(occupationSource, setUnexusRouters)

    useEffect(() => setActiveTab("OccupationTemplates"), [setActiveTab])
    useEffect(loadOccupationSource, [id])
    useEffect(loadDateUnexusRouterOccupations, [occupationSource, selectedDate])
    useEffect(loadDayUnexusRouterOccupations, [occupationSource, selectedDay])
    useEffect(loadUnexusRouters, [occupationSource])
    useEffect(() => {
        if (occupationTemplate && occupationTemplate.location !== location.id) {
            navigate("/basisbezettingen")
        }
    }, [occupationTemplate])

    const target = useMemo(() => {
        if (occupationSource?.role) {
            return resolveRoleForSelectedLocation(occupationSource.role)?.name
        } else if (occupationSource?.roleGroup) {
            return resolveRoleGroupForSelectedLocation(occupationSource.roleGroup)?.name
        }
    }, [occupationSource, resolveRoleForSelectedLocation, resolveRoleGroupForSelectedLocation])

    return (
        <RegularPage
            id="OccupationSource"
            breadCrumbs={[
                { title: "Basisbezettingen", link: "/basisbezettingen" },
                { title: occupationTemplate ? occupationTemplate.name : "", link: occupationTemplate ? `/basisbezettingen/${occupationTemplate.id}` : "" },
                { title: `Bezettingsbron ${target}` },
            ]}
        >
            <h2 className="mb-4 d-inline-block">Bezettingsbron {target}</h2>
            <div className="row">
                <div className="form-group mb-4 col-md-5 col-lg-4">
                    <label className="input-label">Behandeltijd</label>
                    <span className="text-bold">{occupationSource ? occupationSource.handlingTime : ""} sec</span>
                </div>
                <div className="form-group mb-4 col-md-5 col-lg-4">
                    <label className="input-label">Antwoordsnelheid</label>
                    <span className="text-bold">{occupationSource ? occupationSource.speedOfAnswer : ""} sec</span>
                </div>
            </div>
            <div className="row">
                <div className="form-group mb-4 col-md-5 col-lg-4">
                    <label className="input-label">Serviceniveau</label>
                    <span className="text-bold">{occupationSource ? occupationSource.serviceLevel : ""}</span>
                </div>
                <div className="form-group mb-4 col-md-5 col-lg-4">
                    <label className="input-label">Agenten werklast</label>
                    <span className="text-bold">{occupationSource ? occupationSource.maxOccupancy : ""}</span>
                </div>
            </div>
            <div className="form-group mb-4">
                <label className="input-label">Wachtrij(en)</label>
                <span className="text-bold">{occupationSource ? getRouterNames(occupationSource.routers, unexusRouters) : ""}</span>
            </div>
            <div className="form-section mb-4">
                <div className="form-group mb-4">
                    <label className="input-label d-inline-block me-2">Toon bezettingsberekening voor datum: </label>
                    <DateSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} labelType={DateLabelType.DATE_AND_YEAR} />
                    <InfoPopover
                        id="datePopover"
                        body="Hiermee kun je zien hoe de basisbezetting van een taak voor een specifieke datum berekend wordt. Deze dag-specifieke basisbezetting wordt gebruikt op de roosterpagina."
                    />
                </div>
                <UnexusRouterOccupationsView referenceDates={dateReferenceDates} occupations={dateUnexusOccupations} routerOccupations={dateUnexusRouterOccupations} />
            </div>
            <div className="form-section">
                <div className="form-group mb-4">
                    <label className="input-label d-inline-block me-2">Toon bezettingsberekening voor dag: </label>
                    <DaySelectorInline selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
                    <InfoPopover
                        id="dayPopover"
                        body="Hiermee kun je zien hoe de algemene basisbezetting van een taak berekend wordt. De berekende algemene basisbezetting wordt bijvoorbeeld gebruikt bij het genereren van een basisrooster."
                    />
                </div>
                <UnexusRouterOccupationsView referenceDates={dayReferenceDates} occupations={dayUnexusOccupations} routerOccupations={dayUnexusRouterOccupations} />
            </div>
        </RegularPage>
    )
}

export default OccupationSourcePage
