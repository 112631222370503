import { FC, useMemo } from "react"
import { useLocations, useRoles } from "../../../contexts/UserSettingsContext"
import { dateToDjango } from "../../../helpers/DaysHelper"
import { getRoleBadge } from "../../../helpers/RolesHelper"
import { ScheduleItem, ScheduleMap, StaticShift } from "../../../helpers/ScheduleMapHelper"
import LocationType from "../../../types/LocationType"
import RoleType from "../../../types/RoleType"
import StaticDayItem from "./StaticDayItem"

interface Props {
    date: Date
    scheduleMap: ScheduleMap
}

interface StaticDayItemProps {
    startSlot: number
    endSlot: number
    role?: RoleType
    location?: LocationType
    firstOfLocation: boolean
}

const StaticDaySchedule: FC<Props> = ({ date, scheduleMap }) => {
    const locations = useLocations()
    const roles = useRoles()

    const shiftItems = useMemo(() => {
        const items: StaticDayItemProps[] = []

        let shifts: StaticShift[] = []
        scheduleMap.forEach((dateMap) => {
            const userMap = dateMap.get(dateToDjango(date))
            if (!userMap) {
                return []
            }
            const scheduleItem: ScheduleItem = userMap.values().next().value
            shifts = [...shifts, ...scheduleItem.shifts]
        })
        shifts.sort((a, b) => (a.startSlot < b.startSlot ? -1 : 1))

        let previousLocationId: number = -1
        for (let i = 0; i < shifts.length; i++) {
            const shift = shifts[i]
            const { startSlot, endSlot } = shift
            const role = roles.find((r) => r.id === shift.role)!
            const location = locations.length > 1 ? locations.find((l) => l.id === role.location) : undefined
            const firstOfLocation = previousLocationId !== role.location
            previousLocationId = role.location

            items.push({ startSlot, endSlot, role, location, firstOfLocation })
            if (i < shifts.length - 1) {
                const nextShift = shifts[i + 1]
                const gap = nextShift.startSlot - shift.endSlot - 1
                if (gap > 0 && gap <= 4) {
                    items.push({ startSlot: shift.endSlot + 1, endSlot: nextShift.startSlot - 1, role: undefined, location, firstOfLocation: false })
                }
            }
        }

        return items
    }, [scheduleMap, date])

    return (
        <table className="w-100">
            <tbody>
                {shiftItems.length > 0 ? (
                    shiftItems.map(({ startSlot, endSlot, role, location, firstOfLocation }) => (
                        <StaticDayItem
                            key={`${startSlot}_${endSlot}_${role?.id}_${location?.id}`}
                            startSlot={startSlot}
                            endSlot={endSlot}
                            role={role}
                            location={location}
                            firstOfLocation={firstOfLocation}
                        />
                    ))
                ) : (
                    <tr>
                        <td>{getRoleBadge()}</td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}

export default StaticDaySchedule
