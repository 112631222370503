import { FieldValues, Path, UseFormSetError } from "react-hook-form"

export const setOptionalError = <Type extends FieldValues>(setError: UseFormSetError<Type>, key: `root.${string}` | "root" | Path<Type>, error?: string) => {
    if (error) {
        setError(key, { type: "manual", message: error })
    }
}

export const formDataHeader = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
}
