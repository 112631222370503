import { useCallback, useContext, useEffect, useState } from "react"
import { Badge, Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import FormErrorMessages from "../../components/form/FormErrorMessages"
import RoleArray from "../../components/form/RoleArray"
import UserArray from "../../components/form/UserArray"
import FullPageCard from "../../components/page/FullPageCard"
import { useInitMode, UserSettingsContext } from "../../contexts/UserSettingsContext"
import { ROLE_TYPE_REGULAR } from "../../helpers/Constants"
import { createEnvironmentGeneration, CreateEnvironmentGenerationRequest } from "../../services/EnvironmentGeneration"
import { UserInitMode } from "../../types/UserType"

const defaultValues: CreateEnvironmentGenerationRequest = {
    users: [
        { firstName: "", lastName: "" },
        { firstName: "", lastName: "" },
    ],
    roles: [
        { name: "", type: ROLE_TYPE_REGULAR },
        { name: "", type: ROLE_TYPE_REGULAR },
    ],
}

const GenerateEnvironmentPage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { reloadUserSettings } = useContext(UserSettingsContext)
    const initMode = useInitMode()
    const [formErrors, setFormErrors] = useState<string[]>([])

    const { control, register, handleSubmit, getValues, setValue } = useForm({
        defaultValues,
    })

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setFormErrors(data.nonFieldErrors || [t("Main.something_went_wrong")])
        },
        [setFormErrors]
    )

    const onSubmit = useCallback(
        (data: CreateEnvironmentGenerationRequest) => {
            createEnvironmentGeneration(data).then(reloadUserSettings).catch(onFailure)
        },
        [reloadUserSettings, onFailure]
    )

    useEffect(() => {
        if (initMode >= UserInitMode.INITIALIZING_ENVIRONMENT) {
            navigate("/omgeving-initialisatie")
        }
    }, [initMode])

    return (
        <FullPageCard>
            <div className="card-header-bar">
                <div className="card-header-bar-item">
                    <h2>Beginnen met Rooster Planner</h2>
                </div>
                <div className="card-header-bar-item end">
                    <Badge>2/3</Badge>
                </div>
            </div>
            <p>Om met een gevuld rooster te beginnen kun je eenvoudig enkele medewerkers en taken registreren. Deze medewerkers en taken kunnen later verwijderd/aangepast worden.</p>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <p className="text-bold">Gebruikers:</p>
                <div className="form-section mb-4">
                    <UserArray control={control} register={register} />
                </div>
                <p className="text-bold">Taken:</p>
                <div className="form-section mb-4">
                    <RoleArray control={control} register={register} setValue={setValue} getValues={getValues} />
                </div>
                <Button type="submit" data-cy="submit-button">
                    Volgende
                </Button>
            </Form>
            <FormErrorMessages errors={formErrors} />
        </FullPageCard>
    )
}

export default GenerateEnvironmentPage
