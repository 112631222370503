import { MouseEvent } from "react"
import { FilterSelectOption } from "../components/table/TableFilters"
import PageRequestType from "../types/PageRequestType"
import { NestedRoleItem } from "./RolesHelper"

export function withParams(url: string, pageDetails: PageRequestType) {
    const { pageIndex, pageSize, filters } = pageDetails
    url += `?pageSize=${pageSize}&page=${pageIndex + 1}`
    if (filters) {
        for (let { key, value } of filters) {
            if (value !== "") {
                url += `&${key}=${value}`
            }
        }
    }
    return url
}

interface RoleFilterOption extends FilterSelectOption {
    checked: boolean
}

export function getRoleFilterOptions(nestedRoles: NestedRoleItem[], includeFreeRole: boolean, checkedFreeRole: boolean) {
    let roleFilterOptions: RoleFilterOption[] = []
    if (nestedRoles) {
        if (includeFreeRole) {
            roleFilterOptions.push({ key: "0", title: "Vrij", checked: checkedFreeRole, parent: undefined, children: undefined })
        }
        for (let i in nestedRoles) {
            roleFilterOptions.push(getRoleFilterOption(nestedRoles[i]))
            if (nestedRoles[i].subRoles) {
                for (const subRole of nestedRoles[i].subRoles!) {
                    roleFilterOptions.push(getRoleFilterOption(subRole))
                }
            }
        }
    }
    return roleFilterOptions
}

function getRoleFilterOption(item: NestedRoleItem) {
    return {
        key: item.uid,
        title: item.name,
        checked: true,
        parent: item.parent,
        children: item.subRoles ? item.subRoles.map((i) => "r" + i.id) : undefined,
    } as RoleFilterOption
}

export function getEnabledRoles(roleFilterValues: string[]) {
    let enabledRoles: number[] = []
    for (let i in roleFilterValues) {
        const value = roleFilterValues[i].toString()
        if (value.startsWith("r")) {
            enabledRoles.push(parseInt(value.substring(1)))
        } else if (value === "0") {
            enabledRoles.push(0)
        }
    }
    return enabledRoles
}

export function onClickFilter(e: MouseEvent): void {
    if (e.target instanceof HTMLElement) {
        if (e.target.classList.contains("close-filters")) {
            return
        }
    }
    e.stopPropagation()
}
