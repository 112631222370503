import { FC, useCallback, useContext, useMemo } from "react"
import { ScheduleEditorContext, useScheduleEditorState } from "../../contexts/ScheduleEditorContext"
import { toScheduleMapFromCopy } from "../../helpers/ScheduleMapHelper"
import CopyModal from "../editor/copyModal/CopyModal"

interface Props {
    visible: boolean
    close: () => void
}

const CopyShiftDayModal: FC<Props> = ({ visible, close }) => {
    const { applyMutations } = useContext(ScheduleEditorContext)
    const editorState = useScheduleEditorState()

    const location = useMemo(() => editorState.location, [editorState.location])
    const enabledDays = useMemo(() => location.enabledDays, [location])
    const shiftTemplate = useMemo(() => editorState.shiftTemplate, [editorState.shiftTemplate])
    const weekCycle = useMemo(() => (shiftTemplate ? shiftTemplate.weekCycle : 1), [shiftTemplate])

    const copyDay = useCallback(
        (fromDay: string, toDays: string[]) => {
            applyMutations(toScheduleMapFromCopy(editorState.mergedSchedule, fromDay, toDays, location.startSlot, location.endSlot))
            close()
        },
        [editorState, location, applyMutations, close]
    )

    return <CopyModal visible={visible} close={close} weekCycle={weekCycle} enabledDays={enabledDays} copyDay={copyDay} />
}

export default CopyShiftDayModal
