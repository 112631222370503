import { FC, useMemo } from "react"
import { useColors } from "../../../contexts/UserSettingsContext"
import { getCSSColor } from "../../../helpers/ColorHelper"
import RoleType from "../../../types/RoleType"
import styles from "./StaticWeekTimeLineItem.module.scss"

interface Props {
    startSlot: number
    endSlot: number
    role?: RoleType
}

const StaticWeekTimeLineItem: FC<Props> = ({ startSlot, endSlot, role }) => {
    const colors = useColors()

    const color = useMemo(() => {
        if (!role) {
            return undefined
        } else {
            return getCSSColor(colors.get(role.color)!)
        }
    }, [colors, role])

    const flex = useMemo(() => `${(endSlot + 1 - startSlot) * 10} ${(endSlot + 1 - startSlot) * 10} 1px`, [startSlot, endSlot])

    return (
        <div className={styles.timeLineItem} style={{ flex: flex, backgroundColor: color }} data-cy="timeLineItem">
            {role ? <div className={styles.timeLineItemLabel}>{role.name}</div> : null}
        </div>
    )
}

export default StaticWeekTimeLineItem
