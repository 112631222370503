import { dateToDjango } from "../helpers/DaysHelper"
import { toObject } from "../helpers/MapHelper"
import { getRoleGroupIds, getRoleIds } from "../helpers/RolesHelper"
import { ScheduleMap } from "../helpers/ScheduleMapHelper"
import GeneratedMutationType from "../types/GeneratedMutationType"
import { ShiftToImportType } from "../types/ShiftImportType"
import api from "./Api"

export interface TaskType<TaskResultType> {
    id: number
    done: boolean
    progress: number
    success: boolean
    result: TaskResultType
    resultUrl: string
    errorId?: string
}

export interface TaskResultWithRoles {
    roleUids: string[]
}

export type IntervalType = "QUARTER" | "DAY" | "WEEK" | "MONTH"

// ----- Create inputs -----

export interface CreateTaskRoleTimeStats {
    location: number
    interval: IntervalType
    roleUids: string[]
    fromDate: Date
    toDate: Date
}

export interface CreateTaskUserTimeStats {
    location: number
    interval: IntervalType
    users: string[]
    fromDate: Date
    toDate: Date
}

export interface CreateTaskForecastStats {
    location: number
    interval: IntervalType
    roleUids: string[]
    fromDate: Date
    toDate: Date
}

export interface CreateTaskBreakMutations {
    mode: "DAYS" | "DATERANGE"
    location: number
    shiftTemplate?: number
    fromDate: Date
    toDate: Date
    days?: string
    breaks: string[]
    users: string[]
}

export interface CreateTaskShiftAdditions {
    location: number
    shiftTemplate: number
    newShiftMutations: ScheduleMap
    days: string
    roles: number[]
    users: number[]
}

export interface CreateTaskOccupationProblems {
    mode: "DAYS" | "DATERANGE"
    location: number
    shiftTemplate?: number
    newShiftMutations: ScheduleMap
    date?: Date
    day?: number
}

// Provide [shiftTemplate + day] xor [date]
// Provide [slot] xor [startSlot, endSlot, type]
export interface CreateTaskOccupationFixes {
    mode: "DAYS" | "DATERANGE"
    location: number
    shiftTemplate?: number
    newShiftMutations: ScheduleMap
    date?: Date
    day?: number
    roleUid: string
    slot?: number
    startSlot?: number
    endSlot?: number
    type?: "UNDER" | "OVER"
}

export interface CreateTaskShiftImport {
    location: number
    role: number
    shifts: ShiftToImportType[]
}

// ----- Results -----

export interface TaskRoleTimeResult {
    interval: IntervalType
    fromDate: string
    toDate: string
    roleUids: string[]
    roleData: any[]
    rolesAggregate: any
}

export interface TaskUserTimeResult {
    interval: IntervalType
    fromDate: string
    toDate: string
    roleUids: string[]
    roleData: any[]
    rolesAggregate: any
}

export interface TaskForecastResult {
    interval: IntervalType
    fromDate: string
    toDate: string
    roleUids: string[]
    roleData: any
    rolesAggregate: any
}

export interface TaskGeneratedMutationsResult {
    mutations: GeneratedMutationType[]
}

export interface TaskBreakMutationsResult extends TaskGeneratedMutationsResult {}

export interface TaskShiftsResult {}

export interface ShiftMutationOptionType {
    user: number
    startSlot: number
    endSlot: number
    score: number
}

export interface TaskShiftAdditionsResult extends TaskGeneratedMutationsResult {}

export interface OccupationProblemType {
    roleUid: string
    startSlot: number
    endSlot: number
    type: "OVER" | "UNDER"
}

export interface TaskOccupationProblemsResult {
    problems: OccupationProblemType[]
}

export interface OccupationFixMutationType {
    dayId: string
    location: number
    user: number
    role: number
    originalRole: number
    startSlot: number
    endSlot: number
}

export interface OccupationFixType {
    index: number
    score: number
    mutations: OccupationFixMutationType[]
}

export interface TaskOccupationFixesResult {
    fixes: OccupationFixType[]
}

export interface TaskShiftImportResult extends TaskGeneratedMutationsResult {}

// ----- Create functions -----

export function createTaskRoleTimeStats(details: CreateTaskRoleTimeStats) {
    const { location, interval, roleUids, fromDate, toDate } = details
    const body = {
        location,
        interval,
        roles: getRoleIds(roleUids),
        roleGroups: getRoleGroupIds(roleUids),
        fromDate: dateToDjango(fromDate),
        toDate: dateToDjango(toDate),
    }
    return api.post<TaskType<TaskRoleTimeResult>>(`/tasks/?type=ROLE_TIME`, body)
}

export function createTaskUserTimeStats(details: CreateTaskUserTimeStats) {
    const { location, interval, users, fromDate, toDate } = details
    const body = {
        location,
        interval,
        users: users.map((id) => parseInt(id)),
        fromDate: dateToDjango(fromDate),
        toDate: dateToDjango(toDate),
    }
    return api.post<TaskType<TaskUserTimeResult>>(`/tasks/?type=USER_TIME`, body)
}

export function createTaskForecastStatus(details: CreateTaskForecastStats) {
    const { location, interval, roleUids, fromDate, toDate } = details
    const body = {
        location,
        interval,
        roles: getRoleIds(roleUids),
        roleGroups: getRoleGroupIds(roleUids),
        fromDate: dateToDjango(fromDate),
        toDate: dateToDjango(toDate),
    }
    return api.post<TaskType<TaskForecastResult>>(`/tasks/?type=FORECAST`, body)
}

export function createTaskBreakMutations(details: CreateTaskBreakMutations) {
    const { mode, location, shiftTemplate, fromDate, toDate, days, breaks, users } = details
    const body = {
        mode,
        location,
        shiftTemplate,
        fromDate: dateToDjango(fromDate),
        toDate: dateToDjango(toDate),
        days: days,
        breaks: breaks.map((id) => parseInt(id)),
        users: users.map((id) => parseInt(id)),
    }
    return api.post<TaskType<TaskBreakMutationsResult>>(`/tasks/?type=BREAK_MUTATIONS`, body)
}

export function createTaskShiftAdditions(details: CreateTaskShiftAdditions) {
    const { location, shiftTemplate, newShiftMutations, days, users, roles } = details
    const body = {
        location,
        shiftTemplate,
        newShiftMutations: toObject(newShiftMutations),
        days,
        users,
        roles,
    }

    return api.post<TaskType<TaskShiftAdditionsResult>>(`/tasks/?type=SHIFT_ADDITIONS`, body)
}

export function createTaskOccupationProblems(details: CreateTaskOccupationProblems) {
    const { mode, location, shiftTemplate, newShiftMutations, date, day } = details
    const body = {
        mode,
        location,
        shiftTemplate,
        newShiftMutations: toObject(newShiftMutations),
        date: date ? dateToDjango(date) : undefined,
        day,
    }
    return api.post<TaskType<TaskOccupationProblemsResult>>(`/tasks/?type=OCCUPATION_PROBLEMS`, body)
}

export function createTaskOccupationFixes(details: CreateTaskOccupationFixes) {
    const { mode, location, shiftTemplate, newShiftMutations, date, day, slot, roleUid, startSlot, endSlot, type } = details
    const body = {
        mode,
        location,
        shiftTemplate,
        newShiftMutations: toObject(newShiftMutations),
        date: date ? dateToDjango(date) : undefined,
        day,
        roleUid,
        slot,
        startSlot,
        endSlot,
        type,
    }
    return api.post<TaskType<TaskOccupationProblemsResult>>(`/tasks/?type=OCCUPATION_FIXES`, body)
}

export function createTaskShiftImport(details: CreateTaskShiftImport) {
    return api.post<TaskType<TaskShiftImportResult>>(`/tasks/?type=SHIFT_IMPORT`, details)
}

// ----- Retrieval -----

export function loadTask<TaskResultType>(id: number) {
    return new Promise<TaskType<TaskResultType>>((resolve) => {
        getTask<TaskResultType>(id).then((response) => resolve(response.data))
    })
}

export function getTask<TaskResultType>(id: number) {
    return api.get<TaskType<TaskResultType>>(`/tasks/${id}/`)
}
