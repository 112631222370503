import { ChangeEvent, FC, useCallback } from "react"
import { Form } from "react-bootstrap"
import { useUsersForSelectedLocation } from "../../contexts/UserSettingsContext"

interface Props {
    setSelectedUser: (user: number) => void
}

const UserSelect: FC<Props> = ({ setSelectedUser }) => {
    const users = useUsersForSelectedLocation()

    const onChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => setSelectedUser(parseInt(event.target.value)), [setSelectedUser])

    return (
        <Form.Select className="form-select-inline" onChange={onChange} data-cy="userSelect">
            {users.map(({ id, firstName, lastName }) => (
                <option key={id} value={id}>
                    {firstName} {lastName}
                </option>
            ))}
        </Form.Select>
    )
}

export default UserSelect
