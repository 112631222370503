export default interface EnvironmentGenerationType {
    id: number
    createdUsers: EnvironmentGenerationStepStatus
    createdRoles: EnvironmentGenerationStepStatus
    createdOccupationTemplate: EnvironmentGenerationStepStatus
    createdShiftTemplate: EnvironmentGenerationStepStatus
    done: boolean
}

export enum EnvironmentGenerationStepStatus {
    TODO = 0,
    EXECUTING = 1,
    FAILED = 2,
    SUCCEEDED = 3,
}
