import { PaginationState } from "@tanstack/react-table"
import { useCallback } from "react"
import { TableFilterDef, TableFilterValue } from "../components/table/Table"
import { useOptionalCurrentUser } from "../contexts/UserSettingsContext"

const useGetFilterCacheKey = () => {
    const currentUser = useOptionalCurrentUser()
    return useCallback((contextId: string, valueId: string) => `${currentUser?.id}_${contextId}_${valueId}`, [currentUser])
}

export const useGetPaginationCacheKey = () => {
    const currentUser = useOptionalCurrentUser()
    return useCallback((contextId: string, value: "pageIndex" | "pageSize") => `${currentUser?.id}_${contextId}_${value}`, [currentUser])
}

export const useGetSelectedLocationCacheKey = () => {
    const getFilterCacheKey = useGetFilterCacheKey()
    return getFilterCacheKey("UserSettings", "location")
}

export const useGetInitialValues = () => {
    const getFilterCacheKey = useGetFilterCacheKey()

    const getInitialValue = useCallback(
        (contextId: string, initialValue: TableFilterValue): TableFilterValue => {
            const cacheKey = getFilterCacheKey(contextId, initialValue.key)
            const cacheValue = sessionStorage.getItem(cacheKey)
            return {
                key: initialValue.key,
                value: cacheValue !== null ? cacheValue : initialValue.value,
            }
        },
        [getFilterCacheKey]
    )

    const getInitialValuesForFilter = useCallback(
        (contextId: string, filter: TableFilterDef): TableFilterValue[] => {
            return filter.initialValues.map((v) => getInitialValue(contextId, v))
        },
        [getInitialValue]
    )

    return useCallback(
        (contextId: string, filters: TableFilterDef[]): TableFilterValue[] => {
            return filters.flatMap((f) => getInitialValuesForFilter(contextId, f))
        },
        [getInitialValuesForFilter]
    )
}

export const useGetPaginationValue = () => {
    const getPaginationCacheKey = useGetPaginationCacheKey()

    return useCallback(
        (contextId: string): PaginationState => {
            const indexCacheKey = getPaginationCacheKey(contextId, "pageIndex")
            const sizeCacheKey = getPaginationCacheKey(contextId, "pageSize")
            const indexCacheValue = sessionStorage.getItem(indexCacheKey)
            const sizeCacheValue = sessionStorage.getItem(sizeCacheKey)
            return {
                pageIndex: indexCacheValue !== null ? parseInt(indexCacheValue) : 0,
                pageSize: sizeCacheValue !== null ? parseInt(sizeCacheValue) : 10,
            }
        },
        [getPaginationCacheKey]
    )
}
