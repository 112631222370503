import { FC, useCallback } from "react"
import DayOption from "./DayOption"

interface DayOptionsProps {
    enabledDays: string
    weekCycle: number
    selectedDays: string
    setSelectedDays: (newValue: string) => void
    disabled: boolean
}

const DayOptions: FC<DayOptionsProps> = ({ enabledDays, weekCycle, selectedDays, disabled, setSelectedDays }) => {
    const toggleDay = useCallback(
        (index: number) => {
            const newValue = selectedDays.substring(0, index) + (selectedDays[index] === "0" ? "1" : "0") + selectedDays.substring(index + 1, weekCycle * 7)
            setSelectedDays(newValue)
        },
        [selectedDays, setSelectedDays, weekCycle]
    )

    const rows = []
    for (let i = 0; i < weekCycle; i++) {
        rows.push(
            <tr key={`week${i}`}>
                {weekCycle > 1 ? (
                    <td className="pe-2">
                        <small>Week {i + 1}:</small>
                    </td>
                ) : null}
                <td>
                    <ul className="multi-selectable-segment mt-auto">
                        <DayOption index={0 + i * 7} enabledDays={enabledDays} selectedDays={selectedDays} disabled={disabled} toggleDay={toggleDay} />
                        <DayOption index={1 + i * 7} enabledDays={enabledDays} selectedDays={selectedDays} disabled={disabled} toggleDay={toggleDay} />
                        <DayOption index={2 + i * 7} enabledDays={enabledDays} selectedDays={selectedDays} disabled={disabled} toggleDay={toggleDay} />
                        <DayOption index={3 + i * 7} enabledDays={enabledDays} selectedDays={selectedDays} disabled={disabled} toggleDay={toggleDay} />
                        <DayOption index={4 + i * 7} enabledDays={enabledDays} selectedDays={selectedDays} disabled={disabled} toggleDay={toggleDay} />
                        <DayOption index={5 + i * 7} enabledDays={enabledDays} selectedDays={selectedDays} disabled={disabled} toggleDay={toggleDay} />
                        <DayOption index={6 + i * 7} enabledDays={enabledDays} selectedDays={selectedDays} disabled={disabled} toggleDay={toggleDay} />
                    </ul>
                </td>
            </tr>
        )
    }

    return (
        <table>
            <tbody>{rows}</tbody>
        </table>
    )
}

export default DayOptions
