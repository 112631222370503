import { FC, ReactNode } from "react"
import styles from "./FullPageCard.module.scss"

interface FullPageCardProps {
    children?: ReactNode
}

const FullPageCard: FC<FullPageCardProps> = ({ children }) => {
    return (
        <div className="w-100 pt-4">
            <div className="card col-md-8 col-xl-6 offset-md-2 offset-xl-3">
                <div className={styles.fullPageCardBody}>{children}</div>
            </div>
        </div>
    )
}

export default FullPageCard
