import { FC } from "react"
import { Link } from "react-router-dom"

interface BreadcrumbComponentProps {
    title: string
    link?: string
    active?: boolean
}

const BreadcrumbComponent: FC<BreadcrumbComponentProps> = ({ title, link, active }) => {
    return (
        <li className={"breadcrumb-item" + (link ? "" : " active")} aria-current={active ? "page" : undefined}>
            {link ? <Link to={link}>{title}</Link> : title}
        </li>
    )
}

export default BreadcrumbComponent
