import { FC, ReactElement } from "react"
import { Navigate } from "react-router-dom"
import { useInitMode, useIsAdmin, useIsAuthenticated } from "../contexts/UserSettingsContext"
import { UserInitMode } from "../types/UserType"

interface AdminElementProps {
    children: ReactElement<any, any>
}

const AdminElement: FC<AdminElementProps> = ({ children }) => {
    const isAuthenticated = useIsAuthenticated()
    const isAdmin = useIsAdmin()
    const initMode = useInitMode()

    if (!isAuthenticated) {
        return <Navigate to="/inloggen" />
    } else if (initMode < UserInitMode.STARTED_TOUR) {
        return <Navigate to="/omgeving-initialiseren" />
    } else if (!isAdmin) {
        return <Navigate to="/dashboard" />
    }
    return children
}

export default AdminElement
