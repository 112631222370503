import { faQuestion } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useMemo } from "react"
import { useColors, useResolveLocalityOptionForSelectedLocation, useUsersForSelectedLocation } from "../../contexts/UserSettingsContext"
import { getDate, getDayName, getTime, getWeekName, isoPrint, prettyPrintTime } from "../../helpers/DaysHelper"
import { getRoleBadge } from "../../helpers/RolesHelper"
import { ScheduleMap } from "../../helpers/ScheduleMapHelper"
import useLocalityOptionIconMap from "../../hooks/UseLocalityOptionIconMap"
import LocalityOptionType from "../../types/LocalityOptionType"
import RoleType from "../../types/RoleType"
import { UserType } from "../../types/UserType"
import styles from "./MutationTable.module.scss"

interface Props {
    mutations: ScheduleMap
    roles: RoleType[]
    mode: "DAY" | "DATE"
    weekCycle?: number
}

interface MutationApprovalType {
    id: string
    dayId: string
    startTime?: Date
    endTime?: Date
    user?: UserType
    role?: RoleType
    locality?: LocalityOptionType
}

const MutationTable: FC<Props> = ({ mutations, roles, mode, weekCycle }) => {
    const resolveLocalityOption = useResolveLocalityOptionForSelectedLocation()
    const iconMap = useLocalityOptionIconMap()
    const colors = useColors()
    const allUsers = useUsersForSelectedLocation()

    const components = useMemo<MutationApprovalType[]>(() => {
        let items: MutationApprovalType[] = []
        mutations.forEach((dayMap, locationId) => {
            dayMap.forEach((userMap, dayId) => {
                userMap.forEach((scheduleItem, userId) => {
                    const user = allUsers.find((u) => u.id.toString() === userId)
                    scheduleItem.shifts.forEach((mutation) => {
                        const { startSlot, endSlot, role: roleId } = mutation
                        const role = roles.find((r) => r.id === roleId)
                        items.push({
                            id: `${locationId}_${dayId}_${userId}_${startSlot}_${roleId}`,
                            dayId,
                            startTime: getTime("start", startSlot),
                            endTime: getTime("end", endSlot),
                            role,
                            user,
                        })
                    })
                    if (scheduleItem.locality !== "INHERIT") {
                        items.push({
                            id: `${locationId}_${dayId}_${userId}_l`,
                            dayId,
                            user,
                            locality: scheduleItem.locality !== "UNKNOWN" ? resolveLocalityOption(scheduleItem.locality) : undefined,
                        })
                    }
                })
            })
        })
        return items
    }, [mutations, allUsers, roles])

    return (
        <table>
            <tbody>
                {components.map((item) => (
                    <tr key={item.id}>
                        <td className="p-1 text-small">
                            {mode === "DATE" ? <span>{isoPrint(getDate(item.dayId))}</span> : null}
                            {mode === "DAY" ? <span className="d-inline-block me-1">{getDayName(parseInt(item.dayId))}</span> : null}
                            {mode === "DAY" && weekCycle !== undefined && weekCycle > 1 ? <span className="d-inline-block text-muted">({getWeekName(parseInt(item.dayId))})</span> : null}
                        </td>
                        {item.startTime ? (
                            <>
                                <td className="p-1 text-small text-center text-muted">{item.startTime ? prettyPrintTime(item.startTime) : ""}</td>
                                <td> - </td>
                                <td className="p-1 text-small text-center text-muted">{item.endTime ? prettyPrintTime(item.endTime) : ""}</td>
                                <td className="p-1 text-bold">
                                    {item.user?.firstName} {item.user?.lastName}
                                </td>
                                <td className="p-1">{getRoleBadge(item.role, colors)}</td>
                            </>
                        ) : (
                            <>
                                <td colSpan={3}></td>
                                <td className="p-1 text-bold">
                                    {item.user?.firstName} {item.user?.lastName}
                                </td>
                                <td className="p-1 text-small">
                                    <div className={styles.icon}>
                                        <FontAwesomeIcon icon={item.locality?.icon ? iconMap.get(item.locality?.icon)! : faQuestion} />
                                    </div>
                                </td>
                            </>
                        )}
                    </tr>
                ))}
                {components.length === 0 ? (
                    <tr>
                        <td className="p-1 text-small">Geen</td>
                    </tr>
                ) : null}
            </tbody>
        </table>
    )
}

export default MutationTable
