import { withParams } from "../helpers/FilterHelper"
import AbsenceRequestType, { CreateAbsenceRequestType, UpdateAbsenceRequestType } from "../types/AbsenceRequestType"
import PageRequestType from "../types/PageRequestType"
import PageResponseType from "../types/PageResponseType"
import api from "./Api"

export function loadAbsenceRequests(request: PageRequestType): Promise<PageResponseType<AbsenceRequestType>> {
    return new Promise<PageResponseType<AbsenceRequestType>>((resolve, reject) =>
        getAbsenceRequests(request)
            .then((response) => resolve(response.data))
            .catch(reject)
    )
}

export function getAbsenceRequests(request: PageRequestType) {
    return api.get<PageResponseType<AbsenceRequestType>>(withParams("/absence-requests/", request))
}

export function getAbsenceRequest(id: number) {
    return api.get<AbsenceRequestType>(`/absence-requests/${id}/`)
}

export function loadAbsenceRequest(id: number) {
    return () =>
        new Promise<AbsenceRequestType>((resolve) => {
            getAbsenceRequest(id).then((response) => resolve(response.data))
        })
}

export function createAbsenceRequest(absenceRequest: CreateAbsenceRequestType) {
    return api.post("/absence-requests/", absenceRequest)
}

export function updateAbsenceRequest(absenceRequest: UpdateAbsenceRequestType) {
    return api.patch(`/absence-requests/${absenceRequest.id}/`, absenceRequest)
}

export function deleteAbsenceRequest(id: number) {
    return api.delete(`/absence-requests/${id}/`)
}
