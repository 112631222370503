import OccupationType, { SourcedDayOccupationType } from "../types/OccupationType"
import { ensureListForKey, ensureMapForKey } from "./MapHelper"

export function mergeOccupationMutation(mutations: OccupationScheduleMap, newMutation: StaticOccupation, dayId: string, roleUid: string): OccupationScheduleMap {
    const newMutationsForDayAndRole = mergeMutation(getOccupations(mutations, dayId, roleUid), newMutation)
    const map: OccupationScheduleMap = new Map(mutations)
    ensureDay(map, dayId)
    map.get(dayId)!.set(roleUid, newMutationsForDayAndRole)
    return map
}

export function mergeMaps(map1: OccupationScheduleMap, map2: OccupationScheduleMap): OccupationScheduleMap {
    const map = new Map(map1)
    map2.forEach((dayMap2, dayId) => {
        dayMap2.forEach((occupations, roleUid) => {
            ensureDayAndRole(map, dayId, roleUid)
            const dayMap1 = map.get(dayId)!
            const mergedList = mergeLists(dayMap1.get(roleUid)!, occupations)
            dayMap1.set(roleUid, mergedList)
        })
    })
    return map
}

export function mergeLists(list1: StaticOccupation[], list2: StaticOccupation[]): StaticOccupation[] {
    let list = [...list1]
    for (const occupation of list2) {
        list = mergeMutation(list, occupation)
    }
    return list
}

export function mergeMutation(occupations: StaticOccupation[], occupationMutation: StaticOccupation) {
    const newOccupations = []

    for (const occupation of occupations) {
        // If mutations overlaps
        if (occupation.startSlot <= occupationMutation.endSlot && occupation.endSlot >= occupationMutation.startSlot) {
            // If shift has a remainder before mutation, add new shift
            if (occupation.startSlot < occupationMutation.startSlot) {
                newOccupations.push({
                    startSlot: occupation.startSlot,
                    endSlot: occupationMutation.startSlot - 1,
                    min: occupation.min,
                    max: occupation.max,
                })
            }
            // If shift has a remainder after mutation, add new shift
            if (occupation.endSlot > occupationMutation.endSlot) {
                newOccupations.push({
                    startSlot: occupationMutation.endSlot + 1,
                    endSlot: occupation.endSlot,
                    min: occupation.min,
                    max: occupation.max,
                })
            }
        } else {
            newOccupations.push(occupation)
        }
    }
    // Add new occupation for mutation
    newOccupations.push({
        startSlot: occupationMutation.startSlot,
        endSlot: occupationMutation.endSlot,
        min: occupationMutation.min,
        max: occupationMutation.max,
    })

    return mergeOccupations(newOccupations)
}

export function mergeOccupations(occupations: StaticOccupation[]) {
    const newOccupations = [...occupations]

    newOccupations.sort((a, b) => (a.startSlot < b.startSlot ? -1 : 1))
    let i = 1
    while (i < newOccupations.length) {
        // If occupations are right after eachother and have the same min and max
        if (newOccupations[i].startSlot <= newOccupations[i - 1].endSlot + 1 && newOccupations[i].min === newOccupations[i - 1].min && newOccupations[i].max === newOccupations[i - 1].max) {
            // Set endslot of first occupation to max endslot
            newOccupations[i - 1].endSlot = newOccupations[i].endSlot
            // And remove the second occupation
            newOccupations.splice(i, 1)
        } else {
            i++
        }
    }

    return newOccupations
}

// ----------- Editor cells -----------

export const getCellValues = (startSlot: number, endSlot: number, occupations: StaticOccupation[]) => {
    const values: (number | null)[][] = []
    const sortedOccupations = occupations.sort((a, b) => (a.startSlot > b.startSlot ? 1 : -1))
    const occupationIterator = sortedOccupations[Symbol.iterator]()
    let currentOccupation: StaticOccupation | undefined
    for (let i = startSlot; i <= endSlot; i++) {
        if (!currentOccupation || i > currentOccupation.endSlot) {
            currentOccupation = occupationIterator.next().value
        }
        if (currentOccupation && currentOccupation.startSlot <= i && currentOccupation.endSlot >= i) {
            values.push([currentOccupation.min, currentOccupation.max])
        } else {
            values.push([null, null])
        }
    }
    return values
}

// ----------- Utils -----------

export type OccupationScheduleMap = Map<string, Map<string, StaticOccupation[]>>

export interface StaticOccupation {
    startSlot: number
    endSlot: number
    min: number | null
    max: number | null
}

export function toOccupationScheduleMap(occupations: OccupationType[], sourcedOccupations: SourcedDayOccupationType[]): OccupationScheduleMap {
    return toDayRoleMap(
        occupations,
        sourcedOccupations,
        (occupation: OccupationType | SourcedDayOccupationType) => occupation.day.toString(),
        (occupation: OccupationType | SourcedDayOccupationType) => (occupation.role ? `r${occupation.role}` : `g${occupation.roleGroup}`),
        (occupation: OccupationType | SourcedDayOccupationType) => {
            return {
                startSlot: occupation.startSlot,
                endSlot: occupation.endSlot,
                min: occupation.min,
                max: occupation.max,
            }
        }
    )
}

export function toDayRoleMap<AnyOccupationType, AnySourcedOccupationType>(
    occupations: AnyOccupationType[],
    sourcedOccupations: AnySourcedOccupationType[],
    getDayId: (occupation: AnyOccupationType | AnySourcedOccupationType) => string,
    getRoleUid: (occupation: AnyOccupationType | AnySourcedOccupationType) => string,
    convert: (occupation: AnyOccupationType | AnySourcedOccupationType) => StaticOccupation
): OccupationScheduleMap {
    const map: OccupationScheduleMap = new Map()
    for (const occupation of occupations) {
        const dayId = getDayId(occupation)
        const roleUid = getRoleUid(occupation)
        ensureDayAndRole(map, dayId, roleUid)
        map.get(dayId)!.get(roleUid)!.push(convert(occupation))
    }
    for (const occupation of sourcedOccupations) {
        const dayId = getDayId(occupation)
        const roleUid = getRoleUid(occupation)
        ensureDayAndRole(map, dayId, roleUid)
        map.get(dayId)!.get(roleUid)!.push(convert(occupation))
    }
    return map
}

export function ensureDay(map: OccupationScheduleMap, dayId: string): void {
    ensureMapForKey(map, dayId)
}

export function ensureDayAndRole(map: OccupationScheduleMap, dayId: string, roleUid: string): void {
    ensureMapForKey(map, dayId)
    const dayMap = map.get(dayId)!
    ensureListForKey(dayMap, roleUid)
}

export function getOccupations(map: OccupationScheduleMap, dayId: string, roleUid: string): StaticOccupation[] {
    const mapForDay: Map<string, StaticOccupation[]> = map.get(dayId) ?? new Map()
    return mapForDay.get(roleUid) ?? []
}

export function toOccupationScheduleMapFromCopy(original: OccupationScheduleMap, fromDay: string, toDays: string[], startSlot: number, endSlot: number) {
    const map: OccupationScheduleMap = new Map()

    for (const dayId of toDays) {
        ensureMapForKey(map, dayId)
        const dayMap = map.get(dayId)!

        // Reset all occupations in target day, in order to mutate all slots to [null, null] if there are no occupations in the fromDay.
        const originalDay: Map<string, StaticOccupation[]> = original.get(dayId) || new Map()
        originalDay.forEach((_, roleUid) => {
            dayMap.set(roleUid, [
                {
                    startSlot,
                    endSlot,
                    min: null,
                    max: null,
                },
            ])
        })

        // Copy all occupations from source day
        original.get(fromDay)!.forEach((originalOccupations, roleUid) => {
            dayMap.set(
                roleUid,
                mergeLists(
                    [
                        {
                            startSlot: startSlot,
                            endSlot: endSlot,
                            min: null,
                            max: null,
                        },
                    ],
                    originalOccupations
                )
            )
        })
    }

    return map
}
