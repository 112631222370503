import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { IntervalType } from "../services/Task"

const useIntervalOptions = () => {
    const { t } = useTranslation()

    return useMemo(() => {
        return [
            { id: "DAY", name: t("per_day") },
            { id: "WEEK", name: t("per week") },
            { id: "MONTH", name: t("per month") },
        ] as { id: IntervalType; name: string }[]
    }, [t])
}

export default useIntervalOptions
