import { FC, useCallback } from "react"
import { Button } from "react-bootstrap"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"
import { getApplicableAvailabilities } from "../../../helpers/AvailabilityHelper"
import { getTimeString } from "../../../helpers/DaysHelper"
import { HiringAvailabilitiesFormProps } from "../../../pages/hireAvailability/HireAvailabilitiesUserPage"
import { HiringAvailabilityStatus } from "../../../types/HiringAvailabilityType"
import { UserDetailedType } from "../../../types/UserType"

interface HiringAvailabilityActionCellProps {
    index: number
    setValue: UseFormSetValue<HiringAvailabilitiesFormProps>
    watch: UseFormWatch<HiringAvailabilitiesFormProps>
    detailedUser: UserDetailedType
}

const HiringAvailabilityActionCell: FC<HiringAvailabilityActionCellProps> = ({ index, setValue, watch, detailedUser }) => {
    const status = watch(`availabilities.${index}.status`)
    const date = watch(`availabilities.${index}.date`)

    const setStatus = useCallback(
        (status: HiringAvailabilityStatus) => {
            setValue(`availabilities.${index}.status`, status)
            if (status === "UNAVAILABLE") {
                setValue(`availabilities.${index}.fromTime`, "")
                setValue(`availabilities.${index}.toTime`, "")
            }
            if (status === "AVAILABLE") {
                const availabilities = getApplicableAvailabilities(detailedUser, date)
                if (availabilities) {
                    const startSlot = Math.min.apply(
                        Math,
                        availabilities.map((a) => a.startSlot)
                    )
                    const endSlot = Math.max.apply(
                        Math,
                        availabilities.map((a) => a.endSlot)
                    )
                    setValue(`availabilities.${index}.fromTime`, getTimeString("start", startSlot))
                    setValue(`availabilities.${index}.toTime`, getTimeString("end", endSlot))
                }
            }
        },
        [detailedUser, date]
    )

    const setAvailable = useCallback(() => setStatus("AVAILABLE"), [setStatus])

    const setUnavailable = useCallback(() => setStatus("UNAVAILABLE"), [setStatus])

    return (
        <ul key={status} className="selectable-segment mb-0">
            <li className="nav-item">
                <Button type="button" variant="selectable" active={status === "AVAILABLE"} onClick={setAvailable} data-cy="setAvailable">
                    Ja
                </Button>
                <Button type="button" variant="selectable" active={status === "UNAVAILABLE"} onClick={setUnavailable} data-cy="setUnavailable">
                    Nee
                </Button>
            </li>
        </ul>
    )
}

export default HiringAvailabilityActionCell
