import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import { ScheduleEditorContext, useScheduleEditorState } from "../../../contexts/ScheduleEditorContext"
import { prettyPrint, prettyPrintTime } from "../../../helpers/DaysHelper"
import { getOptionalDateParam, getOptionalIntParam, getOptionalTimeParam } from "../../../helpers/ParamHelper"
import { ScheduleMap, makeAbsenceMutations } from "../../../helpers/ScheduleMapHelper"
import { loadDetailedUser } from "../../../services/User"
import Loader from "../../Loader"
import MutationTable from "../../editor/MutationTable"

interface Props {
    visible: boolean
    close: () => void
}

const AbsencePlanner: FC<Props> = ({ visible, close }) => {
    const { applyMutations } = useContext(ScheduleEditorContext)
    const editorState = useScheduleEditorState()

    const location = useMemo(() => {
        return editorState.location
    }, [editorState.location])

    const roles = useMemo(() => {
        return editorState.roles || []
    }, [editorState.roles])

    const [searchParams] = useSearchParams()
    const dateFrom = useMemo(() => getOptionalDateParam("startDate", searchParams), [searchParams])
    const timeFrom = useMemo(() => getOptionalTimeParam("startTime", searchParams), [searchParams])
    const dateTo = useMemo(() => getOptionalDateParam("endDate", searchParams), [searchParams])
    const timeTo = useMemo(() => getOptionalTimeParam("endTime", searchParams), [searchParams])
    const roleId = useMemo(() => getOptionalIntParam("role", searchParams), [searchParams])
    const userId = useMemo(() => getOptionalIntParam("user", searchParams), [searchParams])
    const [mutations, setMutations] = useState<ScheduleMap>()

    const { data: user } = useQuery("user-detailed", () => loadDetailedUser(userId!), { enabled: !!userId })

    useEffect(() => {
        if (user && location && dateFrom && dateTo && roleId) {
            setMutations(makeAbsenceMutations(user, location, dateFrom, timeFrom, dateTo, timeTo, roleId))
        }
    }, [user, location, dateFrom, timeFrom, dateTo, timeTo, roleId])

    const submit = useCallback(() => {
        applyMutations(mutations!)
        close()
    }, [applyMutations, mutations, close])

    return (
        <Modal show={visible} onHide={close}>
            <Modal.Header className="justify-content-between">
                <Modal.Title>Verlofaanvraag{user ? ` ${user.firstName} ${user.lastName}` : ""}</Modal.Title>
                <Button type="button" variant="link" onClick={close}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <div className="modal-subheader">
                {dateFrom ? prettyPrint(dateFrom) : ""}
                {timeFrom ? ` ${prettyPrintTime(timeFrom)}` : ""}
                {dateTo ? ` - ${prettyPrint(dateTo)}` : ""}
                {dateTo && timeTo ? ` ${prettyPrintTime(timeTo)}` : ""}
            </div>
            <Modal.Body>
                <div className="text-bold mb-2">Wijzigingen:</div>
                {mutations ? <MutationTable mutations={mutations} roles={roles} mode="DATE" /> : <Loader />}
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary" onClick={submit}>
                    Pas toe in rooster
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default AbsencePlanner
