import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import FormErrorMessages from "../../components/form/FormErrorMessages"
import RegularPage from "../../components/page/RegularPage"
import { useTabs } from "../../contexts/TabsContext"
import { useColors, useIsAdmin, useRolesForSelectedLocation, useUsersForSelectedLocation } from "../../contexts/UserSettingsContext"
import * as DaysHelper from "../../helpers/DaysHelper"
import { getRoleBadge } from "../../helpers/RolesHelper"
import useResolveUserName from "../../hooks/UseResolveUserName"
import { getShiftMutation, removeShiftMutation } from "../../services/ShiftMutation"

const EditMutationPage: FC = () => {
    const { t } = useTranslation()
    const colors = useColors()
    const users = useUsersForSelectedLocation()
    const roles = useRolesForSelectedLocation()
    const { setActiveTab } = useTabs()
    const params = useParams()
    const id = useMemo(() => parseInt(params.id!), [params])
    const navigate = useNavigate()
    const isAdmin = useIsAdmin()

    const [user, setUser] = useState<number>()
    const [startSlot, setStartSlot] = useState(null)
    const [endSlot, setEndSlot] = useState(null)
    const [date, setDate] = useState("")
    const [originalRole, setOriginalRole] = useState(null)
    const [role, setRole] = useState(null)
    const [createdBy, setCreatedBy] = useState<string | null>(null)
    const [createdAt, setCreatedAt] = useState(null)
    const [notifiedAt, setNotifiedAt] = useState(null)
    const [formErrors, setFormErrors] = useState<string[]>([])
    const [deletionWarningVisible, setDeletionWarningsVisible] = useState(false)
    const resolveUserName = useResolveUserName(users)

    const loadMutation = useCallback(() => {
        getShiftMutation(id).then((response) => {
            const data = response.data
            setUser(data.user)
            setStartSlot(data.startSlot)
            setEndSlot(data.endSlot)
            setDate(data.date)
            setOriginalRole(data.originalRole)
            setRole(data.role)
            setCreatedBy(data.createdBy)
            setCreatedAt(data.createdAt)
            setNotifiedAt(data.notifiedAt)
            setFormErrors([])
        })
    }, [id, setUser, setStartSlot, setEndSlot, setDate, setOriginalRole, setRole, setCreatedBy, setCreatedAt, setNotifiedAt, setFormErrors])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setFormErrors(data.nonFieldErrors || [t("Main.something_went_wrong")])
        },
        [setFormErrors]
    )

    const deleteMutation = useCallback(() => {
        if (!deletionWarningVisible) {
            setDeletionWarningsVisible(true)
            return
        }
        removeShiftMutation(id)
            .then(() => navigate(`/wijzigingen`))
            .catch((error) => onFailure(error))
    }, [deletionWarningVisible, setDeletionWarningsVisible, id, navigate, onFailure])

    useEffect(() => setActiveTab("Mutations"), [setActiveTab])
    useEffect(loadMutation, [loadMutation])

    const oRole = roles ? roles.find((r) => r.id === originalRole)! : undefined
    const nRole = roles ? roles.find((r) => r.id === role)! : undefined

    return (
        <RegularPage id="EditMutation" breadCrumbs={[{ title: "Wijzigingen", link: "/wijzigingen" }, { title: id.toString() }]}>
            <h2 className="mb-4">{user ? resolveUserName(user) : ""}</h2>
            <div className="form-group mb-4">
                <label className="input-label">Moment</label>
                <span className="text-bold">
                    Op {date} van {startSlot !== null ? DaysHelper.getTimeString("start", startSlot) : ""} tot {endSlot !== null ? DaysHelper.getTimeString("end", endSlot) : ""}
                </span>
            </div>
            <div className="form-group mb-4">
                <label className="input-label">Originele taak</label>
                {getRoleBadge(oRole, colors)}
            </div>
            <div className="form-group mb-4">
                <label className="input-label">Nieuwe taak</label>
                {getRoleBadge(nRole, colors)}
            </div>
            <div className="text-small text-muted mb-4">
                Ingevoerd door {createdBy} op {createdAt !== null ? DaysHelper.prettyPrintDateTime(createdAt) : ""}.{" "}
                {notifiedAt ? `Notificatie verstuurd op ${DaysHelper.prettyPrintDateTime(notifiedAt)}.` : null}
            </div>
            {isAdmin ? (
                <Button type="button" onClick={deleteMutation} variant="danger" data-cy="deleteMutation">
                    Verwijder
                </Button>
            ) : null}
            {deletionWarningVisible ? (
                <span className="ms-3">
                    <FontAwesomeIcon icon={faExclamationCircle} className="me-1" /> Weet je zeker dat je deze wijziging wil verwijderen?{" "}
                    <Button variant="link" type="button" onClick={deleteMutation} data-cy="confirmDeleteMutation">
                        Ja, verwijder
                    </Button>
                </span>
            ) : null}
            <FormErrorMessages errors={formErrors} />
        </RegularPage>
    )
}

export default EditMutationPage
