import { CSSProperties } from "react"
import ColorType from "../types/ColorType"
import * as ColorHelper from "./ColorHelper"

export function getBorderLeft(index: number, defaultColor: ColorType | null, selected: boolean) {
    const width = index % 4 === 0 ? 3 : 1
    const style = index % 2 === 0 ? "solid" : "dashed"
    return getBorder(defaultColor, width, style, selected)
}

export function getBorder(defaultColor: ColorType | null, width: number, style: "solid" | "dashed", selected: boolean) {
    const color = selected || defaultColor !== null ? ColorHelper.getWhiteColor() : ColorHelper.getBorderColor()
    const css: CSSProperties = {
        border: width + "px " + style + " " + ColorHelper.getCSSColor2(color),
    }
    return css.border
}

export const getOccupationCellBorderLeft = (index: number, selected: boolean, empty: boolean) => {
    const width = index % 4 === 0 ? 3 : 1
    const style = index % 2 === 0 ? "solid" : "dashed"

    return getOccupationCellBorder(width, style, selected, empty)
}

export const getOccupationCellBorder = (width: number, style: "solid" | "dashed", selected: boolean, empty: boolean) => {
    const color = selected || empty === false ? ColorHelper.getWhiteColor() : ColorHelper.getBorderColor()

    return width + "px " + style + " " + ColorHelper.getCSSColor3(color, 0.3)
}
