import { FC } from "react"
import { UseFormSetValue, UseFormWatch, useFieldArray } from "react-hook-form"
import { HiringAvailabilitiesFormProps } from "../../pages/hireAvailability/HireAvailabilitiesUserPage"
import { UserDetailedType } from "../../types/UserType"
import HireAvailabilityEditingRow from "./HireAvailabilityEditingRow"

interface HireAvailabilityArrayProps {
    control: any
    register: any
    setValue: UseFormSetValue<HiringAvailabilitiesFormProps>
    watch: UseFormWatch<HiringAvailabilitiesFormProps>
    detailedUser: UserDetailedType
}

const HireAvailabilityArray: FC<HireAvailabilityArrayProps> = ({ control, register, setValue, watch, detailedUser }) => {
    const { fields } = useFieldArray<HiringAvailabilitiesFormProps>({ control, name: "availabilities" })

    return (
        <>
            {fields.map((_, index) => {
                return <HireAvailabilityEditingRow key={index} index={index} register={register} setValue={setValue} watch={watch} detailedUser={detailedUser} />
            })}
        </>
    )
}

export default HireAvailabilityArray
