import { FC, useEffect, useRef } from "react"
import { useQuery } from "react-query"
import { refetchInterval } from "../../../helpers/QueryHelper"
import { TaskType, loadTask } from "../../../services/Task"
import ProgressBar from "../../ProgressBar"

interface Props {
    initialTask: TaskType<any>
    setTask: (task: TaskType<any>) => void
}

const TaskProgress: FC<Props> = ({ initialTask, setTask }) => {
    const fetchCount = useRef(0)
    const { data: task } = useQuery<TaskType<any>>(["Task", initialTask.id], () => loadTask(initialTask.id), {
        initialData: initialTask,
        refetchInterval: refetchInterval(fetchCount),
    })

    useEffect(() => {
        if (task?.done) {
            setTask(task)
        }
    }, [task, setTask])

    return <ProgressBar done={!!task?.done} progress={task?.progress ?? 0} />
}

export default TaskProgress
