import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback } from "react"
import styles from "./OccupationBadge.module.scss"

interface Props {
    min: number | null
    max: number | null
    actual: number
}

const OccupationBadge: FC<Props> = ({ min, max, actual }) => {
    const getColor = useCallback((isInvalid: boolean, level: 500 | 300 | 200) => {
        return `bg-${isInvalid ? "red" : "green"}-${level}`
    }, [])

    return (
        <span className={styles.badge}>
            {min !== null ? (
                <span className={`${styles.appendix} ${getColor(min !== null && actual < min, max === null ? 300 : 200)}`}>
                    <FontAwesomeIcon icon={faCaretUp} /> {min}
                </span>
            ) : null}
            {max !== null ? (
                <span className={`${styles.appendix} ${getColor(max !== null && actual > max, 300)}`}>
                    <FontAwesomeIcon icon={faCaretDown} /> {max}
                </span>
            ) : null}
            <span className={`${styles.mainValue} ${getColor((min !== null && actual < min) || (max !== null && actual > max), 500)}`}>{actual}</span>
        </span>
    )
}

export default OccupationBadge
