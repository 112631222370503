import TOTPDeviceType from "../types/TOTPDeviceType"

export function getTOTPUrl(device?: TOTPDeviceType) {
    let label = "Rooster Planner"
    const envId = process.env.REACT_APP_ENV_ID
    if (envId && envId.toLowerCase() !== "prod") {
        label += ` - ${envId}`
    }
    const username = device?.email
    const key = device?.key
    if (!username || !key) {
        return undefined
    }
    return `otpauth://totp/${label}:${username}?secret=${key}&issuer=${label}`
}
