import { FC } from "react"
import { getProgressColor } from "../../../helpers/ColorHelper"

interface Props {
    score: number
}

const ShiftImportMappingScore: FC<Props> = ({ score }) => {
    return (
        <div className="progress" style={{ width: "50px", height: "0.5rem" }}>
            <div
                className="progress-bar text-bold"
                role="progressbar"
                aria-valuenow={score}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{
                    width: score + "%",
                    backgroundColor: getProgressColor(score / 100.0),
                }}
            ></div>
        </div>
    )
}

export default ShiftImportMappingScore
