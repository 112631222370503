import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import ErrorMessages from "../form/ErrorMessages"
import { UserTab } from "./UserTabs"

interface Props {
    tabErrors: UserTab[]
    except: UserTab
}

const UserTabErrors: FC<Props> = ({ tabErrors, except }) => {
    const { t } = useTranslation()

    const tabLabels = useMemo(() => {
        const tabLabels: Map<UserTab, string> = new Map()
        tabLabels.set("profile", t("UserTabs.profile"))
        tabLabels.set("locations", t("UserTabs.locations"))
        tabLabels.set("times", t("UserTabs.times"))
        tabLabels.set("roles", t("UserTabs.roles"))
        return tabLabels
    }, [t])

    const errors = useMemo(() => {
        return tabErrors.filter((e) => e !== except).map((tab) => t("UserTabErrors.something_went_wrong_on_tab", { tab: tabLabels.get(tab) }))
    }, [t])

    return errors.length > 0 ? <ErrorMessages forId="form" errors={errors} /> : null
}

export default UserTabErrors
