export const STEP_INDEX_SCHEDULE = 3
export const STEP_INDEX_ABSENCE_REQUEST = STEP_INDEX_SCHEDULE + 1
export const STEP_INDEX_MUTATIONS = STEP_INDEX_ABSENCE_REQUEST + 1
export const STEP_INDEX_STATISTICS = STEP_INDEX_MUTATIONS + 1
export const STEP_INDEX_USERS = STEP_INDEX_STATISTICS + 1
export const STEP_INDEX_ROLES = STEP_INDEX_USERS + 1
export const STEP_INDEX_OCCUPATION_TEMPLATES = STEP_INDEX_ROLES + 1
export const STEP_INDEX_SHIFT_TEMPLATES = STEP_INDEX_OCCUPATION_TEMPLATES + 1

export const tourSteps = [
    {
        title: "Dagplanning",
        target: "#dayView",
        content: "Dit is jouw dagplanning. Je ziet in 1 oogopslag hoe vandaag eruit ziet en je kunt alvast bekijken wat de planning is voor andere dagen.",
        disableBeacon: true,
    },
    {
        title: "Weekplanning",
        target: "#weekView",
        content: "In dit overzicht zie je de weekplanning. Je kunt zelfs 4 weken tegelijk selecteren om een overzicht van de komende periode te zien!",
        disableBeacon: true,
    },
    {
        title: "Verlofaanvragen",
        target: "#absenceRequestsView",
        content: "Als je verlofaanvragen hebt ingediend, zie je die hier terug. Je ziet ook gelijk of de aanvraag al goedgekeurd is.",
        disableBeacon: true,
    },
    {
        title: "Rooster",
        target: "#UpdateSchedule",
        content:
            "Dit is de roosterpagina. Medewerkers kunnen op deze pagina zien welke collega's ingepland zijn. Beheerders kunnen op deze pagina ook ad hoc wijzigingen doorvoeren. Verwerk hier bijvoorbeeld een ziekmelding of vakantieaanvraag. Of rooster extra medewerkers in bij verwachte drukte.",
        disableBeacon: true,
    },
    {
        title: "Verlofaanvragen",
        target: "#AbsenceRequests",
        content: "Verlofaanvraag zijn terug te vinden in deze lijst. Medewerkers zien alleen eigen verlofaanvragen terug, beheerders zien alle verlofaanvragen.",
        disableBeacon: true,
    },
    {
        title: "Wijzigingen",
        target: "#Mutations",
        content: "Wijzigingen in het rooster zijn hier terug te vinden. Ook hier geldt dat medewerkers allen wijzigingen zien die betrekking hebben tot zichzelf, beheerders zien alle wijzigingen.",
        disableBeacon: true,
    },
    {
        title: "Statistieken",
        target: "#Statistics",
        content:
            "Beheerders kunnen verschillende statistieken opvragen met betrekking tot taken en medewerkers op deze pagina. Er kunnen ook forecasts gegenereerd worden om eventuele onderbezetting vroegtijdig te signaleren.",
        disableBeacon: true,
    },
    {
        title: "Medewerkers",
        target: "#Users",
        content:
            "Op deze pagina kunnen medewerkers geregistreerd worden. Beheerders bepalen de werktijden en welke taken medewerkers mogen uitvoeren. Deze pagina is alleen beschikbaar voor beheerders.",
        disableBeacon: true,
    },
    {
        title: "Taken",
        target: "#Roles",
        content:
            "Taken zijn werkzaamheden die medewerkers toebedeeld kunnen krijgen. Een medewerker kan verschillende taken uitvoeren op een dag, maar nooit 2 taken tegelijk. Beheerders kunnen hier zelf taken beheren om Rooster Planner perfect aan te laten sluiten op de werkzaamheden en terminologie binnen het bedrijf.",
        disableBeacon: true,
    },
    {
        title: "Basisbezettingen",
        target: "#OccupationTemplates",
        content:
            "Basisbezettingen kunnen ingezet worden om Rooster Planner optimaal te benutten. Bepaal hoeveel medewerkers gewenst zijn voor elke taak, op elk moment van de dag. Mocht die gewenste bezetting niet behaald worden, komt dat visueel terug in het rooster.",
        disableBeacon: true,
    },
    {
        title: "Basisroosters",
        target: "#ShiftTemplates",
        content:
            "Basisroosters worden gebruikt om het wekelijkse standaardrooster te definiëren. Zo lang er geen nieuwer basisrooster beschikbaar is zal een basisrooster oneindig herhaald worden in de planning. Bij een vaste wijziging, zoals indiensttreding van een medewerker, kan er een nieuw basisrooster aangemaakt worden. Eenmalige wijzigingen zoals vakanties en ziekte moeten niet in het basisrooster worden bijgewerkt, maar in het actuele rooster worden doorgevoerd.",
        disableBeacon: true,
    },
]
