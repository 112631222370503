import { FC, useCallback } from "react"
import { Button } from "react-bootstrap"
import { UserTab } from "./UserTabs"

interface Props {
    tab: UserTab
    label: string
    active: boolean
    setSelectedTab: (userTab: UserTab) => void
}

const UserTabComponent: FC<Props> = ({ tab, label, active, setSelectedTab }) => {
    const onClick = useCallback(() => {
        setSelectedTab(tab)
    }, [setSelectedTab, tab])

    return (
        <li className="nav-item">
            <Button variant="selectable" active={active} onClick={onClick}>
                {label}
            </Button>
        </li>
    )
}

export default UserTabComponent
