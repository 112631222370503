import { FC, useCallback, useContext, useMemo } from "react"
import { ScheduleEditorContext, useScheduleEditorState } from "../../contexts/ScheduleEditorContext"
import { publishShiftTemplate, storeShiftsAndLocalities } from "../../services/ShiftTemplate"
import ShiftTemplateType from "../../types/ShiftTemplateType"

export interface ShiftTemplateSaveButtonsProps {
    onSuccess: () => void
    onFailure: (error: any) => void
}

const ShiftTemplateSaveButtons: FC<ShiftTemplateSaveButtonsProps> = ({ onSuccess, onFailure }) => {
    const { updateShiftTemplate, reloadEditorFromShiftTemplate } = useContext(ScheduleEditorContext)
    const editorState = useScheduleEditorState()

    const hasUnsavedChanges = useMemo(() => {
        return editorState.hasNewShiftMutations || editorState.hasNewLocalityMutations
    }, [editorState.hasNewShiftMutations, editorState.hasNewLocalityMutations])

    const shiftTemplateId = useMemo(() => {
        return editorState.shiftTemplate?.id ?? 0
    }, [editorState.shiftTemplate])

    const onSuccessSave = useCallback(() => {
        onSuccess()
        reloadEditorFromShiftTemplate()
    }, [onSuccess, reloadEditorFromShiftTemplate])

    const onSuccessPublish = useCallback(
        (updatedShiftTemplate: ShiftTemplateType) => {
            onSuccess()
            updateShiftTemplate(updatedShiftTemplate)
        },
        [onSuccess, updateShiftTemplate]
    )

    const save = useCallback(() => {
        storeShiftsAndLocalities(shiftTemplateId, editorState.mergedSchedule).then(onSuccessSave).catch(onFailure)
    }, [shiftTemplateId, editorState.mergedSchedule, onSuccessSave, onFailure])

    const publish = useCallback(() => {
        publishShiftTemplate({
            id: shiftTemplateId,
            isPublished: true,
        })
            .then((response) => onSuccessPublish(response.data))
            .catch(onFailure)
    }, [shiftTemplateId, onSuccessPublish, onFailure])

    return (
        <>
            <button type="button" onClick={save} className="btn btn-primary" disabled={!hasUnsavedChanges}>
                Opslaan
            </button>
            {editorState.shiftTemplate && !editorState.shiftTemplate.isPublished ? (
                <button type="button" onClick={publish} className="ms-2 btn btn-primary" disabled={editorState.hasNewShiftMutations}>
                    Publiceer
                </button>
            ) : null}
        </>
    )
}

export default ShiftTemplateSaveButtons
