import { FC, useMemo } from "react"

interface NoDataProps {
    taskResult: any
}

const NoData: FC<NoDataProps> = ({ taskResult }) => {
    const message = useMemo(() => {
        if (!taskResult) {
            return null
        }
        if (taskResult["roles"].length === 0) {
            return "Geen data"
        }
        return null
    }, [taskResult])

    return <div className="text-small text-muted">{message}</div>
}

export default NoData
