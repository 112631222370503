import BreakTemplateType from "../types/BreakTemplateType"

export const getBreakOptions = (breakTemplates: BreakTemplateType[]) => {
    if (!breakTemplates) {
        return []
    }
    return breakTemplates.map((t) => {
        return {
            id: t.id.toString(),
            name: t.name,
        }
    })
}
