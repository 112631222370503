import { getOccupationSource } from "../services/OccupationSource"
import OccupationSourceType from "../types/OccupationSourceType"

const useLoadOccupationSource = (id: number, setOccupationSource: (occupationSource: OccupationSourceType) => void) => {
    return () => {
        if (!id) {
            return
        }
        getOccupationSource(id).then((response) => {
            setOccupationSource(response.data)
        })
    }
}

export default useLoadOccupationSource
