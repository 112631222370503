import { useRoleGroupsForSelectedLocation } from "../contexts/UserSettingsContext"
import RoleGroupType from "../types/RoleGroupType"

const useResolveRoleGroupForSelectedLocation = () => {
    const roleGroups = useRoleGroupsForSelectedLocation()
    const map: Map<number, RoleGroupType> = new Map()
    for (const roleGroup of roleGroups) {
        map.set(roleGroup.id, roleGroup)
    }
    return (roleGroup: number) => map.get(roleGroup)
}

export default useResolveRoleGroupForSelectedLocation
