import { FC } from "react"
import StatisticsTab from "./StatisticsTab"

interface StatisticsTabsProps {
    tabs: StatisticsTabSettings[]
    activeTabId: string
    setActiveTabId: (tab: string) => void
}

interface StatisticsTabSettings {
    id: string
    name: string
}

const StatisticsTabs: FC<StatisticsTabsProps> = ({ tabs, activeTabId, setActiveTabId }) => {
    return (
        <div className="overflow-auto">
            <ul className="selectable-segment mb-0">
                {tabs.map(({ id, name }) => (
                    <StatisticsTab key={id} id={id} name={name} activeTabId={activeTabId} setActiveTabId={setActiveTabId} />
                ))}
            </ul>
        </div>
    )
}

export default StatisticsTabs
