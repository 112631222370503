import { withParams } from "../helpers/FilterHelper"
import { toObject } from "../helpers/MapHelper"
import { OccupationScheduleMap } from "../helpers/OccupationMapHelper"
import { UnexusFileRow } from "../pages/occupation/EditOccupationTemplateDetailsPage"
import { UnexusRowConnectionDetails } from "../pages/occupation/unexus/UnexusRowConnectionDetails"
import OccupationTemplateType, { CreateOccupationTemplateType, UpdateOccupationTemplateType } from "../types/OccupationTemplateType"
import PageRequestType from "../types/PageRequestType"
import PageResponseType from "../types/PageResponseType"
import api from "./Api"
import { OccupationTemplateGenerationType } from "./OccupationTemplateGeneration"

export function loadOccupationTemplatesForLocation(location: number) {
    const pageDetails = { pageIndex: 0, pageSize: 999, filters: [{ key: "location", value: location.toString() }] }
    return () =>
        new Promise<OccupationTemplateType[]>((resolve, reject) => {
            getOccupationTemplates(pageDetails)
                .then((response) => resolve(response.data.items))
                .catch(reject)
        })
}

export function loadOccupationTemplates(request: PageRequestType): Promise<PageResponseType<OccupationTemplateType>> {
    return new Promise<PageResponseType<OccupationTemplateType>>((resolve, reject) =>
        getOccupationTemplates(request)
            .then((response) => resolve(response.data))
            .catch(reject)
    )
}

export function getOccupationTemplates(pageDetails: PageRequestType) {
    return api.get<{ items: any[]; pageCount: number }>(withParams("/occupation-templates/", pageDetails))
}

export function getOccupationTemplate(id: number) {
    return api.get<OccupationTemplateType>(`/occupation-templates/${id}/`)
}

export function loadOccupationTemplate(id: number) {
    return () =>
        new Promise<OccupationTemplateType>((resolve) => {
            getOccupationTemplate(id).then((response) => resolve(response.data))
        })
}

export interface CreatedOccupationTemplateResponse {
    template: OccupationTemplateType
    generation: OccupationTemplateGenerationType
}

export function createOccupationTemplate(occupationTemplate: CreateOccupationTemplateType, unexusFileRows: UnexusFileRow[], unexusConnectionRows: UnexusRowConnectionDetails[]) {
    const formData = new FormData()

    let fileRows = ""
    for (let i in unexusFileRows) {
        const row = unexusFileRows[i]
        formData.append(`file_row_file${i}`, row.file)
        formData.append(`file_row_role${i}`, row.role)
        fileRows += i.toString()
    }

    let connectionRows = ""
    for (let i in unexusConnectionRows) {
        const row = unexusConnectionRows[i]
        if (row.roleUid.startsWith("g")) {
            formData.append(`conn_row_role_group${i}`, row.roleUid.substring(1))
        } else {
            formData.append(`conn_row_role${i}`, row.roleUid.substring(1))
        }
        formData.append(`conn_row_routers${i}`, row.routers.map((r) => r.id).join(","))
        connectionRows += i.toString()
    }

    const { name, location, sourceTemplate, weekCycle } = occupationTemplate
    formData.append("name", name)
    formData.append("location", location.toString())
    if (sourceTemplate) {
        formData.append("sourceTemplate", sourceTemplate.toString())
    }
    formData.append("weekCycle", weekCycle.toString())
    formData.append("unexusFileRows", fileRows)
    formData.append("unexusConnectionRows", connectionRows)

    return api.post<CreatedOccupationTemplateResponse>("/occupation-templates/", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
}

export function updateOccupationTemplate(occupationTemplate: UpdateOccupationTemplateType) {
    return api.patch(`/occupation-templates/${occupationTemplate.id}/`, occupationTemplate)
}

export function storeOccupations(template: number, occupations: OccupationScheduleMap) {
    return api.post(`/occupation-templates/occupations/${template}/`, toObject(occupations))
}

export function deleteOccupationTemplate(id: number) {
    return api.delete(`/occupation-templates/${id}/`)
}
