import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { components, MultiValueRemoveProps } from "react-select"

const MultiValueRemove = (props: MultiValueRemoveProps) => {
    return (
        <components.MultiValueRemove {...props}>
            <div style={{ fontSize: "80%" }}>
                <FontAwesomeIcon icon={faXmark} />
            </div>
        </components.MultiValueRemove>
    )
}

export default MultiValueRemove
