import { SourcedDayOccupationType } from "../types/OccupationType"
import UnexusRouterOccupationType from "../types/UnexusRouterOccupationType"
import api from "./Api"

export function getAllUnexusRouterOccupationsForDate(occupationSource: number, date: Date) {
    return api.get<{ referenceDates: string[]; routerOccupations: UnexusRouterOccupationType[]; occupations: SourcedDayOccupationType[] }>(
        `/unexus-router-occupations/?source=${occupationSource}&date=${date.getTime()}`
    )
}

export function getAllUnexusRouterOccupationsForDay(occupationSource: number, day: number) {
    return api.get<{ referenceDates: string[]; routerOccupations: UnexusRouterOccupationType[]; occupations: SourcedDayOccupationType[] }>(
        `/unexus-router-occupations/?source=${occupationSource}&day=${day}`
    )
}
