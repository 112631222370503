import { useRolesForSelectedLocation } from "../contexts/UserSettingsContext"
import RoleType from "../types/RoleType"

const useResolveRoleForSelectedLocation = () => {
    const roles = useRolesForSelectedLocation()
    const map: Map<number, RoleType> = new Map()
    for (const role of roles) {
        map.set(role.id, role)
    }
    return (role: number) => map.get(role)
}

export default useResolveRoleForSelectedLocation
