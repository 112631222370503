import "@fortawesome/fontawesome-free/css/all.min.css"
import "bootstrap/dist/js/bootstrap.js"
import { enGB } from "date-fns/locale/en-GB"
import { nl } from "date-fns/locale/nl"
import i18n from "i18next"
import { registerLocale, setDefaultLocale } from "react-datepicker"
import { initReactI18next } from "react-i18next"
import { QueryClient, QueryClientProvider } from "react-query"
import { BrowserRouter } from "react-router-dom"
import { StatusProvider } from "./contexts/StatusContext"
import { TabsProvider } from "./contexts/TabsContext"
import { UserSettingsProvider } from "./contexts/UserSettingsContext"
import MainLayout from "./layouts/MainLayout"
import translationEn from "./locales/en/translation.json"
import translationNl from "./locales/nl/translation.json"
import "./sass/react-datepicker.scss"
import "./sass/rooster.scss"

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: translationEn,
        },
        nl: {
            translation: translationNl,
        },
    },
    fallbackLng: "nl",
    supportedLngs: ["Dev", "Test"].includes(process.env.REACT_APP_ENV_ID!) ? ["en", "nl"] : ["nl"],
    interpolation: {
        escapeValue: false,
    },
})

registerLocale("nl", nl)
registerLocale("en", enGB)
setDefaultLocale("nl")

const queryClient = new QueryClient()

function App() {
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <TabsProvider>
                    <UserSettingsProvider>
                        <StatusProvider>
                            <MainLayout />
                        </StatusProvider>
                    </UserSettingsProvider>
                </TabsProvider>
            </QueryClientProvider>
        </BrowserRouter>
    )
}

export default App
