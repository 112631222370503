import { FC, useMemo } from "react"
import { useCompanyModules, useLocations } from "../../../contexts/UserSettingsContext"
import { COMPANY_MODULE_REMOTE_WORKING } from "../../../helpers/Constants"
import { prettyPrintExtraShort } from "../../../helpers/DaysHelper"
import { StaticShift } from "../../../helpers/ScheduleMapHelper"
import { LocalityOptionOrInherit } from "../../../types/LocalityType"
import StaticWeekDayLocality from "./StaticWeekDayLocality"
import styles from "./StaticWeekDayLocation.module.scss"
import StaticWeekTimeLineItems from "./StaticWeekTimeLineItems"
import StaticWeekTimeLineSlots from "./StaticWeekTimeLineSlots"

interface Props {
    date: Date
    locationId: number
    shifts: StaticShift[]
    locality: LocalityOptionOrInherit
    isFirstOfDay: boolean
    showLocation: boolean
}

const StaticWeekDayLocation: FC<Props> = ({ date, locationId, shifts, locality, isFirstOfDay, showLocation }) => {
    const locations = useLocations()
    const companyModules = useCompanyModules()
    const showLocalities = useMemo(() => companyModules.includes(COMPANY_MODULE_REMOTE_WORKING), [companyModules])

    const showLocationCell = useMemo(() => {
        return showLocation && locations.length > 1
    }, [showLocation, locations])

    const locationName = useMemo(() => {
        if (showLocation) {
            return locations.find((l) => l.id === locationId)?.name
        }
    }, [locations, locationId])

    return (
        <div className="d-flex" data-cy="staticWeekShift">
            <div data-cy="date" className={`d-flex text-small ${styles.dateCell}`}>
                {isFirstOfDay ? prettyPrintExtraShort(date) : null}
            </div>
            {showLocalities ? (
                <div className={styles.locality} data-cy="locality">
                    <StaticWeekDayLocality locality={locality} />
                </div>
            ) : null}
            {showLocationCell ? (
                <div className={`${styles.location} text-muted`} data-cy="location">
                    {shifts.length > 0 ? locationName : null}
                </div>
            ) : null}
            <div className={`text-small w-100 ${styles.timeLineContainer}`}>
                <div className={styles.timeLineSlots}>
                    <StaticWeekTimeLineSlots />
                </div>
                <div className={styles.timeLine}>
                    <StaticWeekTimeLineItems shifts={shifts} />
                </div>
            </div>
        </div>
    )
}

export default StaticWeekDayLocation
