import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback } from "react"
import { Button } from "react-bootstrap"
import { useTabs } from "../../contexts/TabsContext"
import { useInitMode, useIsAuthenticated } from "../../contexts/UserSettingsContext"
import { UserInitMode } from "../../types/UserType"
import Menu from "./Menu"
import styles from "./MenuSlide.module.scss"

const MenuSlide: FC = () => {
    const isAuthenticated = useIsAuthenticated()
    const { menuOpen, setMenuOpen } = useTabs()
    const initMode = useInitMode()
    const closeMenu = useCallback(() => setMenuOpen(false), [setMenuOpen])

    return isAuthenticated && initMode >= UserInitMode.STARTED_TOUR ? (
        <div id="sidenav" className={`${styles.sidenav} d-block d-lg-none`} style={{ left: menuOpen ? "0" : "-12rem" }}>
            <Button type="button" variant="link" onClick={closeMenu} className={styles.closeButton}>
                <FontAwesomeIcon icon={faXmark} />
            </Button>
            <div className={styles.container}>
                <Menu />
            </div>
        </div>
    ) : null
}

export default MenuSlide
