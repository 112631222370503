import { useCallback } from "react"
import { useQuery } from "react-query"
import { useCurrentUser } from "../../contexts/UserSettingsContext"
import { loadDetailedUser } from "../../services/User"
import UserEditPage from "./UserEditPage"

export default function MyAccountPage() {
    const currentUser = useCurrentUser()

    const loadUser = useCallback(() => {
        return loadDetailedUser(currentUser!.id)
    }, [currentUser])

    const { data: user } = useQuery(["User", currentUser!.id], loadUser)

    return <UserEditPage mode="update-my" user={user} />
}
