import { StylesConfig } from "react-select"

const styles: StylesConfig = {
    clearIndicator: (styles) => ({ ...styles, color: "#212529", cursor: "pointer", padding: "0.25rem", fontSize: "80%" }),
    dropdownIndicator: (styles) => ({ ...styles, color: "#212529", cursor: "pointer", padding: "0.25rem", fontSize: "80%" }),
    indicatorsContainer: (styles) => ({ ...styles, paddingRight: "0.5rem" }),
    indicatorSeparator: (styles) => ({ ...styles, backgroundColor: "transparent" }),
    control: (styles, { isFocused }) => ({
        ...styles,
        borderColor: "#ced4da",
        "&:hover": {
            borderColor: "#ced4da",
        },
        boxShadow: isFocused ? "0 0 0 0.25rem rgba(13, 110, 253, 0.25)" : "none",
    }),
    placeholder: (styles) => ({ ...styles, color: "black" }),
}

export default styles
