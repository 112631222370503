import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useMemo } from "react"
import Badge from "react-bootstrap/Badge"
import { useScheduleEditorState } from "../../contexts/ScheduleEditorContext"

const ScheduleErrorMessages: FC = () => {
    const editorState = useScheduleEditorState()

    const errorMessage = useMemo(() => {
        if (editorState.errorMessage === "shiftTemplate") {
            return "Er is geen basisrooster actief voor de gekozen datum."
        }
    }, [editorState.errorMessage])

    if (!errorMessage) {
        return null
    }

    return (
        <Badge bg="danger-light" text="dark" className="mb-3">
            <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
            {errorMessage}
        </Badge>
    )
}

export default ScheduleErrorMessages
