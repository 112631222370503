import { ChangeEvent, FC, useCallback, useEffect, useState } from "react"
import * as DaysHelper from "../../helpers/DaysHelper"
import { isEnabled } from "../../helpers/DaysHelper"
import WeekOptions from "../user/UserTimeInput/WeekOptions"
import DaySelectorButton from "./DaySelectorButton"

interface DaySelectorProps {
    enabledDays: string
    weekCycle: number
    selectDay: (day: number) => void
}

const DaySelector: FC<DaySelectorProps> = ({ enabledDays, weekCycle, selectDay }) => {
    const initialSelectedDay = DaysHelper.getFirstEnabledDayIndex(enabledDays)

    const [selectedDay, setSelectedDay] = useState(initialSelectedDay)
    const [selectedWeek, setSelectedWeek] = useState(Math.floor(initialSelectedDay / 7))

    const onChangeWeek = useCallback(
        (event: ChangeEvent) => {
            const target = event.target as HTMLSelectElement
            setSelectedWeek(parseInt(target.value))
        },
        [setSelectedWeek]
    )

    useEffect(() => {
        selectDay(selectedDay + selectedWeek * 7)
    }, [selectDay, selectedDay, selectedWeek])

    useEffect(() => {
        setSelectedWeek(Math.min(weekCycle - 1, selectedWeek))
    }, [setSelectedWeek, weekCycle, selectedWeek])

    return (
        <div className="overflow-auto">
            <ul className="selectable-segment mb-0">
                <DaySelectorButton day={0} selected={selectedDay === 0} onSelect={setSelectedDay} enabled={isEnabled(enabledDays, weekCycle, 0, true)} />
                <DaySelectorButton day={1} selected={selectedDay === 1} onSelect={setSelectedDay} enabled={isEnabled(enabledDays, weekCycle, 1, true)} />
                <DaySelectorButton day={2} selected={selectedDay === 2} onSelect={setSelectedDay} enabled={isEnabled(enabledDays, weekCycle, 2, true)} />
                <DaySelectorButton day={3} selected={selectedDay === 3} onSelect={setSelectedDay} enabled={isEnabled(enabledDays, weekCycle, 3, true)} />
                <DaySelectorButton day={4} selected={selectedDay === 4} onSelect={setSelectedDay} enabled={isEnabled(enabledDays, weekCycle, 4, true)} />
                <DaySelectorButton day={5} selected={selectedDay === 5} onSelect={setSelectedDay} enabled={isEnabled(enabledDays, weekCycle, 5, true)} />
                <DaySelectorButton day={6} selected={selectedDay === 6} onSelect={setSelectedDay} enabled={isEnabled(enabledDays, weekCycle, 6, true)} />
            </ul>
            {weekCycle > 1 ? (
                <select className="form-select form-control form-select-inline ms-3" onChange={onChangeWeek} value={selectedWeek}>
                    <WeekOptions weekCycle={weekCycle} />
                </select>
            ) : null}
        </div>
    )
}

export default DaySelector
