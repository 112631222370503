import RoleType from "../types/RoleType"

const useRoleNameMap = (roles: RoleType[]) => {
    const map: Map<number, string> = new Map()
    for (const role of roles) {
        map.set(role.id, role.name)
    }
    return map
}

export default useRoleNameMap
