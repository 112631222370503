import { FC, forwardRef, useCallback } from "react"
import { Button, ButtonProps } from "react-bootstrap"
import DatePicker from "react-datepicker"
import { prettyPrintWithYear, prettyPrintWithoutDay, shortDate } from "../helpers/DaysHelper"
import styles from "./DateSelector.module.scss"
import DatePickerHeader from "./table/DatePickerHeader"

export enum DateLabelType {
    NONE = "NONE",
    DATE = "DATE",
    DATE_AND_YEAR = "DATE_AND_YEAR",
}

interface DateSelectorProps {
    selectedDate: Date
    setSelectedDate: (date: Date) => void
    labelType: DateLabelType
}

const DateSelector: FC<DateSelectorProps> = ({ selectedDate, setSelectedDate, labelType }) => {
    const CalendarButton = forwardRef<HTMLButtonElement>(({ onClick }: ButtonProps, ref) => {
        return (
            <div className={labelType === DateLabelType.DATE ? styles.dateSelectorWithDateContainer : null}>
                <Button data-cy="calendarButton" variant="secondary" className={`dropdown-arrowless bg-light-gray ` + styles.dateButton} onClick={onClick} ref={ref}>
                    <i className="fa-regular fa-calendar-days"></i>
                    {labelType === DateLabelType.DATE ? (
                        <>
                            <span className={"ms-2 " + styles.longDateContainer}>{prettyPrintWithoutDay(selectedDate)}</span>
                            <span className={"ms-2 " + styles.shortDateContainer}>{shortDate(selectedDate)}</span>
                        </>
                    ) : null}
                    {labelType === DateLabelType.DATE_AND_YEAR ? <span className="ms-2">{prettyPrintWithYear(selectedDate)}</span> : null}
                </Button>
            </div>
        )
    })

    const onChange = useCallback(
        (date: Date | null) => {
            if (date) {
                setSelectedDate(date)
            }
        },
        [setSelectedDate]
    )

    return (
        <div className="d-inline-block">
            <DatePicker
                selected={selectedDate}
                onChange={onChange}
                showPopperArrow={false}
                customInput={<CalendarButton />}
                calendarStartDay={1}
                todayButton="Vandaag"
                showWeekNumbers
                renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => <DatePickerHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />}
            />
        </div>
    )
}

export default DateSelector
