import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { getDayId } from "../helpers/DaysHelper"
import { LoadedOccupationSchedule } from "../helpers/OccupationHelper"
import { mergeMaps, mergeOccupationMutation, OccupationScheduleMap, StaticOccupation } from "../helpers/OccupationMapHelper"
import { createNestedRoles, loadRolesAndGroupsForLocation, NestedRoleItem } from "../helpers/RolesHelper"
import LocationType from "../types/LocationType"
import OccupationSourceType from "../types/OccupationSourceType"
import OccupationTemplateType from "../types/OccupationTemplateType"
import RoleGroupType from "../types/RoleGroupType"
import RoleType from "../types/RoleType"
import { useLocations } from "./UserSettingsContext"

export interface OccupationEditorState {
    revisionId: number
    location: LocationType
    occupationTemplate: OccupationTemplateType | null
    dayId: string
    date: Date | null
    day: number | null
    errorMessage: string | null
    mergedSchedule: OccupationScheduleMap
    mergedScheduleMutations: OccupationScheduleMap
    newScheduleMutations: OccupationScheduleMap
    hasNewScheduleMutations: boolean
    occupationSources: OccupationSourceType[]
    roles: RoleType[]
    roleGroups: RoleGroupType[]
    nestedRoles: NestedRoleItem[]
}

interface OccupationEditorContextProps {
    loading: boolean
    editorState?: OccupationEditorState
    updateDate: (newDate: Date) => void
    updateDay: (newDay: number) => void
    updateLocation: (newLocation: LocationType) => void
    updateOccupationTemplate: (newOccupationTemplate: OccupationTemplateType) => void
    applyMutation: (roleUid: string, cellFrom: number, cellTo: number, value: (number | null)[]) => void
    applyMutations: (mutations: OccupationScheduleMap) => void
    reloadEditorFromSchedule: () => void
    reloadEditorFromOccupationTemplate: () => void
}

const contextValue: OccupationEditorContextProps = {
    loading: false,
    updateDate: () => {},
    updateDay: () => {},
    updateLocation: () => {},
    updateOccupationTemplate: () => {},
    applyMutation: () => {},
    applyMutations: () => {},
    reloadEditorFromSchedule: () => {},
    reloadEditorFromOccupationTemplate: () => {},
}

export const OccupationEditorContext = createContext<OccupationEditorContextProps>(contextValue)

interface OccupationEditorContextProviderProps {
    children: ReactNode
    initialLocation: LocationType
    initialDate?: Date
    initialDay?: number
    loadOccupationTemplate?: () => Promise<OccupationTemplateType>
    loadSchedule: (occupationTemplate: OccupationTemplateType | null, location: LocationType | null, date: Date | null) => Promise<LoadedOccupationSchedule>
}

export const OccupationEditorContextProvider = ({ children, initialLocation, initialDate, initialDay, loadOccupationTemplate, loadSchedule }: OccupationEditorContextProviderProps) => {
    const locations = useLocations()

    const [lastUsedRevisionId, setLastUsedRevisionId] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(true)
    const [location, setLocation] = useState<LocationType>(initialLocation)
    const [occupationTemplate, setOccupationTemplate] = useState<OccupationTemplateType | null>(null)
    const [date, setDate] = useState<Date | null>(initialDate ?? null)
    const [day, setDay] = useState<number | null>(initialDay ?? null)
    const [roles, setRoles] = useState<RoleType[] | null>(null)
    const [roleGroups, setRoleGroups] = useState<RoleGroupType[] | null>(null)
    const [nestedRoles, setNestedRoles] = useState<NestedRoleItem[] | null>(null)
    const [mergedSchedule, setMergedSchedule] = useState<OccupationScheduleMap | null>(null) // Schedule map with original schedule, mutations, newMutations merged
    const [mergedScheduleMutations, setMergedScheduleMutations] = useState<OccupationScheduleMap>(new Map()) // Schedule map with saved and unsaved mutations merged
    const [schedule, setSchedule] = useState<OccupationScheduleMap>(new Map()) // Schedule map with original schedule
    const [scheduleMutations, setScheduleMutations] = useState<OccupationScheduleMap>(new Map()) // Schedule map with saved mutations
    const [newScheduleMutations, setNewScheduleMutations] = useState<OccupationScheduleMap>(new Map()) // Schedule map with unsaved mutations
    const [occupationSources, setOccupationSources] = useState<OccupationSourceType[] | null>(null)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const dayId = useMemo<string>(() => {
        return date ? getDayId(date) : day!.toString()
    }, [date, day])

    const hasNewScheduleMutations = useMemo(() => {
        for (const dayMap of newScheduleMutations.values()) {
            for (const roleOccupations of dayMap.values()) {
                if (roleOccupations.length > 0) {
                    return true
                }
            }
        }
        return false
    }, [newScheduleMutations])

    const [editorState, setEditorState] = useState<OccupationEditorState>()

    // ----- Reload actions -----

    const reloadRoles = useCallback(
        (location: LocationType) => {
            setNestedRoles(null)
            setLoading(true)
            loadRolesAndGroupsForLocation(location).then((response) => {
                const { roles, roleGroups } = response
                const nestedRoles = createNestedRoles(roles, roleGroups)
                setRoles(roles)
                setRoleGroups(roleGroups)
                setNestedRoles(nestedRoles)
            })
        },
        [setNestedRoles, setLoading, setRoles, setRoleGroups, setNestedRoles]
    )

    const reloadSchedule = useCallback(
        (occupationTemplate: OccupationTemplateType | null, location: LocationType | null, date: Date | null, resetNewScheduleMutations: boolean) => {
            setMergedSchedule(null)
            setLoading(true)
            loadSchedule(occupationTemplate, location, date).then((response) => {
                setSchedule(response.schedule)
                setScheduleMutations(response.scheduleMutations)
                setOccupationSources(response.occupationSources)
                if (resetNewScheduleMutations) {
                    setNewScheduleMutations(new Map())
                }
                setErrorMessage(response.errorMessage ?? null)
            })
        },
        [setMergedSchedule, setLoading, loadSchedule, setSchedule, setScheduleMutations, setErrorMessage]
    )

    const reloadScheduleIfNeeded = useCallback(
        (occupationTemplate: OccupationTemplateType | null, location: LocationType | null, date: Date | null, resetNewScheduleMutations: boolean) => {
            if (mergedSchedule && mergedSchedule.has(dayId)) {
                return
            }
            reloadSchedule(occupationTemplate, location, date, resetNewScheduleMutations)
        },
        [mergedSchedule, occupationTemplate, location, date]
    )

    // ----- Initialization: -----

    useEffect(() => {
        if (loadOccupationTemplate) {
            loadOccupationTemplate().then((response) => updateOccupationTemplate(response))
        } else {
            reloadRoles(initialLocation)
            reloadSchedule(null, initialLocation, initialDate!, false)
        }
    }, [loadOccupationTemplate, reloadRoles, reloadSchedule, initialLocation, initialDate])

    const reloadEditorFromSchedule = useCallback(() => {
        reloadSchedule(null, location, date, true)
    }, [reloadSchedule, setNewScheduleMutations, location, date])

    const reloadEditorFromOccupationTemplate = useCallback(() => {
        reloadSchedule(occupationTemplate, null, null, true)
    }, [reloadSchedule, setNewScheduleMutations, occupationTemplate])

    // ----- External changes: -----

    const updateLocation = useCallback(
        (newLocation: LocationType) => {
            if (newLocation.id === location.id) {
                return
            }
            reloadRoles(newLocation)
            reloadSchedule(null, newLocation, date, false)
            setLocation(newLocation)
        },
        [reloadRoles, setLocation, location, date]
    )

    const updateOccupationTemplate = useCallback(
        (newOccupationTemplate: OccupationTemplateType) => {
            if (newOccupationTemplate === occupationTemplate) {
                return
            }
            const newLocation = locations.find((l) => l.id === newOccupationTemplate.location)!
            reloadRoles(newLocation)
            reloadSchedule(newOccupationTemplate, null, null, true)
            setLocation(newLocation)
            setOccupationTemplate(newOccupationTemplate)
        },
        [occupationTemplate, locations, reloadRoles, reloadSchedule, setLocation, setOccupationTemplate]
    )

    const updateDate = useCallback(
        (newDate: Date) => {
            if (newDate === date) {
                return
            }
            reloadScheduleIfNeeded(null, location, newDate, false)
            setDate(newDate)
        },
        [reloadScheduleIfNeeded, setDate, location, date]
    )

    const updateDay = useCallback((newDay: number) => setDay(newDay), [setDay])

    // ----- Actions -----

    const applyMutation = useCallback(
        (roleUid: string, cellFrom: number, cellTo: number, value: (number | null)[]) => {
            const newMutation: StaticOccupation = {
                startSlot: location.startSlot + cellFrom,
                endSlot: location.startSlot + cellTo,
                min: value[0],
                max: value[1],
            }
            setNewScheduleMutations(mergeOccupationMutation(newScheduleMutations, newMutation, dayId, roleUid))
        },
        [location, setNewScheduleMutations, newScheduleMutations, dayId]
    )

    const applyMutations = useCallback(
        (scheduleMutations: OccupationScheduleMap) => {
            setNewScheduleMutations(mergeMaps(newScheduleMutations, scheduleMutations))
        },
        [setNewScheduleMutations, newScheduleMutations]
    )

    // ----- Side Effects -----

    useEffect(() => {
        setLastUsedRevisionId(editorState?.revisionId ?? 0)
    }, [editorState, setLastUsedRevisionId])

    useEffect(() => {
        setMergedScheduleMutations(mergeMaps(scheduleMutations, newScheduleMutations))
    }, [setMergedScheduleMutations, scheduleMutations, newScheduleMutations])

    useEffect(() => {
        setMergedSchedule(mergeMaps(schedule, mergedScheduleMutations))
    }, [setMergedSchedule, schedule, mergedScheduleMutations])

    useEffect(() => {
        if (!mergedSchedule || !occupationTemplate || !roles || !roleGroups || !nestedRoles || !occupationSources) {
            return
        }

        setLoading(false)
        setEditorState({
            revisionId: lastUsedRevisionId + 1,
            location,
            occupationTemplate,
            dayId,
            date,
            day,
            errorMessage: errorMessage ?? null,
            mergedSchedule,
            mergedScheduleMutations,
            roles,
            roleGroups,
            nestedRoles,
            newScheduleMutations,
            hasNewScheduleMutations,
            occupationSources,
        })
    }, [mergedSchedule, occupationTemplate, roles, roleGroups, nestedRoles, occupationSources, dayId, errorMessage, setLoading, setEditorState])

    return (
        <OccupationEditorContext.Provider
            value={{
                loading,
                editorState,
                updateDate,
                updateDay,
                updateLocation,
                updateOccupationTemplate,
                applyMutation,
                applyMutations,
                reloadEditorFromSchedule,
                reloadEditorFromOccupationTemplate,
            }}
        >
            {children}
        </OccupationEditorContext.Provider>
    )
}

export const OccupationEditorContextConsumer = OccupationEditorContext.Consumer

export function useOccupationEditorState() {
    const { editorState } = useContext(OccupationEditorContext)
    return editorState!
}
