import { FC, useMemo } from "react"
import { useColors } from "../contexts/UserSettingsContext"
import { getBorderColor, getCSSColor, getCSSColor3, getWhiteColor } from "../helpers/ColorHelper"
import RoleType from "../types/RoleType"

interface Props {
    role?: RoleType
}

const RoleBadge: FC<Props> = ({ role }) => {
    const colors = useColors()

    const color = useMemo(() => (role ? colors.get(role.color) ?? getWhiteColor() : getBorderColor()), [colors, role])
    const name = useMemo(() => (role ? role.name : "Vrij"), [role])

    return (
        <span className="role-badge text-bold" style={{ backgroundColor: getCSSColor3(color, 0.2), textWrap: "nowrap" }}>
            <i className="fas fa-circle me-2" style={{ color: getCSSColor(color) }}></i>
            {name}
        </span>
    )
}

export default RoleBadge
