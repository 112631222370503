import { FC, ReactElement, useCallback, useMemo } from "react"
import { OverlayTrigger } from "react-bootstrap"
import { getBorderLeft } from "../../../helpers/BorderHelper"
import { getCSSColor, getSelectedColor } from "../../../helpers/ColorHelper"
import styles from "./OccupationTimeSlot.module.scss"

interface Props {
    slot: number
    startSlot: number
    selectedSlot?: number
    occupationIndicators: number[]
    setSelectedSlot: (slot: number | undefined) => void
    popover: ReactElement
}

const OccupationTimeSlot: FC<Props> = ({ slot, startSlot, selectedSlot, occupationIndicators, setSelectedSlot, popover }) => {
    const slotIndex = useMemo(() => slot - startSlot, [slot, startSlot])

    const isSelected = useMemo(() => slot === selectedSlot, [slot, selectedSlot])

    const isUnderOccupied = useMemo(() => occupationIndicators[slotIndex] === 1, [occupationIndicators, slotIndex])

    const isFirst = useMemo(() => slotIndex === 0, [slotIndex])

    const isLast = useMemo(() => slotIndex === occupationIndicators.length - 1, [slotIndex, occupationIndicators])

    const backgroundColor = useMemo(() => {
        if (isSelected) {
            return getCSSColor(getSelectedColor())
        }
        return isUnderOccupied ? getCSSColor({ r: 231, g: 118, b: 129 }) : getCSSColor({ r: 109, g: 195, b: 129 })
    }, [isSelected, isUnderOccupied])

    const borderRadius = useMemo(() => {
        if (isFirst === true) {
            return "1rem 0 0 1rem"
        } else if (isLast === true) {
            return "0 1rem 1rem 0"
        }
    }, [isFirst, isLast])

    const onToggle = useCallback(
        (nextShow: boolean) => {
            if (nextShow) {
                setSelectedSlot(slot)
            } else {
                if (selectedSlot === slot) {
                    setSelectedSlot(undefined)
                }
            }
        },
        [selectedSlot, setSelectedSlot, slot]
    )

    return (
        <OverlayTrigger trigger="click" rootClose overlay={popover} placement="bottom" onToggle={onToggle}>
            <div
                className={`editor-head-cell ${styles.slot}`}
                style={{
                    backgroundColor: backgroundColor,
                    borderLeft: getBorderLeft(slot, null, false),
                    borderRadius: borderRadius,
                    overflow: "visible",
                }}
            ></div>
        </OverlayTrigger>
    )
}

export default OccupationTimeSlot
