import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import debounce from "debounce"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import Loader from "../../components/Loader"
import RegularPage from "../../components/page/RegularPage"
import { useTabs } from "../../contexts/TabsContext"
import { useUsersForSelectedLocation } from "../../contexts/UserSettingsContext"
import { dateFromDjango, isoPrint, prettyPrintDateTime } from "../../helpers/DaysHelper"
import { setOptionalError } from "../../helpers/FormHelper"
import { hiringAvailabilityStatusOptions } from "../../helpers/HiringAvailabilityHelper"
import { deleteHiringAvailability, loadHiringAvailability, updateHiringAvailability } from "../../services/HiringAvailability"
import { HiringAvailabilityStatus } from "../../types/HiringAvailabilityType"

interface Inputs {
    status: HiringAvailabilityStatus
    message: string
}

const HiringAvailabilityPage: FC = () => {
    const { setActiveTab } = useTabs()
    const params = useParams()
    const id = useMemo(() => parseInt(params.id!), [params])
    const navigate = useNavigate()
    const users = useUsersForSelectedLocation()
    const [deletionWarningVisible, setDeletionWarningVisible] = useState(false)

    const { data: hiringAvailability } = useQuery(["HiringAvailability", id], () => loadHiringAvailability(id))

    const {
        register,
        handleSubmit,
        setValue,
        setError,
        formState: { errors },
    } = useForm<Inputs>()

    useEffect(() => {
        if (hiringAvailability) {
            setValue("status", hiringAvailability.status)
            setValue("message", hiringAvailability.message ?? "")
        }
    }, [hiringAvailability, setValue])

    const onSuccess = useCallback(() => {
        navigate("/beschikbaarheid")
    }, [navigate])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "status", data.status)
            setOptionalError(setError, "message", data.message)
            setOptionalError(setError, "root", data.nonFieldErrors)
        },
        [setError]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        debounce(
            ({ status, message }) => {
                updateHiringAvailability({
                    id: id,
                    status: status,
                    message: message,
                })
                    .then(onSuccess)
                    .catch(onFailure)
            },
            300,
            {
                immediate: true,
            }
        ),
        [id, onSuccess, onFailure]
    )

    const onDeleteHiringAvailability = useCallback(() => {
        if (!deletionWarningVisible) {
            setDeletionWarningVisible(true)
            return
        }

        deleteHiringAvailability(id).then(onSuccess).catch(onFailure)
    }, [deletionWarningVisible, setDeletionWarningVisible, id, navigate, onFailure])

    useEffect(() => setActiveTab("HiringAvailability"), [setActiveTab])

    const targetUser = useMemo(() => {
        if (hiringAvailability) {
            return users.find((u) => u.id === hiringAvailability.user)
        }
    }, [hiringAvailability, users])

    const targetUserName = useMemo(() => (targetUser ? targetUser.firstName + " " + targetUser.lastName : ""), [targetUser])

    return (
        <RegularPage
            id="HiringAvailability"
            breadCrumbs={[{ title: "Beschikbaarheid", link: "/beschikbaarheid" }, { title: hiringAvailability ? isoPrint(dateFromDjango(hiringAvailability.date)) : "" }]}
        >
            {hiringAvailability !== undefined ? (
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <h2 className="mb-4">{targetUserName}</h2>
                    <Form.Group className="mb-3">
                        <Form.Label>Datum</Form.Label>
                        <Form.Control size="lg" disabled={true} value={isoPrint(dateFromDjango(hiringAvailability.date))} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Status</Form.Label>
                        <Form.Select size="lg" {...register("status")} isInvalid={!!errors.status?.message}>
                            {hiringAvailabilityStatusOptions.map(({ id, name }) => (
                                <option key={id} value={id}>
                                    {name}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.status?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Toelichting</Form.Label>
                        <Form.Control as="textarea" {...register("message")} isInvalid={!!errors.message?.message} />
                        <Form.Control.Feedback type="invalid">{errors.message?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <small className="text-muted">
                            Ingevoerd op {prettyPrintDateTime(dateFromDjango(hiringAvailability.createdAt))}
                            {hiringAvailability.updatedAt && hiringAvailability.updatedAt != hiringAvailability.createdAt
                                ? `, aangepast op ${prettyPrintDateTime(dateFromDjango(hiringAvailability.updatedAt))}`
                                : null}
                        </small>
                    </Form.Group>

                    <Button type="submit" className="me-2">
                        Opslaan
                    </Button>
                    <Button type="button" variant="danger" className="me-2" onClick={onDeleteHiringAvailability} data-cy="deleteHiringAvailability">
                        Verwijder
                    </Button>
                    {deletionWarningVisible ? (
                        <span className="ms-3">
                            <FontAwesomeIcon icon={faExclamationCircle} className="me-1" /> Weet je zeker dat je deze doorgegeven beschikbaarheid wil verwijderen?{" "}
                            <Button type="button" variant="link" onClick={onDeleteHiringAvailability} data-cy="confirmDeleteHiringAvailability">
                                Ja, verwijder
                            </Button>
                        </span>
                    ) : null}
                    <Form.Group>
                        <Form.Control type="hidden" isInvalid={!!errors.root} />
                        <Form.Control.Feedback type="invalid" data-cy="root_errors">
                            {errors.root?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            ) : (
                <Loader />
            )}
        </RegularPage>
    )
}

export default HiringAvailabilityPage
