import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useCompanyModules } from "../../contexts/UserSettingsContext"
import { UserTypeType } from "../../types/UserType"

const UserTypeOptions: FC = () => {
    const { t } = useTranslation()
    const companyModules = useCompanyModules()

    const userTypeOptions = useMemo(() => {
        const options: { id: UserTypeType; label: string }[] = []
        options.push({ id: "ADMIN", label: t("UserTypeOptions.admin") })
        options.push({ id: "REGULAR", label: t("UserTypeOptions.regular") })
        if (companyModules.includes("EXTERNAL_HIRES")) {
            options.push({ id: "EXTERNAL", label: t("UserTypeOptions.external") })
        }
        return options
    }, [companyModules, t])

    return userTypeOptions.map(({ id, label }) => (
        <option key={id} value={id}>
            {label}
        </option>
    ))
}

export default UserTypeOptions
