import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback } from "react"
import { Button } from "react-bootstrap"
import { getCSSColor } from "../../helpers/ColorHelper"
import { DefaultColorType } from "../../types/ColorType"
import styles from "./FormColorOption.module.scss"

interface Props {
    id: number
    color: DefaultColorType
    selected: boolean
    setColor: (color: number) => void
}

const FormColorOption: FC<Props> = ({ id, color, selected, setColor }) => {
    const onClick = useCallback(() => setColor(id), [setColor, id])

    return (
        <Button key={id} type="button" style={{ backgroundColor: getCSSColor(color) }} onClick={onClick} className={styles.colorOption}>
            {selected ? <FontAwesomeIcon icon={faCircle} /> : null}
        </Button>
    )
}

export default FormColorOption
