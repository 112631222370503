import { FC, MouseEventHandler } from "react"
import { useIsAdmin } from "../../contexts/UserSettingsContext"
import { getBorder, getBorderLeft } from "../../helpers/BorderHelper"
import { getCSSColor, getGreenColor, getRedColor } from "../../helpers/ColorHelper"
import { ShiftEditorCellDetails } from "../../helpers/editor/ShiftEditorHelper"
import { getShiftCellBackgroundColor } from "../../helpers/ShiftHelper"

interface ShiftsEditorSlotProps {
    slot: number
    id: string
    selectCell: MouseEventHandler<HTMLDivElement>
    details: ShiftEditorCellDetails
}

const ShiftsEditorSlot: FC<ShiftsEditorSlotProps> = ({ slot, id, selectCell, details }) => {
    const isAdmin = useIsAdmin()

    const { color, selected, isFirstOfShift, roleName, nthColor, isMuted, slotType, availability } = details

    return (
        <div
            id={id}
            className="editor-cell"
            data-cy="editorCell"
            style={{
                order: slot,
                backgroundColor: getShiftCellBackgroundColor(color, selected, isMuted),
                borderLeft: getBorderLeft(slot, color, selected),
                borderBottom: getBorder(color, 3, "solid", selected),
                zIndex: nthColor * 2 + (isFirstOfShift ? 1 : 0),
                textAlign: slotType === "AVAILABILITY" ? "center" : undefined,
                fontSize: slotType === "AVAILABILITY" ? "75%" : undefined,
                cursor: isAdmin ? "pointer" : undefined,
            }}
            onClick={isAdmin ? selectCell : undefined}
        >
            {isFirstOfShift ? (
                <div
                    id={id}
                    className="editor-cell-label text-bold"
                    data-cy="editorCellLabel"
                    style={{
                        opacity: isMuted ? 0.3 : 0.75,
                    }}
                >
                    {roleName}
                </div>
            ) : null}
            {slotType === "AVAILABILITY" && availability ? (
                <i
                    id={id}
                    className={`fa-solid ${availability === "UNAVAILABLE" ? "fa-xmark" : "fa-check"}`}
                    style={{ color: getCSSColor(availability === "AVAILABLE" ? getGreenColor() : getRedColor()) }}
                ></i>
            ) : null}
        </div>
    )
}

export default ShiftsEditorSlot
