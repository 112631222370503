import { FC } from "react"
import { useTranslation } from "react-i18next"

interface Props {
    weekCycle: number
}

const WeekOptions: FC<Props> = ({ weekCycle }) => {
    const { t } = useTranslation()
    const components = []
    for (let i = 0; i < weekCycle; i++) {
        components.push(
            <option key={i} value={i}>
                {t("Main.week_number", { week: i + 1 })}
            </option>
        )
    }
    return components
}

export default WeekOptions
