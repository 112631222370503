import { isoPrint } from "../helpers/DaysHelper"
import { NotesType } from "../types/DateNoteType"
import api from "./Api"

export function createDateNote(dateNote: { locations: number[]; date: string; text: string }) {
    return api.post("/datenotes/", dateNote)
}

export function getAllDateNotes(location: number, date: Date) {
    return api.get<NotesType>(`/datenotes/?locations=${location}&date=${isoPrint(date)}`)
}

export function getAllDateNotesForDays(locations: number[], fromDate: Date, numberOfDays: number) {
    return api.get<NotesType>(`/datenotes/?locations=${locations}&fromDate=${isoPrint(fromDate)}&numberOfDays=${numberOfDays}`)
}

export function updateDateNote(dateNote: { id: number; locations: number[]; text: string }) {
    return api.patch(`/datenotes/${dateNote.id}/`, dateNote)
}

export function removeDateNote(id: number) {
    return api.delete(`/datenotes/${id}/`)
}
