import { createContext, FC, ReactNode, useContext, useEffect, useState } from "react"

interface TabsContextProps {
    menuOpen: boolean
    setMenuOpen: (open: boolean) => void
    activeTab: string
    setActiveTab: (tab: string) => void
    openedTabs: string[]
    setOpenedTabs: (tabs: string[]) => void
    foldTab: (tab: string) => void
}

const contextValue = {
    menuOpen: false,
    setMenuOpen: () => {},
    activeTab: "",
    setActiveTab: () => {},
    openedTabs: [],
    setOpenedTabs: () => {},
    foldTab: () => {},
}

export const TabsContext = createContext<TabsContextProps>(contextValue)

interface Props {
    children: ReactNode
}

export const TabsProvider: FC<Props> = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<string>("")
    const [openedTabs, setOpenedTabs] = useState<string[]>([])

    const foldTab = (id: string) => {
        const newOpenedTabs = [...openedTabs]
        if (!newOpenedTabs.includes(id)) {
            newOpenedTabs.push(id)
        } else {
            newOpenedTabs.splice(newOpenedTabs.indexOf(id), 1)
        }
        setOpenedTabs(newOpenedTabs)
    }

    const openActiveTabParent = () => {
        const activeTabParts = activeTab ? activeTab.split("/") : undefined

        if (!activeTabParts) {
            return
        }

        const newOpenedTabs = [...openedTabs]
        for (let i = 0; i < activeTabParts.length - 1; i++) {
            if (!newOpenedTabs.includes(activeTabParts[i])) {
                newOpenedTabs.push(activeTabParts[i])
            }
        }

        if (newOpenedTabs.length !== openedTabs.length) {
            setOpenedTabs(newOpenedTabs)
        }
    }

    useEffect(openActiveTabParent, [activeTab])

    return <TabsContext.Provider value={{ menuOpen, setMenuOpen, activeTab, setActiveTab, openedTabs, setOpenedTabs, foldTab }} children={children} />
}

export const TabsConsumer = TabsContext.Consumer

export const useTabs = () => {
    return useContext(TabsContext)
}
