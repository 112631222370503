import { FC, useCallback, useMemo } from "react"
import { Button, Form } from "react-bootstrap"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"
import { useQuery } from "react-query"
import InfoPopover from "../../../components/InfoPopover"
import { useSelectedLocation } from "../../../contexts/UserSettingsContext"
import { loadUnexusRouters } from "../../../services/UnexusRouter"
import { Inputs } from "../EditOccupationTemplateDetailsPage"
import UnexusConnectionRowView from "./UnexusConnectionRowView"
import { UnexusRowConnectionDetails } from "./UnexusRowConnectionDetails"

interface Props {
    watch: UseFormWatch<Inputs>
    setValue: UseFormSetValue<Inputs>
}

const getNextRowId = (rows: UnexusRowConnectionDetails[]) => {
    return rows.map((r) => r.id).reduce((a, b) => Math.max(a, b), 0) + 1
}

const UnexusConnectionRowsView: FC<Props> = ({ watch, setValue }) => {
    const location = useSelectedLocation()
    const unexusConnectionRows = watch("unexusConnectionRows")
    const { data: unexusRouters } = useQuery(["UnexusRoutersForLocation", location.id], loadUnexusRouters(location.id), { initialData: [] })
    const unexusRouterOptions = useMemo(
        () =>
            unexusRouters
                ? unexusRouters.map((r) => {
                      return { id: r.id.toString(), name: r.name }
                  })
                : [],
        [unexusRouters]
    )
    const addRow = useCallback(() => {
        const rows = [...unexusConnectionRows]
        rows.push({
            id: getNextRowId(rows),
            roleUid: "",
            routers: [],
        })
        setValue("unexusConnectionRows", rows)
    }, [unexusConnectionRows, setValue])

    return (
        <Form.Group className="mb-3">
            <Form.Label>Unexusbron toevoegen per taak</Form.Label>
            <InfoPopover
                id="unexusConnectionInfo"
                body="Indien je een active Unexus koppeling hebt kun je de basisbezetting van een taak baseren op historische gegevens uit Unexus. Kies een taak, koppel een wachtrij en laat de ideale basisbezetting automatisch berekend worden."
            />
            {unexusConnectionRows.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <td style={{ minWidth: "150px" }}>
                                <span className="input-label">Taak</span>
                            </td>
                            <td className="ps-2" style={{ minWidth: "150px" }}>
                                <span className="input-label">Wachtrij(en)</span>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {unexusConnectionRows.map((row, i) => (
                            <UnexusConnectionRowView key={row.id} rowIndex={i} watch={watch} setValue={setValue} unexusRouters={unexusRouterOptions} />
                        ))}
                    </tbody>
                </table>
            ) : null}
            <Button type="button" variant="link" onClick={addRow} data-cy="addConnectionRow">
                Voeg toe
            </Button>
        </Form.Group>
    )
}

export default UnexusConnectionRowsView
