import { FC, useMemo } from "react"
import { ScheduleMap } from "../../../helpers/ScheduleMapHelper"
import StaticWeek from "./StaticWeek"
import styles from "./StaticWeekSchedule.module.scss"

interface StaticWeekScheduleProps {
    fromDate: Date
    numberOfWeeks: number
    scheduleMap: ScheduleMap
    showLocation: boolean
}

const StaticWeekSchedule: FC<StaticWeekScheduleProps> = ({ fromDate, scheduleMap, numberOfWeeks, showLocation }) => {
    const showWeekNumber = useMemo(() => numberOfWeeks > 1, [numberOfWeeks])

    return (
        <div className={styles.scrollContainer}>
            <div className={styles.weeksContainer}>
                <StaticWeek showWeekNumber={showWeekNumber} scheduleMap={scheduleMap} fromDate={fromDate} weekIndex={0} showLocation={showLocation} />
                {numberOfWeeks > 1 ? <StaticWeek showWeekNumber={showWeekNumber} scheduleMap={scheduleMap} fromDate={fromDate} weekIndex={1} showLocation={showLocation} /> : null}
                {numberOfWeeks > 2 ? <StaticWeek showWeekNumber={showWeekNumber} scheduleMap={scheduleMap} fromDate={fromDate} weekIndex={2} showLocation={showLocation} /> : null}
                {numberOfWeeks > 3 ? <StaticWeek showWeekNumber={showWeekNumber} scheduleMap={scheduleMap} fromDate={fromDate} weekIndex={3} showLocation={showLocation} /> : null}
            </div>
        </div>
    )
}

export default StaticWeekSchedule
