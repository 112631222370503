import { FC, MouseEventHandler, useCallback, useContext, useMemo, useState } from "react"
import { ScrollSync, ScrollSyncNode } from "scroll-sync-react"
import { OccupationEditorContext, useOccupationEditorState } from "../../contexts/OccupationEditorContext"
import { useContinueTour } from "../../contexts/TourContext"
import { getTimeSlots } from "../../helpers/DaysHelper"
import { Cell, CellSelection } from "../../helpers/editor/OccupationEditorHelper"
import { getClickedCell } from "../../helpers/OccupationHelper"
import { NestedRoleItem } from "../../helpers/RolesHelper"
import EditorDivider from "../editor/EditorDivider"
import EditorFilling from "../editor/EditorFilling"
import styles from "./OccupationsEditor.module.scss"
import OccupationsEditorControls from "./OccupationsEditorControls"
import OccupationsEditorRole from "./OccupationsEditorRole"

const OccupationsEditor: FC = () => {
    const { applyMutation } = useContext(OccupationEditorContext)
    const editorState = useOccupationEditorState()
    const continueTour = useContinueTour()

    const [cellSelection, setCellSelection] = useState<CellSelection>({ from: undefined, to: undefined })

    const { revisionId, dayId, location, nestedRoles } = editorState
    const { startSlot, endSlot } = location

    const updateSelection = useCallback(
        (selectedFrom: Cell | undefined, selectedTo: Cell | undefined) => {
            setCellSelection({ from: selectedFrom, to: selectedTo })
        },
        [setCellSelection]
    )

    const selectCell: MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            const target = event.currentTarget as HTMLDivElement
            const cell = getClickedCell(target.id)
            if (cellSelection.from !== undefined && cellSelection.to === undefined && cellSelection.from!.dayId === cell.dayId && cellSelection.from!.roleUid === cell.roleUid) {
                if (cell.slot < cellSelection.from.slot) {
                    updateSelection(cell, cellSelection.from)
                } else {
                    updateSelection(cellSelection.from, cell)
                }
            } else {
                updateSelection(cell, undefined)
            }
        },
        [updateSelection, cellSelection]
    )

    const clearSelection = useCallback(() => updateSelection(undefined, undefined), [updateSelection])

    const setOccupationData = useCallback(
        (roleUid: string, cellFrom: number, cellTo: number, value: (number | null)[]) => {
            applyMutation(roleUid, cellFrom, cellTo, value)
            clearSelection()
        },
        [applyMutation, clearSelection]
    )

    const setOccupation = useCallback(
        (value: (number | null)[]) => {
            if (cellSelection.from === undefined) {
                return
            }

            const roleUid = cellSelection.from.roleUid
            const cellFrom = cellSelection.from.slot
            const cellTo = cellSelection.to !== undefined ? cellSelection.to.slot : cellSelection.from.slot

            setOccupationData(roleUid, cellFrom, cellTo, value)
        },
        [cellSelection, setOccupationData]
    )

    const roleItems = useMemo(() => {
        const roleItems: NestedRoleItem[] = []
        for (const item of nestedRoles) {
            if (!item.hasOccupationTarget && !item.subRoles) {
                continue
            }

            roleItems.push(item)
            if (item.subRoles) {
                for (const subItem of item.subRoles) {
                    roleItems.push(subItem)
                }
            }
        }
        return roleItems
    }, [nestedRoles])

    return (
        <ScrollSync>
            <div className={styles.scrollContainer}>
                <div className="editor-sticky-add-on editor-sticky-header">
                    <ScrollSyncNode>
                        <div className="overflow-auto no-scrollbar">
                            <div className="editor-container">
                                <div className="editor-add-on editor-head">
                                    <div className="editor-cell-title" />
                                    {getTimeSlots(startSlot, endSlot, "regular")}
                                </div>
                            </div>
                        </div>
                    </ScrollSyncNode>
                </div>
                <ScrollSyncNode>
                    <div className="overflow-auto no-scrollbar">
                        <div className={`editor-container ${styles.editorContentContainer}`} data-cy="editorRows">
                            {roleItems.map((roleItem, i) => (
                                <OccupationsEditorRole key={`${dayId}#${i}#${revisionId}`} roleItem={roleItem} cellSelection={cellSelection} selectCell={selectCell} />
                            ))}
                            <EditorDivider order={998} />
                            <EditorFilling startSlot={startSlot} endSlot={endSlot} order={99997} />
                            <EditorDivider order={99998} />
                        </div>
                    </div>
                </ScrollSyncNode>
                <div className="editor-sticky-add-on editor-sticky-footer">
                    <ScrollSyncNode>
                        <div className="overflow-auto">
                            <div className="editor-container">
                                <div className="editor-add-on">
                                    <div className="editor-head-title"></div>
                                    <OccupationsEditorControls setOccupation={setOccupation} clearSelection={clearSelection} />
                                </div>
                            </div>
                        </div>
                    </ScrollSyncNode>
                </div>
            </div>
        </ScrollSync>
    )
}

export default OccupationsEditor
