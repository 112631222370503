import { FC } from "react"
import { Link } from "react-router-dom"

interface Props {
    userName: string
    link: string
}

const UserNameCell: FC<Props> = ({ userName, link }) => {
    return (
        <Link to={link} className="text-truncated">
            {userName}
        </Link>
    )
}

export default UserNameCell
