import { ChangeEvent, FC, useCallback, useMemo } from "react"

interface InlineFormMultiSelectOption {
    id: string
    name: string
}

interface Props {
    id: string
    options: InlineFormMultiSelectOption[]
    values: string[]
    onChange: (values: string[]) => void
    toggleAllEnabled: boolean
    disabledIds?: string[]
}

const InlineFormMultiSelect: FC<Props> = ({ id, options, values, onChange, toggleAllEnabled, disabledIds }) => {
    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            let newValues = [...values]
            const optionKey = event.target.name

            if (newValues.indexOf(optionKey) !== -1) {
                newValues.splice(newValues.indexOf(optionKey), 1)
            } else {
                newValues.push(optionKey)
            }

            onChange(newValues)
        },
        [values, onChange]
    )

    const toggleAll = useCallback(() => {
        const allChecked = options.length === values.length
        const newValues = allChecked === true ? [] : options.map((item) => item.id)

        onChange(newValues)
    }, [options, values, onChange])

    const allChecked = useMemo(() => options.length === values.length, [options, values])

    const optionComponents = useMemo(() => {
        const components = []
        if (toggleAllEnabled) {
            components.push(
                <div key="-1" className="mb-2">
                    <input className="form-check-input" type="checkbox" id={id + "_all"} name="_all" checked={allChecked} onChange={toggleAll} />
                    <label className="ms-2 text-bold text-muted text-truncated filter-label" htmlFor={id + "_all"}>
                        {allChecked === true ? "Deselecteer" : "Selecteer"} alle
                    </label>
                </div>
            )
        }
        for (let i in options) {
            const option = options[i]
            const disabled = disabledIds?.includes(option.id)
            components.push(
                <div key={option.id} style={{ whiteSpace: "nowrap" }} className="mb-2">
                    <input className="form-check-input" type="checkbox" id={id + "_" + option.id} name={option.id} checked={values.includes(option.id)} onChange={handleChange} disabled={disabled} />
                    <label className={"ms-2 text-bold text-truncated filter-label" + (disabled ? " text-muted" : "")} htmlFor={id + "_" + option.id}>
                        {option.name}
                    </label>
                </div>
            )
        }
        return components
    }, [allChecked, toggleAll, options, disabledIds, values, handleChange])

    return (
        <div>
            <div className="form-group">{optionComponents}</div>
        </div>
    )
}

export default InlineFormMultiSelect
