import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useMemo } from "react"
import { useColors } from "../../../contexts/UserSettingsContext"
import { getCSSColor } from "../../../helpers/ColorHelper"
import { NestedRoleItem } from "../../../helpers/RolesHelper"
import subIcon from "../../../images/sub-icon.svg"
import OccupationBadge from "./OccupationBadge"
import styles from "./OccupationTimeslotDetailRow.module.scss"

interface Props {
    roleItem: NestedRoleItem
    min: number | null
    max: number | null
    actual: number
    selectRoleItem: (roleUid: string) => void
}

const OccupationTimeSlotDetailRow: FC<Props> = ({ roleItem, min, max, actual, selectRoleItem }) => {
    const colors = useColors()

    const roleColor = useMemo(() => getCSSColor(colors.get(roleItem.color)!), [colors, roleItem])

    const onClick = useCallback(() => selectRoleItem(roleItem.uid), [selectRoleItem, roleItem])

    return (
        <tr className={styles.row} onClick={onClick}>
            <td className="ps-3 pe-2">
                <FontAwesomeIcon icon={faCircle} style={{ color: roleColor }} />
            </td>
            <td className={styles.roleCell}>
                {roleItem.parent ? <img className="custom-fa-icon me-1" src={subIcon} alt="sub-arrow" /> : null}
                {roleItem.name}
            </td>
            <td className="ps-2 pe-3 text-end">
                <OccupationBadge min={min} max={max} actual={actual} />
            </td>
        </tr>
    )
}

export default OccupationTimeSlotDetailRow
