import { FC, ReactNode } from "react"
import { Breadcrumb } from "./Breadcrumb"
import BreadcrumbComponent from "./BreadcrumbComponent"
import styles from "./RegularPage.module.scss"

interface RegularPageProps {
    id: string
    children?: ReactNode
    breadCrumbs?: Breadcrumb[]
}

const RegularPage: FC<RegularPageProps> = ({ id, children, breadCrumbs }) => {
    return (
        <div className="w-100">
            <div className="card" id={id}>
                <div className={styles.fullPageCardBody}>
                    {breadCrumbs ? (
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-light">
                                {breadCrumbs
                                    ? breadCrumbs.map((crumb, index) => <BreadcrumbComponent key={id + "_" + index} title={crumb.title} link={crumb.link} active={index === breadCrumbs.length - 1} />)
                                    : null}
                            </ol>
                        </nav>
                    ) : null}
                    {children}
                </div>
            </div>
        </div>
    )
}

export default RegularPage
