import { withParams } from "../helpers/FilterHelper"
import HiringAvailabilityType, { UpdateHiringAvailabilityType, UpsertHiringAvailabilityType } from "../types/HiringAvailabilityType"
import PageRequestType from "../types/PageRequestType"
import PageResponseType from "../types/PageResponseType"
import api from "./Api"

export function loadHiringAvailabilities(request: PageRequestType): Promise<PageResponseType<HiringAvailabilityType>> {
    return new Promise<PageResponseType<HiringAvailabilityType>>((resolve, reject) =>
        getHiringAvailabilities(request)
            .then((response) => resolve(response.data))
            .catch(reject)
    )
}

export function getHiringAvailabilities(pageDetails: PageRequestType) {
    return api.get<{ items: HiringAvailabilityType[]; pageCount: number }>(withParams("/hiring-availabilities/", pageDetails))
}

export function loadHiringAvailability(id: number) {
    return new Promise<HiringAvailabilityType>((resolve) => {
        getHiringAvailability(id).then((response) => resolve(response.data))
    })
}

export function getHiringAvailability(id: number) {
    return api.get<HiringAvailabilityType>(`/hiring-availabilities/${id}/`)
}

export function saveAllHiringAvailabilities(hiringAvailabilities: UpsertHiringAvailabilityType[]) {
    return api.post("/hiring-availabilities/", { availabilities: hiringAvailabilities })
}

export function updateHiringAvailability(hiringAvailability: UpdateHiringAvailabilityType) {
    return api.patch(`/hiring-availabilities/${hiringAvailability.id}/`, hiringAvailability)
}

export function deleteHiringAvailability(id: number) {
    return api.delete(`/hiring-availabilities/${id}/`)
}
