import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CSSProperties } from "react"
import { ClearIndicatorProps } from "react-select"

const ClearIndicator = (props: ClearIndicatorProps) => {
    const {
        getStyles,
        innerProps: { ref, ...restInnerProps },
    } = props
    return (
        <div {...restInnerProps} ref={ref} style={getStyles("clearIndicator", props) as CSSProperties}>
            <FontAwesomeIcon icon={faXmark} />
        </div>
    )
}

export default ClearIndicator
