import { FC, useCallback } from "react"
import DatePicker from "react-datepicker"
import DatePickerHeader from "../table/DatePickerHeader"
import styles from "./DateRangeInput.module.scss"

interface DateRangeInputProps {
    startDate?: Date
    setStartDate: (date: Date | null) => void
    endDate?: Date
    setEndDate: (date: Date | null) => void
}

const DateRangeInput: FC<DateRangeInputProps> = ({ startDate, setStartDate, endDate, setEndDate }) => {
    const onChangeStartDate = useCallback(
        (date: Date | null) => {
            setStartDate(date)
            if (date !== null && !!endDate && date > endDate) {
                setEndDate(null)
            }
        },
        [setStartDate, endDate]
    )

    const onChangeEndDate = useCallback((date: Date | null) => setEndDate(date), [setEndDate])

    return (
        <div className="me-2">
            <span className="text-small me-2">Van</span>
            <div className="d-inline-block">
                <DatePicker
                    className={styles.dateRangeInput}
                    dateFormat="d-M-yyyy"
                    selected={startDate}
                    onChange={onChangeStartDate}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    todayButton="Vandaag"
                    showWeekNumbers
                    disabledKeyboardNavigation
                    isClearable={startDate !== null}
                    renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => <DatePickerHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />}
                />
            </div>
            <span className="text-small mx-2">tot</span>
            <div className="d-inline-block">
                <DatePicker
                    className={styles.dateRangeInput}
                    dateFormat="d-M-yyyy"
                    selected={endDate}
                    onChange={onChangeEndDate}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    todayButton="Vandaag"
                    showWeekNumbers
                    disabledKeyboardNavigation
                    isClearable={endDate !== null}
                    renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => <DatePickerHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />}
                />
            </div>
        </div>
    )
}

export default DateRangeInput
