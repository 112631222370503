import { getAllDateNotes, getAllDateNotesForDays } from "../services/DateNote"
import { NotesType } from "../types/DateNoteType"
import LocationType from "../types/LocationType"
import { dateFromDjango, getDayId } from "./DaysHelper"

export function loadNotesForDate(location: LocationType, date: Date): Promise<NotesType> {
    return new Promise((resolve) => {
        getAllDateNotes(location.id, date).then((response) => resolve(response.data))
    })
}

export const loadNotesForWeek = (locations: number[], user: number, fromDate: Date): Promise<Map<string, NotesType>> => {
    return new Promise((resolve) => {
        getAllDateNotesForDays(locations, fromDate, 7).then((response) => resolve(createMap(response.data)))
    })
}

const createMap = (notes: NotesType) => {
    const map = new Map<string, NotesType>()
    for (let note of notes.dateNotes) {
        const dateId = getDayId(dateFromDjango(note.date))
        if (!map.has(dateId)) {
            map.set(dateId, { dateNotes: [], holidayNotes: [] })
        }
        map.get(dateId)!.dateNotes.push(note)
    }
    for (let holidayNote of notes.holidayNotes) {
        const dateId = getDayId(dateFromDjango(holidayNote.date))
        if (!map.has(dateId)) {
            map.set(dateId, { dateNotes: [], holidayNotes: [] })
        }
        map.get(dateId)!.holidayNotes.push(holidayNote)
    }
    return map
}
