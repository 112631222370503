import moment from "moment"
import "moment/locale/nl"
import LocationType from "../types/LocationType"

export function getDayName(i: number) {
    return ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag", "Zondag"][i % 7]
}

export function getWeekName(i: number) {
    return `week ${1 + Math.floor(i / 7)}`
}

export function getDayNameWeekCycle(i: number) {
    return `${getDayName(i)} (${getWeekName(i)})`
}

export function getShortDayName(i: number) {
    return ["Ma", "Di", "Wo", "Do", "Vr", "Za", "Zo"][i % 7]
}

export function getMonthName(i: number) {
    return ["Jan", "Feb", "Mrt", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"][i]
}

export function getLongMonthName(i: number) {
    return ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"][i]
}

export function shortDate(date: Date) {
    return moment(date).format("D MMM")
}

export function prettyPrint(date: Date) {
    moment.locale("nl")
    return moment(date).format("dddd D MMMM")
}

export function prettyPrintTime(date: Date) {
    return moment(date).format("HH:mm")
}

export function prettyPrintWithYear(date: Date) {
    moment.locale("nl")
    return moment(date).format("dddd D MMMM YYYY")
}

export function prettyPrintShort(date: Date) {
    moment.locale("nl")
    return moment(date).format("dddd D MMM")
}

export function prettyPrintExtraShort(date: Date) {
    moment.locale("nl")
    return moment(date).format("D MMM")
}

export function quartersFromQuarterId(quarterId: string): number {
    return parseInt(quarterId.split("-")[3])
}

export function getYearFromDayId(dayId: string): number {
    return parseInt(dayId.split("-")[0])
}

export function getMonthFromDayId(dayId: string): number {
    return parseInt(dayId.split("-")[1])
}

export function getDayFromDayId(dayId: string): number {
    return parseInt(dayId.split("-")[2])
}

export function getYearFromWeekId(weekId: string): number {
    return parseInt(weekId.split("-")[0])
}

export function getWeekFromWeekId(weekId: string): number {
    return parseInt(weekId.split("-")[1])
}

export function getYearFromMonthId(monthId: string): number {
    return parseInt(monthId.split("-")[0])
}

export function getMonthFromMonthId(monthId: string): number {
    return parseInt(monthId.split("-")[1])
}

export function prettyPrintWithoutDay(date: Date) {
    moment.locale("nl")
    return moment(date).format("D MMMM")
}

export function prettyPrintDateTime(date: Date) {
    moment.locale("nl")
    return moment(date).format("D-M-YYYY HH:mm")
}

export function shortPrintMonth(date: Date) {
    moment.locale("nl")
    return moment(date).format("MMM 'YY")
}

export function prettyPrintMonth(date: Date) {
    moment.locale("nl")
    return moment(date).format("MMMM YYYY")
}

export function prettyPrintQuarters(quarters: number) {
    if (quarters === 0) {
        return "0"
    }
    let hours = Math.floor(quarters / 4)
    let remainingQuarters = Math.round(quarters % 4)
    if (remainingQuarters === 4) {
        hours += 1
        remainingQuarters = 0
    }

    return hours + "u" + (remainingQuarters > 0 ? ` ${remainingQuarters * 15}m` : "")
}

export function isoPrint(date: Date) {
    return moment(date).format("D-M-YYYY")
}

export function dateFromDjango(date: string) {
    return moment(date, "YYYY-MM-DD").toDate()
}

export function dateTimeFromDjango(date: string) {
    return moment(date, "YYYY-MM-DDTHH:mm:ss").toDate()
}

export function dateToDjango(date: Date) {
    return moment(date).format("YYYY-MM-DD")
}

export function dateFromIso(date: string) {
    if (!isIsoFormat(date)) {
        return
    }
    return moment(date, "D-M-YYYY").toDate()
}

export function isIsoFormat(date: string) {
    return moment(date, "D-M-YYYY", true).isValid()
}

export function getWeekNumber(date: Date): number {
    var date = new Date(date)
    date.setHours(0, 0, 0, 0)
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7))
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4)
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7)
}

export function isOddWeek(date: Date): Boolean {
    return getWeekNumber(date) % 2 === 1
}

export function getTimeSlots(startSlot: number, endSlot: number, style: "small" | "regular") {
    let components = []
    let previousEndSlot = startSlot - 1
    for (let slot = startSlot; slot <= endSlot; slot++) {
        let shouldAppend = false
        let shouldIncludeText = false
        if ((slot + 1) % 4 === 0) {
            shouldAppend = true
            shouldIncludeText = slot - previousEndSlot === 4
        } else if (slot === endSlot) {
            shouldAppend = true
            shouldIncludeText = true
        }

        if (shouldAppend) {
            components.push(
                <div
                    key={slot}
                    className="editor-head-cell text-muted"
                    style={{ flexGrow: slot - previousEndSlot, fontSize: style === "small" ? "0.7rem" : undefined, borderLeft: style === "small" ? "1px solid #E8F1FF" : undefined }}
                >
                    {shouldIncludeText ? `${(previousEndSlot + 1) / 4}u` : null}
                </div>
            )
            previousEndSlot = slot
        }
    }

    return components
}

export function isEnabled(enabledDays: string, weekCycle: number, i: number, zeroBased: boolean) {
    let day: number
    if (!zeroBased) {
        day = i > 0 ? i - 1 : 6
    } else {
        day = i
    }
    if (Math.floor(day / 7) > weekCycle) {
        return false
    }
    return enabledDays[day % 7] === "1"
}

export function isVisible(enabledDays: string, selectedWeek: number, i: number) {
    const isInVisibleWeek = selectedWeek === Math.floor(i / 7)
    const isEnabledDay = enabledDays[i % 7] === "1"
    return isInVisibleWeek && isEnabledDay
}

export function getEnabledDays(locations: LocationType[]) {
    let enabledDays = ["0", "0", "0", "0", "0", "0", "0"]
    for (const location of locations) {
        for (let j = 0; j < location.enabledDays.length; j++) {
            if (location.enabledDays.charAt(j) === "1") {
                enabledDays[j] = "1"
            }
        }
    }
    return enabledDays.join("")
}

export function getStartAndEndSlot(locations: LocationType[]) {
    let minStart = 48
    let maxEnd = 51
    for (let location of locations) {
        if (location.startSlot < minStart) {
            minStart = location.startSlot
        }
        if (location.endSlot > maxEnd) {
            maxEnd = location.endSlot
        }
    }
    return [minStart, maxEnd]
}

export function isEnabledDate(enabledDays: string, date: Date): boolean {
    const day = date.getDay() % 7
    return isEnabled(enabledDays, 1, day, false)
}

export function getFirstEnabledDayIndex(enabledDays: string): number {
    return enabledDays.indexOf("1")
}

export function isFirstEnabledDay(date: Date, enabledDays: string) {
    return (date.getDay() - 1) % 7 === getFirstEnabledDayIndex(enabledDays)
}

export function addDays(date: Date, days: number) {
    let result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
}

export function addWeeks(date: Date, weeks: number) {
    const result = new Date(date)
    result.setDate(result.getDate() + 7 * weeks)
    return result
}

export function addMonths(date: Date, months: number) {
    var result = new Date(date)
    result.setMonth(result.getMonth() + months)
    return result
}

export function getFirstNextWorkday(date: Date, enabledDays: string): Date {
    const currentDay = date.getDay()
    for (var i = 0; i < 7; i++) {
        const day = (currentDay + i) % 7
        if (isEnabled(enabledDays, 1, day, false)) {
            return new Date(addDays(date, i).setHours(0, 0, 0, 0))
        }
    }
    return new Date(addDays(date, 0).setHours(0, 0, 0, 0))
}

export function getFirstPreviousWorkday(date: Date, enabledDays: string): Date {
    const currentDay = date.getDay()
    for (var i = 0; i < 7; i++) {
        const day = (7 + (currentDay - i)) % 7
        if (isEnabled(enabledDays, 1, day, false)) {
            return new Date(addDays(date, -i).setHours(0, 0, 0, 0))
        }
    }
    return new Date(addDays(date, 0).setHours(0, 0, 0, 0))
}

export function getFirstOfMonth(date: Date): Date {
    return addDays(date, -(date.getDate() - 1))
}

export function getFirstMondayOfWeek(date: Date): Date {
    return addDays(date, -(date.getDay() === 0 ? 6 : date.getDay() - 1))
}

export function getFirstMondayOfMonthView(date: Date): Date {
    const firstOfMonth = getFirstOfMonth(date)
    const currentDay = firstOfMonth.getDay() % 7
    return addDays(firstOfMonth, 1 - currentDay)
}

export function getLastSundayOfMonthView(date: Date): Date {
    const endOfMonth = addMonths(date, 1)
    endOfMonth.setDate(0)
    const currentDay = endOfMonth.getDay() % 7
    return addDays(endOfMonth, 7 - currentDay)
}

export function getDayId(date: Date) {
    return moment(date).format("YYYYMMDD")
}

export function getDate(dayId: string) {
    return moment(dayId, "YYYYMMDD").toDate()
}

export function getTime(at: "start" | "end", slot: number) {
    const quarterIndex = at === "start" ? slot : slot + 1
    var date = new Date(0)
    date.setHours(Math.floor(quarterIndex / 4))
    date.setMinutes((quarterIndex % 4) * 15)
    return date
}

export function getTimeString(at: "start" | "end", slot: number) {
    const time = getTime(at, slot)
    if (!time) {
        return ""
    }
    return moment(time).format("H:mm")
}

export function isoPrintTime(date?: Date) {
    if (!date) {
        return null
    }
    return moment(date).format("H:mm")
}

export function timeFromIso(time: string) {
    if (!isIsoFormatTime(time)) {
        return
    }
    return moment(time, "H:mm").toDate()
}

export function isIsoFormatTime(time: string) {
    return moment(time, "H:mm", true).isValid()
}

export function getSlot(at: "start" | "end", time: Date): number {
    const quarterIndex = time.getHours() * 4 + time.getMinutes() / 15
    if (quarterIndex === 0 && at === "end") {
        return 95
    }
    return at === "start" ? quarterIndex : quarterIndex - 1
}

export function getMoment(time: string) {
    return moment(time, "HH:mm")
}

export function timeToDate(time: string) {
    return getMoment(time).toDate()
}

export function getGreeting(date: Date): string {
    if (date.getHours() < 12) {
        return "Goedemorgen"
    } else if (date.getHours() < 18) {
        return "Goedemiddag"
    } else {
        return "Goedenavond"
    }
}

export function parseDates(dates: string[]): Date[] {
    return dates.map(dateFromDjango)
}

export function daysBetween(date1: Date, date2: Date): number {
    return moment(date1).diff(moment(date2), "days")
}

export function weeksBetween(date1: Date, date2: Date): number {
    return moment(date1).diff(moment(date2), "weeks")
}

export function monthsBetween(date1: Date, date2: Date): number {
    return moment(date1).diff(moment(date2), "months")
}

export const getDaysString = (weekCycle: number) => {
    return "".padStart(7 * weekCycle, "0")
}
