import { FC } from "react"
import RoleType from "../types/RoleType"

interface RoleSelectProps {
    roles: RoleType[]
    selectedRoleId: number
    setSelectedRoleId: (value: number) => void
}

const RoleSelect: FC<RoleSelectProps> = ({ roles, selectedRoleId, setSelectedRoleId }) => {
    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault()
        setSelectedRoleId(parseInt(event.target.value))
    }

    let components = []
    components.push(
        <option key={-1} value={0}>
            Vrij
        </option>
    )
    for (let i in roles) {
        const role = roles[i]
        const { id, name } = role

        components.push(
            <option key={id} value={id}>
                {name}
            </option>
        )
    }

    return (
        <select data-cy="roleSelect" className="form-select form-select-inline ms-2 me-2 pb-1" value={selectedRoleId} onChange={onChange}>
            {components}
        </select>
    )
}

export default RoleSelect
