import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import debounce from "debounce"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import DateTimeSelector from "../../components/form/DateTimeSelector"
import RegularPage from "../../components/page/RegularPage"
import { useTabs } from "../../contexts/TabsContext"
import { useCurrentUser, useIsAdmin, useRolesForSelectedLocation, useSelectedLocation, useUsersForSelectedLocation } from "../../contexts/UserSettingsContext"
import { handleStatusOptions, reviewStatusOptions } from "../../helpers/AbsenceRequestHelper"
import { ROLE_TYPE_LEAVE } from "../../helpers/Constants"
import { dateFromDjango, getFirstNextWorkday, getSlot, getTime, isoPrint, isoPrintTime, timeFromIso } from "../../helpers/DaysHelper"
import { setOptionalError } from "../../helpers/FormHelper"
import { getRoleOptionsForType } from "../../helpers/RolesHelper"
import { getUserOptions } from "../../helpers/UsersHelper"
import { createAbsenceRequest, deleteAbsenceRequest, loadAbsenceRequest, updateAbsenceRequest } from "../../services/AbsenceRequest"
import { AbsenceRequestHandleStatus, AbsenceRequestReviewStatus } from "../../types/AbsenceRequestType"
import styles from "./EditAbsenceRequestPage.module.scss"

interface Props {
    mode: "Create" | "Update"
}

interface Inputs {
    user: number
    startDate: Date
    startTime: string
    endDate: Date
    endTime: string
    userMessage: string
    adminMessage: string
    reviewStatus: AbsenceRequestReviewStatus
    handleStatus: AbsenceRequestHandleStatus
    role?: number
}

const EditAbsenceRequestPage: FC<Props> = ({ mode }) => {
    const { setActiveTab } = useTabs()
    const params = useParams()
    const location = useSelectedLocation()
    const roles = useRolesForSelectedLocation()
    const id = useMemo(() => parseInt(params.id!), [params])
    const navigate = useNavigate()
    const isAdmin = useIsAdmin()
    const currentUser = useCurrentUser()
    const firstNextWorkday = useMemo(() => getFirstNextWorkday(new Date(), currentUser.enabledDays), [currentUser])
    const users = useUsersForSelectedLocation()
    const roleOptions = useMemo(() => getRoleOptionsForType(roles, ROLE_TYPE_LEAVE), [roles])

    const {
        register,
        setError,
        setValue,
        getValues,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm<Inputs>({
        defaultValues: {
            user: currentUser.id,
            startDate: firstNextWorkday,
            startTime: "",
            endDate: firstNextWorkday,
            endTime: "",
            userMessage: "",
            adminMessage: "",
            reviewStatus: "TO_BE_REVIEWED",
            handleStatus: "TO_BE_HANDLED",
        },
    })

    const [deletionWarningVisible, setDeletionWarningVisible] = useState(false)

    const { data: absencerequest } = useQuery(["AbsenceRequest", id], loadAbsenceRequest(id), { enabled: mode !== "Create" })

    useEffect(() => {
        if (!absencerequest) {
            return
        }
        setValue("user", absencerequest.user)
        setValue("startDate", dateFromDjango(absencerequest.startDate))
        setValue("startTime", absencerequest.startSlot !== null ? isoPrintTime(getTime("start", absencerequest.startSlot)!)! : "")
        setValue("endDate", dateFromDjango(absencerequest.endDate))
        setValue("endTime", absencerequest.endSlot !== null ? isoPrintTime(getTime("end", absencerequest.endSlot)!)! : "")
        setValue("userMessage", absencerequest.userMessage)
        setValue("adminMessage", absencerequest.adminMessage)
        setValue("reviewStatus", absencerequest.reviewStatus)
        setValue("handleStatus", absencerequest.handleStatus)
    }, [absencerequest])

    const navigateToPlan = useCallback(() => {
        const { user, startDate, startTime, endDate, endTime, role } = getValues()
        navigate(
            `/rooster?absencerequest=${id}&user=${user}&location=${location.id}&date=${isoPrint(startDate)}&startDate=${isoPrint(startDate)}&startTime=${startTime}&endDate=${isoPrint(endDate)}&endTime=${endTime}&role=${role}`
        )
    }, [navigate, id])

    const onSuccess = useCallback(() => navigate("/verlofaanvragen"), [navigate])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "user", data.user)
            setOptionalError(setError, "startDate", data.startDate)
            setOptionalError(setError, "startTime", data.startTime)
            setOptionalError(setError, "endDate", data.endDate)
            setOptionalError(setError, "endTime", data.endTime)
            setOptionalError(setError, "userMessage", data.userMessage)
            setOptionalError(setError, "adminMessage", data.adminMessage)
            setOptionalError(setError, "reviewStatus", data.reviewStatus)
            setOptionalError(setError, "handleStatus", data.handleStatus)
            setOptionalError(setError, "root", data.nonFieldErrors)
        },
        [setError]
    )

    const onSave = useCallback(
        debounce(
            ({ user, startDate, startTime, endDate, endTime, userMessage, adminMessage, reviewStatus, handleStatus }: Inputs, plan?: boolean) => {
                if (mode === "Create") {
                    createAbsenceRequest({
                        user: user,
                        startDate: isoPrint(startDate),
                        startSlot: startTime !== "" ? getSlot("start", timeFromIso(startTime)!)! : undefined,
                        endDate: isoPrint(endDate),
                        endSlot: endTime !== "" ? getSlot("end", timeFromIso(endTime)!)! : undefined,
                        userMessage: userMessage,
                    })
                        .then(onSuccess)
                        .catch(onFailure)
                } else if (mode === "Update") {
                    updateAbsenceRequest({
                        id: id,
                        user: user,
                        startDate: isoPrint(startDate),
                        startSlot: startTime !== "" ? getSlot("start", timeFromIso(startTime)!)! : undefined,
                        endDate: isoPrint(endDate),
                        endSlot: endTime !== "" ? getSlot("end", timeFromIso(endTime)!)! : undefined,
                        userMessage: userMessage,
                        adminMessage: adminMessage,
                        reviewStatus: reviewStatus,
                        handleStatus: handleStatus,
                    })
                        .then(plan ? navigateToPlan : onSuccess)
                        .catch(onFailure)
                }
            },
            300,
            {
                immediate: true,
            }
        ),
        [id]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback((inputs: Inputs) => onSave(inputs, false), [onSave])

    const onSubmitWithPlan = useCallback(() => onSave(getValues(), true), [onSave, getValues])

    const onDeleteAbsenceRequest = useCallback(() => {
        if (!deletionWarningVisible) {
            setDeletionWarningVisible(true)
            return
        }
        deleteAbsenceRequest(id).then(onSuccess).catch(onFailure)
    }, [deletionWarningVisible, setDeletionWarningVisible, id, onSuccess, onFailure])

    const userOptions = useMemo(() => getUserOptions(users), [users])

    useEffect(() => setActiveTab("AbsenceRequests"), [setActiveTab])

    const setStartDate = useCallback((value: Date | null) => setValue("startDate", value!), [setValue])
    const setStartTime = useCallback((value: string) => setValue("startTime", value), [setValue])
    const setEndDate = useCallback((value: Date | null) => setValue("endDate", value!), [setValue])
    const setEndTime = useCallback((value: string) => setValue("endTime", value), [setValue])

    const user = watch("user")
    const startDate = watch("startDate")
    const startTime = watch("startTime")
    const endDate = watch("endDate")
    const endTime = watch("endTime")
    const reviewStatus = watch("reviewStatus")
    const handleStatus = watch("handleStatus")

    const targetUser = useMemo(() => users.find((u) => u.id === user), [users])
    const targetUserName = useMemo(() => (targetUser ? targetUser.firstName + " " + targetUser.lastName : ""), [targetUser])
    const crumbTitle = useMemo(() => (mode === "Create" ? "Nieuw" : "Verlofaanvraag"), [mode])
    const pageTitle = useMemo(() => (mode === "Create" ? "Nieuwe verlofaanvraag" : targetUserName), [mode, targetUserName])

    return (
        <RegularPage id="EditAbsenceRequest" breadCrumbs={[{ title: "Verlofaanvragen", link: "/verlofaanvragen" }, { title: crumbTitle }]}>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <h2 className="mb-4">{pageTitle}</h2>
                {mode === "Create" && isAdmin ? (
                    <Form.Group className="mb-3">
                        <Form.Label>Medewerker</Form.Label>
                        <Form.Select {...register("user")} isInvalid={!!errors.user} size="lg" data-cy="user">
                            {userOptions.map(({ id, name }) => (
                                <option key={id} value={id}>
                                    {name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                ) : null}

                <div className="row">
                    <div className="col-12 col-md-5 col-xl-4">
                        <Form.Group className="mb-3">
                            <Form.Label>Van</Form.Label>
                            <DateTimeSelector
                                selectedDate={startDate}
                                setSelectedDate={setStartDate}
                                dateIsError={!!errors.startDate}
                                dateTabIndex={2}
                                selectedTime={startTime}
                                setSelectedTime={setStartTime}
                                timeIsError={!!errors.startTime}
                                timeTabIndex={3}
                                startSlot={currentUser.startSlot}
                                endSlot={currentUser.endSlot}
                                disabled={!isAdmin && reviewStatus !== "TO_BE_REVIEWED"}
                                inline={false}
                                type="start"
                            />
                            <Form.Control.Feedback type="invalid">{errors.startDate?.message}</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors.startTime?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-5 col-xl-4">
                        <Form.Group className="mb-3">
                            <Form.Label>Tot</Form.Label>
                            <DateTimeSelector
                                selectedDate={endDate}
                                setSelectedDate={setEndDate}
                                dateIsError={!!errors.endDate}
                                dateTabIndex={4}
                                selectedTime={endTime}
                                setSelectedTime={setEndTime}
                                timeIsError={!!errors.endTime}
                                timeTabIndex={5}
                                startSlot={currentUser.startSlot}
                                endSlot={currentUser.endSlot}
                                disabled={!isAdmin && reviewStatus !== "TO_BE_REVIEWED"}
                                inline={false}
                                type="end"
                            />
                            <Form.Control.Feedback type="invalid">{errors.endDate?.message}</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors.endTime?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                <Form.Group className="mb-3">
                    <Form.Label>{mode === "Create" ? "Toelichting" : "Toelichting aanvrager"}</Form.Label>
                    <Form.Control as="textarea" {...register("userMessage")} rows={3} isInvalid={!!errors.userMessage} disabled={!isAdmin && reviewStatus !== "TO_BE_REVIEWED"} />
                    <Form.Control.Feedback type="invalid">{errors.userMessage?.message}</Form.Control.Feedback>
                </Form.Group>
                {mode === "Update" ? (
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Status beoordeling</Form.Label>
                                <Form.Select {...register("reviewStatus")} isInvalid={!!errors.reviewStatus} disabled={!isAdmin}>
                                    {reviewStatusOptions.map(({ id, name }) => (
                                        <option key={id} value={id}>
                                            {name}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.reviewStatus?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </div>

                        <div className="col-12 col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Status afhandeling</Form.Label>
                                <Form.Select {...register("handleStatus")} isInvalid={!!errors.handleStatus} disabled={!isAdmin}>
                                    {handleStatusOptions.map(({ id, name }) => (
                                        <option key={id} value={id}>
                                            {name}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.handleStatus?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </div>
                    </div>
                ) : null}
                {mode === "Update" ? (
                    <Form.Group className="mb-3">
                        <Form.Label>Toelichting beoordelaar</Form.Label>
                        <Form.Control as="textarea" {...register("adminMessage")} rows={3} isInvalid={!!errors.adminMessage} disabled={!isAdmin} />
                        <Form.Control.Feedback type="invalid">{errors.adminMessage?.message}</Form.Control.Feedback>
                    </Form.Group>
                ) : null}
                <div className="d-flex align-items-center">
                    <Button type="submit">{mode === "Create" ? "Maak" : "Opslaan"}</Button>
                    {isAdmin && mode === "Update" ? (
                        <div className={`d-flex align-items-center mx-2 ${styles.saveAndPlan}`}>
                            <Button type="button" className="text-nowrap" onClick={onSubmitWithPlan} disabled={handleStatus !== "TO_BE_HANDLED"}>
                                Opslaan en inplannen
                            </Button>
                            <small className="mx-2">Taak:</small>
                            <Form.Select {...register("role")} className="form-select-inline">
                                {roleOptions.map(({ id, name }) => (
                                    <option key={id} value={id}>
                                        {name}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    ) : null}
                    {isAdmin && mode === "Update" ? (
                        <Button type="button" onClick={onDeleteAbsenceRequest} variant="danger">
                            Verwijder
                        </Button>
                    ) : null}
                </div>
                {deletionWarningVisible ? (
                    <div className="ms-1 mt-2">
                        <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                        <span className="me-1">Weet je zeker dat je deze verlofaanvraag wil verwijderen?</span>
                        <Button variant="link" type="button" onClick={onDeleteAbsenceRequest}>
                            Ja, verwijder
                        </Button>
                    </div>
                ) : null}
                <Form.Group>
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid" data-cy="root_errors">
                        {errors.root?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </RegularPage>
    )
}

export default EditAbsenceRequestPage
