import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AxiosResponse } from "axios"
import { FC, useCallback } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useRolesForSelectedLocation, useSelectedLocation } from "../../../contexts/UserSettingsContext"
import { setOptionalError } from "../../../helpers/FormHelper"
import { uploadShiftImport } from "../../../services/Schedule"
import { SuggestedShiftImportType } from "../../../types/ShiftImportType"

interface Props {
    setShiftImport: (shiftImport: SuggestedShiftImportType | null) => void
    onHide: () => void
}

interface Inputs {
    file: FileList
    role: string
}

const ShiftImportUpload: FC<Props> = ({ setShiftImport, onHide }) => {
    const { t } = useTranslation()
    const location = useSelectedLocation()
    const roles = useRolesForSelectedLocation()

    const {
        register,
        setError,
        formState: { errors },
        handleSubmit,
    } = useForm<Inputs>({ defaultValues: { role: "" } })

    const onSuccess = useCallback(
        (response: AxiosResponse<SuggestedShiftImportType>) => {
            setShiftImport(response.data)
        },
        [setShiftImport]
    )

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "file", data.file)
            setOptionalError(setError, "role", data.role)
            setOptionalError(setError, "root", data.nonFieldErrors)
        },
        [setError]
    )

    const onSubmit = useCallback(
        ({ file, role }: Inputs) => {
            uploadShiftImport(location, role, file).then(onSuccess).catch(onFailure)
        },
        [location, onSuccess, onFailure]
    )

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header className="justify-content-between">
                <Modal.Title>Importeer shifts</Modal.Title>
                <Button type="button" variant="link" onClick={onHide}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>{t("Forms.file")}</Form.Label>
                    <Form.Control type="file" {...register("file")} isInvalid={!!errors.file} />
                    <Form.Control.Feedback type="invalid">{errors.file?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("Forms.role")}</Form.Label>
                    <Form.Select {...register("role")} isInvalid={!!errors.role}>
                        <option value="">{t("Buttons.select")}</option>
                        {roles.map(({ id, name }) => (
                            <option key={id} value={id.toString()}>
                                {name}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.role?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid">{errors.root?.message}</Form.Control.Feedback>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" data-cy="submit">
                    {t("Buttons.next")}
                </Button>
            </Modal.Footer>
        </Form>
    )
}

export default ShiftImportUpload
