import { getHiringAvailabilities } from "../services/HiringAvailability"
import HiringAvailabilityType, { HiringAvailabilityStatus } from "../types/HiringAvailabilityType"
import LocationType from "../types/LocationType"
import { isoPrint } from "./DaysHelper"

export const hiringAvailabilityStatus = [
    { id: "AVAILABLE", name: "Beschikbaar" },
    { id: "UNAVAILABLE", name: "Onbeschikbaar" },
]

export const prettyPrintStatus = (status: HiringAvailabilityStatus) => {
    switch (status) {
        case "AVAILABLE":
            return "Ja"
        case "UNAVAILABLE":
            return "Nee"
        default:
            return ""
    }
}

export const hiringAvailabilityStatusOptions = hiringAvailabilityStatus

export const hiringAvailabilityStatusFilterOptions = [
    { key: "AVAILABLE", title: "Beschikbaar" },
    { key: "UNAVAILABLE", title: "Onbeschikbaar" },
    { key: "UNKNOWN", title: "Onbekend" },
]

export function loadHiringAvailabilitiesForDate(location: LocationType, date: Date): Promise<HiringAvailabilityType[]> {
    return new Promise((resolve) => {
        const pageDetails = {
            pageIndex: 0,
            pageSize: 999,
            filters: [
                { key: "dateFrom", value: isoPrint(date) },
                { key: "dateTo", value: isoPrint(date) },
                { key: "location", value: location.id.toString() },
            ],
        }
        getHiringAvailabilities(pageDetails).then((response) => resolve(response.data.items))
    })
}

export const isAppliccable = (hiringAvailability: HiringAvailabilityType, slot: number) => {
    const { startSlot, endSlot } = hiringAvailability
    let result = true
    if (startSlot && slot < startSlot) {
        result = false
    }
    if (endSlot && slot > endSlot) {
        result = false
    }
    return result
}
