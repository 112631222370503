import { FC, useMemo } from "react"
import { useCurrentUser } from "../../../contexts/UserSettingsContext"
import { addDays, dateToDjango, getWeekNumber, isEnabledDate } from "../../../helpers/DaysHelper"
import { ScheduleItem, ScheduleMap, toDashboardScheduleMap } from "../../../helpers/ScheduleMapHelper"
import StaticWeekDay from "./StaticWeekDay"
import StaticWeekHead from "./StaticWeekHead"

interface Props {
    scheduleMap: ScheduleMap
    fromDate: Date
    weekIndex: number
    showLocation: boolean
    showWeekNumber: boolean
}

interface StaticDay {
    date: Date
    locationMap: Map<string, ScheduleItem>
}

const StaticWeek: FC<Props> = ({ scheduleMap, fromDate, weekIndex, showLocation, showWeekNumber }) => {
    const { enabledDays } = useCurrentUser()
    const startDate = useMemo(() => addDays(fromDate, weekIndex * 7), [fromDate, weekIndex])
    const endDate = useMemo(() => addDays(startDate, 7), [startDate])
    const dashboardMap = useMemo(() => toDashboardScheduleMap(scheduleMap, startDate, endDate), [scheduleMap, startDate, endDate])

    const items: StaticDay[] = useMemo(() => {
        const dates: StaticDay[] = []
        for (let date = startDate; date < endDate; date = addDays(date, 1)) {
            if (isEnabledDate(enabledDays, date)) {
                const dayId = dateToDjango(date)
                const locationMap: Map<string, ScheduleItem> = dashboardMap.get(dayId) ?? new Map()
                dates.push({
                    date,
                    locationMap,
                })
            }
        }
        return dates
    }, [startDate, endDate, dashboardMap])

    const weekNumber = useMemo(() => getWeekNumber(fromDate) + weekIndex, [fromDate, weekIndex])

    return (
        <div className="d-flex flex-column">
            <StaticWeekHead showWeekNumber={showWeekNumber} weekNumber={weekNumber} showLocation={showLocation} />
            <div>
                {items.map(({ date, locationMap }) => (
                    <StaticWeekDay key={`${date}`} date={date} locationMap={locationMap} showLocation={showLocation} />
                ))}
            </div>
        </div>
    )
}

export default StaticWeek
