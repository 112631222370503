import { FC, useCallback, useContext, useMemo } from "react"
import { OccupationEditorContext, useOccupationEditorState } from "../../contexts/OccupationEditorContext"
import { toOccupationScheduleMapFromCopy } from "../../helpers/OccupationMapHelper"
import CopyModal from "../editor/copyModal/CopyModal"

interface Props {
    visible: boolean
    close: () => void
}

const CopyOccupationsDayModal: FC<Props> = ({ visible, close }) => {
    const { applyMutations } = useContext(OccupationEditorContext)
    const editorState = useOccupationEditorState()

    const location = useMemo(() => editorState.location, [editorState.location])
    const enabledDays = useMemo(() => location.enabledDays, [location])
    const occupationTemplate = useMemo(() => editorState.occupationTemplate, [editorState.occupationTemplate])
    const weekCycle = useMemo(() => occupationTemplate!.weekCycle, [occupationTemplate])

    const copyDay = useCallback(
        (fromDay: string, toDays: string[]) => {
            applyMutations(toOccupationScheduleMapFromCopy(editorState.mergedSchedule, fromDay, toDays, location.startSlot, location.endSlot))
            close()
        },
        [editorState, location, applyMutations, close]
    )

    return <CopyModal visible={visible} close={close} weekCycle={weekCycle} enabledDays={enabledDays} copyDay={copyDay} />
}

export default CopyOccupationsDayModal
