import { useCallback, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import FormSuccessMessage from "../components/form/FormSuccessMessage"
import FullPageCard from "../components/page/FullPageCard"
import { setOptionalError } from "../helpers/FormHelper"
import { passwordLost } from "../services/User"

interface Inputs {
    email: string
}

const PasswordLostPage = () => {
    const { t } = useTranslation()
    const [succeededId, setSucceededId] = useState(0)
    const {
        register,
        reset,
        setError,
        formState: { errors },
        handleSubmit,
    } = useForm<Inputs>()

    const onSuccess = useCallback(() => {
        reset()
        setSucceededId(succeededId + 1)
    }, [reset, setSucceededId])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "email", data.email)
            setOptionalError(setError, "root", data.nonFieldErrors)
            setSucceededId(0)
        },
        [setError]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ email }) => {
            passwordLost(email).then(onSuccess).catch(onFailure)
        },
        [onSuccess, onFailure]
    )

    return (
        <FullPageCard>
            <div>
                <h2>{t("PasswordLostPage.password_lost")}</h2>
                <p>{t("PasswordLostPage.fill_email_to_reset_password")}</p>
            </div>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                    <Form.Label>{t("Forms.email_address")}</Form.Label>
                    <Form.Control type="email" autoComplete="email" {...register("email")} isInvalid={!!errors.email} data-cy="email" autoFocus />
                    <Form.Control.Feedback type="invalid" data-cy="email_errors">
                        {errors.email?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 flex-row">
                    <Button type="submit" data-cy="reset-password">
                        {t("PasswordLostPage.reset_password")}
                    </Button>
                    <small className="end text-muted">
                        <Trans i18nKey="Forms.back_to_login">
                            Back to
                            <Link data-cy="toLogin" to="/inloggen">
                                login
                            </Link>
                        </Trans>
                    </small>
                </Form.Group>
                <Form.Group>
                    <FormSuccessMessage succeededId={succeededId} />
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid" data-cy="root_errors">
                        {errors.root?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </FullPageCard>
    )
}

export default PasswordLostPage
