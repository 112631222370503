import { FC } from "react"

interface ErorrMessagesProps {
    forId: string
    errors: string[]
}

const ErorrMessages: FC<ErorrMessagesProps> = ({ forId, errors }) => {
    if (errors.length === 0) {
        return null
    }

    return (
        <div data-cy={forId + "_errors"}>
            {errors.map((item, index) => (
                <div key={index} className="form-input-error-label">
                    {item}
                </div>
            ))}
        </div>
    )
}

export default ErorrMessages
