import { useContext } from "react"
import { ScheduleEditorContext } from "../../contexts/ScheduleEditorContext"
import Loader from "../Loader"

const ScheduleLoader = () => {
    const { loading } = useContext(ScheduleEditorContext)

    return loading ? (
        <div className="ms-3">
            <Loader />
        </div>
    ) : null
}

export default ScheduleLoader
