import ColorType, { DefaultColorType } from "../types/ColorType"
import { HiringAvailabilityStatusWithUnknown } from "../types/HiringAvailabilityType"

export function getCSSColor(color: ColorType) {
    if (!color) return "transparent"
    return "rgb(" + color.r + "," + color.g + "," + color.b + ")"
}

export function getCSSColor2(color: ColorType) {
    if (!color) return "transparent"
    return "rgba(" + color.r + "," + color.g + "," + color.b + ", 0.3)"
}

export function getCSSColor3(color: ColorType, opacity: number) {
    if (!color) return "transparent"
    return "rgba(" + color.r + "," + color.g + "," + color.b + ", " + opacity + ")"
}

export function getSelectedColor() {
    return { r: 13, g: 110, b: 253 }
}

export function getBorderColor() {
    return { r: 206, g: 212, b: 218 }
}

export function getLightBorderColor() {
    return { r: 240, g: 242, b: 244 }
}

export function getWhiteColor() {
    return { r: 255, g: 255, b: 255 }
}

export function getRedColor() {
    return { r: 203, g: 68, b: 74 }
}

export function getDisabledRedColor(percentage: number) {
    const p = Math.min(percentage, 1)
    let r = 240 - p * 35
    let g = 242 - p * 100
    let b = 244 - p * 100
    return { r: r, g: g, b: b }
}

export function getGreenColor() {
    return { r: 109, g: 195, b: 129 }
}

export function getAvailabilityColor(availability: HiringAvailabilityStatusWithUnknown) {
    if (availability === "AVAILABLE") {
        return getGreenColor()
    } else if (availability === "UNAVAILABLE") {
        return getRedColor()
    } else {
        return null
    }
}

export function getProgressColor(pct: number) {
    var percentColors = [
        { pct: 0, color: { r: 231, g: 118, b: 129 } },
        { pct: 0.2, color: { r: 231, g: 118, b: 129 } },
        { pct: 0.35, color: { r: 255, g: 193, b: 7 } },
        { pct: 0.5, color: { r: 109, g: 195, b: 129 } },
        { pct: 1.0, color: { r: 109, g: 195, b: 129 } },
    ]

    for (var i = 1; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
            break
        }
    }
    var lower = percentColors[i - 1]
    var upper = percentColors[i]
    var range = upper.pct - lower.pct
    var rangePct = (pct - lower.pct) / range
    var pctLower = 1 - rangePct
    var pctUpper = rangePct
    var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
    }
    return "rgb(" + [color.r, color.g, color.b].join(",") + ")"
}

export function constructColorMap(colors: DefaultColorType[]) {
    const map: Map<number, DefaultColorType> = new Map()
    for (const color of colors) {
        map.set(color.id, color)
    }
    return map
}
