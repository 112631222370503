import { dateToDjango } from "../helpers/DaysHelper"
import { withParams } from "../helpers/FilterHelper"
import PageRequestType from "../types/PageRequestType"
import PageResponseType from "../types/PageResponseType"
import ShiftMutationType from "../types/ShiftMutationType"
import api from "./Api"

export function loadShiftMutations(request: PageRequestType): Promise<PageResponseType<ShiftMutationType>> {
    return new Promise<PageResponseType<ShiftMutationType>>((resolve, reject) =>
        getShiftMutations(request)
            .then((response) => resolve(response.data))
            .catch(reject)
    )
}

export function getShiftMutations(pageDetails: PageRequestType) {
    return api.get<{ items: ShiftMutationType[]; pageCount: number }>(withParams("/shift-mutations/", pageDetails))
}

export function getShiftMutation(id: number) {
    return api.get(`/shift-mutations/${id}/`)
}

export function removeShiftMutation(id: number) {
    return api.delete(`/shift-mutations/${id}/`)
}

export function loadUnnotifiedShiftMutations(location: number, minCreatedAt: Date) {
    const pageDetails = {
        pageIndex: 0,
        pageSize: 999,
        filters: [
            {
                key: "unnotified",
                value: "true",
            },
            {
                key: "location",
                value: location.toString(),
            },
            {
                key: "minCreatedAt",
                value: dateToDjango(minCreatedAt),
            },
        ],
    }

    return new Promise<ShiftMutationType[]>((resolve, reject) => {
        getShiftMutations(pageDetails)
            .then((response) => resolve(response.data.items))
            .catch(reject)
    })
}

export function notifyShiftMutation(location: number, minCreatedAt: Date) {
    return api.post(`/shift-mutations/notify/?unnotified=true&location=${location.toString()}&minCreatedAt=${dateToDjango(minCreatedAt)}`)
}
