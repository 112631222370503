import { withParams } from "../helpers/FilterHelper"
import PageRequestType from "../types/PageRequestType"
import PageResponseType from "../types/PageResponseType"
import TOTPDeviceType from "../types/TOTPDeviceType"
import UserSettingsType from "../types/UserSettingsType"
import { CreateUserType, UpdateMyUserType, UpdateUserInitModeType, UpdateUserType, UserDetailedType, UserInvitationMode, UserType } from "../types/UserType"
import api from "./Api"

export function csrf() {
    return api.get("/accounts/csrf/")
}

export function me(companySystemName: string | undefined) {
    let url = "/accounts/me/"
    if (companySystemName) {
        url += `?c=${companySystemName}`
    }
    return api.get<UserSettingsType>(url)
}

export function loadUsers(request: PageRequestType): Promise<PageResponseType<UserType>> {
    return new Promise<PageResponseType<UserType>>((resolve, reject) =>
        getUsers(request)
            .then((response) => resolve(response.data))
            .catch(reject)
    )
}

export function getUsers(pageDetails: PageRequestType) {
    return api.get<PageResponseType<UserType>>(withParams("/accounts/", pageDetails))
}

export function getDetailedUsers(pageDetails: PageRequestType) {
    return api.get<PageResponseType<UserDetailedType>>(withParams("/accounts/detailed/", pageDetails))
}

export function loadDetailedUsers(request: PageRequestType): Promise<PageResponseType<UserDetailedType>> {
    return new Promise<PageResponseType<UserDetailedType>>((resolve) => {
        getDetailedUsers(request).then((response) => resolve(response.data))
    })
}

export function loadDetailedUser(id: number) {
    return new Promise<UserDetailedType>((resolve) => {
        getDetailedUser(id).then((response) => resolve(response.data))
    })
}

export function getDetailedUser(id: number) {
    return api.get<UserDetailedType>(`/accounts/detailed/${id}/`)
}

export function createUser(user: CreateUserType) {
    return api.post("/accounts/", user)
}

export function updateUser(user: UpdateUserType) {
    return api.patch(`/accounts/${user.id}/`, user)
}

export function updateMy(user: UpdateMyUserType) {
    return api.patch(`/accounts/${user.id}/`, user)
}

export function updateInitMode(user: UpdateUserInitModeType) {
    return api.patch(`/accounts/${user.id}/`, user)
}

export function login(email: string, password: string) {
    const form = new FormData()
    form.append("username", email)
    form.append("password", password)

    return api.post("/accounts/login/", form)
}

export function signup(firstName: string, lastName: string, email: string, password1: string, password2: string) {
    const form = new FormData()
    form.append("first_name", firstName)
    form.append("last_name", lastName)
    form.append("email", email)
    form.append("password1", password1)
    form.append("password2", password2)

    return api.post("/accounts/signup/", form)
}

export function activate(uid: string, token: string) {
    return api.post(`/accounts/activate/${uid}/${token}/`)
}

export function start(password1: string, password2: string, uid: string, token: string) {
    const form = new FormData()
    form.append("new_password1", password1)
    form.append("new_password2", password2)
    return api.post(`/accounts/activate/${uid}/${token}/`, form)
}

export function invite(id: number, invitationMode: UserInvitationMode) {
    return api.post(`/accounts/invite/${id}/`, { invitationMode })
}

export function deactivate(id: number) {
    return api.post(`/accounts/deactivate/${id}/`)
}

export function editPassword(oldPassword: string, password1: string, password2: string) {
    const form = new FormData()
    form.append("old_password", oldPassword)
    form.append("new_password1", password1)
    form.append("new_password2", password2)

    return api.post("/accounts/password-change/", form)
}

export function passwordLost(email: string) {
    const form = new FormData()
    form.append("email", email)

    return api.post("/accounts/password-reset/", form)
}

export function resetPassword(uid: string, token: string, password1: string, password2: string) {
    const form = new FormData()
    form.append("new_password1", password1)
    form.append("new_password2", password2)

    return api.post(`/accounts/password-reset/${uid}/${token}/`, form)
}

export function logout() {
    return api.get("/accounts/logout/")
}

export function submitMfa(otp_device: string, otp_token: string) {
    const form = new FormData()
    form.append("otp_device", otp_device)
    form.append("otp_token", otp_token)
    return api.post("/accounts/mfa/", form)
}

export function loadInitialTOTPDevice() {
    return new Promise<TOTPDeviceType>((resolve) => {
        api.get<TOTPDeviceType>("/accounts/mfa/initial/").then((response) => resolve(response.data))
    })
}

export function confirmInitialTOTPDevice(otp_device: string, otp_token: string) {
    const form = new FormData()
    form.append("otp_device", otp_device)
    form.append("otp_token", otp_token)
    return api.post("/accounts/mfa/confirm/", form)
}
