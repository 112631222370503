import { UserType } from "../types/UserType"

const useResolveUserName = (users: UserType[]) => {
    const userMap: Map<string, string> = new Map()
    users.forEach(({ id, firstName, lastName }) => {
        userMap.set(id.toString(), `${firstName} ${lastName}`)
    })
    return function (id: number) {
        return userMap.get(id.toString()) ?? ""
    }
}

export default useResolveUserName
