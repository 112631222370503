import { faBolt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, MouseEventHandler, useCallback, useMemo } from "react"
import { Link } from "react-router-dom"
import { useOccupationEditorState } from "../../contexts/OccupationEditorContext"
import { getCellValues } from "../../helpers/OccupationMapHelper"
import { NestedRoleItem } from "../../helpers/RolesHelper"
import { CellSelection, isSelected, OccupationEditorCellDetails } from "../../helpers/editor/OccupationEditorHelper"
import subIcon from "../../images/sub-icon.svg"
import styles from "./OccupationsEditorRole.module.scss"
import OccupationsEditorSlot from "./OccupationsEditorSlot"

interface Props {
    roleItem: NestedRoleItem
    cellSelection: CellSelection
    selectCell: MouseEventHandler<HTMLDivElement>
}

const OccupationsEditorRole: FC<Props> = ({ roleItem, cellSelection, selectCell }) => {
    const occupationEditorState = useOccupationEditorState()
    const { occupationTemplate, dayId, mergedSchedule, occupationSources } = occupationEditorState
    const { startSlot, endSlot } = occupationEditorState.location

    const values = useMemo(() => {
        const occupations = mergedSchedule.get(dayId)?.get(roleItem.uid) || []
        return getCellValues(startSlot, endSlot, occupations)
    }, [startSlot, endSlot, mergedSchedule, dayId, roleItem])

    const occupationSource = useMemo(() => {
        if (roleItem.type === "role") {
            return occupationSources.find((os) => os.role === roleItem.id)
        } else {
            return occupationSources.find((os) => os.roleGroup === roleItem.id)
        }
    }, [occupationSources, roleItem])

    const getSlotState = useCallback(
        (slot: number): OccupationEditorCellDetails => {
            const i = slot - startSlot
            const value = values[i]
            const selected = isSelected(cellSelection, roleItem.uid, i)
            const empty = value[0] === null && value[1] === null
            const isFirstOfRange = !empty && (i === 0 || values[i - 1][0] !== value[0] || values[i - 1][1] !== value[1])
            const disabled = !roleItem.hasOccupationTarget || !!occupationSource

            return {
                value,
                selected,
                empty,
                isFirstOfRange,
                disabled,
            }
        },
        [startSlot, values, cellSelection, roleItem, occupationSource]
    )

    const getSlots = () => {
        let slots = []
        for (let slot = startSlot; slot <= endSlot; slot++) {
            const i = slot - startSlot
            const value = values[i]
            const id = dayId + "#" + roleItem.uid + "#" + i
            slots.push(<OccupationsEditorSlot key={slot + "#" + value} id={id} slot={slot} selectCell={selectCell} details={getSlotState(slot)} />)
        }
        return slots
    }

    const slots = getSlots()
    if (!slots) {
        return null
    }

    return (
        <div className={styles.cellContainer}>
            <div className={styles.cellTitle}>
                <span className={styles.cellTitleLabel + " text-bold"}>
                    {roleItem.parent ? <img className="custom-fa-icon me-1" src={subIcon} alt="sub-arrow" /> : null}
                    {roleItem.name}
                </span>
                {occupationSource && occupationTemplate ? (
                    <Link to={`/basisbezettingen/${occupationTemplate.id}/bezettingsbronnen/${occupationSource.id}`}>
                        <FontAwesomeIcon icon={faBolt} className="ms-2" color="#FD7E14" />
                    </Link>
                ) : null}
            </div>
            {slots}
        </div>
    )
}

export default OccupationsEditorRole
