import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useMemo, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { useQuery } from "react-query"
import { useScheduleEditorState } from "../../../contexts/ScheduleEditorContext"
import { prettyPrint } from "../../../helpers/DaysHelper"
import { normalizeMutations, ScheduleMap, toScheduleMapFromMutations } from "../../../helpers/ScheduleMapHelper"
import { loadUnnotifiedShiftMutations, notifyShiftMutation } from "../../../services/ShiftMutation"
import LocalityMutationType from "../../../types/LocalityMutationType"
import ShiftMutationType from "../../../types/ShiftMutationType"
import DateSelector, { DateLabelType } from "../../DateSelector"
import MutationTable from "../../editor/MutationTable"
import Loader from "../../Loader"
import styles from "./SendNotificationsView.module.scss"

interface Props {
    visible: boolean
    close: () => void
}

const SendNotificationsView: FC<Props> = ({ visible, close }) => {
    const editorState = useScheduleEditorState()
    const [minCreatedAt, setMinCreatedAt] = useState<Date>(new Date())

    const { data: mutations } = useQuery(["ShiftMutationsUnnotified", editorState.location, minCreatedAt], () => loadUnnotifiedShiftMutations(editorState.location.id, minCreatedAt), {
        initialData: [],
        enabled: visible,
    })

    const mutationMap = useMemo<ScheduleMap>(() => {
        if (!mutations) {
            return new Map()
        }
        const map = toScheduleMapFromMutations(mutations, [], (mutation: ShiftMutationType | LocalityMutationType) => mutation.date)
        normalizeMutations(map)
        return map
    }, [mutations])

    const submit = useCallback(() => {
        notifyShiftMutation(editorState.location.id, minCreatedAt).then(close)
    }, [editorState.location, minCreatedAt])

    return (
        <Modal show={visible} onHide={close}>
            <Modal.Header className="justify-content-between">
                <Modal.Title>Stuur notificaties</Modal.Title>
                <div>
                    <DateSelector selectedDate={minCreatedAt} setSelectedDate={setMinCreatedAt} labelType={DateLabelType.NONE} />
                    <Button type="button" variant="link" onClick={close}>
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-2">
                    <span className="text-small">Ongenotificeerde wijzigingen, gemaakt vanaf {prettyPrint(minCreatedAt)}:</span>
                </div>
                <div className={styles.container}>{mutationMap ? <MutationTable mutations={mutationMap} roles={editorState.roles} mode="DATE" /> : <Loader />}</div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary" onClick={submit}>
                    Verstuur
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default SendNotificationsView
