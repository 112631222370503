import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMemo } from "react"
import { Link } from "react-router-dom"
import { useScheduleEditorState } from "../../contexts/ScheduleEditorContext"

const ShiftTemplateEditorHead = () => {
    const editorState = useScheduleEditorState()

    const { id, name } = useMemo(() => {
        return {
            id: editorState.shiftTemplate?.id ?? 0,
            name: editorState.shiftTemplate?.name ?? "",
        }
    }, [editorState.shiftTemplate])

    return (
        <h2 className="mb-4 d-inline-block">
            {name}
            <span className="ms-3 title-button">
                <Link to={`/basisroosters/${id}/details`}>
                    <FontAwesomeIcon icon={faEdit} />
                </Link>
            </span>
        </h2>
    )
}

export default ShiftTemplateEditorHead
