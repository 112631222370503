import { FC } from "react"
import { Form } from "react-bootstrap"
import { UseFormWatch } from "react-hook-form"
import { HOLIDAY_TYPE_DATE, HOLIDAY_TYPE_DAY, HOLIDAY_TYPE_ONCE } from "../../helpers/Constants"
import { getLongMonthName } from "../../helpers/DaysHelper"
import { HolidaySettingsType } from "../../types/HolidaySettingsType"

interface CustomHolidayItemProps {
    item: any
    index: number
    register: any
    remove: (index: number) => void
    watch: UseFormWatch<HolidaySettingsType>
}

const CustomHolidayItem: FC<CustomHolidayItemProps> = ({ item, index, register, remove, watch }) => {
    const watchType = watch(`customHolidays.${index}.type`)

    return (
        <tr key={item.id}>
            <td className="p-1">
                <input {...register(`customHolidays.${index}.name`)} data-cy={`customHolidays.${index}.name`} className="form-control" />
            </td>
            <td className="p-1">
                <select {...register(`customHolidays.${index}.type`, { required: true })} className="form-select form-control">
                    <option value={HOLIDAY_TYPE_DATE}>Jaarlijkse datum</option>
                    <option value={HOLIDAY_TYPE_DAY}>Jaarlijkse dag</option>
                    <option value={HOLIDAY_TYPE_ONCE}>Eenmalige datum</option>
                </select>
            </td>
            {watchType === HOLIDAY_TYPE_ONCE ? (
                <td>
                    <div className="d-flex">
                        <select {...register(`customHolidays.${index}.day`, { required: false })} className="form-select form-control d-inline-block m-1">
                            {Array(31)
                                .fill(1)
                                .map((a, b) => (
                                    <option key={b + 1} value={b + 1}>
                                        {b + 1}
                                    </option>
                                ))}
                        </select>
                        <select {...register(`customHolidays.${index}.month`, { required: false })} className="form-select form-control d-inline-block m-1">
                            {Array(12)
                                .fill(1)
                                .map((a, b) => (
                                    <option key={b + 1} value={b + 1}>
                                        {getLongMonthName(b)}
                                    </option>
                                ))}
                        </select>
                        <select {...register(`customHolidays.${index}.year`, { required: false })} className="form-select form-control d-inline-block m-1">
                            <option value={2023}>2023</option>
                            <option value={2024}>2024</option>
                            <option value={2025}>2025</option>
                            <option value={2026}>2026</option>
                            <option value={2027}>2027</option>
                            <option value={2028}>2028</option>
                            <option value={2029}>2029</option>
                            <option value={2030}>2030</option>
                        </select>
                    </div>
                </td>
            ) : null}
            {watchType === HOLIDAY_TYPE_DATE ? (
                <td>
                    <div className="d-flex">
                        <select {...register(`customHolidays.${index}.day`, { required: false })} className="form-select form-control d-inline-block m-1">
                            {Array(31)
                                .fill(1)
                                .map((a, b) => (
                                    <option key={b + 1} value={b + 1}>
                                        {b + 1}
                                    </option>
                                ))}
                        </select>
                        <select {...register(`customHolidays.${index}.month`, { required: false })} className="form-select form-control d-inline-block m-1">
                            {Array(12)
                                .fill(1)
                                .map((a, b) => (
                                    <option key={b + 1} value={b + 1}>
                                        {getLongMonthName(b)}
                                    </option>
                                ))}
                        </select>
                    </div>
                </td>
            ) : null}
            {watchType === HOLIDAY_TYPE_DAY ? (
                <td>
                    <div className="d-flex">
                        <select {...register(`customHolidays.${index}.dayOccurrence`, { required: false })} className="form-select form-control d-inline-block m-1">
                            <option value={1}>Eerste</option>
                            <option value={2}>Tweede</option>
                            <option value={3}>Derde</option>
                            <option value={4}>Laatste</option>
                        </select>
                        <select {...register(`customHolidays.${index}.dayOfWeek`, { required: false })} className="form-select form-control d-inline-block m-1">
                            <option value={0}>maandag</option>
                            <option value={1}>dinsdag</option>
                            <option value={2}>woensdag</option>
                            <option value={3}>donderdag</option>
                            <option value={4}>vrijdag</option>
                            <option value={5}>zaterdag</option>
                            <option value={6}>zondag</option>
                        </select>
                        <select {...register(`customHolidays.${index}.month`, { required: false })} className="form-select form-control d-inline-block m-1">
                            {Array(12)
                                .fill(1)
                                .map((a, b) => (
                                    <option key={b + 1} value={b + 1}>
                                        {getLongMonthName(b)}
                                    </option>
                                ))}
                        </select>
                    </div>
                </td>
            ) : null}
            <td className="p-1">
                <div className="d-inline-block">
                    <Form.Select {...register(`customHolidays.${index}.mode`)}>
                        <option value="show">Toon</option>
                        <option value="wipe">Toon en maak rooster leeg</option>
                    </Form.Select>
                </div>
            </td>
            <td className="p-1">
                <button type="button" className="btn btn-link" data-cy={`customHolidays.${index}.delete`} onClick={() => remove(index)}>
                    <i className="fas fa-trash-alt"></i>
                </button>
            </td>
        </tr>
    )
}

export default CustomHolidayItem
