import { parseDates } from "../helpers/DaysHelper"
import { getAllUnexusRouterOccupationsForDate, getAllUnexusRouterOccupationsForDay } from "../services/UnexusRouterOccupation"
import OccupationSourceType from "../types/OccupationSourceType"
import { SourcedDayOccupationType } from "../types/OccupationType"
import UnexusRouterOccupationType from "../types/UnexusRouterOccupationType"

export const useLoadDateUnexusRouterOccupations = (
    occupationSource: OccupationSourceType | undefined,
    date: Date,
    setUnexusRouterOccupations: (unexusRouterOccupations: UnexusRouterOccupationType[]) => void,
    setUnexusOccupations: (unexusOccupations: SourcedDayOccupationType[]) => void,
    setReferenceDates: (referenceDates: Date[]) => void
) => {
    return () => {
        if (!occupationSource) {
            return
        }
        getAllUnexusRouterOccupationsForDate(occupationSource.id, date).then((response) => {
            setUnexusRouterOccupations(response.data.routerOccupations)
            setUnexusOccupations(response.data.occupations)
            setReferenceDates(parseDates(response.data.referenceDates))
        })
    }
}

export const useLoadDayUnexusRouterOccupations = (
    occupationSource: OccupationSourceType | undefined,
    day: number,
    setUnexusRouterOccupations: (unexusRouterOccupations: UnexusRouterOccupationType[]) => void,
    setUnexusOccupations: (unexusOccupations: SourcedDayOccupationType[]) => void,
    setReferenceDates: (referenceDates: Date[]) => void
) => {
    return () => {
        if (!occupationSource) {
            return
        }
        getAllUnexusRouterOccupationsForDay(occupationSource.id, day).then((response) => {
            setUnexusRouterOccupations(response.data.routerOccupations)
            setUnexusOccupations(response.data.occupations)
            setReferenceDates(parseDates(response.data.referenceDates))
        })
    }
}
