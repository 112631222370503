import { useColors } from "../contexts/UserSettingsContext"
import { DefaultColorType } from "../types/ColorType"
import RoleType from "../types/RoleType"

const useRoleColorMap = (roles: RoleType[]) => {
    const colors = useColors()

    const map: Map<number, DefaultColorType> = new Map()
    for (const role of roles) {
        map.set(role.id, colors.get(role.color)!)
    }
    return map
}

export default useRoleColorMap
