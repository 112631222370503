import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback } from "react"
import { Button } from "react-bootstrap"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"
import { isFirstEnabledDay, shortDate } from "../../helpers/DaysHelper"
import { HiringAvailabilitiesFormProps } from "../../pages/hireAvailability/HireAvailabilitiesUserPage"
import { UserDetailedType } from "../../types/UserType"
import TimeRangeInput from "../form/TimeRangeInput"
import HiringAvailabilityActionCell from "../table/cells/HiringAvailabilityActionCell"
import styles from "./HireAvailabilityEditingRow.module.scss"

interface HireAvailabilityEditingRowProps {
    index: number
    register: any
    setValue: UseFormSetValue<HiringAvailabilitiesFormProps>
    watch: UseFormWatch<HiringAvailabilitiesFormProps>
    detailedUser: UserDetailedType
}

const HireAvailabilityEditingRow: FC<HireAvailabilityEditingRowProps> = ({ index, register, setValue, watch, detailedUser }) => {
    const { enabledDays } = detailedUser

    const date = watch(`availabilities.${index}.date`)
    const status = watch(`availabilities.${index}.status`)

    const reset = useCallback(() => {
        setValue(`availabilities.${index}.status`, "UNKNOWN")
        setValue(`availabilities.${index}.fromTime`, "")
        setValue(`availabilities.${index}.toTime`, "")
        setValue(`availabilities.${index}.message`, "")
    }, [setValue, index])

    return (
        <tr className={isFirstEnabledDay(date, enabledDays) ? styles.firstOfWeek : ""} data-cy="hiringAvailabilityRow">
            <td className="text-no-wrap text-bold" data-cy="dateCell">
                {shortDate(date)}
            </td>
            <td className="text-no-wrap">
                <HiringAvailabilityActionCell index={index} setValue={setValue} watch={watch} detailedUser={detailedUser} />
            </td>
            <td>
                <div style={{ width: "250px" }}>
                    <TimeRangeInput index={index} setValue={setValue} watch={watch} detailedUser={detailedUser} />
                </div>
            </td>
            <td>
                <div className="text-no-wrap">
                    <input {...register(`availabilities.${index}.message`)} className="form-control" disabled={status === "UNKNOWN"} />
                </div>
            </td>
            <td>
                <Button type="button" variant="link" onClick={reset} data-cy="delete">
                    <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
            </td>
        </tr>
    )
}

export default HireAvailabilityEditingRow
