import { FC, useCallback, useContext, useEffect, useRef, useState } from "react"
import { Badge, Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import Loader from "../../components/Loader"
import FormErrorMessages from "../../components/form/FormErrorMessages"
import FullPageCard from "../../components/page/FullPageCard"
import EnvinromentGenerationStep from "../../components/user/EnvironmentGenerationStep"
import { UserSettingsContext, useCurrentUser, useInitMode } from "../../contexts/UserSettingsContext"
import { refetchInterval } from "../../helpers/QueryHelper"
import { getEnvironmentGeneration } from "../../services/EnvironmentGeneration"
import { updateInitMode } from "../../services/User"
import EnvironmentGenerationType from "../../types/EnvironmentGenerationType"
import { UserInitMode } from "../../types/UserType"

export const loadEnvironmentGeneration = (): Promise<EnvironmentGenerationType> => {
    return new Promise((resolve) => {
        getEnvironmentGeneration().then((response) => resolve(response.data))
    })
}

const EnvinromentGenerationPage: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { reloadUserSettings } = useContext(UserSettingsContext)
    const currentUser = useCurrentUser()
    const initMode = useInitMode()
    const [formErrors, setFormErrors] = useState<string[]>([])
    const fetchCount = useRef(0)
    const { data: generation } = useQuery<EnvironmentGenerationType>(["environmentGeneration"], () => loadEnvironmentGeneration(), {
        refetchInterval: refetchInterval(fetchCount),
    })

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setFormErrors(data.nonFieldErrors || [t("Main.something_went_wrong")])
        },
        [setFormErrors]
    )

    const onStart = useCallback(() => {
        updateInitMode({
            id: currentUser.id,
            initMode: UserInitMode.STARTED_TOUR,
        })
            .then(reloadUserSettings)
            .catch(onFailure)
    }, [currentUser])

    useEffect(() => {
        if (initMode < UserInitMode.INITIALIZING_ENVIRONMENT) {
            navigate("/omgeving-initialisatie")
        } else if (initMode >= UserInitMode.STARTED_TOUR) {
            navigate("/dashboard")
        }
    }, [initMode])

    return (
        <FullPageCard>
            <div className="card-header-bar">
                <div className="card-header-bar-item">
                    <h2>Beginnen met Rooster Planner</h2>
                </div>
                <div className="card-header-bar-item end">
                    <Badge>3/3</Badge>
                </div>
            </div>
            <div>
                {!generation ? (
                    <Loader />
                ) : (
                    <>
                        <EnvinromentGenerationStep index={0} title="Taken aanmaken" status={generation.createdRoles} />
                        <EnvinromentGenerationStep index={1} title="Gebruikers aanmaken" status={generation.createdUsers} />
                        <EnvinromentGenerationStep index={2} title="Basisbezetting aanmaken" status={generation.createdOccupationTemplate} />
                        <EnvinromentGenerationStep index={3} title="Basisrooster aanmaken" status={generation.createdShiftTemplate} />
                    </>
                )}
            </div>
            <div>
                <Button disabled={!generation || !generation.done} onClick={onStart} data-cy="start-button">
                    Start
                </Button>
            </div>
            <FormErrorMessages errors={formErrors} />
        </FullPageCard>
    )
}

export default EnvinromentGenerationPage
