import { FC, useCallback, useMemo } from "react"
import { getDayName } from "../../helpers/DaysHelper"

interface Props {
    day: number
    selected: boolean
    enabled: boolean
    onSelect: (day: number) => void
}

const DaySelectorButton: FC<Props> = ({ day, selected, enabled, onSelect }) => {
    const classNames = useMemo(() => {
        return "btn btn-selectable" + (selected ? " active" : "")
    }, [selected])

    const onClick = useCallback(() => {
        onSelect(day)
    }, [onSelect, day])

    if (!enabled) {
        return null
    }

    return (
        <li className="nav-item">
            <button id={"btn-day-" + day} className={classNames} onClick={onClick}>
                {getDayName(day)}
            </button>
        </li>
    )
}

export default DaySelectorButton
