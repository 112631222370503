import { FC } from "react"
import { useFieldArray } from "react-hook-form"
import NationalHolidayItem from "./NationalHolidayItem"

interface NationalHolidayArrayProps {
    control: any
    register: any
}

const NationalHolidayArray: FC<NationalHolidayArrayProps> = ({ control, register }) => {
    const { fields } = useFieldArray({ control, name: "nationalHolidays" })

    return (
        <table className="ms-2">
            <thead>
                <tr>
                    <td className="p-1 text-small text-bold">Naam</td>
                    <td className="p-1 text-small text-bold">Eerstvolgende</td>
                    <td className="p-1 text-small text-bold">Verwerking</td>
                </tr>
            </thead>
            <tbody>
                {fields.map((item, index) => {
                    return <NationalHolidayItem key={index} item={item} index={index} register={register} />
                })}
            </tbody>
        </table>
    )
}

export default NationalHolidayArray
