import { FC, useCallback } from "react"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"
import { HiringAvailabilitiesFormProps } from "../../pages/hireAvailability/HireAvailabilitiesUserPage"
import { UserDetailedType } from "../../types/UserType"
import UserTimeInputPopover from "../user/UserTimeInput/UserTimeInputPopover"
import InlineGroupLabel from "./InlineGroupLabel"

interface TimeRangeInputProps {
    index: number
    setValue: UseFormSetValue<HiringAvailabilitiesFormProps>
    watch: UseFormWatch<HiringAvailabilitiesFormProps>
    detailedUser: UserDetailedType
}

const TimeRangeInput: FC<TimeRangeInputProps> = ({ index, setValue, watch, detailedUser }) => {
    const { startSlot, endSlot } = detailedUser

    const fromTime = watch(`availabilities.${index}.fromTime`)
    const toTime = watch(`availabilities.${index}.toTime`)
    const status = watch(`availabilities.${index}.status`)

    const setFromTime = useCallback(
        (time: string) => {
            setValue(`availabilities.${index}.fromTime`, time)
            if (time && status === "UNKNOWN") {
                setValue(`availabilities.${index}.status`, "AVAILABLE")
            }
        },
        [setValue, status]
    )

    const setToTime = useCallback(
        (time: string) => {
            setValue(`availabilities.${index}.toTime`, time)
            if (time && status === "UNKNOWN") {
                setValue(`availabilities.${index}.status`, "AVAILABLE")
            }
        },
        [setValue, status]
    )

    return (
        <div className="input-group">
            <InlineGroupLabel inline={false}>Van</InlineGroupLabel>
            <UserTimeInputPopover startSlot={startSlot} endSlot={endSlot} selectedTime={fromTime} setSelectedTime={setFromTime} type="start" disabled={status !== "AVAILABLE"} />
            <InlineGroupLabel inline={false}>tot</InlineGroupLabel>
            <UserTimeInputPopover startSlot={startSlot} endSlot={endSlot} selectedTime={toTime} setSelectedTime={setToTime} type="end" disabled={status !== "AVAILABLE"} />
        </div>
    )
}

export default TimeRangeInput
