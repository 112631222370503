import { FC, useEffect, useState } from "react"

interface Props {
    succeededId: number
    className?: string
}

const FormSuccessMessage: FC<Props> = ({ succeededId, className }) => {
    const [visible, setVisible] = useState(false)
    const [fadingOut, setFadingOut] = useState(false)
    const [startFadingTimer, setStartFadingTimer] = useState<NodeJS.Timeout>()
    const [removeComponentTimer, setRemoveComponentTimer] = useState<NodeJS.Timeout>()

    const clearTimeouts = () => {
        if (startFadingTimer) {
            clearTimeout(startFadingTimer)
        }
        if (removeComponentTimer) {
            clearTimeout(removeComponentTimer)
        }
    }

    useEffect(() => {
        if (succeededId > 0) {
            setVisible(true)
            setFadingOut(false)
            clearTimeouts()
            setStartFadingTimer(
                setTimeout(() => {
                    setFadingOut(true)
                }, 3000)
            )
        }
    }, [succeededId])

    useEffect(() => {
        if (fadingOut) {
            setRemoveComponentTimer(
                setTimeout(() => {
                    setVisible(false)
                }, 1000)
            )
        }
    }, [fadingOut])

    useEffect(() => {
        return clearTimeouts
    }, [])

    return visible ? (
        <ul data-cy="succeededMessage" className={"ms-1 font-awesome-list d-inline-block fade-animated" + (fadingOut ? " faded-out" : "") + (className ? " " + className : "")}>
            <li className="font-success">
                <i className="fas fa-check-circle"></i> Geslaagd!
            </li>
        </ul>
    ) : null
}

export default FormSuccessMessage
