import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import FullPageCard from "../components/page/FullPageCard"

const SamlDeniedPage = () => {
    const { t } = useTranslation()

    return (
        <FullPageCard>
            <div>
                <h2>{t("SamlDeniedPage.access_denied")}</h2>
                <p className="mb-1">
                    <Trans i18nKey="SamlDeniedPage.contact_via_email">
                        Neem contact op met <Link to="mailto:info@llmsolutions.nl">info@llmsolutions.nl</Link> als dit probleem zich blijft voordoen.
                    </Trans>
                </p>
            </div>
        </FullPageCard>
    )
}

export default SamlDeniedPage
