import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Badge, Form } from "react-bootstrap"
import { FieldErrors, UseFormSetValue, UseFormWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useLocations, useRoles } from "../../contexts/UserSettingsContext"
import { initRolePreferences } from "../../helpers/UsersHelper"
import { UserEditInputs, UserEditMode } from "../../pages/users/UserEditPage"
import RoleType from "../../types/RoleType"
import UserRolesTable from "./UserRolesTable"
import UserTabErrors from "./UserTabErrors"
import { UserTab } from "./UserTabs"

interface Props {
    selectedTab: UserTab
    watch: UseFormWatch<UserEditInputs>
    setValue: UseFormSetValue<UserEditInputs>
    mode: UserEditMode
    errors: FieldErrors<UserEditInputs>
    tabErrors: UserTab[]
}

const UserRolesForm: FC<Props> = ({ selectedTab, watch, setValue, mode, errors, tabErrors }) => {
    const { t } = useTranslation()
    const allLocations = useLocations()
    const allRoles = useRoles()

    const initializeRolePreferencesIfNeeded = useCallback(
        (roles: RoleType[]) => {
            if (mode === "create") {
                setValue("rolePreferences", initRolePreferences(roles))
            }
        },
        [mode, setValue]
    )

    const locations = watch("locations")

    const [selectedLocation, setSelectedLocation] = useState<number>(-1)

    useEffect(() => setSelectedLocation(locations.length > 0 ? locations[0] : -1), [locations])

    useEffect(() => initializeRolePreferencesIfNeeded(allRoles), [allRoles])

    const locationRoles = useMemo(() => {
        return allRoles.filter((r) => r.location === selectedLocation)
    }, [allRoles, selectedLocation])

    if (selectedTab !== "roles") {
        return null
    }

    return (
        <>
            {locations.length > 1 ? (
                <div className="mb-2">
                    <select className="form-select form-select-inline form-control mb-3" onChange={(e) => setSelectedLocation(parseInt(e.target.value))} value={selectedLocation}>
                        {locations.map((id) => {
                            const location = allLocations.find((location) => location.id === id)!
                            return (
                                <option key={id} value={id}>
                                    {location.name}
                                </option>
                            )
                        })}
                    </select>
                </div>
            ) : null}
            {locations.length < 1 ? (
                <Badge bg="info-light" text="dark" className="mb-3">
                    <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                    {t("UserRolesForm.activate_a_location_first")}
                </Badge>
            ) : locationRoles.length > 0 ? (
                <Form.Group>
                    <UserRolesTable roles={locationRoles} watch={watch} setValue={setValue} disabled={mode === "update-my"} />
                    <Form.Control type="hidden" isInvalid={!!errors.rolePreferences} />
                    <Form.Control.Feedback type="invalid">{errors.rolePreferences?.message}</Form.Control.Feedback>
                </Form.Group>
            ) : (
                <Badge bg="info-light" text="dark" className="mb-3">
                    <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                    {t("UserRolesForm.no_roles_available")}
                </Badge>
            )}
            <UserTabErrors tabErrors={tabErrors} except="roles" />
        </>
    )
}

export default UserRolesForm
