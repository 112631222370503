import { ChangeEvent, FC, useCallback, useMemo } from "react"
import { TableFilterValue } from "./Table"

interface ToggleFilterProps {
    id: string
    label: string
    values: TableFilterValue[]
    putFilterValue: (value: TableFilterValue) => void
}

const ToggleFilter: FC<ToggleFilterProps> = ({ id, label, values, putFilterValue }) => {
    const checked = useMemo(() => values.find((v) => v.key === id)?.value === "true", [values, id])

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = { key: id, value: event.target.checked ? "true" : "" }
            putFilterValue(value)
        },
        [putFilterValue, id]
    )

    return (
        <li>
            <div>
                <label className="toggle-switch d-inline-block" htmlFor={id}>
                    <input id={id} type="checkbox" className="toggle-switch-input" checked={checked} onChange={onChange} />
                    <span className="toggle-switch-label">
                        <span className="toggle-switch-indicator"></span>
                    </span>
                </label>
                <label className="toggle-switch-content d-inline-block text-bold text-muted ms-1" htmlFor={id}>
                    {label}
                </label>
            </div>
        </li>
    )
}

export default ToggleFilter
