import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useMemo } from "react"
import Badge from "react-bootstrap/Badge"
import { useScheduleEditorState } from "../../contexts/ScheduleEditorContext"
import { useUsersForSelectedLocation } from "../../contexts/UserSettingsContext"
import useResolveUserName from "../../hooks/UseResolveUserName"

const ScheduleValidationMessages: FC = () => {
    const users = useUsersForSelectedLocation()
    const resolveUserName = useResolveUserName(users)
    const editorState = useScheduleEditorState()

    const messages = useMemo(() => editorState.validationMessages, [editorState.validationMessages])

    if (!messages) {
        return null
    }

    return (
        <div className="d-flex flex-column align-items-start mb-2">
            {messages.map(({ user, location, template }) => (
                <Badge key={`${user}_${location}_${template}`} bg="danger-light" text="dark" className="d-inline-block mb-2">
                    <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                    {resolveUserName(user)} is ook ingepland {template ? `in basisrooster "${template}" van locatie "${location}"` : `in locatie "${location}"`}
                </Badge>
            ))}
        </div>
    )
}

export default ScheduleValidationMessages
