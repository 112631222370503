import { faCalendarCheck, faCalendarDays, faChartPie, faClipboardList, faHouse, faInfoCircle, faShuffle, faUmbrellaBeach, faUsers } from "@fortawesome/free-solid-svg-icons"
import { FC } from "react"
import { useTabs } from "../../contexts/TabsContext"
import { useCompanyModules, useIsAdmin, useIsExternalHire, useSelectedLocation } from "../../contexts/UserSettingsContext"
import { COMPANY_MODULE_ABSENCE_REQUESTS, COMPANY_MODULE_EXTERNAL_HIRES } from "../../helpers/Constants"
import styles from "./Menu.module.scss"
import MenuDivider from "./MenuDivider"
import MenuItem from "./MenuItem"

const Menu: FC = () => {
    const isAdmin = useIsAdmin()
    const isExternalHire = useIsExternalHire()
    const companyModules = useCompanyModules()
    const location = useSelectedLocation()
    const { activeTab } = useTabs()
    const activeTabParts = activeTab ? activeTab.split("/") : undefined
    const tabId = activeTabParts ? activeTabParts[activeTabParts.length - 1] : undefined

    const registrationDivider = <MenuDivider />
    const configDivider = isAdmin ? <MenuDivider /> : null
    const statisticsItem = isAdmin ? <MenuItem dataCy="statistics" title="Statistieken" active={tabId === "Statistics"} path={"/statistieken"} icon={faChartPie} /> : null
    const usersItem = isAdmin ? <MenuItem dataCy="users" title="Medewerkers" active={tabId === "Users"} path="/medewerkers" icon={faUsers} /> : null
    const rolesItem = isAdmin ? <MenuItem key="Roles" dataCy={`${location.id}/roles`} title="Taken" active={tabId === "Roles"} path="/taken" icon={faClipboardList} /> : null
    const occupationTemplatesItem = isAdmin ? (
        <MenuItem
            key="OccupationTemplates"
            dataCy={`${location.id}/occupationTemplates`}
            title="Basisbezettingen"
            active={tabId === "OccupationTemplates"}
            path="/basisbezettingen"
            icon={faCalendarCheck}
        />
    ) : null
    const shiftTemplatesItem = isAdmin ? (
        <MenuItem key="ShiftTemplates" dataCy={`${location.id}/shiftTemplates`} title="Basisroosters" active={tabId === "ShiftTemplates"} path="/basisroosters" icon={faCalendarDays} />
    ) : null
    return (
        <div>
            <ul className={`${styles.menu}`}>
                <MenuItem dataCy="dashboard" title="Dashboard" active={tabId === "Dashboard"} path="/dashboard" icon={faHouse} />
                <MenuItem dataCy="schedule" title="Rooster" active={tabId === "Schedule"} path="/rooster" icon={faCalendarDays} />
                {registrationDivider}
                {companyModules.includes(COMPANY_MODULE_EXTERNAL_HIRES) && (isAdmin || isExternalHire) ? (
                    <MenuItem dataCy="hireAvailabilities" title="Beschikbaarheid" active={tabId === "HireAvailabilities"} path="/beschikbaarheid" icon={faInfoCircle} />
                ) : null}
                {companyModules.includes(COMPANY_MODULE_ABSENCE_REQUESTS) ? (
                    <MenuItem dataCy="absenceRequests" title="Verlofaanvragen" active={tabId === "AbsenceRequests"} path="/verlofaanvragen" icon={faUmbrellaBeach} />
                ) : null}
                <MenuItem dataCy="shiftMutations" title="Wijzigingen" active={tabId === "Mutations"} path={"/wijzigingen"} icon={faShuffle} />
                {statisticsItem}
                {configDivider}
                {usersItem}
                {rolesItem}
                {occupationTemplatesItem}
                {shiftTemplatesItem}
            </ul>
        </div>
    )
}

export default Menu
