import { FC, useCallback, useMemo } from "react"
import { Button } from "react-bootstrap"
import { getTimeString } from "../../../helpers/DaysHelper"

interface Props {
    slot: number
    type: "start" | "end"
    setSelectedTime: (time: string) => void
}

const UserTimeInputButton: FC<Props> = ({ slot, type, setSelectedTime }) => {
    const onClick = useCallback(() => {
        setSelectedTime(getTimeString(type, slot))
    }, [setSelectedTime, type, slot])

    const label = useMemo(() => {
        return getTimeString(type, slot)
    }, [type, slot])

    return (
        <Button type="button" variant="selectable" key={slot} className="p-1" onClick={onClick}>
            {label}
        </Button>
    )
}

export default UserTimeInputButton
