import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import { FC } from "react"

interface Props {
    iconProps?: FontAwesomeIconProps
}

const IconCell: FC<Props> = ({ iconProps }) => {
    if (!iconProps) {
        return null
    }
    return <FontAwesomeIcon {...iconProps} />
}

export default IconCell
