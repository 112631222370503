import { dateFromIso, timeFromIso } from "./DaysHelper"

export const getOptionalDateParam = (param: string, params: URLSearchParams) => {
    if (params.has(param)) {
        return dateFromIso(params.get(param)!)
    }
}

export const getOptionalTimeParam = (param: string, params: URLSearchParams) => {
    if (params.has(param)) {
        return timeFromIso(params.get(param)!)
    }
}
export const getOptionalIntParam = (param: string, params: URLSearchParams) => {
    if (params.has(param)) {
        return parseInt(params.get(param)!)
    }
}
