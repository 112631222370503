import { faExclamationCircle, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { ScheduleEditorContext, useScheduleEditorState } from "../../../contexts/ScheduleEditorContext"
import { useSelectedLocation } from "../../../contexts/UserSettingsContext"
import { isoPrint, prettyPrint } from "../../../helpers/DaysHelper"
import { setOptionalError } from "../../../helpers/FormHelper"
import { createDateNote, removeDateNote, updateDateNote } from "../../../services/DateNote"
import DateNoteType from "../../../types/DateNoteType"

interface Inputs {
    text: string
}

interface Props {
    visible: boolean
    close: () => void
    note: DateNoteType | null
}

const EditDateNoteModal: FC<Props> = ({ visible, close, note }) => {
    const location = useSelectedLocation()
    const { reloadNotes } = useContext(ScheduleEditorContext)
    const editorState = useScheduleEditorState()

    const {
        register,
        setValue,
        setError,
        formState: { errors },
        handleSubmit,
        watch,
        reset,
    } = useForm<Inputs>({ defaultValues: { text: "" } })

    const [deletionWarningVisible, setDeletionWarningVisible] = useState(false)

    const mode = useMemo(() => (note ? "Update" : "Create"), [note])

    const date = useMemo(() => {
        if (mode === "Update") {
            return note ? new Date(note.date) : new Date()
        } else {
            return editorState.date!
        }
    }, [mode, note, editorState.date])

    useEffect(() => {
        if (note) {
            setValue("text", note.text)
        }
    }, [note])

    const onSuccess = useCallback(() => {
        reloadNotes()
        close()
        reset()
    }, [reloadNotes, close, reset])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "text", data.text)
            setOptionalError(setError, "root", data.nonFieldErrors)
        },
        [setError]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ text }) => {
            if (text.length > 100) {
                setOptionalError(setError, "text", "Gebruik maximaal 100 tekens")
                return
            }
            if (mode === "Create") {
                createDateNote({
                    locations: [location.id],
                    date: isoPrint(date),
                    text,
                })
                    .then(onSuccess)
                    .catch(onFailure)
            } else if (mode === "Update") {
                updateDateNote({
                    id: note!.id,
                    locations: [location.id],
                    text,
                })
                    .then(onSuccess)
                    .catch(onFailure)
            }
        },
        [setError, mode, location, date]
    )

    const onDeleteDateNote = useCallback(() => {
        if (!deletionWarningVisible) {
            setDeletionWarningVisible(true)
            return
        }
        removeDateNote(note!.id).then(onSuccess).catch(onFailure)
    }, [deletionWarningVisible, setDeletionWarningVisible, note, onSuccess, onFailure])

    const text = watch("text")

    return (
        <Modal show={visible} onHide={close}>
            <Modal.Header className="justify-content-between">
                <Modal.Title>
                    Notitie {prettyPrint(date)} {date?.getFullYear()}
                </Modal.Title>
                <Button type="button" variant="link" onClick={close}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Control as="textarea" {...register("text")} isInvalid={!!errors.text?.message} maxLength={100} />
                        <Form.Control.Feedback type="invalid">{errors.text?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <div className="text-small text-end text-muted w-100">{text.length}/100</div>
                    <Form.Group>
                        <Form.Control type="hidden" isInvalid={!!errors.root} />
                        <Form.Control.Feedback type="invalid" data-cy="root_errors">
                            {errors.root?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit">Opslaan</Button>
                    {mode === "Update" ? (
                        <Button type="button" variant="danger" onClick={onDeleteDateNote} className="ms-2">
                            Verwijder
                        </Button>
                    ) : null}
                    {deletionWarningVisible ? (
                        <div className="ms-1 mt-2">
                            <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                            <span className="me-1">Weet je zeker dat je deze notitie wil verwijderen?</span>
                            <Button variant="link" type="button" onClick={onDeleteDateNote}>
                                Ja, verwijder
                            </Button>
                        </div>
                    ) : null}
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default EditDateNoteModal
