import { FC } from "react"
import { useScheduleEditorState } from "../../contexts/ScheduleEditorContext"
import DateNoteType from "../../types/DateNoteType"
import DateNotes from "../dateNotes/DateNotes"

interface ScheduleDateNotesProps {
    setEditingDateNote: (dateNote: DateNoteType) => void
}

const ScheduleDateNotes: FC<ScheduleDateNotesProps> = ({ setEditingDateNote }) => {
    const editorState = useScheduleEditorState()

    return <DateNotes notes={editorState.notes} setEditingDateNote={setEditingDateNote} />
}

export default ScheduleDateNotes
