import { FC, useCallback, useMemo } from "react"
import { AxisLinearOptions, Chart, Series, SeriesFocusStatus, SeriesStyles } from "react-charts"
import { useColors, useNestedRolesForSelectedLocation } from "../../../../contexts/UserSettingsContext"
import { prettyPrintQuarters } from "../../../../helpers/DaysHelper"
import { constructNestedRoleMap } from "../../../../helpers/RolesHelper"
import { RoleHoursDatum, canShowData, getRoleHoursData, getTimeXAxis } from "../../../../helpers/TaskHelper"
import { TaskRoleTimeResult, TaskType } from "../../../../services/Task"
import NoData from "../../NoData"

interface RoleHoursGraph1Props {
    task?: TaskType<TaskRoleTimeResult>
}

const RoleHoursGraph1: FC<RoleHoursGraph1Props> = ({ task }) => {
    const colors = useColors()
    const nestedRoles = useNestedRolesForSelectedLocation()

    const nestedRoleMap = useMemo(() => {
        return constructNestedRoleMap(nestedRoles)
    }, [nestedRoles])

    const taskResult = useMemo(() => {
        return task?.result
    }, [task])

    const data = useMemo(() => {
        return taskResult ? getRoleHoursData(taskResult, nestedRoleMap, colors) : undefined
    }, [taskResult, nestedRoleMap, colors])

    const primaryAxis = useMemo(() => {
        return task ? getTimeXAxis(task) : undefined
    }, [task])

    const secondaryAxes = useMemo<AxisLinearOptions<RoleHoursDatum>[]>(
        () => [
            {
                getValue: (datum) => datum.count / 4,
                elementType: "line",
                min: 0,
                formatters: {
                    scale: (datum) => prettyPrintQuarters(datum * 4),
                },
            },
        ],
        []
    )

    const getSeriesStyle = useCallback((series: Series<RoleHoursDatum>, status: SeriesFocusStatus): SeriesStyles => {
        return {
            color: series.originalSeries.color,
            opacity: status === "focused" ? 1 : 0.7,
        }
    }, [])

    return (
        <div className="w-100 h-100">
            {canShowData(taskResult) ? (
                <Chart
                    options={{
                        data: data!,
                        primaryAxis: primaryAxis!,
                        secondaryAxes,
                        getSeriesStyle,
                    }}
                />
            ) : (
                <NoData taskResult={taskResult} />
            )}
        </div>
    )
}

export default RoleHoursGraph1
