import { FC } from "react"
import { useSelectedLocation } from "../../../contexts/UserSettingsContext"
import { getCSSColor3, getGreenColor, getRedColor } from "../../../helpers/ColorHelper"
import { getTimeSlots } from "../../../helpers/DaysHelper"
import styles from "./UnexusTimeLine.module.scss"

export type UnexusTimeLineType = "TIMELINE" | "ROUTER_OCCUPATIONS" | "OCCUPATIONS"

interface UnexusTimeLineProps {
    title?: string
    values?: number[]
    type: UnexusTimeLineType
    lineAbove?: boolean
}

const UnexusTimeLine: FC<UnexusTimeLineProps> = ({ title, values, type, lineAbove }) => {
    const location = useSelectedLocation()
    const { startSlot, endSlot } = location

    const color = type === "ROUTER_OCCUPATIONS" ? getGreenColor() : getRedColor()
    const colorLimit = type === "ROUTER_OCCUPATIONS" ? 100 : 10

    return (
        <div className={styles.container + (lineAbove ? ` ${styles.lineAbove}` : "")}>
            <div className={styles.header}>{title}</div>
            {values
                ? values.map((v, i) => {
                      return (
                          <div key={i} className={styles.cell} style={{ backgroundColor: getCSSColor3(color, 0.1 + v / colorLimit) }}>
                              {v === 0 ? <span className={styles.zeroValue}>0</span> : <span className={styles.value}>{`${v}`}</span>}
                          </div>
                      )
                  })
                : null}
            {type === "TIMELINE" ? getTimeSlots(startSlot, endSlot, "regular") : null}
        </div>
    )
}

export default UnexusTimeLine
