import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useContext, useMemo, useRef, useState } from "react"
import { Button } from "react-bootstrap"
import { useQuery } from "react-query"
import { ScheduleEditorContext, useScheduleEditorState } from "../../../../contexts/ScheduleEditorContext"
import { useNestedRolesMapForSelectedLocation } from "../../../../contexts/UserSettingsContext"
import { refetchInterval } from "../../../../helpers/QueryHelper"
import { createTaskOccupationFixes, loadTask, TaskOccupationFixesResult, TaskType } from "../../../../services/Task"
import OccupationTimeSlotDetails from "../OccupationTimeSlotDetails"
import styles from "./OccupationFixPopover.module.scss"
import OccupationFixesView from "./OccupationFixesView"

interface Props {
    selectedSlot?: number
    occupationData: Map<string, (number | null)[][]>
    closeModal: () => void
}

const OccupationFixPopover: FC<Props> = ({ selectedSlot, occupationData, closeModal }) => {
    const nestedRolesMap = useNestedRolesMapForSelectedLocation()

    const { mode } = useContext(ScheduleEditorContext)
    const editorState = useScheduleEditorState()
    const { location, shiftTemplate, newScheduleMutations, date, day } = editorState
    const { startSlot } = location

    const [selectedRoleUid, setSelectedRoleUid] = useState<string | null>(null)
    const roleName = useMemo(() => (selectedRoleUid ? nestedRolesMap.get(selectedRoleUid)?.name ?? "" : ""), [nestedRolesMap, selectedRoleUid])

    const fetchCount = useRef(0)
    const [taskId, setTaskId] = useState<number | null>(null)
    const { data: task } = useQuery<TaskType<TaskOccupationFixesResult>>(["Task", taskId], () => loadTask<TaskOccupationFixesResult>(taskId!), {
        enabled: !!taskId,
        refetchInterval: refetchInterval(fetchCount),
        keepPreviousData: false,
    })

    const selectRoleItem = useCallback(
        (roleUid: string) => {
            setSelectedRoleUid(roleUid)
            createTaskOccupationFixes({
                mode,
                location: location.id,
                shiftTemplate: shiftTemplate?.id,
                newShiftMutations: newScheduleMutations,
                date: date !== null ? date : undefined,
                day: day !== null ? day : undefined,
                roleUid,
                slot: selectedSlot!,
            }).then((response) => setTaskId(response.data.id))
        },
        [setSelectedRoleUid, mode, location, shiftTemplate, newScheduleMutations, date, day, setTaskId]
    )

    const goBackFromFixes = useCallback(() => setTaskId(null), [setTaskId])

    const finishModal = useCallback(() => {
        setTaskId(null)
        closeModal()
    }, [setTaskId, closeModal])

    if (selectedSlot === undefined) {
        return null
    }

    return (
        <div>
            <h5 className="m-3">
                {task ? (
                    <Button type="button" variant="link" onClick={goBackFromFixes} size="sm">
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                ) : null}
                {task ? roleName : "Bezetting"}
            </h5>
            <div className={`${styles.scrollContainer} my-3`}>
                {!task ? <OccupationTimeSlotDetails selectedSlotIndex={selectedSlot - startSlot} occupationData={occupationData} selectRoleItem={selectRoleItem} /> : null}
                {task ? <OccupationFixesView task={task} finishModal={finishModal} /> : null}
            </div>
        </div>
    )
}

export default OccupationFixPopover
