import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, MouseEventHandler } from "react"
import { getOccupationCellBorder, getOccupationCellBorderLeft } from "../../helpers/BorderHelper"
import { OccupationEditorCellDetails } from "../../helpers/editor/OccupationEditorHelper"
import { getOccupationCellBackgroundColor } from "../../helpers/OccupationHelper"
import styles from "./OccupationsEditorSlot.module.scss"

interface OccupationsEditorSlotProps {
    slot: number
    id: string
    selectCell: MouseEventHandler<HTMLDivElement>
    details: OccupationEditorCellDetails
}

const OccupationsEditorSlot: FC<OccupationsEditorSlotProps> = ({ slot, id, selectCell, details }) => {
    const { value, selected, empty, isFirstOfRange, disabled } = details

    return (
        <div
            id={id}
            className={"editor-cell" + (disabled ? " editor-cell-disabled" : "")}
            style={{
                order: slot,
                backgroundColor: getOccupationCellBackgroundColor(value, selected, empty, disabled),
                borderLeft: getOccupationCellBorderLeft(slot, selected, empty),
                borderBottom: getOccupationCellBorder(3, "solid", selected, empty),
                zIndex: isFirstOfRange ? 2 : 1,
                cursor: "pointer",
            }}
            onClick={!disabled ? selectCell : undefined}
        >
            {isFirstOfRange ? (
                <div id={id} className={styles.label}>
                    <div className="d-inline-flex flex-column">
                        <span className={styles.entry} style={{ opacity: value[1] === null ? 0.3 : undefined }}>
                            <FontAwesomeIcon icon={faCaretDown} className={styles.icon} />
                            <div className={styles.value}>{value[1] ?? ""}</div>
                        </span>
                        <span className={styles.entry} style={{ opacity: value[0] === null ? 0.3 : undefined }}>
                            <FontAwesomeIcon icon={faCaretUp} className={styles.icon} />
                            <span className={styles.value}>{value[0] ?? ""}</span>
                        </span>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default OccupationsEditorSlot
