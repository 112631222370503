import Joyride, { CallBackProps, EVENTS } from "react-joyride"
import { useNavigate } from "react-router-dom"
import { useTourContext } from "../../contexts/TourContext"
import {
    STEP_INDEX_ABSENCE_REQUEST,
    STEP_INDEX_MUTATIONS,
    STEP_INDEX_OCCUPATION_TEMPLATES,
    STEP_INDEX_ROLES,
    STEP_INDEX_SCHEDULE,
    STEP_INDEX_SHIFT_TEMPLATES,
    STEP_INDEX_STATISTICS,
    STEP_INDEX_USERS,
    tourSteps,
} from "../../contexts/TourSteps"
import JoyrideTooltip from "./JoyrideTooltip"

const JoyRideTooltip = () => {
    const { started, setStarted, index, setIndex, run, setRun } = useTourContext()
    const navigate = useNavigate()
    const handleCallback = (data: CallBackProps) => {
        const { index, type } = data
        if (type === EVENTS.STEP_AFTER) {
            if (index === STEP_INDEX_SCHEDULE - 1) {
                setRun(false)
                navigate("/rooster")
            } else if (index === STEP_INDEX_ABSENCE_REQUEST - 1) {
                setRun(false)
                navigate("/verlofaanvragen")
            } else if (index === STEP_INDEX_MUTATIONS - 1) {
                setRun(false)
                navigate("/wijzigingen")
            } else if (index === STEP_INDEX_STATISTICS - 1) {
                setRun(false)
                navigate("/statistieken")
            } else if (index === STEP_INDEX_USERS - 1) {
                setRun(false)
                navigate("/medewerkers")
            } else if (index === STEP_INDEX_ROLES - 1) {
                setRun(false)
                navigate("/taken")
            } else if (index === STEP_INDEX_OCCUPATION_TEMPLATES - 1) {
                setRun(false)
                navigate("/basisbezettingen")
            } else if (index === STEP_INDEX_SHIFT_TEMPLATES - 1) {
                setRun(false)
                navigate("/basisroosters")
            } else if (index === tourSteps.length - 1) {
                setStarted(false)
                setIndex(0)
                setRun(false)
            } else {
                setIndex(index + 1)
            }
        }
    }

    return (
        <Joyride
            key={`${started ? "1" : "0"}${run ? "1" : "0"}${index}`}
            callback={handleCallback}
            continuous
            hideBackButton
            hideCloseButton
            stepIndex={index}
            run={run}
            steps={tourSteps}
            styles={{
                spotlight: {
                    borderRadius: "1.4rem",
                },
            }}
            tooltipComponent={JoyrideTooltip}
        />
    )
}

export default JoyRideTooltip
