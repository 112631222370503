import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useMemo } from "react"
import { Button } from "react-bootstrap"
import { addDays, getFirstNextWorkday, getFirstPreviousWorkday } from "../../helpers/DaysHelper"

interface Props {
    mode: "PREVIOUS" | "NEXT"
    unit: "DAY" | "WEEK"
    selectedDate: Date
    setSelectedDate: (selectedDate: Date) => void
    enabledDays: string
}

const DateControl: FC<Props> = ({ mode, unit, selectedDate, setSelectedDate, enabledDays }) => {
    const onClick = useCallback(() => {
        const days = unit === "WEEK" ? 7 : 1
        const delta = mode === "PREVIOUS" ? -days : days
        const date = addDays(selectedDate, delta)
        const finalDate = mode === "PREVIOUS" ? getFirstPreviousWorkday(date, enabledDays) : getFirstNextWorkday(date, enabledDays)
        setSelectedDate(finalDate)
    }, [setSelectedDate, selectedDate, enabledDays])

    const dataCy = useMemo(() => `${mode.toLowerCase()}${unit.charAt(0)}${unit.toLowerCase().slice(1)}`, [])

    return (
        <Button variant="link" size="sm" className={mode === "NEXT" ? "ps-1" : "pe-1"} data-cy={dataCy} onClick={onClick}>
            <FontAwesomeIcon icon={mode === "NEXT" ? faCaretRight : faCaretLeft} />
        </Button>
    )
}

export default DateControl
