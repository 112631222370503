import { FC } from "react"
import * as DaysHelper from "../../../helpers/DaysHelper"

interface Props {
    date: Date
    at: "start" | "end"
    slot: number
}

const DateSlotCell: FC<Props> = ({ date, at, slot }) => {
    return (
        <span>
            {DaysHelper.isoPrint(date)}
            {slot ? <span className="ms-2 text-muted">{DaysHelper.getTimeString(at, slot)}</span> : null}
        </span>
    )
}

export default DateSlotCell
