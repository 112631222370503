import { FC, useMemo } from "react"
import { toScheduleMapFromPlan } from "../../helpers/ScheduleMapHelper"
import RoleType from "../../types/RoleType"
import ShiftMutationsPlanType from "../../types/ShiftMutationsPlanType"
import ShiftTemplateMutationsPlanType from "../../types/ShiftTemplateMutationsPlanType"
import MutationTable from "../editor/MutationTable"
import styles from "./ShiftPlannerResult.module.scss"

interface ShiftPlannerResultProps {
    plan: ShiftMutationsPlanType | ShiftTemplateMutationsPlanType
    roles: RoleType[]
    weekCycle: number
}

const ShiftPlannerResult: FC<ShiftPlannerResultProps> = ({ plan, roles, weekCycle }) => {
    const scheduleMap = useMemo(() => {
        return toScheduleMapFromPlan(plan)
    }, [plan])

    return (
        <div className={styles.container}>
            <MutationTable mutations={scheduleMap} roles={roles} mode={plan.shiftTemplate ? "DAY" : "DATE"} weekCycle={weekCycle} />
        </div>
    )
}

export default ShiftPlannerResult
