import { FC } from "react"
import { useSelectedLocation } from "../../../contexts/UserSettingsContext"
import { dateToDjango, isoPrint } from "../../../helpers/DaysHelper"
import { getCallCounts, getOccupationCounts } from "../../../helpers/OccupationHelper"
import { SourcedDayOccupationType } from "../../../types/OccupationType"
import UnexusRouterOccupationType from "../../../types/UnexusRouterOccupationType"
import UnexusTimeLine from "./UnexusTimeLine"

interface UnexusRouterOccupationsViewProps {
    referenceDates: Date[]
    routerOccupations: UnexusRouterOccupationType[]
    occupations: SourcedDayOccupationType[]
}

const UnexusRouterOccupationsView: FC<UnexusRouterOccupationsViewProps> = ({ referenceDates, routerOccupations, occupations }) => {
    const location = useSelectedLocation()
    const { startSlot, endSlot } = location

    const components = referenceDates.map((date) => {
        const dateString = dateToDjango(date)
        const occupationsForDate = routerOccupations.filter((o) => o.date === dateString)
        const values = getCallCounts(startSlot, endSlot, occupationsForDate, 1)
        return <UnexusTimeLine key={dateString} title={isoPrint(date)} values={values} type={"ROUTER_OCCUPATIONS"} />
    })

    const callValues = getCallCounts(startSlot, endSlot, routerOccupations, referenceDates.length)
    const occupationValues = getOccupationCounts(startSlot, endSlot, occupations)
    return (
        <>
            <UnexusTimeLine key="times" type="TIMELINE" />
            {components}
            <UnexusTimeLine key="average" title="Gemiddeld" values={callValues} type="ROUTER_OCCUPATIONS" lineAbove={true} />
            <UnexusTimeLine key="occupation" title="Bezetting" values={occupationValues} type="OCCUPATIONS" lineAbove={true} />
        </>
    )
}

export default UnexusRouterOccupationsView
