import { ChangeEventHandler, FC, useCallback, useMemo } from "react"
import { Form } from "react-bootstrap"
import { UseFormWatch } from "react-hook-form"
import { getDayName } from "../../../helpers/DaysHelper"
import { CopyModalInputs } from "./CopyModal"

interface Props {
    day: string
    watch: UseFormWatch<CopyModalInputs>
    toggleDay: (day: string) => void
}

const CopyModalDay: FC<Props> = ({ day, watch, toggleDay }) => {
    const fromDay = watch("fromDay")

    const toDays = watch("toDays")

    const checked = useMemo(() => toDays.includes(day) && day !== fromDay, [toDays, day, fromDay])

    const disabled = useMemo(() => day === fromDay, [day, fromDay])

    const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(() => toggleDay(day), [toggleDay, day])

    return <Form.Check id={`day_${day}`} type="checkbox" disabled={disabled} checked={checked} onChange={onChange} label={getDayName(parseInt(day))} />
}

export default CopyModalDay
