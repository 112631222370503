import { FC } from "react"
import { Form } from "react-bootstrap"
import { dateFromDjango, prettyPrintWithYear } from "../../helpers/DaysHelper"

interface NationalHolidayItemProps {
    item: any
    index: number
    register: any
}

const NationalHolidayItem: FC<NationalHolidayItemProps> = ({ item, index, register }) => {
    return (
        <tr key={item.id}>
            <td className="p-1">
                <label htmlFor={`national-holiday-${item.id}`}>{item.name}</label>
            </td>
            <td className="p-1">
                <span className="text-small text-muted">{prettyPrintWithYear(dateFromDjango(item.nextOccurrence))}</span>
            </td>
            <td className="p-1">
                <div className="d-inline-block me-2">
                    <Form.Select {...register(`nationalHolidays.${index}.mode`)}>
                        <option value="hide">Verberg</option>
                        <option value="show">Toon</option>
                        <option value="wipe">Toon en maak rooster leeg</option>
                    </Form.Select>
                </div>
            </td>
        </tr>
    )
}

export default NationalHolidayItem
