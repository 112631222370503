import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CSSProperties } from "react"
import { DropdownIndicatorProps } from "react-select"

const DropdownIndicator = (props: DropdownIndicatorProps) => {
    const {
        getStyles,
        innerProps: { ref, ...restInnerProps },
    } = props
    return (
        <div {...restInnerProps} ref={ref} style={getStyles("dropdownIndicator", props) as CSSProperties}>
            <FontAwesomeIcon icon={faChevronDown} />
        </div>
    )
}

export default DropdownIndicator
