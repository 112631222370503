import { useMemo } from "react"
import { useTranslation } from "react-i18next"

const useDayNames = () => {
    const { t } = useTranslation()

    return useMemo(() => {
        return [t("Calendar.monday"), t("Calendar.tuesday"), t("Calendar.wednesday"), t("Calendar.thursday"), t("Calendar.friday"), t("Calendar.saturday"), t("Calendar.sunday")]
    }, [t])
}

export default useDayNames
