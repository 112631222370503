import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useMemo } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { getDayName, getDayNameWeekCycle, getFirstEnabledDayIndex, isEnabled } from "../../../helpers/DaysHelper"
import CopyModalWeek from "./CopyModalWeek"

export interface CopyModalInputs {
    fromDay: string
    toDays: string[]
}

interface Props {
    visible: boolean
    close: () => void
    weekCycle: number
    enabledDays: string
    copyDay: (fromDay: string, toDays: string[]) => void
}

const CopyModal: FC<Props> = ({ visible, close, weekCycle, enabledDays, copyDay }) => {
    const {
        register,
        setValue,
        clearErrors,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm<CopyModalInputs>({
        defaultValues: { fromDay: getFirstEnabledDayIndex(enabledDays).toString(), toDays: [] },
    })

    const onSubmit: SubmitHandler<CopyModalInputs> = useCallback(({ fromDay, toDays }) => copyDay(fromDay, toDays), [copyDay])

    const fromDayOptions = useMemo(() => {
        const options: { day: number; name: string }[] = []
        for (let day = 0; day < weekCycle * 7; day++) {
            if (isEnabled(enabledDays, weekCycle, day, true)) {
                const name = weekCycle > 1 ? getDayNameWeekCycle(day) : getDayName(day)
                options.push({ day, name })
            }
        }
        return options
    }, [weekCycle, enabledDays])

    const weeks = useMemo(() => {
        const weeks: number[] = []
        for (let week = 0; week < weekCycle; week++) {
            weeks.push(week)
        }
        return weeks
    }, [weekCycle])

    const setToDays = useCallback(
        (days: string[]) => {
            setValue("toDays", days)
            clearErrors("toDays")
        },
        [setValue, clearErrors]
    )

    return (
        <Modal show={visible} onHide={close}>
            <Modal.Header className="justify-content-between">
                <Modal.Title>Kopieer gegevens</Modal.Title>
                <Button type="button" variant="link" onClick={close}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3">
                        <Form.Label>Van dag:</Form.Label>
                        <Form.Select size="lg" {...register("fromDay")}>
                            {fromDayOptions.map(({ day, name }) => (
                                <option key={`${day}_${name}`} value={day}>
                                    {name}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.fromDay?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Naar dag(en):</Form.Label>
                        <div className="ms-2">
                            {weeks.map((w) => (
                                <div className="my-2" key={w}>
                                    <CopyModalWeek week={w} weekCycle={weekCycle} enabledDays={enabledDays} watch={watch} setToDays={setToDays} />
                                </div>
                            ))}
                        </div>
                        <Form.Control type="hidden" isInvalid={!!errors.toDays} />
                        <Form.Control.Feedback type="invalid">{errors.toDays?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit">Kopieer</Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default CopyModal
