import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { ScheduleEditorContext, useScheduleEditorState } from "../../../contexts/ScheduleEditorContext"
import { useBreakTemplatesForSelectedLocation } from "../../../contexts/UserSettingsContext"
import { getBreakOptions } from "../../../helpers/BreakHelper"
import { getDaysString, getFirstNextWorkday } from "../../../helpers/DaysHelper"
import { setOptionalError } from "../../../helpers/FormHelper"
import { toScheduleMapFromGeneratedMutations } from "../../../helpers/ScheduleMapHelper"
import { getUserOptions } from "../../../helpers/UsersHelper"
import { TaskBreakMutationsResult, TaskType, createTaskBreakMutations } from "../../../services/Task"
import DayOptions from "../../DayOptions"
import DateRangeInput from "../../form/DateRangeInput"
import InlineFormMultiSelect from "../../form/InlineFormMultiSelect"
import styles from "./BreakPlanner.module.scss"
import GeneratedMutationsResult from "./GeneratedMutationsResult"
import TaskProgress from "./TaskProgress"

interface Inputs {
    dateFrom?: Date
    dateTo?: Date
    days: string
    breaks: string[]
    users: string[]
}

interface Props {
    visible: boolean
    mode: "DAYS" | "DATERANGE"
    close: () => void
}

const BreakPlanner: FC<Props> = ({ visible, mode, close }) => {
    const { applyMutations } = useContext(ScheduleEditorContext)
    const editorState = useScheduleEditorState()
    const breakTemplates = useBreakTemplatesForSelectedLocation()

    const roles = useMemo(() => editorState.roles || [], [editorState.roles])
    const location = useMemo(() => editorState.location, [editorState.location])
    const breakOptions = useMemo(() => getBreakOptions(breakTemplates), [location])
    const userOptions = useMemo(() => getUserOptions(editorState.users), [editorState.users])
    const enabledDays = useMemo(() => location.enabledDays, [location])
    const shiftTemplate = useMemo(() => editorState.shiftTemplate, [editorState.shiftTemplate])
    const weekCycle = useMemo(() => (shiftTemplate ? shiftTemplate.weekCycle : 1), [shiftTemplate])
    const firstNextWorkday = useMemo(() => getFirstNextWorkday(new Date(), enabledDays), [enabledDays])

    const {
        setValue,
        setError,
        clearErrors,
        formState: { errors },
        handleSubmit,
        watch,
        reset,
    } = useForm<Inputs>({
        defaultValues: {
            dateFrom: firstNextWorkday,
            dateTo: firstNextWorkday,
            days: getDaysString(weekCycle),
            breaks: [],
            users: [],
        },
    })

    const [task, setTask] = useState<TaskType<TaskBreakMutationsResult>>()

    useEffect(() => setValue("days", getDaysString(weekCycle)), [setValue, weekCycle])

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ dateFrom, dateTo, days, breaks, users }) => {
            const required = "Dit veld is vereist."
            const validDate = "Vul een geldige datum in"
            let newDateFromError: string | undefined = undefined
            let newDateToError: string | undefined = undefined
            let newDaysError: string | undefined = undefined
            let newBreaksError: string | undefined = undefined
            let newUsersError: string | undefined = undefined

            if (mode === "DATERANGE") {
                if (!dateFrom) {
                    newDateFromError = required
                } else if (dateTo && dateFrom > dateTo) {
                    newDateFromError = validDate
                }
            } else if (mode === "DAYS") {
                let hasIncorrect = false
                let hasSelected = false
                for (let i = 0; i < weekCycle * 7; i++) {
                    if (enabledDays[i % 7] === "1" && days[i] === "1") {
                        hasSelected = true
                    }
                    if (days[i] !== "1" && days[i] !== "0") {
                        hasIncorrect = true
                    }
                }
                if (hasIncorrect || !hasSelected) {
                    newDaysError = required
                }
            }
            if (!breaks || breaks.length === 0) {
                newBreaksError = required
            }
            if (!users || users.length === 0) {
                newUsersError = required
            }

            setOptionalError(setError, "dateFrom", newDateFromError)
            setOptionalError(setError, "dateTo", newDateToError)
            setOptionalError(setError, "days", newDaysError)
            setOptionalError(setError, "breaks", newBreaksError)
            setOptionalError(setError, "users", newUsersError)

            if (newDateFromError || newDateToError || newDaysError || newBreaksError || newUsersError) {
                // Show error, do nothing
            } else {
                createTaskBreakMutations({
                    mode,
                    location: location.id,
                    shiftTemplate: shiftTemplate?.id,
                    fromDate: dateFrom!,
                    toDate: dateTo!,
                    days,
                    breaks: breaks,
                    users,
                }).then((response) => setTask(response.data))
            }
        },
        [mode, location, shiftTemplate, enabledDays, setError, setTask]
    )

    const apply = useCallback(() => {
        const scheduleMap = toScheduleMapFromGeneratedMutations(task!.result.mutations)
        applyMutations(scheduleMap)
        close()
        setTask(undefined)
        reset()
    }, [task, applyMutations, close, setTask, reset])

    const resetTask = useCallback(() => setTask(undefined), [setTask])

    const dateFrom = watch("dateFrom")
    const dateTo = watch("dateTo")
    const setDateFrom = useCallback(
        (dateFrom: Date | null) => {
            setValue("dateFrom", dateFrom ?? undefined)
            clearErrors("dateFrom")
        },
        [setValue, clearErrors]
    )
    const setDateTo = useCallback(
        (dateTo: Date | null) => {
            setValue("dateTo", dateTo ?? undefined)
            clearErrors("dateTo")
        },
        [setValue, clearErrors]
    )

    const days = watch("days")
    const setDays = useCallback(
        (days: string) => {
            setValue("days", days)
            clearErrors("days")
        },
        [setValue, clearErrors]
    )

    const breaks = watch("breaks")
    const setBreaks = useCallback(
        (breaks: string[]) => {
            setValue("breaks", breaks)
            clearErrors("breaks")
        },
        [setValue, clearErrors]
    )

    const users = watch("users")
    const setUsers = useCallback(
        (users: string[]) => {
            setValue("users", users)
            clearErrors("users")
        },
        [setValue, clearErrors]
    )

    return (
        <Modal show={visible} onHide={close}>
            <Modal.Header className="justify-content-between">
                <Modal.Title>Pauzes inplannen {task?.result?.mutations ? `(${task.result.mutations.length})` : null}</Modal.Title>
                <Button type="button" variant="link" onClick={close}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body>
                {task ? (
                    <>
                        {task.done ? <GeneratedMutationsResult task={task} roles={roles} mode={mode} weekCycle={weekCycle} /> : <TaskProgress initialTask={task} setTask={setTask} />}
                        <div className="mt-4">
                            <Button type="button" onClick={resetTask}>
                                Vorige
                            </Button>
                            {task.done ? (
                                <Button type="button" onClick={apply} className="ms-2">
                                    Toepassen
                                </Button>
                            ) : null}
                        </div>
                    </>
                ) : (
                    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <table className="form-inline-table">
                            <tbody>
                                {mode === "DAYS" ? (
                                    <tr>
                                        <td className={styles.nameCell}>
                                            <span className={styles.title}>Dagen:</span>
                                        </td>
                                        <td>
                                            <Form.Group>
                                                <div className="d-inline-block form-inline-input-group px-2">
                                                    <DayOptions enabledDays={enabledDays} weekCycle={weekCycle} selectedDays={days} disabled={false} setSelectedDays={setDays} />
                                                </div>
                                                <Form.Control type="hidden" isInvalid={!!errors.days} />
                                                <Form.Control.Feedback type="invalid">{errors.days?.message}</Form.Control.Feedback>
                                            </Form.Group>
                                        </td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td colSpan={2}>
                                            <DateRangeInput startDate={dateFrom} setStartDate={setDateFrom} endDate={dateTo} setEndDate={setDateTo} />
                                            <Form.Control type="hidden" isInvalid={!!(errors.dateFrom?.message || errors.dateTo?.message)} />
                                            <Form.Control.Feedback type="invalid">{errors.dateFrom?.message || errors.dateTo?.message}</Form.Control.Feedback>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td className={styles.nameCell}>
                                        <span className={styles.title}>Pauzes:</span>
                                    </td>
                                    <td>
                                        <div className="container-scroll" style={{ maxHeight: "150px", paddingTop: "0.375rem" }}>
                                            <InlineFormMultiSelect id="breaks" options={breakOptions} values={breaks} onChange={(newValue) => setBreaks(newValue)} toggleAllEnabled={true} />
                                        </div>
                                        <Form.Control type="hidden" isInvalid={!!errors.breaks?.message} />
                                        <Form.Control.Feedback type="invalid">{errors.breaks?.message}</Form.Control.Feedback>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.nameCell}>
                                        <span className={styles.title}>Medewerker(s):</span>
                                    </td>
                                    <td>
                                        <div className="container-scroll" style={{ maxHeight: "150px", paddingTop: "0.375rem" }}>
                                            <InlineFormMultiSelect id="users" options={userOptions} values={users} onChange={setUsers} toggleAllEnabled={true} />
                                        </div>
                                        <Form.Control type="hidden" isInvalid={!!errors.users?.message} />
                                        <Form.Control.Feedback type="invalid">{errors.users?.message}</Form.Control.Feedback>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Button type="submit">Volgende</Button>
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default BreakPlanner
