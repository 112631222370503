import {
    IconDefinition,
    fa0,
    fa1,
    fa2,
    fa3,
    fa4,
    fa5,
    fa6,
    fa7,
    fa8,
    fa9,
    faA,
    faB,
    faBuilding,
    faC,
    faD,
    faE,
    faF,
    faG,
    faH,
    faHome,
    faI,
    faJ,
    faK,
    faL,
    faM,
    faN,
    faO,
    faP,
    faPlane,
    faQ,
    faR,
    faS,
    faT,
    faU,
    faUmbrellaBeach,
    faV,
    faW,
    faX,
    faY,
    faZ,
} from "@fortawesome/free-solid-svg-icons"

export type LocalityIcon =
    | "HOME"
    | "OFFICE"
    | "BEACH"
    | "PLANE"
    | "0"
    | "1"
    | "2"
    | "3"
    | "4"
    | "5"
    | "6"
    | "7"
    | "8"
    | "9"
    | "A"
    | "B"
    | "C"
    | "D"
    | "E"
    | "F"
    | "G"
    | "H"
    | "I"
    | "J"
    | "K"
    | "L"
    | "M"
    | "N"
    | "O"
    | "P"
    | "Q"
    | "R"
    | "S"
    | "T"
    | "U"
    | "V"
    | "W"
    | "X"
    | "Y"
    | "Z"

export const localityIconOptions: { id: LocalityIcon; icon: IconDefinition }[] = [
    { id: "HOME", icon: faHome },
    { id: "OFFICE", icon: faBuilding },
    { id: "BEACH", icon: faUmbrellaBeach },
    { id: "PLANE", icon: faPlane },
    { id: "0", icon: fa0 },
    { id: "1", icon: fa1 },
    { id: "2", icon: fa2 },
    { id: "3", icon: fa3 },
    { id: "4", icon: fa4 },
    { id: "5", icon: fa5 },
    { id: "6", icon: fa6 },
    { id: "7", icon: fa7 },
    { id: "8", icon: fa8 },
    { id: "9", icon: fa9 },
    { id: "A", icon: faA },
    { id: "B", icon: faB },
    { id: "C", icon: faC },
    { id: "D", icon: faD },
    { id: "E", icon: faE },
    { id: "F", icon: faF },
    { id: "G", icon: faG },
    { id: "H", icon: faH },
    { id: "I", icon: faI },
    { id: "J", icon: faJ },
    { id: "K", icon: faK },
    { id: "L", icon: faL },
    { id: "M", icon: faM },
    { id: "N", icon: faN },
    { id: "O", icon: faO },
    { id: "P", icon: faP },
    { id: "Q", icon: faQ },
    { id: "R", icon: faR },
    { id: "S", icon: faS },
    { id: "T", icon: faT },
    { id: "U", icon: faU },
    { id: "V", icon: faV },
    { id: "W", icon: faW },
    { id: "X", icon: faX },
    { id: "Y", icon: faY },
    { id: "Z", icon: faZ },
]

export default interface LocalityOptionType {
    id: number
    location: number
    order: number
    icon: LocalityIcon
    name: string
}
