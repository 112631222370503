import ShiftTemplateMutationsPlanType, { CreateShiftTemplateMutationsPlanType } from "../types/ShiftTemplateMutationsPlanType"
import api from "./Api"

export function createShiftTemplateMutationsPlan(shiftTemplateMutationsPlan: CreateShiftTemplateMutationsPlanType) {
    return api.post<ShiftTemplateMutationsPlanType>(`/shift-templates/plan/`, shiftTemplateMutationsPlan)
}

export function get(id: number) {
    return api.get<ShiftTemplateMutationsPlanType>(`/shift-templates/plan/${id}/`)
}

export function loadShiftTemplateMutationsPlan(id: number) {
    return new Promise<ShiftTemplateMutationsPlanType>((resolve) => {
        get(id).then((response) => resolve(response.data))
    })
}
