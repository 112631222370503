import { FC, useCallback, useMemo } from "react"

interface StatisticsTabProps {
    id: string
    name: string
    activeTabId: string
    setActiveTabId: (tab: string) => void
}

const StatisticsTab: FC<StatisticsTabProps> = ({ id, name, activeTabId, setActiveTabId }) => {
    const active = useMemo(() => {
        return id === activeTabId
    }, [id, activeTabId])

    const onSelect = useCallback(() => {
        setActiveTabId(id)
    }, [setActiveTabId, id])

    return (
        <li className="nav-item">
            <button className={`btn btn-selectable ${active ? "active" : ""}`} onClick={onSelect}>
                {name}
            </button>
        </li>
    )
}

export default StatisticsTab
