import { AxisLinearOptions, AxisOptions, UserSerie } from "react-charts"
import { UnexusDatum } from "../pages/statistics/section/forecast/ForecastGraph1"
import { IntervalType, TaskForecastResult, TaskResultWithRoles, TaskRoleTimeResult, TaskType, TaskUserTimeResult } from "../services/Task"
import { DefaultColorType } from "../types/ColorType"
import LocationType from "../types/LocationType"
import { getCSSColor } from "./ColorHelper"
import { addDays, addMonths, addWeeks, dateFromDjango, daysBetween, getFirstMondayOfWeek, getFirstOfMonth, getMonthName, getWeekNumber, monthsBetween, shortDate, weeksBetween } from "./DaysHelper"
import { NestedRoleItem } from "./RolesHelper"

export function getIntervalLabel(interval: IntervalType) {
    if (interval === "DAY") {
        return "dag"
    } else if (interval === "WEEK") {
        return "week"
    } else {
        return "maand"
    }
}

export interface RoleHoursDatum {
    x: string // DAY: 2024-01=01, WEEKLY: 2024-52, MONTHLY: 2024: 2024-12
    count: number
}

export interface RoleHoursAggregatedDatum {
    roleItem: NestedRoleItem
    total: number
    isSubItem: boolean
}

export const getForecastGraph1Data: (taskResult: TaskForecastResult, location: LocationType) => UserSerie<UnexusDatum>[] = (taskResult, location) => {
    const result: UserSerie<UnexusDatum>[] = []

    if (!taskResult) return []

    if (taskResult.roleUids.length > 0) {
        const roleData = []
        const occupationData = []
        for (const x in taskResult.roleData) {
            roleData.push({
                x,
                count: taskResult.roleData[x][0],
            })
            occupationData.push({
                x,
                count: taskResult.roleData[x][1],
            })
        }
        if (roleData.length) {
            result.push({
                label: "Ingepland",
                color: "#0262ef",
                data: roleData,
            })
            result.push({
                label: "Basisbezetting",
                color: "rgb(40, 167, 69)",
                data: occupationData,
            })
        }
    }

    return result
}

export const getForecastGraph2Data: (taskResult: TaskForecastResult, location: LocationType) => UserSerie<UnexusDatum>[] = (taskResult, location) => {
    const result: UserSerie<UnexusDatum>[] = []

    if (!taskResult) return []

    if (taskResult.roleUids.length > 0) {
        const shortageData = []
        for (const x in taskResult.roleData) {
            shortageData.push({
                x,
                count: taskResult.roleData[x][2],
            })
        }
        if (shortageData.length) {
            result.push({
                label: "Onderbezetting",
                color: "rgb(220, 53, 69)",
                data: shortageData,
            })
        }
    }

    return result
}

export const getRoleHoursAggregatedData: (taskResult: TaskRoleTimeResult | TaskUserTimeResult, nestedRoles: NestedRoleItem[]) => UserSerie<RoleHoursAggregatedDatum>[] = (taskResult, nestedRoles) => {
    const result: UserSerie<RoleHoursAggregatedDatum>[] = []

    if (!taskResult) return []

    const data = []
    for (const roleItem of nestedRoles) {
        const itemAggregate = taskResult["rolesAggregate"][roleItem.uid]
        if (itemAggregate) {
            data.push({
                roleItem,
                total: itemAggregate[0],
                isSubItem: false,
            })
        }
        if (roleItem.subRoles) {
            for (const subItem of roleItem.subRoles) {
                const subItemAggregate = taskResult["rolesAggregate"][subItem.uid]
                if (subItemAggregate) {
                    data.push({
                        roleItem: subItem,
                        total: subItemAggregate[0],
                        isSubItem: true && !!itemAggregate, // If parent is not included, don't treat role as subRole
                    })
                }
            }
        }
    }
    data.reverse()

    result.push({
        label: "Totaal",
        data: data,
    })

    return result
}

export const getRoleHoursData: (
    taskResult: TaskRoleTimeResult | TaskUserTimeResult,
    nestedRoleMap: Map<string, NestedRoleItem>,
    colors: Map<number, DefaultColorType>
) => UserSerie<RoleHoursDatum>[] = (taskResult, nestedRoleMap, colors) => {
    const result: UserSerie<RoleHoursDatum>[] = []

    if (!taskResult) return []

    const map: Map<string, RoleHoursDatum[]> = new Map()
    for (const x in taskResult.roleData) {
        for (const roleId in taskResult.roleData[x]) {
            if (!map.has(roleId)) {
                map.set(roleId, [])
            }
            map.get(roleId)!.push({
                x,
                count: taskResult.roleData[x][roleId],
            })
        }
    }

    for (const roleId of taskResult.roleUids) {
        const roleItem = nestedRoleMap.get(roleId)
        result.push({
            label: roleItem?.name,
            color: roleItem?.color ? getCSSColor(colors.get(roleItem?.color)!) : undefined,
            data: map.get(roleId.toString()) ?? [],
        })
    }

    return result
}

interface IntervalDatum {
    x: string
}

export const getNameXAxis: (task: TaskType<TaskRoleTimeResult | TaskUserTimeResult>) => AxisOptions<IntervalDatum> = (task: TaskType<TaskRoleTimeResult | TaskUserTimeResult>) => {
    const interval: IntervalType = (task?.result?.interval as IntervalType) ?? "DAY"
    const referenceDate = getReferenceDate(dateFromDjango(task?.result?.fromDate), interval)

    return {
        getValue: (datum: IntervalDatum) => getXValue(referenceDate, interval, datum.x).toString(),
        formatters: {
            scale: (value: string) => getXFormatted(referenceDate, interval, parseInt(value)),
        },
    }
}

const getReferenceDate = (fromDate: Date, interval: IntervalType): Date => {
    if (interval === "WEEK") {
        return getFirstMondayOfWeek(fromDate)
    } else if (interval === "MONTH") {
        return getFirstOfMonth(fromDate)
    }
    return fromDate
}

const getXValue = (referenceDate: Date, interval: IntervalType, x: string): number => {
    if (interval === "DAY") {
        return daysBetween(dateFromDjango(x), referenceDate)
    } else if (interval === "WEEK") {
        return weeksBetween(dateFromDjango(x), referenceDate)
    } else if (interval === "MONTH") {
        return monthsBetween(dateFromDjango(x), referenceDate)
    }
    return 0
}

const getXFormatted = (referenceDate: Date, interval: IntervalType, value: number): string => {
    if (value === undefined || value % 1 !== 0) {
        return ""
    } else if (interval === "DAY") {
        return shortDate(addDays(referenceDate, value))
    } else if (interval === "WEEK") {
        const startOfWeek = addWeeks(referenceDate, value)
        const weekNumber = getWeekNumber(startOfWeek)
        const yearSuffix = startOfWeek.getFullYear() !== new Date().getFullYear() ? ` '${startOfWeek.getFullYear().toString().substring(2)}` : ""
        return `Week ${weekNumber}${yearSuffix}`
    } else if (interval === "MONTH") {
        const startOfMonth = addMonths(referenceDate, value)
        const yearSuffix = startOfMonth.getFullYear() !== new Date().getFullYear() ? ` '${startOfMonth.getFullYear().toString().substring(2)}` : ""
        return `${getMonthName(startOfMonth.getMonth())}${yearSuffix}`
    }
    return ""
}

export const getTimeXAxis: (task: TaskType<TaskRoleTimeResult | TaskUserTimeResult | TaskForecastResult>) => AxisLinearOptions<IntervalDatum> = (
    task: TaskType<TaskRoleTimeResult | TaskUserTimeResult | TaskForecastResult>
) => {
    const interval: IntervalType = (task?.result?.interval as IntervalType) ?? "DAY"
    const referenceDate = getReferenceDate(dateFromDjango(task?.result?.fromDate), interval)
    return {
        getValue: (datum: IntervalDatum) => getXValue(referenceDate, interval, datum.x),
        formatters: {
            scale: (value: number) => getXFormatted(referenceDate, interval, value),
        },
    }
}

export const canShowData = (taskResult?: TaskResultWithRoles) => {
    return !!taskResult && taskResult.roleUids.length > 0
}
