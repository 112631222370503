import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback } from "react"
import { Button } from "react-bootstrap"

interface Props {
    pageIndex: number
    goToPage: (page: number) => void
    isCurrent?: boolean
    isFirst?: boolean
    isLast?: boolean
    show: boolean
}

const TablePaginationButton: FC<Props> = ({ pageIndex, goToPage, isCurrent, isFirst, isLast, show }) => {
    const onClick = useCallback(() => {
        goToPage(pageIndex)
    }, [pageIndex, goToPage])

    if (!show) {
        return null
    }

    return (
        <Button type="button" variant={isCurrent ? "primary" : "light"} onClick={onClick}>
            {isFirst ? <FontAwesomeIcon icon={faAnglesLeft} /> : null}
            {!(isFirst || isLast) ? pageIndex + 1 : null}
            {isLast ? <FontAwesomeIcon icon={faAnglesRight} /> : null}
        </Button>
    )
}

export default TablePaginationButton
