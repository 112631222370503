import { FC, ReactNode } from "react"
import styles from "./InlineGroupLabel.module.scss"

interface InlineGroupLabelProps {
    inline: boolean
    children: ReactNode
}

const InlineGroupLabel: FC<InlineGroupLabelProps> = ({ inline, children }) => {
    return <span className={(inline ? styles.inlineGroupLabel : "input-group-label input-group-text") + " text-small text-muted"}>{children}</span>
}

export default InlineGroupLabel
