import OccupationSourceType from "../types/OccupationSourceType"
import OccupationType, { SourcedDateOccupationType, SourcedDayOccupationType } from "../types/OccupationType"
import api from "./Api"

export function getAllOccupations(template: number) {
    return api.get<{ occupations: OccupationType[]; sourcedOccupations: SourcedDayOccupationType[]; occupationSources: OccupationSourceType[]; weekCycle: number }>(
        `/occupations/?template=${template}`
    )
}

export function getApplicableOccupations(location: number, date: number) {
    return api.get<{ occupations: OccupationType[]; sourcedOccupations: SourcedDateOccupationType[]; weekCycle: number }>(`/occupations/applicable/?location=${location}&date=${date}`)
}
