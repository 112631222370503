import { withParams } from "../helpers/FilterHelper"
import PageRequestType from "../types/PageRequestType"
import PageResponseType from "../types/PageResponseType"
import RoleGroupType, { CreateRoleGroupType, UpdateRoleGroupType } from "../types/RoleGroupType"
import api from "./Api"

export function loadRoleGroups(request: PageRequestType): Promise<PageResponseType<RoleGroupType>> {
    return new Promise<PageResponseType<RoleGroupType>>((resolve, reject) =>
        getRoleGroups(request)
            .then((response) => resolve(response.data))
            .catch(reject)
    )
}

export function getRoleGroups(pageDetails: PageRequestType) {
    return api.get<PageResponseType<RoleGroupType>>(withParams("/role-groups/", pageDetails))
}

export function getRoleGroup(id: number) {
    return api.get<RoleGroupType>(`/role-groups/${id}/`)
}

export function loadRoleGroup(id: number) {
    return () =>
        new Promise<RoleGroupType>((resolve) => {
            getRoleGroup(id).then((response) => resolve(response.data))
        })
}

export function createRoleGroup(roleGroup: CreateRoleGroupType) {
    return api.post("/role-groups/", roleGroup)
}

export function updateRoleGroup(roleGroup: UpdateRoleGroupType) {
    return api.patch(`/role-groups/${roleGroup.id}/`, roleGroup)
}

export function removeRoleGroup(id: number) {
    return api.delete(`/role-groups/${id}/`)
}
