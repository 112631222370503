import { FC, ReactElement } from "react"
import { Navigate } from "react-router-dom"
import { useIsAuthenticated } from "../contexts/UserSettingsContext"

interface AnonymousElementProps {
    children: ReactElement<any, any>
}

const AnonymousElement: FC<AnonymousElementProps> = ({ children }) => {
    const isAuthenticated = useIsAuthenticated()

    return isAuthenticated ? <Navigate to="/dashboard" /> : children
}

export default AnonymousElement
