import { FC, useCallback, useMemo } from "react"
import { AxisLinearOptions, Chart, Series, SeriesFocusStatus, SeriesStyles } from "react-charts"
import { useSelectedLocation } from "../../../../contexts/UserSettingsContext"
import { prettyPrintQuarters } from "../../../../helpers/DaysHelper"
import { canShowData, getForecastGraph2Data, getTimeXAxis } from "../../../../helpers/TaskHelper"
import { TaskForecastResult, TaskType } from "../../../../services/Task"
import NoData from "../../NoData"

export interface UnexusDatum {
    x: string
    count: number
}

interface ForecastGraph1Props {
    task?: TaskType<TaskForecastResult>
}

const ForecastGraph1: FC<ForecastGraph1Props> = ({ task }) => {
    const location = useSelectedLocation()

    const taskResult = useMemo(() => {
        return task?.result
    }, [task])

    const data = useMemo(() => {
        return taskResult ? getForecastGraph2Data(taskResult, location) : undefined
    }, [taskResult, location])

    const primaryAxis = useMemo(() => {
        return task ? getTimeXAxis(task) : undefined
    }, [task])

    const secondaryAxes = useMemo<AxisLinearOptions<UnexusDatum>[]>(
        () => [
            {
                getValue: (datum) => datum.count / 4,
                elementType: "line",
                min: 0,
                max: 10,
                formatters: {
                    scale: (datum) => prettyPrintQuarters(datum * 4),
                },
            },
        ],
        []
    )

    const getSeriesStyle = useCallback((series: Series<UnexusDatum>, status: SeriesFocusStatus): SeriesStyles => {
        return {
            color: series.originalSeries.color,
        }
    }, [])

    return (
        <div className="w-100 h-100">
            {canShowData(taskResult) ? (
                <Chart
                    options={{
                        data: data!,
                        primaryAxis: primaryAxis!,
                        secondaryAxes,
                        getSeriesStyle,
                    }}
                />
            ) : (
                <NoData taskResult={taskResult} />
            )}
        </div>
    )
}

export default ForecastGraph1
