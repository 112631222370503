import { getUnexusRouterForSource } from "../services/UnexusRouter"
import OccupationSourceType from "../types/OccupationSourceType"
import UnexusRouterType from "../types/UnexusRouterType"

const useLoadUnexusRoutersForSource = (source: OccupationSourceType | undefined, setUnexusRouters: (unexusRouters: UnexusRouterType[]) => void) => {
    return () => {
        if (!source) {
            return
        }
        getUnexusRouterForSource(source).then((response) => setUnexusRouters(response.data.items))
    }
}

export default useLoadUnexusRoutersForSource
