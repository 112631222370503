import { FC } from "react"
import { TaskOccupationFixesResult, TaskType } from "../../../../services/Task"
import Loader from "../../../Loader"
import OccupationFix from "./OccupationFix"

interface Props {
    task?: TaskType<TaskOccupationFixesResult>
    finishModal: () => void
}

const OccupationFixesView: FC<Props> = ({ task, finishModal }) => {
    return (
        <div>
            {!task || !task.done ? (
                <div className="px-3">
                    <Loader />
                </div>
            ) : !task.success || task.result.fixes.length === 0 ? (
                <div className="mx-3">Geen oplossingen gevonden</div>
            ) : (
                <div>{task.result?.fixes.map((f, i) => <OccupationFix key={`${task.id}_${i}`} fix={f} finishModal={finishModal} />)}</div>
            )}
        </div>
    )
}

export default OccupationFixesView
