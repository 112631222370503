import { FC, useContext, useEffect } from "react"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import FullPageCard from "../components/page/FullPageCard"
import { UserSettingsContext, useIsAuthenticated } from "../contexts/UserSettingsContext"

const StartSAMLAccountPage: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const isAuthenticated = useIsAuthenticated()
    const { userSettings } = useContext(UserSettingsContext)

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/dashboard")
        }
    }, [isAuthenticated])

    return (
        <FullPageCard>
            <div className="card-header-bar">
                <div className="card-header-bar-item">
                    <h2>{t("StartSAMLAccountPage.start_with_rooster_planner")}</h2>
                </div>
            </div>
            {userSettings?.samlLoginConfig?.samlOptions.map(({ id, name }) => (
                <div className="mb-3">
                    <Link to={`${process.env.REACT_APP_API_URL}/accounts/saml/${id}/acs/signin/`}>
                        <Button type="button">{t("StartSAMLAccountPage.log_in_with", { service: name })}</Button>
                    </Link>
                </div>
            ))}
        </FullPageCard>
    )
}

export default StartSAMLAccountPage
