import { FC } from "react"

interface ProgressBarProps {
    done: boolean
    progress: number
}

const ProgressBar: FC<ProgressBarProps> = ({ done, progress }) => {
    return (
        <div className="progress">
            <div
                className={"text-bold progress-bar" + (!done ? " progress-bar-striped progress-bar-animated" : "")}
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ width: progress + "%" }}
            >
                {progress + "%"}
            </div>
        </div>
    )
}

export default ProgressBar
