import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import debounce from "debounce"
import { FC, useCallback, useContext, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { ScheduleEditorContext, useScheduleEditorState } from "../../../contexts/ScheduleEditorContext"
import { useCompanyModules } from "../../../contexts/UserSettingsContext"
import { COMPANY_MODULE_NOTIFICATIONS } from "../../../helpers/Constants"
import { storeShiftsAndLocalities } from "../../../services/Schedule"
import MutationTable from "../../editor/MutationTable"

interface Props {
    visible: boolean
    absenceRequest?: number
    close: () => void
    onSuccess: () => void
    onFailure: (error: any) => void
}

const ScheduleApproval: FC<Props> = ({ visible, absenceRequest, close, onSuccess, onFailure }) => {
    const companyModules = useCompanyModules()
    const { reloadEditorFromSchedule } = useContext(ScheduleEditorContext)
    const editorState = useScheduleEditorState()

    const [handleAbsenceRequest, setHandleAbsenceRequest] = useState<boolean>(!!absenceRequest)
    const [sendNotifications, setSendNotifications] = useState<boolean>(true)

    const onSaveSuccess = useCallback(() => {
        onSuccess()
        reloadEditorFromSchedule()
    }, [onSuccess, reloadEditorFromSchedule])

    const approve = useCallback(
        debounce(
            () => {
                storeShiftsAndLocalities(editorState.newScheduleMutations, sendNotifications, handleAbsenceRequest ? absenceRequest : undefined)
                    .then(onSaveSuccess)
                    .catch(onFailure)
            },
            300,
            {
                immediate: true,
            }
        ),
        [editorState.newScheduleMutations, handleAbsenceRequest, sendNotifications]
    )

    const toggleHandleAbsenceRequest = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setHandleAbsenceRequest(event.target.checked)
        },
        [setHandleAbsenceRequest]
    )

    const toggleSendNotifications = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSendNotifications(event.target.checked)
        },
        [setSendNotifications]
    )

    return (
        <Modal show={visible} onHide={close}>
            <Modal.Header className="justify-content-between">
                <Modal.Title>Weet je zeker dat je de volgende wijziging(en) wil opslaan?</Modal.Title>
                <Button type="button" variant="link" onClick={close}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <MutationTable mutations={editorState.newScheduleMutations} roles={editorState.roles} mode="DATE" />
            </Modal.Body>
            <Modal.Footer>
                {absenceRequest ? (
                    <div className="d-block w-100 mb-2">
                        <input type="checkbox" checked={handleAbsenceRequest} id="handleAbsenceRequest" onChange={toggleHandleAbsenceRequest} />
                        <label className="ms-2 text-small" htmlFor="handleAbsenceRequest">
                            Update verlofaanvraag {absenceRequest} naar status 'Rooster aangepast'
                        </label>
                    </div>
                ) : null}
                {companyModules.includes(COMPANY_MODULE_NOTIFICATIONS) && editorState.hasNewShiftMutations ? (
                    <div className="d-block w-100 mb-2">
                        <input type="checkbox" checked={sendNotifications} id="sendNotifications" onChange={toggleSendNotifications} />
                        <label className="ms-2 text-small" htmlFor="sendNotifications">
                            Verstuur notificaties naar betreffende medewerkers
                        </label>
                    </div>
                ) : null}
                <button type="button" className="btn btn-primary" onClick={approve} data-cy="approveSchedule">
                    Opslaan
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ScheduleApproval
