import { FC, useMemo } from "react"
import { useCurrentUser } from "../../../contexts/UserSettingsContext"
import styles from "./StaticWeekTimeLineSlots.module.scss"

const StaticWeekTimeLineSlots: FC = () => {
    const { startSlot, endSlot } = useCurrentUser()

    const slots = useMemo(() => {
        const slots = []
        for (let slot = startSlot; slot <= endSlot; slot++) {
            slots.push(slot)
        }
        return slots
    }, [startSlot, endSlot])

    return slots.map((slot) => (
        <div key={slot} className={styles.timeTableCell} style={{ borderLeftStyle: slot % 4 === 0 ? "solid" : undefined }}>
            {" "}
        </div>
    ))
}

export default StaticWeekTimeLineSlots
