import EnvironmentGenerationType from "../types/EnvironmentGenerationType"
import api from "./Api"

export interface CreateEnvironmentGenerationRequest {
    users: { firstName: string; lastName: string }[]
    roles: { name: string; type: string }[]
}

export function createEnvironmentGeneration(data: CreateEnvironmentGenerationRequest) {
    return api.post<EnvironmentGenerationType>("/environment-generation/", data)
}

export function getEnvironmentGeneration() {
    return api.get<EnvironmentGenerationType>("/environment-generation/")
}
