import { FC, useCallback, useMemo } from "react"
import { Button } from "react-bootstrap"
import { getShortDayName } from "../helpers/DaysHelper"

interface DayOptionProps {
    index: number
    enabledDays: string
    selectedDays: string
    disabled: boolean
    toggleDay: (index: number) => void
}

const DayOption: FC<DayOptionProps> = ({ index, enabledDays, selectedDays, disabled, toggleDay }) => {
    const enabledDay = useMemo(() => {
        return enabledDays[index % 7] === "1"
    }, [enabledDays, index])

    const selected = useMemo(() => {
        return selectedDays[index] === "1"
    }, [selectedDays, index])

    const className = useMemo(() => {
        let className = "btn btn-multi-selectable"
        if (selected) {
            className += " active"
        }
        if (disabled) {
            className += " disabled"
        }
        return className
    }, [selected, disabled])

    const onClick = useCallback(() => {
        toggleDay(index)
    }, [toggleDay, index])

    if (!enabledDay) {
        return null
    }

    return (
        <li>
            <Button id={"day_" + index} type="button" className={className} onClick={disabled ? undefined : onClick}>
                {getShortDayName(index)}
            </Button>
        </li>
    )
}

export default DayOption
