import { FC, useMemo } from "react"
import { useLocations } from "../../../contexts/UserSettingsContext"
import { ScheduleItem, StaticShift } from "../../../helpers/ScheduleMapHelper"
import { LocalityOptionOrInherit } from "../../../types/LocalityType"
import StaticWeekDayLocation from "./StaticWeekDayLocation"

interface Props {
    date: Date
    locationMap: Map<string, ScheduleItem>
    showLocation: boolean
}

interface Item {
    locationId: number
    shifts: StaticShift[]
    locality: LocalityOptionOrInherit
    isFirstOfDay: boolean
}

const StaticWeekDay: FC<Props> = ({ date, locationMap, showLocation }) => {
    const locations = useLocations()

    const itemsToShow = useMemo(() => {
        const items: Item[] = []
        if (locationMap.size === 0) {
            items.push({
                locationId: locations[0].id,
                shifts: [],
                locality: "UNKNOWN",
                isFirstOfDay: true,
            })
        } else {
            Array.from(locationMap.entries()).forEach(([locationId, scheduleItem], index) => {
                items.push({
                    locationId: parseInt(locationId),
                    shifts: scheduleItem.shifts,
                    locality: scheduleItem.locality,
                    isFirstOfDay: index === 0,
                })
            })
        }
        return items
    }, [locationMap])

    return itemsToShow.map(({ locationId, shifts, locality, isFirstOfDay }) => (
        <StaticWeekDayLocation key={`${date}_${locationId}`} date={date} locationId={locationId} shifts={shifts} locality={locality} isFirstOfDay={isFirstOfDay} showLocation={showLocation} />
    ))
}

export default StaticWeekDay
