import { FC, useCallback } from "react"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"
import { useLocations } from "../../contexts/UserSettingsContext"
import { UserEditInputs, UserEditMode } from "../../pages/users/UserEditPage"
import UserLocationRow from "./UserLocationRow"

interface Props {
    mode: UserEditMode
    watch: UseFormWatch<UserEditInputs>
    setValue: UseFormSetValue<UserEditInputs>
}

const UserLocationRows: FC<Props> = ({ mode, watch, setValue }) => {
    const allLocations = useLocations()

    const locations = watch("locations")

    const handleLocationChange = useCallback(
        (location: number) => {
            const newLocations = [...locations]
            if (locations.includes(location)) {
                newLocations.splice(newLocations.indexOf(location), 1)
            } else {
                newLocations.push(location)
            }
            setValue("locations", newLocations)
        },
        [locations]
    )

    return allLocations.map((l) => <UserLocationRow key={l.id} location={l} selected={locations.includes(l.id)} handleLocationChange={handleLocationChange} disabled={mode === "update-my"} />)
}

export default UserLocationRows
