import { TableFilterDef } from "../components/table/Table"
import LocationType from "../types/LocationType"

export function getLocationFilter(location: LocationType): TableFilterDef {
    return {
        id: "location",
        type: "hidden",
        initialValues: [{ key: "location", value: location.id.toString() }],
    }
}
