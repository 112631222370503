import OccupationSourceType from "../types/OccupationSourceType"
import UnexusRouterType from "../types/UnexusRouterType"
import api from "./Api"

export function loadUnexusRouters(location: number) {
    return () =>
        new Promise<UnexusRouterType[]>((resolve) => {
            getUnexusRouters(location).then((response) => resolve(response.data.items))
        })
}

export function getUnexusRouters(location: number) {
    return api.get<{ items: UnexusRouterType[] }>(`/unexus-routers/?location=${location}`)
}

export function getUnexusRouterForSource(source: OccupationSourceType) {
    return api.get<{ items: UnexusRouterType[] }>(`/unexus-routers/?source=${source.id}`)
}
