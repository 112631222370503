import { FC } from "react"
import { Form } from "react-bootstrap"
import { FieldErrors, UseFormSetValue, UseFormWatch } from "react-hook-form"
import { UserEditInputs, UserEditMode } from "../../pages/users/UserEditPage"
import UserLocationRows from "./UserLocationRows"
import UserTabErrors from "./UserTabErrors"
import { UserTab } from "./UserTabs"

interface Props {
    selectedTab: UserTab
    watch: UseFormWatch<UserEditInputs>
    setValue: UseFormSetValue<UserEditInputs>
    mode: UserEditMode
    errors: FieldErrors<UserEditInputs>
    tabErrors: UserTab[]
}

const UserLocationsForm: FC<Props> = ({ selectedTab, watch, setValue, mode, errors, tabErrors }) => {
    if (selectedTab !== "locations") {
        return null
    }

    return (
        <div>
            <Form.Group className="mb-3">
                <table className="table mb-3" role="table">
                    <tbody>
                        <UserLocationRows mode={mode} watch={watch} setValue={setValue} />
                    </tbody>
                </table>
                <Form.Control type="hidden" isInvalid={!!errors.locations} />
                <Form.Control.Feedback type="invalid">{errors.locations?.message}</Form.Control.Feedback>
            </Form.Group>
            <UserTabErrors tabErrors={tabErrors} except="locations" />
        </div>
    )
}

export default UserLocationsForm
