interface ObjectWithDoneField {
    done: boolean
}

export function refetchInterval(fetchCount: React.MutableRefObject<number>) {
    return (data?: ObjectWithDoneField) => {
        if (data?.done) {
            fetchCount.current = 0
            return false
        }
        fetchCount.current += 1
        return 200 + fetchCount.current * 100
    }
}
