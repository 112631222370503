import api from "./Api"

export interface OccupationTemplateGenerationType {
    id: number
    done: boolean
    success: boolean
    progress: number
}

export function get(id: number) {
    return api.get<OccupationTemplateGenerationType>(`/occupation-template-generations/${id}/`)
}

export function load(id: number) {
    return new Promise<OccupationTemplateGenerationType>((resolve) => {
        get(id).then((response) => resolve(response.data))
    })
}
