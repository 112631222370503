import { useCallback } from "react"
import { NavDropdown } from "react-bootstrap"
import { useTranslation } from "react-i18next"

interface LanguageButtonProps {
    title: string
    language: string
}

function LanguageButton({ title, language }: LanguageButtonProps) {
    const { i18n } = useTranslation()

    const onClick = useCallback(() => {
        i18n.changeLanguage(language)
    }, [i18n, language])

    return <NavDropdown.Item onClick={onClick}>{title}</NavDropdown.Item>
}

export default LanguageButton
