import { getScheduleForDate } from "../services/Schedule"
import { getScheduleForShiftTemplate } from "../services/ShiftTemplate"
import ColorType from "../types/ColorType"
import LocalityType from "../types/LocalityType"
import LocationType from "../types/LocationType"
import ShiftTemplateType from "../types/ShiftTemplateType"
import ShiftType from "../types/ShiftType"
import { ValidationMessageType } from "../types/ValidationMessageType"
import { getCSSColor, getCSSColor2, getSelectedColor, getWhiteColor } from "./ColorHelper"
import { getDayId } from "./DaysHelper"
import { ScheduleMap, toScheduleMap, toScheduleMapFromMutations } from "./ScheduleMapHelper"

export type ScheduleErrorMessage = "shiftTemplate" | "holiday"

export interface LoadedSchedule {
    schedule: ScheduleMap
    scheduleMutations: ScheduleMap
    validationMessages: ValidationMessageType[]
    errorMessage?: ScheduleErrorMessage
}

export function loadScheduleForShiftTemplate(shiftTemplate: ShiftTemplateType): Promise<LoadedSchedule> {
    if (!shiftTemplate) {
        return Promise.resolve({ schedule: new Map(), scheduleMutations: new Map(), validationMessages: [] })
    }
    return new Promise((resolve, reject) => {
        getScheduleForShiftTemplate(shiftTemplate.id)
            .then((response) => {
                resolve({
                    schedule: toScheduleMap(response.data.shifts, response.data.localities, (i: ShiftType | LocalityType) => i.day.toString(), "UNKNOWN"),
                    scheduleMutations: new Map(),
                    validationMessages: response.data.messages,
                    errorMessage: response.data.errorMessage,
                })
            })
            .catch((error) => reject(error))
    })
}

export function loadScheduleForDate(location: LocationType, date: Date): Promise<LoadedSchedule> {
    return new Promise((resolve, reject) => {
        const dayId = getDayId(date)
        getScheduleForDate(location.id, date.getTime())
            .then((response) => {
                resolve({
                    schedule: toScheduleMap(response.data.shifts, response.data.localities, () => dayId, "UNKNOWN"),
                    scheduleMutations: toScheduleMapFromMutations(response.data.shiftMutations, response.data.localityMutations, () => dayId),
                    validationMessages: response.data.messages,
                    errorMessage: response.data.errorMessage,
                })
            })
            .catch((error) => reject(error))
    })
}

export const getShiftCellBackgroundColor = (defaultColor: ColorType | null, selected: boolean, isMuted: boolean) => {
    let color: ColorType
    if (selected) {
        color = getSelectedColor()
    } else if (defaultColor !== null) {
        color = defaultColor
        if (isMuted) {
            return getCSSColor2(color)
        }
    } else {
        color = getWhiteColor()
    }
    return getCSSColor(color)
}
