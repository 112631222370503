import { FC, useCallback, useContext } from "react"
import { ScheduleEditorContext } from "../../../../contexts/ScheduleEditorContext"
import { toScheduleMapFromGeneratedMutations } from "../../../../helpers/ScheduleMapHelper"
import { OccupationFixType } from "../../../../services/Task"
import styles from "./OccupationFix.module.scss"
import OccupationFixMutation from "./OccupationFixMutation"
import OccupationFixScore from "./OccupationFixScore"

interface Props {
    fix: OccupationFixType
    finishModal: () => void
}

const OccupationFix: FC<Props> = ({ fix, finishModal }) => {
    const { applyMutations } = useContext(ScheduleEditorContext)

    const apply = useCallback(() => {
        const scheduleMap = toScheduleMapFromGeneratedMutations(fix.mutations)
        applyMutations(scheduleMap)
        finishModal()
    }, [applyMutations, finishModal])

    return (
        <div className={styles.container} onClick={apply}>
            <small className="ms-3">Optie {fix.index + 1}</small>
            <div className="ms-2 d-inline-block">
                <OccupationFixScore score={fix.score} />
            </div>
            <table className={`${styles.table} mb-1`}>
                <tbody>
                    {fix.mutations.map((m, i) => (
                        <OccupationFixMutation key={i} mutation={m} />
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default OccupationFix
