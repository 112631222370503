import { createContext, FC, ReactNode, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"

interface TourContextProps {
    started: boolean
    setStarted: (started: boolean) => void
    index: number
    setIndex: (index: number) => void
    run: boolean
    setRun: (run: boolean) => void
}

const contextValue = {
    started: false,
    setStarted: () => {},
    index: 0,
    setIndex: () => {},
    run: false,
    setRun: () => {},
}

export const TourContext = createContext<TourContextProps>(contextValue)

interface Props {
    children: ReactNode
}

export const TourContextProvider: FC<Props> = ({ children }) => {
    const [started, setStarted] = useState<boolean>(false)
    const [index, setIndex] = useState<number>(0)
    const [run, setRun] = useState<boolean>(false)

    return <TourContext.Provider value={{ started, setStarted, index, setIndex, run, setRun }} children={children} />
}

export const TourContextConsumer = TourContext.Consumer

export const useTourContext = () => {
    return useContext(TourContext)
}

export const useStartTour = () => {
    const { setStarted, setIndex, setRun } = useContext(TourContext)
    const navigate = useNavigate()
    return () => {
        navigate("/dashboard")
        setStarted(true)
        setIndex(0)
        setRun(true)
    }
}

export const useContinueTour = () => {
    const { started, setIndex, setRun } = useContext(TourContext)
    return (index: number) => {
        if (started) {
            setIndex(index)
            setRun(true)
        }
    }
}
