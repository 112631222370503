import { FC } from "react"
import { useIsAdmin } from "../../contexts/UserSettingsContext"
import HireAvailabilitiesAdminPage from "./HireAvailabilitiesAdminPage"
import HireAvailabilitiesUserPage from "./HireAvailabilitiesUserPage"

const HireAvailabilitiesPage: FC = () => {
    const isAdmin = useIsAdmin()

    return isAdmin ? <HireAvailabilitiesAdminPage /> : <HireAvailabilitiesUserPage />
}

export default HireAvailabilitiesPage
