import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useMemo } from "react"
import { useUsersForSelectedLocation } from "../../../../contexts/UserSettingsContext"
import { getTime, prettyPrintTime } from "../../../../helpers/DaysHelper"
import useResolveRoleForSelectedLocation from "../../../../hooks/UseResolveRole"
import useResolveUserName from "../../../../hooks/UseResolveUserName"
import { OccupationFixMutationType } from "../../../../services/Task"
import RoleBadge from "../../../RoleBadge"
import styles from "./OccupationFixMutation.module.scss"

interface Props {
    mutation: OccupationFixMutationType
}

const OccupationFixMutation: FC<Props> = ({ mutation }) => {
    const users = useUsersForSelectedLocation()
    const resolveUserName = useResolveUserName(users)
    const resolveRole = useResolveRoleForSelectedLocation()

    const userName = useMemo(() => resolveUserName(mutation.user), [resolveUserName, mutation.user])
    const startTime = useMemo(() => prettyPrintTime(getTime("start", mutation.startSlot)), [mutation.startSlot])
    const endTime = useMemo(() => prettyPrintTime(getTime("end", mutation.endSlot)), [mutation.startSlot])
    const originalRole = useMemo(() => resolveRole(mutation.originalRole), [mutation.originalRole])
    const role = useMemo(() => resolveRole(mutation.role), [mutation.role])

    return (
        <tr>
            <td className="py-1 ps-3 pe-2">
                <div className={styles.userName}>
                    <small className="fw-bold">{userName}</small>
                </div>
            </td>
            <td>
                <small>{startTime}</small>
            </td>
            <td>
                <small>-</small>
            </td>
            <td>
                <small>{endTime}</small>
            </td>
            <td className="px-2">
                <div className={styles.rolesCell}>
                    <RoleBadge role={originalRole} />
                    <FontAwesomeIcon icon={faAngleRight} className="mx-2" />
                    <RoleBadge role={role} />
                </div>
            </td>
        </tr>
    )
}

export default OccupationFixMutation
