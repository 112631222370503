import { FC, useMemo } from "react"
import { useColors } from "../../contexts/UserSettingsContext"
import FormColorOption from "./FormColorOption"

interface Props {
    selectedColor: number | undefined
    setSelectedColor: (selectedColor: number) => void
}

const FormColorSelect: FC<Props> = ({ selectedColor, setSelectedColor }) => {
    const colors = useColors()

    const colorOptions = useMemo(() => Array.from(colors.values()), [colors])

    return (
        <div className="overflow-auto">
            <div className="btn-group d-flex color-option-container" role="group">
                {colorOptions.map((color) => (
                    <FormColorOption key={`${color.id}_${color.id === selectedColor}`} id={color.id} color={color} selected={color.id === selectedColor} setColor={setSelectedColor} />
                ))}
            </div>
        </div>
    )
}

export default FormColorSelect
