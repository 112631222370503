import { FC, ReactElement } from "react"
import { Navigate } from "react-router-dom"
import { useCompanyModules, useInitMode, useIsAuthenticated } from "../contexts/UserSettingsContext"
import { UserInitMode } from "../types/UserType"

interface PrivateElementProps {
    children: ReactElement<any, any>
    requiredModule?: string
}

const PrivateElement: FC<PrivateElementProps> = ({ children, requiredModule }) => {
    const isAuthenticated = useIsAuthenticated()
    const companyModules = useCompanyModules()
    const initMode = useInitMode()

    if (!isAuthenticated) {
        return <Navigate to="/inloggen" />
    } else if (initMode < UserInitMode.STARTED_TOUR) {
        return <Navigate to="/omgeving-initialiseren" />
    } else if (requiredModule && !companyModules.includes(requiredModule)) {
        return <Navigate to="/dashboard" />
    }
    return children
}

export default PrivateElement
