import { FC } from "react"
import { Link } from "react-router-dom"
import { dateFromDjango, isoPrint } from "../../../helpers/DaysHelper"

interface DateRangeCellProps {
    from: string
    to: string
    link: string
}

const DateRangeCell: FC<DateRangeCellProps> = ({ from, to, link }) => {
    return (
        <span>
            <Link to={link}>{isoPrint(dateFromDjango(from)) + (to !== from ? ` t/m ${isoPrint(dateFromDjango(to))}` : "")}</Link>
        </span>
    )
}

export default DateRangeCell
