import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback } from "react"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useTabs } from "../../contexts/TabsContext"
import styles from "./MenuItem.module.scss"

interface MenuItemProps {
    dataCy: string
    title: string
    path: string
    active: boolean
    icon: IconProp
}

const MenuItem: FC<MenuItemProps> = ({ dataCy, title, path, active, icon }) => {
    const { setMenuOpen } = useTabs()
    const navigate = useNavigate()

    const openLink = useCallback(() => {
        setMenuOpen(false)
        navigate(path)
    }, [setMenuOpen, navigate, path])

    return (
        <li className={styles.row}>
            <Button id={dataCy} variant="menu" active={active} className={styles.item} data-cy={dataCy} onClick={openLink}>
                <FontAwesomeIcon icon={icon} className={`${styles.icon} me-2`} />
                {title}
            </Button>
        </li>
    )
}

export default MenuItem
