import { withParams } from "../helpers/FilterHelper"
import PageRequestType from "../types/PageRequestType"
import PageResponseType from "../types/PageResponseType"
import RoleGroupType from "../types/RoleGroupType"
import RoleType, { CreateRoleType, UpdateRoleType } from "../types/RoleType"
import api from "./Api"

export const loadAllRoles = (): Promise<RoleType[]> => {
    return new Promise((resolve, reject) => {
        getRoles({ pageIndex: 0, pageSize: 999 })
            .then((response) => resolve(response.data.items))
            .catch(reject)
    })
}

export function loadRoles(request: PageRequestType): Promise<PageResponseType<RoleType>> {
    return new Promise<PageResponseType<RoleType>>((resolve, reject) =>
        getRoles(request)
            .then((response) => resolve(response.data))
            .catch(reject)
    )
}

export function getRoles(pageDetails: PageRequestType) {
    return api.get<PageResponseType<RoleType>>(withParams("/roles/", pageDetails))
}

export function getNestedRoles(location: number) {
    return api.get<{ roles: RoleType[]; roleGroups: RoleGroupType[] }>(`/roles-and-groups/?location=${location}`)
}

export function getRole(id: number) {
    return api.get(`/roles/${id}/`)
}

export function loadRole(id: number) {
    return () =>
        new Promise<RoleType>((resolve) => {
            getRole(id).then((response) => resolve(response.data))
        })
}

export function createRole(role: CreateRoleType) {
    return api.post("/roles/", role)
}

export function updateRole(role: UpdateRoleType) {
    return api.patch(`/roles/${role.id}/`, role)
}

export function removeRole(id: number) {
    return api.delete(`/roles/${id}/`)
}
