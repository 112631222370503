export interface Cell {
    dayId: string
    roleUid: string
    slot: number
}

export interface CellSelection {
    from: Cell | undefined
    to: Cell | undefined
}

export interface OccupationEditorCellDetails {
    value: (number | null)[]
    selected: boolean
    empty: boolean
    isFirstOfRange: boolean
    disabled: boolean
}

export const isSelected = (cellSelection: CellSelection, roleUid: string, cell: number): boolean => {
    const roleItemMatches = !!cellSelection.from && cellSelection.from!.roleUid === roleUid
    const cellAfterStart = !!cellSelection.from && cellSelection.from!.slot <= cell
    const cellBeforeEnd = !!cellSelection.to && cellSelection.to!.slot >= cell
    const cellIsStartWithoutEnd = !cellSelection.to && !!cellSelection.from && cellSelection.from!.slot === cell
    return roleItemMatches && cellAfterStart && (cellIsStartWithoutEnd || cellBeforeEnd)
}
