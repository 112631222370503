import { ChangeEvent, FC, useCallback } from "react"
import { Form } from "react-bootstrap"

interface Props {
    setNumberOfWeeks: (numberOfWeeks: number) => void
}

const NumberOfWeeksSelector: FC<Props> = ({ setNumberOfWeeks }) => {
    const onChangeNumberOfWeeks = useCallback(
        (event: ChangeEvent<HTMLSelectElement>) => {
            setNumberOfWeeks(parseInt(event.target.value))
        },
        [setNumberOfWeeks]
    )

    return (
        <Form.Select className="form-select-inline" onChange={onChangeNumberOfWeeks} data-cy="weekSelect">
            <option value={1}>1 week</option>
            <option value={2}>2 weken</option>
            <option value={4}>4 weken</option>
        </Form.Select>
    )
}

export default NumberOfWeeksSelector
