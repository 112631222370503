import { withParams } from "../helpers/FilterHelper"
import { toObject } from "../helpers/MapHelper"
import { ScheduleMap } from "../helpers/ScheduleMapHelper"
import LocalityType from "../types/LocalityType"
import PageRequestType from "../types/PageRequestType"
import PageResponseType from "../types/PageResponseType"
import { ScheduleErrorMessageType } from "../types/ScheduleErrorMessageType"
import ShiftTemplateType, { CreateShiftTemplateType, PublishShiftTemplateType, UpdateShiftTemplateType } from "../types/ShiftTemplateType"
import ShiftType from "../types/ShiftType"
import { ValidationMessageType } from "../types/ValidationMessageType"
import api from "./Api"
import { TaskShiftsResult, TaskType } from "./Task"

export function loadShiftTemplatesForLocation(location: number) {
    const pageDetails = { pageIndex: 0, pageSize: 999, filters: [{ key: "location", value: location.toString() }] }
    return () =>
        new Promise<ShiftTemplateType[]>((resolve, reject) => {
            getShiftTemplates(pageDetails)
                .then((response) => resolve(response.data.items))
                .catch(reject)
        })
}

export function loadShiftTemplates(request: PageRequestType): Promise<PageResponseType<ShiftTemplateType>> {
    return new Promise<PageResponseType<ShiftTemplateType>>((resolve, reject) =>
        getShiftTemplates(request)
            .then((response) => resolve(response.data))
            .catch(reject)
    )
}

export function getShiftTemplates(pageDetails: PageRequestType) {
    return api.get<{ items: ShiftTemplateType[]; pageCount: number }>(withParams("/shift-templates/", pageDetails))
}

export function getShiftTemplate(id: number) {
    return api.get<ShiftTemplateType>(`/shift-templates/${id}/`)
}

export function loadShiftTemplate(id: number) {
    return () =>
        new Promise<ShiftTemplateType>((resolve) => {
            getShiftTemplate(id).then((response) => resolve(response.data))
        })
}

export interface CreatedShiftTemplateResponse {
    template: ShiftTemplateType
    task: TaskType<TaskShiftsResult>
}

export function createShiftTemplate(shiftTemplate: CreateShiftTemplateType) {
    return api.post<CreatedShiftTemplateResponse>("/shift-templates/", shiftTemplate)
}

export function updateShiftTemplate(shiftTemplate: UpdateShiftTemplateType) {
    return api.patch<ShiftTemplateType>(`/shift-templates/${shiftTemplate.id}/`, shiftTemplate)
}

export function publishShiftTemplate(shiftTemplate: PublishShiftTemplateType) {
    return api.patch<ShiftTemplateType>(`/shift-templates/${shiftTemplate.id}/`, shiftTemplate)
}

export function deleteShiftTemplate(id: number) {
    return api.delete(`/shift-templates/${id}/`)
}

export function getScheduleForShiftTemplate(shiftTemplate: number) {
    return api.get<{ shifts: ShiftType[]; localities: LocalityType[]; messages: ValidationMessageType[]; errorMessage?: ScheduleErrorMessageType }>(
        `/shift-templates/shifts-and-localities/${shiftTemplate}/`
    )
}

export function storeShiftsAndLocalities(template: number, shiftsAndLocalities: ScheduleMap) {
    return api.post(`/shift-templates/shifts-and-localities/${template}/`, toObject(shiftsAndLocalities))
}
