import { FC, MouseEvent, useCallback, useState } from "react"
import RoleType from "../../types/RoleType"
import RoleSelect from "../RoleSelect"

interface ShiftEditorControlsProps {
    roles: RoleType[]
    clearSelection: () => void
    setShift: (value: number | null) => void
}

const ShiftEditorControls: FC<ShiftEditorControlsProps> = ({ roles, clearSelection, setShift }) => {
    const [selectedRoleId, setSelectedRoleId] = useState<number>(0)

    const apply = useCallback(
        (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
            event.preventDefault()
            setShift(selectedRoleId === 0 ? null : selectedRoleId)
        },
        [selectedRoleId, setShift]
    )

    const clear = useCallback(
        (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
            event.preventDefault()
            clearSelection()
        },
        [clearSelection]
    )

    return (
        <div className="d-flex mt-3">
            <div className="form-group mb-3">
                <RoleSelect roles={roles} selectedRoleId={selectedRoleId} setSelectedRoleId={setSelectedRoleId} />
            </div>
            <div className="form-group">
                <button data-cy="apply" onClick={apply} className="btn btn-primary">
                    Pas toe
                </button>
                <button onClick={clear} className="btn btn-link">
                    Annuleer
                </button>
            </div>
        </div>
    )
}

export default ShiftEditorControls
