import { getAllOccupations, getApplicableOccupations } from "../services/Occupation"
import ColorType from "../types/ColorType"
import LocationType from "../types/LocationType"
import OccupationSourceType from "../types/OccupationSourceType"
import OccupationTemplateType from "../types/OccupationTemplateType"
import OccupationType, { SourcedDateOccupationType, SourcedDayOccupationType, StaticOccupationType } from "../types/OccupationType"
import ShiftTemplateType from "../types/ShiftTemplateType"
import UnexusRouterOccupationType from "../types/UnexusRouterOccupationType"
import { getCSSColor3, getDisabledRedColor, getRedColor, getSelectedColor, getWhiteColor } from "./ColorHelper"
import { getDayId } from "./DaysHelper"
import { ensureListForKey, ensureMapForKey } from "./MapHelper"
import { OccupationScheduleMap, toOccupationScheduleMap } from "./OccupationMapHelper"
import { constructNestedRoleMap, NestedRoleItem } from "./RolesHelper"
import { ScheduleItem } from "./ScheduleMapHelper"

export interface LoadedOccupationSchedule {
    schedule: OccupationScheduleMap
    scheduleMutations: OccupationScheduleMap
    occupationSources: OccupationSourceType[]
    errorMessage?: string
}

export function loadOccupationScheduleForOccupationTemplate(occupationTemplate: OccupationTemplateType): Promise<LoadedOccupationSchedule> {
    if (!occupationTemplate) {
        return Promise.resolve({ schedule: new Map(), scheduleMutations: new Map(), occupationSources: [] })
    }
    return new Promise((resolve, reject) => {
        getAllOccupations(occupationTemplate.id)
            .then((response) => {
                resolve({
                    schedule: toOccupationScheduleMap(response.data.occupations, response.data.sourcedOccupations),
                    scheduleMutations: new Map(),
                    occupationSources: response.data.occupationSources,
                })
            })
            .catch(reject)
    })
}

export type OccupationMap = Map<"roles" | "roleGroups", Map<string, StaticOccupationType[]>>

export type OccupationDayMap = Map<string, OccupationMap>

export interface OccupationDetails {
    weekCycle: number
    map: OccupationDayMap
}

export function getOccupationDetails(
    occupations: OccupationType[],
    sourcedDayOccupations: SourcedDayOccupationType[],
    sourcedDateOccupations: SourcedDateOccupationType[],
    weekCycle: number,
    generateDayId: (occupation: OccupationType | SourcedDayOccupationType | SourcedDateOccupationType) => string
): OccupationDetails {
    let map: OccupationDayMap = new Map()
    for (let occupation of [...occupations, ...sourcedDayOccupations, ...sourcedDateOccupations]) {
        const dayId = generateDayId(occupation)
        ensureMapForKey(map, dayId)
        const dayMap = map.get(dayId)!
        if (occupation.role) {
            ensureMapForKey(dayMap, "roles")
            const rolesMap = dayMap.get("roles")!
            const roleId = occupation.role.toString()
            ensureListForKey(rolesMap, roleId)
            rolesMap.get(roleId)!.push(occupation)
        } else {
            ensureMapForKey(dayMap, "roleGroups")
            const roleGroupsMap = dayMap.get("roleGroups")!
            const roleGroupId = occupation.roleGroup!.toString()
            ensureListForKey(roleGroupsMap, roleGroupId)
            roleGroupsMap.get(roleGroupId)!.push(occupation)
        }
    }

    return {
        weekCycle,
        map,
    }
}

export function getClickedCell(id: string) {
    const idRegex = /(\d+)#([r,g]\d+)#(\d+)/g
    const match = idRegex.exec(id)!

    return {
        dayId: match[1],
        roleUid: match[2],
        slot: parseInt(match[3]),
    }
}

export function isEnabled(item: NestedRoleItem, enabledRoles: number[]) {
    if (item.type === "role" && enabledRoles.indexOf(item.id) !== -1 && !item.isAbsence) {
        return true
    } else if (item.type === "roleGroup") {
        for (const subRole of item.subRoles!) {
            if (enabledRoles.includes(subRole.id)) {
                return true
            }
        }
    }
    return false
}

export function loadOccupationsForShiftTemplate(shiftTemplate: ShiftTemplateType): Promise<OccupationDetails> {
    return new Promise((resolve, reject) => {
        getAllOccupations(shiftTemplate.occupationTemplate)
            .then((response) => resolve(getOccupationDetails(response.data.occupations, response.data.sourcedOccupations, [], response.data.weekCycle, getOccupationDayId)))
            .catch((error) => reject(error))
    })
}

export function loadOccupationsForSchedule(location: LocationType, date: Date): Promise<OccupationDetails> {
    return new Promise((resolve, reject) => {
        getApplicableOccupations(location.id, date.getTime())
            .then((response) => resolve(getOccupationDetails(response.data.occupations, [], response.data.sourcedOccupations, response.data.weekCycle, () => getDayId(date))))
            .catch((error) => reject(error))
    })
}

export function getOccupationDayId(occupation: OccupationType | SourcedDayOccupationType | SourcedDateOccupationType): string {
    if (Object.hasOwn(occupation, "day")) {
        const { day } = occupation as { day: number }
        return day.toString()
    }
    return ""
}

export const getCallCounts = (startSlot: number, endSlot: number, occupations: UnexusRouterOccupationType[], numberOfDates: number) => {
    const values = []
    for (let slot = startSlot; slot <= endSlot; slot += 4) {
        const relevantOccupations = occupations.filter((o) => slot >= o.startSlot && slot <= o.endSlot)
        const totalCalls = relevantOccupations.reduce((total, o) => (total += o.callCount), 0)
        const totalDays = relevantOccupations.filter((o) => o.callCount > 0).length
        const value = totalCalls / Math.max(totalDays, 1)
        values.push(Math.round(value * 10) / 10)
    }
    return values
}

export const getOccupationCounts = (startSlot: number, endSlot: number, occupations: SourcedDayOccupationType[]) => {
    const values = []
    for (let slot = startSlot; slot <= endSlot; slot += 4) {
        values.push(occupations.filter((o) => slot >= o.startSlot && slot <= o.endSlot).reduce((total, o) => (total += o.min !== null ? o.min : 0), 0))
    }
    return values
}

const getInitialOccupationDataMap = (nestedRoles: NestedRoleItem[], enabledRoles: number[], startSlot: number, endSlot: number) => {
    const dataMap: Map<string, (number | null)[][]> = new Map()

    for (const roleItem of nestedRoles) {
        if (!isEnabled(roleItem, enabledRoles)) {
            continue
        }
        dataMap.set(roleItem.uid, [])
        for (let j = 0; j <= endSlot - startSlot; j++) {
            dataMap.get(roleItem.uid)!.push([null, null, 0])
        }
        if (roleItem.subRoles) {
            for (const subItem of roleItem.subRoles) {
                if (!isEnabled(subItem, enabledRoles)) {
                    continue
                }
                dataMap.set(subItem.uid, [])
                for (let j = 0; j <= endSlot - startSlot; j++) {
                    dataMap.get(subItem.uid)!.push([null, null, 0])
                }
            }
        }
    }

    return dataMap
}

export const getOccupationData = (nestedRoles: NestedRoleItem[], enabledRoles: number[], startSlot: number, endSlot: number, daySchedule: Map<string, ScheduleItem>, occupations: OccupationMap) => {
    const occupationData = getInitialOccupationDataMap(nestedRoles, enabledRoles, startSlot, endSlot)

    const nestedRolesMap = constructNestedRoleMap(nestedRoles)

    daySchedule.forEach((scheduleItem) => {
        scheduleItem.shifts.forEach((userShift) => {
            const roleId = userShift.role
            const roleUid = "r" + roleId
            const role = nestedRolesMap.get(roleUid)
            if (!role) {
                return
            }
            if (!isEnabled(role, enabledRoles)) {
                return
            }
            for (let slot = userShift.startSlot; slot <= userShift.endSlot; slot++) {
                const slotIndex = slot - startSlot
                occupationData.get(roleUid)![slotIndex]![2]! += 1
                if (role.parent) {
                    occupationData.get(role.parent)![slotIndex]![2]! += 1
                }
            }
        })
    })

    occupations.forEach((occupationsMap, typeId) => {
        const prefix = typeId === "roles" ? "r" : "g"
        occupationsMap.forEach((occupations, id) => {
            const values = occupationData.get(`${prefix}${id}`)
            if (!values) {
                return
            }
            for (const occupation of occupations) {
                for (let slot = occupation.startSlot; slot <= occupation.endSlot; slot++) {
                    if (slot >= startSlot && slot <= endSlot) {
                        values[slot - startSlot][0] = occupation.min
                        values[slot - startSlot][1] = occupation.max
                    }
                }
            }
        })
    })

    return occupationData
}

const hasInvalidOccupancy = (slotIndex: number, occupationData: Map<string, (number | null)[][]>) => {
    for (const roleValues of occupationData.values()) {
        const values = roleValues[slotIndex]
        const min = values[0]
        const max = values[1]
        const actual = values[2]!
        if ((min !== null && actual < min) || (max !== null && actual > max)) {
            return true
        }
    }
    return false
}

export const getOccupationIndicators = (startSlot: number, endSlot: number, occupationData: Map<string, (number | null)[][]>) => {
    const occupationIndicators: number[] = []

    for (let slot = startSlot; slot <= endSlot; slot++) {
        const value = hasInvalidOccupancy(slot - startSlot, occupationData) ? 1 : 0
        occupationIndicators.push(value)
    }

    return occupationIndicators
}

export const getCountForTotal = (item: NestedRoleItem, enabledRoles: number[], occupationsPerRole: Map<string, (number | null)[][]>, selectedSlotIndex: number) => {
    if (item.type === "roleGroup") {
        return 0
    }
    if (!isEnabled(item, enabledRoles)) {
        return 0
    }
    return occupationsPerRole.get(item.uid)?.at(selectedSlotIndex)?.at(2) ?? 0
}

export const getOccupationCellBackgroundColor = (value: (number | null)[], selected: boolean, empty: boolean, disabled: boolean) => {
    let usedValue: number = 0
    if (value[0] === null && value[1] !== null) {
        usedValue = Math.max(0, value[1] - 1)
    } else if (value[0] !== null && value[1] === null) {
        usedValue = value[0] + 1
    } else if (value[0] !== null && value[1] !== null) {
        usedValue = (value[0] + value[1]) / 2
    }

    let color: ColorType
    let opacity = 1
    if (selected) {
        color = getSelectedColor()
    } else if (disabled) {
        color = getDisabledRedColor(usedValue / 10)
    } else if (empty) {
        color = getWhiteColor()
    } else {
        color = getRedColor()
        opacity = 0.1 + usedValue / 10
    }

    return getCSSColor3(color, opacity)
}
