import { FC } from "react"
import { addDays, getShortDayName, isEnabledDate } from "../../helpers/DaysHelper"

interface Props {
    enabledDays: string
    selectedDate: Date
    setSelectedDate: (date: Date) => void
}

const DateSelectorDays: FC<Props> = ({ enabledDays, selectedDate, setSelectedDate }) => {
    const currentDay = (selectedDate.getDay() + 6) % 7
    const monday = addDays(selectedDate, -currentDay)

    const components = []
    for (let i = 0; i < 7; i++) {
        const currentDate = addDays(monday, i)
        if (!isEnabledDate(enabledDays, currentDate)) {
            continue
        }
        let classNames = "btn btn-selectable"
        if (currentDate.getTime() === selectedDate.getTime()) {
            classNames += " active"
        }

        components.push(
            <button key={i} className={classNames} onClick={() => setSelectedDate(currentDate)}>
                {getShortDayName(i)}
            </button>
        )
    }

    return <div>{components}</div>
}

export default DateSelectorDays
