import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMemo } from "react"
import { Badge } from "react-bootstrap"
import { useScheduleEditorState } from "../../contexts/ScheduleEditorContext"

const ShiftTemplatePublishMessage = () => {
    const editorState = useScheduleEditorState()

    const published = useMemo(() => {
        return editorState.shiftTemplate?.isPublished
    }, [editorState.shiftTemplate])

    if (published !== false) {
        return null
    }

    return (
        <div data-cy="publishMessage">
            <Badge bg="info-light" text="dark" className="mb-2">
                <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                Dit basisrooster is nog niet gepubliceerd. Pas na publicatie wordt dit rooster zichtbaar in de roosterpagina en in het dashboard.
            </Badge>
        </div>
    )
}

export default ShiftTemplatePublishMessage
