import { FC, useCallback } from "react"
import { Button } from "react-bootstrap"
import { ButtonVariant } from "react-bootstrap/esm/types"
import { useNavigate } from "react-router-dom"

interface Props {
    name: string
    link?: string
    onClick?: () => void
    variant?: ButtonVariant
    dataCy?: string
}

const TableButton: FC<Props> = ({ name, link, onClick, variant, dataCy }) => {
    const navigate = useNavigate()

    const handleClick = useCallback(() => {
        if (link) {
            navigate(link)
        } else if (onClick) {
            onClick()
        }
    }, [link, navigate, onClick])

    return (
        <Button type="button" onClick={handleClick} disabled={!link && !onClick} variant={variant} data-cy={dataCy}>
            {name}
        </Button>
    )
}

export default TableButton
