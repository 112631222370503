import { FC } from "react"
import { useQuery } from "react-query"
import IconCell from "../../components/table/cells/IconCell"
import { getHandleStatusIcon, getReviewStatusIcon, loadAbsenceRequests } from "../../helpers/AbsenceRequestHelper"
import { getBorderColor, getCSSColor3 } from "../../helpers/ColorHelper"
import { dateFromDjango, isoPrint } from "../../helpers/DaysHelper"

interface AbsenceRequestViewProps {
    startOfToday: Date
    userId: number
}

const AbsenceRequestsView: FC<AbsenceRequestViewProps> = ({ startOfToday, userId }) => {
    const { data, isLoading, isError } = useQuery(["absenceRequests", startOfToday, userId], () => loadAbsenceRequests(startOfToday, userId))

    if (isLoading || isError) {
        return null
    }

    if (data?.length === 0) {
        return (
            <tr>
                <td className="pt-2">
                    <span className="role-badge text-bold" style={{ backgroundColor: getCSSColor3(getBorderColor(), 0.2) }}>
                        Geen
                    </span>
                </td>
            </tr>
        )
    }

    return (
        <>
            {data?.map((ar) => (
                <tr data-cy="absenceRequest" key={ar.id}>
                    <td data-cy="startDate" className="text-small">
                        {isoPrint(dateFromDjango(ar.startDate))}
                    </td>
                    <td data-cy="endDate" className="text-small">
                        {isoPrint(dateFromDjango(ar.endDate))}
                    </td>
                    <td>
                        <IconCell iconProps={getReviewStatusIcon(ar.reviewStatus)} />
                    </td>
                    <td>
                        <IconCell iconProps={getHandleStatusIcon(ar.handleStatus)} />
                    </td>
                </tr>
            ))}
        </>
    )
}

export default AbsenceRequestsView
